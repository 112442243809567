<template>
  <BaselineLayout>
    <v-container slot="main">
      <v-row>
        <v-col>
          <h1>Red Eléctrica de España: Dashboard</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-dialog
            ref="dialogStartDate"
            v-model="modal"
            :return-value.sync="queryParams.start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="queryParams.start_date"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="queryParams.start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogStartDate.save(queryParams.start_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="3">
          <v-dialog
            ref="dialogEndDate"
            v-model="modalEndDate"
            :return-value.sync="queryParams.end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="queryParams.end_date"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="queryParams.end_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalEndDate = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogEndDate.save(queryParams.end_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <Combobox
            v-model="queryParams.time_trunc"
            :items="timeTrunc"
            :rules="rules"
            label="Time Interval"
          />
        </v-col>
        <v-col>
          <v-btn class="m-0" @click="getData">Update</v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-card>
            <v-card-title>Balance</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <BalanceChart />
                </v-row>
                <v-row>
                  <GenerationChart />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Supply</v-card-title>
            <v-card-text>Generation</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Demand</v-card-title>
            <v-card-text>Demand</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9" align="center">
          <v-card>
            <v-card-title>Transporte</v-card-title>
            <v-card-text>Transporte</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Market</v-card-title>
            <!-- <v-card-text><PricingChart /></v-card-text> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <PricingChart />
                </v-row>
                <v-row>
                  <MonthlyPriceComponentsChart />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row> </v-row>
    </v-container>
  </BaselineLayout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import Combobox from "@/components/base/Combobox.vue";
import BalanceChart from "@/components/databank/red-electrica/charts/BalanceChart.vue";
import GenerationChart from "@/components/databank/red-electrica/charts/GenerationChart.vue";
import PricingChart from "@/components/databank/red-electrica/charts/PricingChart.vue";
import MonthlyPriceComponentsChart from "@/components/databank/red-electrica/charts/MonthlyPriceComponentsChart.vue";
// import PriceComponentsChart from "@/components/databank/red-electrica/charts/PriceComponentsChart.vue";

let storeModule = "redElectrica";

export default {
  components: {
    BaselineLayout,
    Combobox,
    BalanceChart,
    GenerationChart,
    MonthlyPriceComponentsChart,
    PricingChart,
  }, //
  data() {
    return {
      modal: false,
      modalEndDate: false,
      queryParams: {
        lang: "en",
        category: "balance",
        widget: "balance-electrico",
        start_date: "2021-01-01",
        end_date: new Date().toISOString().slice(0, 10),
        time_trunc: "month",
        geo_trunc: null,
        geo_limit: null,
        geo_ids: null,
      },

      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    getWidgets() {
      this.$store.dispatch(
        `${storeModule}/getWidgets`,
        this.queryParams.category
      );
    },
    getData() {
      this.$store.dispatch(`${storeModule}/getData`, this.queryParams);
    },
  },
  mounted() {},
  computed: {
    languages() {
      return this.$store.getters[`${storeModule}/languages`];
    },
    timeTrunc() {
      return this.$store.getters[`${storeModule}/timeTrunc`];
    },
  },
};
</script>

<style></style>
