var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.searchResults.geo)?_c('v-form',[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Address","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.google_data.address
              : null}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Longitude","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.google_data.lng
              : null}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Latitude","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.google_data.lat
              : null}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"CCAA","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.ccaa_name
              : null}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Province","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.provincia_name
              : null}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Municipio","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.municipio_name
              : null}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Distrito","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.distrito
              : null}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Seccion Censal","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.seccion
              : null}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }