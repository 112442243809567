<template>
  <v-container>
    <v-divider></v-divider>

    <!-- EMPLOYEE TABLE -->
    <v-row justify="center">
      <v-col cols="10">
        <v-data-table dense :items="employees" :headers="headers">
          <template v-slot:top>
            <v-row justify="center">
              <v-col cols="10">
                <h2 class="marco--text">Employee CVs</h2>
              </v-col>
              <v-col cols="2">
                <v-btn small color="success" @click="createNewItem()"
                  >New</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template #item.cv="{item}">
            <v-icon color="green" @click="getEmployeeInfo(item)"
              >mdi-eye-outline</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- CV DIALOG -->
    <v-dialog
      v-model="dialog.view"
      persistent
      max-width="60%"
      v-if="employeeCV && employee"
    >
      <v-card>
        <v-toolbar color="marco">
          <v-toolbar-title class="white--text"
            >Curriculum Vitae
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            v-if="dialog.readonly"
            color="white"
            @click="dialog.readonly = !dialog.readonly"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="!dialog.readonly" color="white" @click="postCurriculum"
            >mdi-content-save</v-icon
          >
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <h2 class="marco--text">
                    Profile
                  </h2>
                  <v-divider></v-divider>
                  <v-row justify="center">
                    <v-col align="center">
                      <v-img
                        :src="employee.photo"
                        alt="No Photo Available"
                        width="150"
                        height="150"
                      ></v-img>
                      <br />
                      <h2 class="marco--text">
                        {{ employee.first_name }} {{ employee.last_name }}
                      </h2>
                      <br />
                      <h3 class="marco--text">
                        {{ employee.category.company.legal_name }}
                      </h3>
                      <br />
                      <h3 class="marco--text">
                        {{ employee.los.long_name }} -
                        {{ employee.category.name }}
                      </h3>
                    </v-col>
                    <v-col>
                      <h3 class="marco--text">Skillset</h3>
                      <v-divider></v-divider>
                      <v-textarea
                        outlined
                        :readonly="dialog.readonly"
                        :filled="!dialog.readonly"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <h2 class="marco--text">
                    Credentials
                  </h2>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <h3 class="marco--text">Experience</h3>
                      <v-divider></v-divider>
                      <v-textarea
                        outlined
                        :readonly="dialog.readonly"
                        :filled="!dialog.readonly"
                        v-model="employeeCV.experience"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <h3 class="marco--text">Education</h3>
                      <v-divider></v-divider>
                      <v-textarea
                        outlined
                        :readonly="dialog.readonly"
                        :filled="!dialog.readonly"
                        v-model="employeeCV.education"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="6">
                      <h3 class="marco--text">Clients</h3>
                      <v-divider></v-divider>
                      <v-textarea
                        outlined
                        :readonly="dialog.readonly"
                        :filled="!dialog.readonly"
                        v-model="employeeCV.clients"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" v-on:click="dialog.view = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="dialog.readonly"
            text
            color="marco"
            v-on:click="dialog.readonly = false"
            >Edit</v-btn
          >

          <v-btn
            v-if="employeeCV.id"
            text
            color="warning"
            v-on:click="postCurriculum()"
            >Update</v-btn
          >
          <v-btn
            v-if="!employeeCV.id"
            text
            color="success"
            v-on:click="postCurriculum()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CompanyAPI from "@/api/company";
export default {
  data() {
    return {
      dialog: { view: false, readonly: true },
      employee: null,
      employeeCV: null,

      selectedCV: null,
      employeePhoto: null,

      showTableColumns: [
        "id",
        "company",
        "category_name",
        "last_name",
        "first_name",
        "email",
        "CV",
      ],
      headers: [
        { text: "ID", value: "id" },
        { text: "Company", value: "company" },
        { text: "Category", value: "category_name" },
        { text: "Last Name", value: "last_name" },
        { text: "First Name", value: "first_name" },
        { text: "Email", value: "email" },
        { text: "CV", value: "cv" },
      ],
    };
  },
  methods: {
    postCurriculum: function() {
      let form = new FormData();
      form.append("id", this.employeeCV.id);
      form.append("language", this.employeeCV.language);
      form.append("education", this.employeeCV.education);
      form.append("experience", this.employeeCV.experience);
      form.append("clients", this.employeeCV.clients);
      form.append("other", this.employeeCV.other);
      form.append("employee", JSON.parse(this.employeeCV.employee));

      //   console.log(this.employeeCV);

      this.$store.dispatch("company/postCurriculum", form);
      this.dialog.readonly = true;
    },
    deleteObject(item) {
      this.$store.dispatch("company/deleteCurriculum", item.id);
    },
    createNewItem: function() {
      this.clearItem();
      this.dialog.view = true;
    },
    async getEmployeeInfo(item) {
      CompanyAPI.getEmployee(item.id).then((response) => {
        this.employee = response.data;
      });
      CompanyAPI.employeeCV(item.id).then((response) => {
        this.employeeCV = response.data;
      });
      this.dialog.view = true;
    },
    clearItem: function() {
      this.selectedCV = null;
      this.employeeCV = null;
    },
  },
  computed: {
    lang() {
      return this.$store.getters["lang"];
    },
    employees() {
      return this.$store.state.company.employees;
    },
    curriculums() {
      return this.$store.state.company.curriculums;
    },
  },
  mounted: function() {
    this.$store.dispatch("company/getCurriculums");
  },
};
</script>

<style scoped></style>
