import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import catastroModule from "./modules/catastro";
import realEstateModule from "./modules/real-estate";
import realEstatePortfolios from "./modules/real-estate-portfolio-workbench";
import realEstatePortfolioDashboard from "./modules/real-estate-portfolio-dashboard";
import redElectrica from "./modules/red-electrica";
import companyModule from "./modules/company-setup";
import clientsModule from "./modules/clients";
import newsModule from "./modules/news";
import dealsModule from "./modules/deals";
import databankModule from "./modules/databank";
import authModule from "./modules/auth";
import mainModule from "./modules/main";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main: mainModule,
    auth: authModule,
    catastro: catastroModule,
    company: companyModule,
    clients: clientsModule,
    deals: dealsModule,
    news: newsModule,
    realEstate: realEstateModule,
    realEstatePortfolios: realEstatePortfolios,
    realEstatePortfolioDashboard: realEstatePortfolioDashboard,
    redElectrica: redElectrica,
    databank: databankModule,
  },

  // State is the centralized Data from
  state: {
    // Global Functionality
    lang: "en",
    isBusy: false,
    drawer: false,
    drawer2: true,
    snackbars: [],

    // Company Setup
    companyName: "MGX Advisors",
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    loggedIn(state) {
      return state.token != null;
    },
    snackbars(state) {
      return state.snackbars;
    },
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    toggleBusy(state) {
      state.isBusy = !state.isBusy;
    },
    setSnackbar(state, snackbar) {
      state.snackbars = state.snackbars.concat(snackbar);
    },
    SET_LANGUAGE(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    toggleBusy(context) {
      context.commit("toggleBusy");
    },
    axiosRequest(context, request) {
      axios(request.details, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          let snackbar = {
            showing: true,
            text: `${request.success}: ${response}`,
            color: "success",
          };
          context.dispatch("setSnackbar", snackbar, { root: true });
        })
        .catch((error) => {
          let snackbar = {
            showing: true,
            // text: `${request.error}: ${error}`,
            text: `${error}`,
            color: "error",
          };
          context.dispatch("setSnackbar", snackbar, { root: true });
        });
    },
    followUrl(url) {
      window.open(url, "_blank");
    },
    toggleDrawer(context) {
      context.commit("toggleDrawer");
    },
    setSnackbar(context, snackbar) {
      context.commit("setSnackbar", snackbar);
    },
    snackbarError(context, message) {
      let snackbar = {
        text: message,
        showing: true,
        color: "error",
      };
      context.commit("setSnackbar", snackbar);
    },
    snackbarInfo(context, message) {
      let snackbar = {
        text: message,
        showing: true,
        color: "info",
      };
      context.commit("setSnackbar", snackbar);
    },
    snackbarSuccess(context, message) {
      let snackbar = {
        text: message,
        showing: true,
        color: "success",
      };
      context.commit("setSnackbar", snackbar);
    },
    setLanguage(context, lang) {
      context.commit("SET_LANGUAGE", lang);
    },
  },
  plugins: [createPersistedState()],
});
