<template>
  <v-btn v-bind="$attrs" v-on="$listeners" class="ma-3"
    ><v-icon class="mr-3">{{ icon }}</v-icon
    >{{ text }}</v-btn
  >
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
