<template>
  <v-navigation-drawer
    absolute
    clipped
    color="secondary"
    overlay-color="success"
    expand-on-hover
    hide-overlay
    mini-variant
    :value="drawer2"
  >
    <v-list dense>
      <v-list-item
        link
        v-for="item in sidebarItems"
        :key="item.title"
        router
        :to="item.link"
      >
        <v-list-item-action
          ><v-icon color="white">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      sidebarItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          link: { name: "deals" },
        },
        {
          title: "Search Deals",
          icon: "mdi-magnify",
          link: { name: "deals-search" },
        },
        {
          title: "Add Deal",
          icon: "mdi-new-box",
          link: { name: "deals-create" },
        },
        // {
        //   title: "Edit",
        //   icon: "mdi-account-edit",
        //   link: { name: "client-update" },
        // },
        // {
        //   title: "Detail",
        //   icon: "mdi-account-details",
        //   link: { name: "client-retrieve" },
        // },
      ],
    };
  },
  computed: {
    drawer2() {
      return this.$store.state.drawer2;
    },
  },
};
</script>

<style></style>
