<template>
  <v-app id="inspire">
    <NavBar>
      <slot name="navbar-menu"> </slot>
    </NavBar>

    <v-main>
      <!-- <v-snackbar
        v-model="snackbars"
        elevation="15"
        :timeout="3000"
        :height="50"
        top
        right
        outlined
        text
        color="error"
      >
        <v-container class="pa-0">
          <v-row>
            <v-col cols="10">I'm a SNACKBAR!</v-col>
            <v-col cols="2"
              ><v-icon color="error" small @click="snackbar = !snackbar"
                >mdi-close</v-icon
              ></v-col
            >
          </v-row>
        </v-container>
      </v-snackbar> -->
      <!-- <v-expand-transition> -->
      <v-snackbar
        v-for="(snackbar, index) in snackbars.filter((s) => s.showing)"
        :key="index"
        v-model="snackbar.showing"
        :color="snackbar.color"
        :timeout="5000"
        transition="scale-transition"
        top
        right
        outlined
        text
      >
        <v-container class="pa-0">
          <v-row>
            <v-col cols="10">{{ snackbar.text }}</v-col>
            <v-col cols="2"
              ><v-icon
                :color="snackbar.color"
                small
                @click="snackbar.showing = !snackbar.showing"
                >mdi-close</v-icon
              ></v-col
            >
          </v-row>
        </v-container>
      </v-snackbar>
      <!-- </v-expand-transition> -->

      <AppDrawer />
      <slot name="menu-drawer"></slot>
      <slot name="tool-drawer"></slot>

      <slot name="main"></slot>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/app/NavBar.vue";
import AppDrawer from "@/components/app/AppDrawer.vue";
import Footer from "@/components/app/Footer.vue";

export default {
  components: { NavBar, AppDrawer, Footer },
  props: {
    source: String,
  },
  data() {
    return {
      snackbar: false,

      menuItems: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      toolItems: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      mini: true,
    };
  },
  methods: {
    toggleAppDrawer() {
      this.$store.dispatch("main/toggleAppDrawer");
    },
    toggleMenuDrawer() {
      this.$store.dispatch("main/toggleMenuDrawer");
    },
    toggleToolDrawer() {
      this.$store.dispatch("main/toggleToolDrawer");
    },
    toggleDialogMenu() {
      this.$store.dispatch("main/toggleDialogMenu");
    },
    toggleDialogFullscreen() {
      this.$store.dispatch("main/toggleDialogFullscreen");
    },
  },
  computed: {
    snackbars() {
      return this.$store.getters["snackbars"];
    },
    appDrawer() {
      return this.$store.getters["main/appDrawer"];
    },
    menuDrawer() {
      return this.$store.getters["main/menuDrawer"];
    },
    toolDrawer() {
      return this.$store.getters["main/toolDrawer"];
    },
    dialogMenu() {
      return this.$store.getters["main/dialogMenu"];
    },
    dialogFullscreen() {
      return this.$store.getters["main/dialogFullscreen"];
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Reggae+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Pacifico&family=Reggae+One&display=swap");

#app {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.app-footer {
  font-size: 9pt;
}
.app-bar-title {
  font-size: 16pt;
  /* font-family: "Courgette", cursive; */
  font-family: Calibri;
}
</style>
