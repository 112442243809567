<template>
  <div>
    <v-navigation-drawer
      slot="menu-drawer"
      :value="menuDrawer"
      absolute
      temporary
      color="marco"
      dark
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Menu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in menuDrawerItems"
          :key="item.title"
          link
          router
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuDrawerItems: [
        {
          title: "Browser",
          icon: "mdi-magnify",
          link: { name: "databank-ine-browser" },
        },
        {
          title: "Wizard",
          icon: "mdi-wizard-hat",
          link: { name: "databank-ine-wizard" },
        },
        {
          title: "Download Center",
          icon: "mdi-download",
          link: { name: "databank-ine-downloads" },
        },
      ],
    };
  },
  methods: {
    // toggleMenuDrawer() {
    //   this.$store.dispatch("main/toggleMenuDrawer");
    // },
  },
  computed: {
    menuDrawer() {
      return this.$store.getters["main/menuDrawer"];
    },
  },
};
</script>

<style></style>
