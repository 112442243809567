<template>
  <div id="map" class="google-map" />
</template>

<script>
import gmapsInit from "@/utils/gmaps";

export default {
  props: ["center", "assets"],
  data() {
    return {
      bounds: null,
      currentMarker: null,
      google: null,
      geocoder: null,
      heatmap: null,
      map: null,
      mapOptions: {
        zoom: 7,
        mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        // mapTypeControlOptions: {
        //   style: this.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        // },
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      address: "Madrid",
      coords: { lat: 40.416775, lng: -3.70379 },
      markers: [],
    };
  },
  methods: {
    addAssets() {
      let google = this.google;
      for (let i = 0; i < this.assets.length; i++) {
        let marker = new google.maps.Marker({
          position: {
            lat: this.assets[i].geo_lat,
            lng: this.assets[i].geo_lng,
          },
          map: this.map,
          //   animation: this.google.maps.Animation.DROP,
        });

        let infowindow = new google.maps.InfoWindow({
          content: this.assetInfoWindow(this.assets[i]),
        });

        marker.addListener("click", () => {
          infowindow.open(this.map, marker);
          //   this.markerFunction();
        });
        this.markers.push({ id: this.assets[i].asset_id, marker: marker });
      }
    },
    markerFunction() {
      alert(`You Clicked on Asset!`);
    },
    assetInfoWindow(asset) {
      const contentString = `
			<div id="content">
				<div id="siteNotice">
					<h1 id="firstHeading" class="firstHeading">
						${asset.asset_id}
					</h1>
					<div id="bodyContent">
					<p><b>Asset Type:</b>${asset.asset_type}</p>
					<p><b>Appraisal Value:</b>${asset.appraisal_value}</p>
						<p><b>€/m2 Asking:</b>${asset.eur_sqm_ask_buy}</p>
						<p><b>€/m2 Closing:</b>${asset.eur_sqm_closing}</p>
						<p><b>Cadastral Reference:</b>${asset.cadastral_reference}</p>
					</div>
				</div>
			</div>
			`;
      return contentString;
    },
    addHeatMapLayer() {
      let google = this.google;
      let heatmapData = [];
      for (let i = 0; i < this.assets.length; i++) {
        heatmapData.push(
          new google.maps.LatLng(this.assets[i].geo_lat, this.assets[i].geo_lng)
        );
      }

      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        gradient: null,
        radius: 15,
        opacity: 1,
      });
      this.heatmap.setMap(this.map);
    },
    toggleHeatmap() {
      if (this.heatmap.map) {
        this.heatmap.setMap(null);
      } else {
        this.heatmap.setMap(this.map);
      }
    },
    toggleTransitLayer() {
      let google = this.google;
      const transitLayer = new google.maps.TransitLayer();
      transitLayer.setMap(this.map);
    },

    clearMarkers() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },

    refreshMapBounds() {
      this.google.maps.event.trigger(this.map, "resize");
      this.map.fitBounds(this.map.getBounds());
    },
    setZoom(zoom) {
      this.map.setZoom(zoom);
    },
  },
  watch: {
    // whenever the center changes, update
    assets: function(newAssets, oldAssets) {
      newAssets;
      oldAssets;
      this.clearMarkers();
      this.map.setCenter(this.coords);
      this.map.zoom = 7;
      this.addAssets();
      //   this.addHeatMapLayer();
      this.refreshMapBounds();
      //   this.toggleTransitLayer();
    },
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      let google = this.google;
      const geocoder = new google.maps.Geocoder();
      this.map = new google.maps.Map(this.$el, this.mapOptions);
      this.google.maps.event.addListener(
        this.map,
        "bounds_changed",
        function() {
          this.map.fitBounds(this.map.getBounds());
        }
      );

      geocoder.geocode({ address: this.address }, (results, status) => {
        if (status !== "OK" || !results[0]) {
          throw new Error(status);
        }

        // this.map.setCenter(results[0].geometry.location);
        this.map.setCenter(this.center);
        this.map.fitBounds(results[0].geometry.viewport);
        this.map.setZoom(6);

        // this.addMarker(this.center);
      });
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#map {
  width: 80vw;
  height: 80vh;
}
</style>
