<template>
  <div>
    <StackedColumnChart
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="hcData"
      :AxisCategories="axisCategories"
      :xAxisTitle="xAxisTitle"
      :yAxisTitle="yAxisTitle"
    />
  </div>
</template>

<script>
import StackedColumnChart from "@/components/charts/highcharts/StackedColumnChart.vue";
import IneAPI from "@/api/ine";
export default {
  components: { StackedColumnChart },
  props: ["hcTitle", "series"],
  data() {
    return {
      apiData: null,
      hcData: [],
      axisCategories: [],
      hcSubtitle: null,
      xAxisTitle: null,
      yAxisTitle: null,
    };
  },
  methods: {
    async getApiData() {
      this.apiData = {};
      for (let serie of this.series) {
        console.log(serie);
        await IneAPI.seriesData(serie).then((response) => {
          this.apiData[serie] = response.data;
          console.log(this.apiData);
        });
      }
    },
    getHcData() {
      let series = [];
      if (this.apiData) {
        for (let serie of this.series) {
          series.push({
            name: this.apiData[serie].Nombre,
            //   color: "#18515E",
            data: this.apiData[serie].Data.map((item) => {
              return item.value;
            }),
          });
        }
      }
      this.hcData = series;
    },
    getChartProps() {
      let firstSerie = Object.keys(this.apiData)[0];

      if (firstSerie) {
        this.axisCategories = this.apiData[firstSerie].Data.map((item) => {
          return item.period;
        });
        this.hcSubtitle = this.apiData[firstSerie].Unidad.nombre_eng;
        this.yAxisTitle = this.apiData[firstSerie].Unidad.nombre_eng;
      } else {
        this.axisCategories = [];
      }
    },
  },
  watch: {
    $props: {
      async handler() {
        await this.getApiData();
        await this.getHcData();
        await this.getChartProps();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  mounted() {
    this.getApiData();
  },
};
</script>

<style></style>
