<template>
  <!-- @input="$emit('changeDialog', dialog)" -->
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-toolbar class="marco" dark dense>
        <v-toolbar-title>Create New Client</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <TextField
                label="Legal Name"
                v-model="clientForm.legal_name"
                :readonly="$attrs.readonly"
                :required="true"
              ></TextField>
              <TextField
                label="Short Name"
                v-model="clientForm.short_name"
                :readonly="$attrs.readonly"
                :required="true"
              ></TextField>
              <Combobox
                label="Client Type"
                v-model="clientForm.client_type"
                :readonly="$attrs.readonly"
                :items="clientTypes"
                item-text="client_type"
                item-value="id"
                :multiple="true"
              >
              </Combobox>

              <TextField
                label="VAT"
                v-model="clientForm.vat_number"
                :readonly="$attrs.readonly"
                :required="true"
              ></TextField>
              <Combobox
                label="Tier"
                v-model="clientForm.tier"
                :readonly="$attrs.readonly"
                :items="tiers"
                item-text="text"
                item-value="value"
                :multiple="false"
              >
              </Combobox>
              <TextArea
                label="Description"
                v-model="clientForm.description"
                :readonly="$attrs.readonly"
                :counter="false"
                outlined
                class="text-subtitle-2"
              >
              </TextArea>
              <TextField
                label="URL"
                v-model="clientForm.url"
                :readonly="$attrs.readonly"
                :required="true"
                append-icon="mdi-magnify"
                @click:append="followUrl"
              ></TextField>
              <v-row justify="left">
                <v-file-input
                  v-model="file"
                  chips
                  show-size
                  label="Client Logo"
                  class="text-subtitle-2"
                >
                </v-file-input>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="setDialog(false)">Cancel</v-btn>
        <v-btn text color="success" @click="postClient">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextField from "@/components/base/TextField.vue";
import TextArea from "@/components/base/TextArea.vue";
import Combobox from "@/components/base/Combobox.vue";

import ClientsAPI from "@/api/clients.js";
export default {
  components: {
    TextField,
    TextArea,
    Combobox,
  },
  props: [],
  data() {
    return {
      clients: [],
      readonly: true,
      userForm: {},
      clientForm: {
        id: null,
        legal_name: null,
        short_name: null,
        tier: null,
        vat_number: null,
        description: null,
        url: null,
        logo: null,
        address: null,
        client_type: [],
        parent_company: [],
      },
      file: null,

      tiers: [
        { text: "Tier 1", value: "1" },
        { text: "Tier 2", value: "2" },
        { text: "Tier 3", value: "3" },
        { text: "Tier 4", value: "4" },
      ],
    };
  },
  methods: {
    setDialog(state) {
      let params = {
        dialog: "clientForm",
        view: state,
      };
      this.$store.dispatch("clients/setDialog", params);
    },
    postClient() {
      let form = new FormData();
      Object.keys(this.clientForm).forEach((key) => {
        if (this.clientForm[key]) {
          if (typeof this.clientForm[key] === "object") {
            if (this.clientForm[key].length > 0) {
              this.clientForm[key].forEach((element) => {
                form.append(key, element);
              });
            }
          } else {
            form.append(key, this.clientForm[key]);
          }
        }
      });
      if (this.file) {
        form.set("logo", this.file);
      } else {
        form.delete("logo");
      }

      ClientsAPI.postClient(form)
        .then(() => {
          this.$store.dispatch(
            "snackbarSuccess",
            "Client Created Successfuly!"
          );

          this.$store.dispatch("clients/getClients");
          this.setDialog(false);
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
    },
    resetClientForm() {
      if (this.clientData)
        Object.keys(this.clientForm).forEach((key) => {
          this.clientForm[key] = this.clientData[key];
        });
    },
    followUrl() {
      window.open(this.clientForm.url, "_blank");
    },
    cancelUpdate() {
      this.resetClientForm();
      this.readonly = true;
    },
  },
  computed: {
    clientTypes() {
      return this.$store.getters["clients/clientTypes"];
    },
    dialog() {
      return this.$store.getters["clients/dialogClientForm"];
    },
  },
  beforeMount() {
    this.$store.dispatch("clients/getClients");
    this.resetClientForm();
  },
};
</script>

<style></style>
