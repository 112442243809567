<template>
  <v-card flat>
    <!-- <v-toolbar dense color="marco" dark>
      <v-toolbar-title>Search Results</v-toolbar-title>
    </v-toolbar> -->
    <v-card-text>
      <v-tabs centered v-model="tab">
        <v-tab
          >Geolocation
          <!-- <v-progress-circular
            v-if="isBusy.askCompsSummary && isBusy.askCompsHistograms"
            class="ml-3"
            :indeterminate="isBusy.askCompsSummary && isBusy.askCompsHistograms"
            :width="3"
            :size="20"
          /> -->
        </v-tab>
        <v-tab
          >Pricing Analysis
          <v-progress-circular
            v-if="isBusy.askCompsSummary && isBusy.askCompsHistograms"
            class="ml-3"
            :indeterminate="isBusy.askCompsSummary && isBusy.askCompsHistograms"
            :width="3"
            :size="20"
          />
        </v-tab>
        <v-tab
          >Asking Comps
          <v-progress-circular
            v-if="isBusy.askCompsDetail"
            class="ml-3"
            :indeterminate="isBusy.askCompsDetail"
            :width="3"
            :size="20"
          />
        </v-tab>
        <v-tab
          >Closing Comps
          <v-progress-circular
            v-if="isBusy.closeCompsDetail"
            class="ml-3"
            :indeterminate="isBusy.closeCompsDetail"
            :width="3"
            :size="20"
          />
        </v-tab>
        <v-tab>Selected Comps </v-tab>
        <v-tab
          >Catastro
          <v-progress-circular
            v-if="isBusy.catastroFincaData"
            class="ml-3"
            :indeterminate="isBusy.catastroFincaData"
            :width="3"
            :size="20"
          />
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <SearchResultsGeo />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <SearchResultsPricing />
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <SearchResultsAsking />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <SearchResultsClosing />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <SearchResultsSelected />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <!-- <TabCatastro /> -->
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import SearchResultsGeo from "./SearchResultsGeo.vue";
import SearchResultsPricing from "./SearchResultsPricingAnalysis.vue";
import SearchResultsClosing from "./SearchResultsClosing.vue";
import SearchResultsAsking from "./SearchResultsAsking.vue";
import SearchResultsSelected from "./SearchResultsSelected.vue";

export default {
  components: {
    SearchResultsGeo,
    SearchResultsPricing,
    // TabCatastro,
    SearchResultsClosing,
    SearchResultsAsking,
    SearchResultsSelected,
  },
  data() {
    return {
      tab: null,
      tabs: [
        { name: "Pricing Analysis" },
        { name: "Cadastre" },
        { name: "Closing Comps" },
        { name: "Asking Comps" },
        { name: "Selected Comps" },
        { name: "Summary" },
        { name: "Report" },
      ],
    };
  },
  computed: {
    isBusy() {
      return this.$store.state.realEstate.isBusy;
    },
  },
};
</script>

<style scoped>
#results-pane {
  margin-top: 15px;
  width: 100%;
  /* border: 1px solid lightgrey; */
  padding: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
</style>
