import PortfolioAPI from "@/api/real-estate-portfolios";

export default {
  namespaced: true,
  state: {
    step: 1,
    userPortfolios: [],
    userInputs: {
      portfolioSelection: "new",
      newFile: null,
      fileId: null,
      assetTypeMap: null,
      fieldMap: null,
      options: {
        asking: true,
        cadastre: true,
        cadastre_split: true,
        closing: true,
        geolocation: true,
        liquidity: true,
        max_num_comps: 15,
        search_criteria: "cusec",
        search_similar_area: false,
        strats: false,
      },
      strat_fields: {},
    },
    portfolio: {
      id: null,
      user: null,
      input_file: null,
      filename: null,
      size: null,
      num_rows: null,
      num_cols: null,
      columns: null,
      portfolio_options: null,
      strat_fields: null,
      strat_report_file: null,
      comps_report_file: null,

      field_map: null,
      asset_types: null,
      asset_type_map: null,
    },
  },
  getters: {
    fieldMap(state) {
      return state.portfolio.field_map;
    },
    assetTypeMap(state) {
      return state.portfolio.asset_type_map;
    },
    userInputs(state) {
      return state.userInputs;
    },
    portfolio(state) {
      return state.portfolio;
    },
    // userInputs(state) {
    //   let userInputs = {
    //     assetTypeMap: state.portfolio.asset_types,
    //     fieldMap: state.portfolio.field_map,
    //     options: {
    //       geolocation: true,
    //       cadastre: true,
    //       asking: true,
    //       closing: true,
    //       cadastreSplit: true,
    //     },
    //   };
    //   return userInputs;
    // },
  },
  mutations: {
    GET_USER_PORTFOLIOS(state, userPortfolios) {
      state.userPortfolios = userPortfolios;
    },
    UPDATE_PORTFOLIO(state, portfolio) {
      state.portfolio.id = portfolio.id;
      state.portfolio.input_file = portfolio.input_file;
      state.portfolio.filename = portfolio.filename;
      state.portfolio.size = portfolio.size;
      state.portfolio.num_rows = portfolio.num_rows;
      state.portfolio.num_cols = portfolio.num_cols;
      state.portfolio.columns = portfolio.columns;
      state.portfolio.strat_report_file = portfolio.strat_report_file;
      state.portfolio.comps_report_file = portfolio.comps_report_file;
      state.portfolio.portfolio_options = portfolio.portfolio_options;
      state.portfolio.strat_fields = portfolio.strat_fields;
    },
    UPDATE_PORTFOLIO_ASSET_TYPES(state, assetTypes) {
      state.portfolio.asset_types = assetTypes;
    },
    UPDATE_PORTFOLIO_ASSET_TYPE_MAP(state, assetTypeMap) {
      state.portfolio.asset_type_map = assetTypeMap;
    },
    UPDATE_PORTFOLIO_FIELD_MAP(state, fieldMap) {
      state.portfolio.field_map = fieldMap;
    },
    UPDATE_USER_INPUTS(state, userInputs) {
      state.userInputs = userInputs;
    },
    UPDATE_USER_INPUTS_ASSET_TYPE_MAP(state, assetTypeMap) {
      state.userInputs.assetTypeMap = assetTypeMap;
    },
    UPDATE_USER_INPUTS_SEARCH_OPTIONS(state, searchOptions) {
      state.userInputs.options = searchOptions;
    },
    UPDATE_STRAT_FIELDS(state, stratFields) {
      state.userInputs.strat_fields = stratFields;
    },
    FIRST_STEP(state) {
      state.step = 1;
    },
    NEXT_STEP(state) {
      state.step = Math.min(5, state.step + 1);
    },
    PREVIOUS_STEP(state) {
      state.step = Math.max(1, state.step - 1);
    },
    RESET_USER_INPUTS(state) {
      state.userInputs = {
        portfolioSelection: "new",
        newFile: null,
        fileId: null,
        assetTypeMap: null,
        fieldMap: null,
        options: {
          asking: true,
          cadastre: true,
          cadastre_split: true,
          closing: true,
          geolocation: true,
          liquidity: true,
          max_num_comps: 15,
          search_criteria: "cusec",
          search_similar_area: false,
          strats: false,
        },
        strat_fields: [],
      };
    },
    RESET_PORTFOLIO_DATA(state) {
      state.portfolio = {
        id: null,
        user: null,
        input_file: null,
        filename: null,
        size: null,
        num_rows: null,
        num_cols: null,
        columns: null,
        portfolio_options: null,
        strat_fields: {},
        strat_report_file: null,
        comps_report_file: null,

        field_map: null,
        asset_types: null,
        asset_type_map: null,
      };
    },
  },
  actions: {
    async resetWorkbench(context) {
      await context.commit("RESET_USER_INPUTS");
      await context.commit("RESET_PORTFOLIO_DATA");
      await context.commit("FIRST_STEP");
    },
    async getUserPortfolios(context) {
      await PortfolioAPI.getPortfolios()
        .then((response) => {
          context.commit("GET_USER_PORTFOLIOS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async postPortfolioFieldMap(context, fieldMap) {
      PortfolioAPI.postPortfolioFieldMap(fieldMap);
      context.commit("UPDATE_PORTFOLIO_FIELD_MAP", fieldMap);
    },
    async previousStep(context) {
      context.commit("PREVIOUS_STEP");
    },
    async updateUserInputs(context, userInputs) {
      context.commit("UPDATE_USER_INPUTS", userInputs);
    },
    async updatePortfolio(context, portfolio) {
      context.commit("UPDATE_PORTFOLIO", portfolio);
    },
    async updatePortfolioAssetTypes(context, assetTypes) {
      context.commit("UPDATE_PORTFOLIO_ASSET_TYPES", assetTypes);
    },
    async updatePortfolioAssetTypeMap(context, assetTypeMap) {
      context.commit("UPDATE_PORTFOLIO_ASSET_TYPE_MAP", assetTypeMap);
    },
    async updatePortfolioFieldMap(context, fieldMap) {
      context.commit("UPDATE_PORTFOLIO_FIELD_MAP", fieldMap);
    },
    async updateUserInputsAssetTypeMap(context, assetTypeMap) {
      context.commit("UPDATE_USER_INPUTS_ASSET_TYPE_MAP", assetTypeMap);
    },
    async updateUserInputsSearchOptions(context, searchOptions) {
      context.commit("UPDATE_USER_INPUTS_SEARCH_OPTIONS", searchOptions);
    },
    async updateStratFields(context, stratFields) {
      context.commit("UPDATE_STRAT_FIELDS", stratFields);
    },
    async handleStep1(context) {
      if (context.state.userInputs.portfolioSelection === "load") {
        // GET PORTFOLIO INFORMATION
        await PortfolioAPI.getPortfolio(context.state.userInputs.fileId)
          .then((response) => {
            context.dispatch("updatePortfolio", response.data);
          })
          .catch((error) => {
            context.dispatch(
              "errorSnackbar",
              {
                text: error.response.data,
              },
              { root: true }
            );
          });
      } else if (context.state.userInputs.portfolioSelection === "new") {
        // CREATE PORTFOLIO
        let form = new FormData();
        form.append("project_name", context.state.userInputs.projectName);
        form.append("input_file", context.state.userInputs.newFile);
        form.append("user", null);
        await PortfolioAPI.postPortfolio(form)
          .then((response) => {
            console.log(response.data);
            context.dispatch("updatePortfolio", response.data);
            // Need to update the userInputs.fileId = response.data.id
            // context.commit("NEXT_STEP");
          })
          .catch((error) => {
            context.dispatch(
              "errorSnackbar",
              {
                text: error.response.data,
              },
              { root: true }
            );
          });
      }
      // REFRESH USER PORTFOLIO LIST
      await context.dispatch("getUserPortfolios");

      // GET PORTFOLIO FIELD MAP
      await PortfolioAPI.getPortfolioFieldMap(context.state.portfolio.id)
        .then((response) => {
          context.dispatch("updatePortfolioFieldMap", response.data);
          context.commit("NEXT_STEP");
        })
        .catch((error) => {
          context.dispatch(
            "errorSnackbar",
            {
              text: error,
            },
            { root: true }
          );
        });
    }, // handleStep1 Close
    async handleStep2(context) {
      let fileId = context.state.portfolio.id;
      let fieldMap = context.state.portfolio.field_map;

      // Create Form
      let form = new FormData();
      form.append("file", fileId);

      Object.keys(fieldMap).forEach((k) => {
        if (k !== "file") {
          form.append(k, fieldMap[k]);
        }
      });

      // Post Portfolio Field Map
      await PortfolioAPI.postPortfolioFieldMap(form)
        .then((response) => {
          // Update Field Map
          context.dispatch("updatePortfolioFieldMap", response.data);
          // Get Portfolio Asset Types based on Field Map
          PortfolioAPI.getPortfolioAssetTypes(fileId)
            .then((response1) => {
              context.dispatch(
                "updatePortfolioAssetTypes",
                response1.data.asset_types
              );
              PortfolioAPI.getPortfolioAssetMap(fileId)
                .then((response2) => {
                  context.dispatch(
                    "updatePortfolioAssetTypeMap",
                    response2.data
                  );
                  context.dispatch(
                    "updateUserInputsAssetTypeMap",
                    response2.data
                  );

                  context.commit("NEXT_STEP");
                })
                .catch((error) => {
                  context.dispatch(
                    "errorSnackbar",
                    {
                      text: error.response.data,
                    },
                    { root: true }
                  );
                });
            })
            .catch((error) => {
              context.dispatch(
                "errorSnackbar",
                {
                  text: error.response.data,
                },
                { root: true }
              );
            });
        })
        .catch((error) => {
          context.dispatch(
            "errorSnackbar",
            {
              text: error.response.data,
            },
            { root: true }
          );
        });
    }, // handleStep2 Close
    async handleStep3(context) {
      // POST PORTFOLIO ASSET TYPE MAP
      let form = new FormData();
      form.append("id", context.state.portfolio.asset_type_map.id);
      form.append("file", context.state.portfolio.id);
      form.append(
        "asset_type_key",
        JSON.stringify(context.state.portfolio.asset_type_map.asset_type_key)
      );
      await PortfolioAPI.postPortfolioAssetMap(form)
        .then((response) => {
          context.dispatch("updatePortfolioAssetTypeMap", response.data);
          context.dispatch("updateUserInputsAssetTypeMap", response.data);
          context.dispatch(
            "updateUserInputsSearchOptions",
            context.state.portfolio.portfolio_options
          );
          context.dispatch(
            "updateStratFields",
            context.state.portfolio.strat_fields
          );

          context.commit("NEXT_STEP");
        })
        .catch((error) => {
          console.log(error);
        });

      // UPDATE PORTFOLIO ASSET MAP
      // await PortfolioAPI.getPortfolioAssetMap(this.fileId).then((response) => {
      //   this.assetTypeMap = response.data;
      // });

      //   await PortfolioAPI.postPortfolioAssetMap(this.assetTypeMap).then(
      //     (response) => {
      //       console.log(response.data);
      //     }
      //   );
      // NEXT STEP
    }, // handleStep3 Close
    async handleStep4(context) {
      context;
      let form = new FormData();
      form.append("id", context.state.portfolio.id);
      form.append(
        "portfolio_options",
        JSON.stringify(context.state.userInputs.options)
      );
      form.append(
        "strat_fields",
        JSON.stringify(context.state.userInputs.strat_fields)
      );
      await PortfolioAPI.patchPortfolioOptions(form)
        .then((response) => {
          console.log(response.data);
          context.commit("NEXT_STEP");
        })
        .catch((error) => {
          console.log(error);
        });
      //   await PortfolioAPI.createPortfolioAssets(context.state.portfolio.id)
      //     .then((response) => {
      //       console.log(response);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    }, // handleStep4 Close
  }, // Actions Close
};
