<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Add News Article</h2> </template>
    <v-container slot="main">
      <SideBar />
      <v-row justify="center">
        <v-col align="center">
          <NewsForm />
        </v-col>
      </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/news/SideBar.vue";
import NewsForm from "@/components/news/NewsForm.vue";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
    NewsForm,
  },
};
</script>

<style></style>
