<template>
  <v-navigation-drawer v-model="appDrawer" absolute temporary>
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>MGX Advisors</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="item in appDrawerItems"
        :key="item.title"
        link
        router
        :to="item.link"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      appDrawerItems: [
        {
          title: "Home",
          icon: "mdi-home",
          link: { name: "home" },
        },
        {
          title: "Company",
          icon: "mdi-wrench",
          link: { name: "company-setup" },
        },
        {
          title: "Clients & Contacts",
          icon: "mdi-account",
          link: { name: "client-home" },
        },
        // {
        //   title: "Contacts",
        //   icon: "mdi-account-box-multiple",
        //   link: { name: "contact-dashboard" },
        // },

        {
          title: "News Room",
          icon: "mdi-newspaper-variant-multiple-outline",
          link: { name: "news" },
        },
        { title: "Deals", icon: "mdi-handshake", link: { name: "deals" } },
        {
          title: "Workbenches",
          icon: "mdi-desktop-classic",
          link: { name: "workbench" },
        },
        // {
        //   title: "Financials",
        //   icon: "mdi-finance",
        //   link: { name: "financials" },
        // },
        // { title: "Real Estate", icon: "mdi-home", link: "./real-estate" },
        // { title: "Report Maker", icon: "mdi-file", link: { name: "reports" } },
        {
          title: "Data Lake",
          icon: "mdi-database",
          link: { name: "databank" },
        },
        {
          title: "Credentials",
          icon: "mdi-trophy",
          link: { name: "credentials" },
        },
        {
          title: "Tasks",
          icon: "mdi-calendar-check",
          link: { name: "user-tasks" },
        },
        // {
        //   title: "GIS",
        //   icon: "mdi-earth",
        //   link: { name: "gis" },
        // },
        // {
        //   title: "Cloud Loader",
        //   icon: "mdi-cloud-upload",
        //   link: { name: "uploader" },
        // },
        // {
        //   title: "My Files",
        //   icon: "mdi-folder",
        //   link: { name: "files" },
        // },
        {
          title: "Testing",
          icon: "mdi-test-tube",
          link: { name: "testing" },
        },
      ],
    };
  },
  computed: {
    appDrawer() {
      return this.$store.getters["main/appDrawer"];
    },
  },
};
</script>

<style></style>
