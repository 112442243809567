<template>
  <div>
    <v-chip small class="ml-3 white marco--text" @click="toggleMenuDrawer"
      >Menu</v-chip
    >
    <v-chip small class="ml-3 white marco--text" @click="toggleToolDrawer"
      >Tools</v-chip
    >
  </div>
</template>

<script>
export default {
  methods: {
    toggleMenuDrawer() {
      this.$store.dispatch("main/toggleMenuDrawer");
    },
    toggleToolDrawer() {
      this.$store.dispatch("main/toggleToolDrawer");
    },
  },
};
</script>

<style></style>
