<template>
  <highcharts class="hc" :options="chartOptions"></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { Chart } from "highcharts-vue";
HighchartsMore(Highcharts);

export default {
  components: { highcharts: Chart },
  props: ["hcTitle", "hcSubtitle", "hcData"],
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "waterfall", // line / spline / area / areaspline / column / bar / pie / scatter / gauge / arearange / areasplinerange / columnrange
          scrollablePlotArea: {
            // minWidth: 700,
            scrollPositionX: 1,
            zoomType: "xy",
          },
        },
        title: {
          text: this.hcTitle,
        },
        subtitle: {
          text: this.hcSubtitle,
        },
        credits: {
          enabled: false,
        },
        series: this.hcData,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  enabled: false,
                },
              },
            },
          ],
        },
        tooltip: {
          backgroundColor: "#DDFCFF",
          borderColor: "black",
          borderRadius: 10,
          borderWidth: 1,
          crosshairs: [true, true],
          formatter: function() {
            return (
              "The value for <b>" +
              this.x +
              "</b> is <b>" +
              this.y +
              "</b>, in series " +
              this.series.name
            );
          },
        },
        xAxis: {
          type: "category",
          title: { text: null },
          alignTicks: false,
          opposite: true,
        },
        yAxis: {
          title: {
            text: this.hcSubtitle,
          },
        },
        plotOptions: {
          histogram: {
            accessibility: {
              pointDescriptionFormatter: function(point) {
                var ix = point.index + 1,
                  x1 = point.x.toFixed(3),
                  x2 = point.x2.toFixed(3),
                  val = point.y;
                return ix + ". " + x1 + " to " + x2 + ", " + val + ".";
              },
            },
          },
        },
      };
    },
  },
};
</script>

<style></style>
