<template>
  <div>
    <v-data-table
      v-if="tableData"
      dense
      :headers="headers"
      :items="tableData"
      :loading="isBusy"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <h3>
          {{ title }}
        </h3>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["title", "tableData", "fields", "isBusy"],
  data() {
    return {};
  },
  computed: {
    headers() {
      let headers = [];
      for (var [key, value] of Object.entries(this.tableData[0])) {
        value;
        if (this.fields) {
          if (this.fields.includes(key)) {
            headers.push({
              text: key.replace("_", " "),
              value: key,
              filterable: true,
            });
          }
        } else {
          headers.push({
            text: key.replace("_", " "),
            value: key,
            filterable: true,
          });
        }
      }
      return headers;
    },
  },
};
</script>

<style></style>
