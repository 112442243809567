<template>
  <v-timeline dense>
    <v-timeline-item
      v-for="(deal, i) in deals"
      :key="i"
      :color="colors[i]"
      small
      right
    >
      <!-- <template v-slot:opposite>
        <span
          :class="`headline font-weight-bold ${colors[i]}--text`"
          v-text="deal.date"
        ></span>
      </template> -->
      <v-card>
        <v-card-title>{{ deal.date }}</v-card-title>
        <v-card-subtitle>{{ deal.price_millions }}</v-card-subtitle>
        <v-card-text>{{ deal.description }} </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  props: {
    deals: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    colors: ["cyan", "green", "pink", "amber", "orange"],
    years: [
      {
        color: "cyan",
        year: "1960",
      },
      {
        color: "green",
        year: "1970",
      },
      {
        color: "pink",
        year: "1980",
      },
      {
        color: "amber",
        year: "1990",
      },
      {
        color: "orange",
        year: "2000",
      },
    ],
  }),
  computed: {
    // deals() {
    //   return this.$store.state.deals.clientDeals;
    // },
  },
};
</script>
