import Api from "./Api.js";

export default {
  clientAssetSubtypes(clientId) {
    return Api.get("real-estate/client-asset-types/", {
      params: { client_id: clientId },
    });
  },
  postClientAssetSubtypes(form) {
    return Api.post("real-estate/client-asset-types/", form);
  },

  assetSubtypes() {
    return Api.get("real-estate/asset-subtypes/");
  },
  assetTypes() {
    return Api.get("real-estate/asset-types/");
  },
  getAskCompsDetail(params) {
    return Api.get("real-estate/ask-comps/search/detail", { params: params });
  },
  //   getAskCompsDetailFull(params) {
  //     return Api.get("real-estate/ask-comps/search/detail/full", {
  //       params: params,
  //     });
  //   },
  getAskCompsSummary(params) {
    return Api.get("real-estate/ask-comps/search/summary", { params: params });
  },
  getAskCompsHistogram(params) {
    return Api.get("real-estate/ask-comps/search/histogram", {
      params: params,
    });
  },
  getCloseCompsDetail(params) {
    return Api.get("real-estate/closing-comps/search/detail", {
      params: params,
    });
  },
  getCloseCompsSummary(params) {
    return Api.get("real-estate/closing-comps/search/summary", {
      params: params,
    });
  },
  getCloseCompsHistogram() {
    return Api.get("real-estate/closing-comps/search/histogram");
  },
};
