<template>
  <v-container v-if="closeCompsDetail">
    <v-row justify="center">
      <v-btn
        v-if="closeCompsDetail.previous"
        v-on:click="browseClosingComps('previous')"
        class="success"
        >Previous</v-btn
      >
      <v-btn
        v-if="closeCompsDetail.next"
        v-on:click="browseClosingComps('next')"
        class="success"
        >Next</v-btn
      >
    </v-row>
    <v-row>
      <v-data-table
        dense
        show-select
        :single-select="false"
        :items="closeCompsDetail"
        :item-selected="updateSelectedComps('closing')"
        item-key="id"
        :headers="tableHeaders"
        v-if="closeCompsDetail"
        v-model="selectedComps"
        :hide-default-footer="false"
        :options="tableOptions"
      >
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedComps: [],
      showTableColumns: [
        "distance",
        "f_escritura_corta",
        "descripcion",
        "tipo",
        "estado",
        "calle_api",
        "codigo_postal",
        "anyo_construccion",
        "id_vpo",
        "superficie",
        "valor",
        "valor_m",
      ],
      tableHeaders: [
        { text: "Distance (m)", value: "distance" },
        { text: "Date", value: "f_escritura_corta" },
        { text: "Description", value: "descripcion" },
        { text: "Asset Type", value: "tipo" },
        { text: "State", value: "estado" },
        { text: "Street", value: "calle_api" },
        { text: "Zip Code", value: "codigo_postal" },
        { text: "Construction Year", value: "anyo_construccion" },
        { text: "VPO", value: "id_vpo" },
        { text: "Surface", value: "superficie" },
        { text: "Value", value: "valor" },
        { text: "€/m2", value: "valor_m" },
      ],

      tableOptions: {
        itemsPerPage: 50,
      },
      //   pageCount: 1,
      //   page: 1,
    };
  },
  methods: {
    updateSelectedComps(compsGroup) {
      console.log(this.selectedComps);
      let compsObject = {
        compsGroup: compsGroup,
        selectedComps: this.selectedComps,
      };
      this.$store.dispatch("realEstate/updateSelectedComps", compsObject);
    },
    browseClosingComps: function(direction) {
      this.$store.dispatch("realEstate/browseClosingComps", direction);
    },
  },
  computed: {
    closeCompsDetail() {
      return this.$store.getters["realEstate/closeCompsDetail"];
    },
  },
};
</script>

<style></style>
