<template>
  <div v-if="compsData">
    <Histogram
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="HistogramSeries"
    />
  </div>
</template>

<script>
import Histogram from "@/components/charts/highcharts/Histogram.vue";
export default {
  components: { Histogram },
  props: ["hcTitle", "hcSubtitle", "compsData"],
  data() {
    return {
      HistogramSeries: [],
    };
  },
  methods: {
    updateHistogram() {
      if (this.compsData) {
        let data = this.compsData.map((item) => {
          return item.eur_sqm;
        });

        this.HistogramSeries = [
          {
            name: "Histogram",
            type: "histogram",
            xAxis: 1,
            yAxis: 1,
            baseSeries: "s1",
            zIndex: -1,
          },
          {
            name: "Frequency",
            type: "scatter",
            data: data,
            id: "s1",
            marker: {
              radius: 1.5,
            },
          },
        ];
      }
    },
  },
  watch: {
    $props: {
      handler() {
        this.updateHistogram();
      },
      deep: true,
      immediate: true,
    },
  },

  // AxisCategories() {
  //   let categories;
  //   if (this.apiData.serie1) {
  //     categories = this.apiData.serie1.Data.map((item) => {
  //       return item.period;
  //     });
  //   } else {
  //     categories = [];
  //   }
  //   return categories;
  // },
  // hcSubtitle() {
  //   if (this.apiData.serie1) {
  //     return this.apiData.serie1.Unidad.nombre_eng;
  //   } else {
  //     return null;
  //   }
  // },
  // hcData() {
  //   let series = [];
  //   if (this.apiData.serie1) {
  //     series.push({
  //       name: this.apiData.serie1.Nombre,
  //       color: "#18515E",
  //       data: this.apiData.serie1.Data.map((item) => {
  //         return item.value;
  //       }),
  //     });
  //   }
  //   if (this.apiData.serie2) {
  //     series.push({
  //       name: this.apiData.serie2.Nombre,
  //       color: "#606061",
  //       data: this.apiData.serie2.Data.map((item) => {
  //         return item.value;
  //       }),
  //     });
  //   }
  //   return series;
  // },
  // xAxisTitle() {
  //   return null;
  // },
  // yAxisTitle() {
  //   if (this.apiData.serie1) {
  //     return this.apiData.serie1.Unidad.nombre_eng;
  //   } else {
  //     return null;
  //   }
  // },

  mounted() {
    this.updateHistogram();
  },
};
</script>

<style></style>
