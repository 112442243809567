<template>
  <v-text-field
    class="textfield text-subtitle-2"
    v-bind="$attrs"
    v-on="$listeners"
    dense
  >
  </v-text-field>
</template>

<script>
export default {
  computed: {
    // outlined() {
    //   if (this.$attrs["readonly"]) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
};
</script>

<style scoped>
/* .textfield {
  font-size: 10pt;
} */
</style>
