<template>
  <BaselineLayout>
    <template slot="navbarContent">
      <v-toolbar-title>
        <v-btn
          text
          @click="$router.push({ name: 'real-estate-portfolio-dashboard' })"
          >Dashboard</v-btn
        >
      </v-toolbar-title>
      <v-toolbar-title>
        <v-toolbar-title>
          <v-btn text @click="$router.push({ name: 'real-estate-portfolio' })"
            >Workbench</v-btn
          >
        </v-toolbar-title>
      </v-toolbar-title>
    </template>
    <v-container slot="main">
      <SideBar />
      <v-toolbar dense class="marco white--text">
        <v-toolbar-title>Real Estate Portfolio Workbench</v-toolbar-title>
      </v-toolbar>

      <v-row justify="center">
        <v-overlay :value="isBusy">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-col>
          <v-stepper alt-labels v-model="step">
            <v-stepper-header dense>
              <v-stepper-step step="1">
                Select File
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2">
                Map Key Fields
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="3">
                Map Asset Types
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                Select Options
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">
                Begin Portfolio Tasks
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <!-- STEP 1 -->
              <v-stepper-content step="1">
                <v-card class="mb-12" height="600px">
                  <v-card-title>Select Portfolio File</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row justify="center">
                        <v-radio-group
                          v-model="userInputs.portfolioSelection"
                          row
                        >
                          <v-radio label="New" value="new"></v-radio>
                          <v-radio label="Load" value="load"></v-radio>
                        </v-radio-group>
                      </v-row>
                      <v-row
                        v-if="userInputs.portfolioSelection === 'new'"
                        justify="center"
                      >
                        <v-col cols="4">
                          <v-text-field
                            v-model="userInputs.projectName"
                            label="Project Name"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-form ref="fileSelecteForm">
                            <v-file-input
                              show-size
                              truncate-length="15"
                              label="Portfolio File"
                              v-model="userInputs.newFile"
                              :rules="fileValidation"
                            ></v-file-input>
                          </v-form>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="userInputs.portfolioSelection === 'load'"
                        justify="center"
                      >
                        <v-col cols="4">
                          <v-data-table
                            v-if="userPortfolios"
                            dense
                            show-select
                            v-model="portfolioTable.selected"
                            :single-select="portfolioTable.singleSelect"
                            :headers="portfolioTable.headers"
                            :items="userPortfolios"
                            :search="portfolioTable.search"
                            item-text="filename"
                            item-value="id"
                            @item-selected="selectPortfolio"
                          >
                            <template v-slot:top>
                              <h3>User Portfolios</h3>
                            </template>
                          </v-data-table>

                          <!-- <Combobox
                            v-model="userInputs.fileId"
                            label="Portfolio File"
                            :items="userPortfolios"
                            item-text="filename"
                            item-value="id"
                          /> -->
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-card-actions>
                  <v-btn color="primary" @click="handleStep1">
                    Continue
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="resetWorkbench">Reset</v-btn>
                </v-card-actions>
              </v-stepper-content>

              <!-- STEP 2 -->
              <v-stepper-content step="2">
                <v-card class="mb-12" height="600px" v-if="userInputs.fieldMap">
                  <v-card-title>Select Key Fields</v-card-title>

                  <v-card-text>
                    <!-- <v-overlay :absolute="absolute" :value="true">
                      <v-progress></v-progress
                    ></v-overlay> -->
                    <Combobox
                      v-model="userInputs.fieldMap['asset_id']"
                      label="Asset ID"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['asset_type']"
                      label="Asset Type"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['address']"
                      label="Address"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['area']"
                      label="Built Area"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['longitude']"
                      label="Longitude"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['latitude']"
                      label="Latitude"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['cadastral_reference']"
                      label="Cadastral Reference"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['appraisal_value']"
                      label="Appraisal Value"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['net_book_value']"
                      label="Net Book Value"
                      :items="fileColumns"
                    />
                    <Combobox
                      v-model="userInputs.fieldMap['market_value']"
                      label="Market Value"
                      :items="fileColumns"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" @click="handleStep2">
                      Continue
                    </v-btn>
                    <v-btn color="secondary" @click="previousStep">
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="resetWorkbench">Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <!-- STEP 3 -->
              <v-stepper-content step="3">
                <v-card class="mb-12">
                  <v-card-title>Map Asset Types</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row v-if="userInputs.assetTypeMap">
                        <v-col
                          v-for="assetType in portfolioAssetTypes"
                          :key="assetType"
                          cols="3"
                        >
                          <Combobox
                            :label="assetType"
                            v-model="
                              userInputs.assetTypeMap.asset_type_key[assetType]
                            "
                            :items="assetTypes"
                            item-text="asset_type_ENG"
                            item-value="id"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" @click="handleStep3">
                      Continue
                    </v-btn>
                    <v-btn color="secondary" @click="previousStep">
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="resetWorkbench">Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <!-- STEP 4 -->
              <v-stepper-content step="4">
                <v-card class="mb-12">
                  <v-card-title elevation="6">Select Options</v-card-title>
                  <v-card-text>
                    <v-container v-if="userInputs.options">
                      <v-row>
                        <v-col>
                          <OptionsCard title="Portfolio Strats">
                            <v-container>
                              <v-row>
                                <v-col cols="3">
                                  <v-checkbox
                                    v-model="userInputs.options.strats"
                                    label="Portfolio Stratifications"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="9">
                                  <Combobox
                                    v-if="
                                      userInputs.options.strats && fileColumns
                                    "
                                    label="Strat Fields"
                                    v-model="userInputs.options.strat_fields"
                                    :items="fileColumns"
                                    :multiple="true"
                                    :chips="true"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </OptionsCard>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <OptionsCard title="Geolocation">
                            <v-container fluid>
                              <v-row justify="center" align="center">
                                <v-col justify="center">
                                  <v-radio-group
                                    v-model="userInputs.options.geolocation"
                                    row
                                  >
                                    <v-radio
                                      label="Longitude / Latitude"
                                      value="coords"
                                    ></v-radio>
                                    <v-radio
                                      label="Cadastral Reference"
                                      value="cadastre"
                                    ></v-radio>
                                    <v-radio
                                      label="Address"
                                      value="address"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                            </v-container>
                          </OptionsCard>
                        </v-col>
                        <v-col>
                          <OptionsCard title="Cadastre">
                            <v-container>
                              <v-row>
                                <v-col>
                                  <v-checkbox
                                    v-model="userInputs.options.cadastre"
                                    label="Cadastral Enrichment"
                                  ></v-checkbox>
                                </v-col>
                                <v-col>
                                  <v-checkbox
                                    v-model="userInputs.options.cadastre_split"
                                    label="Split Cadastral References"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-container>
                          </OptionsCard>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <OptionsCard title="Market Comps">
                            <v-container>
                              <v-row>
                                <v-col>
                                  <v-checkbox
                                    v-model="userInputs.options.asking"
                                    label="Asking Comps"
                                  ></v-checkbox>
                                </v-col>
                                <v-col>
                                  <v-checkbox
                                    v-model="userInputs.options.closing"
                                    label="Closing Comps"
                                  ></v-checkbox>
                                </v-col>
                                <v-col>
                                  <v-checkbox
                                    v-model="userInputs.options.liquidity"
                                    label="Liquidity Adjustment"
                                  ></v-checkbox>
                                </v-col>
                                <v-col> </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <Combobox
                                    label="Comps per Asset"
                                    v-model="userInputs.options.max_num_comps"
                                    :items="max_num_comps"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </OptionsCard>
                        </v-col>
                        <v-col>
                          <OptionsCard title="Comps Search Criteria">
                            <v-container>
                              <v-row>
                                <v-col>
                                  <v-radio-group
                                    v-model="userInputs.options.search_criteria"
                                    row
                                  >
                                    <v-radio
                                      label="Distance"
                                      value="distance"
                                    ></v-radio>
                                    <v-radio
                                      label="Seccion Censal"
                                      value="cusec"
                                    ></v-radio>
                                  </v-radio-group>
                                  <Combobox
                                    v-if="
                                      userInputs.options.search_criteria ===
                                        'distance'
                                    "
                                    label="Max Distance (m)"
                                    v-model="userInputs.options.max_distance"
                                    :items="max_distance"
                                  />
                                </v-col>
                                <v-col>
                                  <v-checkbox
                                    v-model="
                                      userInputs.options.search_similar_area
                                    "
                                    label="Similar Area"
                                  ></v-checkbox>
                                  <Combobox
                                    v-if="
                                      userInputs.options.search_similar_area
                                    "
                                    label="Area Tolerance (%)"
                                    v-model="userInputs.options.area_tolerance"
                                    :items="area_tolerance"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </OptionsCard>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" @click="handleStep4">
                      Continue
                    </v-btn>
                    <v-btn color="secondary" @click="previousStep">
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="resetWorkbench">Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="5">
                <v-card class="mb-12">
                  <v-card-title>Select Options</v-card-title>
                  <v-card-text>
                    <v-container flui>
                      <v-row justify="center">
                        <v-col align="center">
                          <h1>
                            Congratulations!! Your portfolio is now being
                            processed.
                          </h1>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col align="center">
                          <h2>
                            Check the PORTFOLIOS tab to monitor progress and
                            access reports!
                          </h2>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <!-- <v-btn color="primary" @click="handleStep4">
                  Continue
                </v-btn> -->
                <v-btn color="secondary" @click="previousStep">
                  Back
                </v-btn>
                <v-btn color="error" @click="resetWorkbench">Reset</v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <v-row>{{ $store.state.realEstatePortfolios.userInputs }}</v-row>
      <v-divider></v-divider>
      <v-row>{{ userInputs }}</v-row>
      <v-divider></v-divider>
      <v-row>{{ portfolio }}</v-row>
      <v-row></v-row>
    </v-container>
  </BaselineLayout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/real-estate/PortfolioSideBar.vue";
import Combobox from "@/components/base/Combobox.vue";
import OptionsCard from "@/components/real-estate/CardSearchOptions.vue";

export default {
  components: {
    BaselineLayout,
    SideBar,
    Combobox,
    OptionsCard,
  },
  data() {
    return {
      isBusy: false,
      userInputs: {
        portfolioSelection: "new",
        projectName: null,
        newFile: null,
        fileId: null,
        assetTypeMap: null,
        fieldMap: {
          id: null,
          asset_id: null,
          asset_type: null,
          address: null,
          area: null,
          cadastral_reference: null,
          appraisal_value: null,
          net_book_value: null,
          market_value: null,
          longitude: null,
          latitude: null,
          file: null,
        },
        options: {
          geolocation: true,
          cadastre: true,
          asking: true,
          closing: true,
          liquidity: true,
          cadastre_split: true,
          search_criteria: "distance",
          search_similar_area: true,
          area_tolerance: 20,
          max_distance: 500,
          max_num_comps: 10,
          strats: true,
          strat_fields: [],
        },
      },
      fileValidation: [
        (value) => {
          if (!value) {
            return "File is required!";
          } else {
            if (value) {
              if (!value.name.endsWith("xlsx") && !value.name.endsWith("csv")) {
                return "Valid file formats are XLSX and CSV";
              } else {
                return true;
              }
            }
          }
        },
      ],
      portfolioTable: {
        headers: [
          { text: "ID", value: "id", filterable: true },
          { text: "Project", value: "project_name", filterable: true },
          { text: "Filename", value: "filename", filterable: true },
        ],
        search: "",
        selected: null,
        singleSelect: true,
      },
      max_num_comps: [0, 5, 10, 15, 25, 30, 50, 75, 100],
      area_tolerance: [0, 5, 10, 15, 20, 25, 50, 75, 100],
      max_distance: [50, 100, 150, 200, 250, 500, 750, 1000, 2500, 5000],
    };
  },
  methods: {
    selectPortfolio(event) {
      this.userInputs.fileId = event.item.id;
    },
    async getStoreInputs() {
      await this.$store.dispatch(
        "realEstatePortfolios/updateUserInputs",
        this.userInputs
      );
    },
    async handleStep1() {
      this.isBusy = true;
      if (this.userInputs.portfolioSelection === "new") {
        if (this.$refs.fileSelecteForm.validate()) {
          // Update User Inputs in Store
          await this.$store.dispatch(
            "realEstatePortfolios/updateUserInputs",
            this.userInputs
          );
          // Handle Step 1 in Store
          await this.$store.dispatch("realEstatePortfolios/handleStep1");
          // Read Field Map and Asset Map from Store
          this.userInputs.assetTypeMap = null;
          this.userInputs.fieldMap = this.$store.state.realEstatePortfolios.portfolio.field_map;
        } else {
          this.$store.dispatch("errorSnackbar", {
            text: "You must select a valid file before proceeding",
          });
        }
        this.isBusy = false;
      } else {
        await this.$store.dispatch(
          // Update User Inputs in Store
          "realEstatePortfolios/updateUserInputs",
          this.userInputs
        );
        // Handle Step 1 in Store
        await this.$store.dispatch("realEstatePortfolios/handleStep1");

        // UPDATE LOCAL FIELD MAP AFTER UPDATE IN STORE
        this.userInputs.fieldMap = this.$store.getters[
          "realEstatePortfolios/fieldMap"
        ];
        this.isBusy = false;
      }
    },
    async handleStep2() {
      this.isBusy = true;
      await this.$store.dispatch(
        "realEstatePortfolios/updatePortfolioFieldMap",
        this.userInputs.fieldMap
      );
      await this.$store.dispatch("realEstatePortfolios/handleStep2");
      this.userInputs.assetTypeMap = this.$store.state.realEstatePortfolios.portfolio.asset_type_map;

      this.userInputs = this.storeUserInputs;
      this.isBusy = false;
    },
    async handleStep3() {
      this.isBusy = true;
      // Update user inputs in store

      await this.$store.dispatch(
        "realEstatePortfolios/updatePortfolioAssetTypeMap",
        this.userInputs.assetTypeMap
      );

      // handle in Store
      await this.$store.dispatch("realEstatePortfolios/handleStep3");
      this.isBusy = false;
    },
    async handleStep4() {
      //   this.isBusy = true;
      await this.$store.dispatch(
        "realEstatePortfolios/updateUserInputsSearchOptions",
        this.userInputs.options
      );
      await this.$store.dispatch(
        "realEstatePortfolios/updateStratFields",
        this.userInputs.options.strat_fields
      );
      await this.$store.dispatch("realEstatePortfolios/handleStep4");
    },
    async handleStep5() {},
    async previousStep() {
      await this.$store.dispatch("realEstatePortfolios/previousStep");
    },
    async updateUserInputs() {
      await this.$store.dispatch(
        "realEstatePortfolios/updateUserInputs",
        this.userInputs
      );
    },
    async resetWorkbench() {
      await this.$store.dispatch(
        "realEstatePortfolios/resetWorkbench",
        this.userInputs
      );
      this.userInputs = this.$store.state.realEstatePortfolios.userInputs;
    },
  },
  mounted: async function() {
    // await this.$store.dispatch(
    //   "realEstatePortfolios/updateUserInputs",
    //   this.userInputs
    // );
    await this.$store.dispatch("realEstatePortfolios/getUserPortfolios");
    await this.$store.dispatch("realEstate/getAssetTypes");
    // this.userInputs = this.$store.getters[
    //   "realEstatePortfolios/userInputs"
    // ];
    // this.userInputs = this.$store.getters["realEstatePortfolios/userInputs"];
    this.userInputs = this.$store.state.realEstatePortfolios.userInputs;
  },
  computed: {
    assetTypes() {
      return this.$store.state.realEstate.optionsAssetType;
    },
    fileColumns() {
      return this.$store.state.realEstatePortfolios.portfolio.columns;
    },
    portfolio() {
      return this.$store.getters["realEstatePortfolios/portfolio"];
    },
    portfolioAssetTypes() {
      return this.$store.state.realEstatePortfolios.portfolio.asset_types;
    },
    step() {
      return this.$store.state.realEstatePortfolios.step;
    },
    userPortfolios() {
      return this.$store.state.realEstatePortfolios.userPortfolios;
    },
    storeUserInputs() {
      return this.$store.state.realEstatePortfolios.userInputs;
    },
  },
};
</script>

<style scoped></style>
