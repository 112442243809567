import Api from "./Api.js";

export default {
  seccionCensalAssets(seccionCensal) {
    return Api.get("hospitality/asset-search/", {
      params: { seccionCensal: seccionCensal },
    });
  },
  seccionCensalPlayers(seccionCensal) {
    return Api.get("hospitality/player-search/", {
      params: { seccionCensal: seccionCensal },
    });
  },
};
