<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Search Deals</h2> </template>
    <v-container slot="main">
      <SideBar />

      <v-row>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search Filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container class="pa-4">
                <v-row>
                  <v-col>
                    <!-- NEWS SEARCH -->
                    <v-text-field
                      label="Search"
                      v-model="searchStr"
                      append-icon="mdi-magnify"
                    >
                    </v-text-field>
                    <Combobox
                      multiple
                      label="Sector"
                      v-model="selectedClients"
                      :items="clients"
                      item-text="legal_name"
                      item-value="id"
                    />
                    <Combobox
                      multiple
                      label="Clients"
                      v-model="selectedClients"
                      :items="clients"
                      item-text="legal_name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialogFromDate"
                      v-model="modalFromDate"
                      :return-value.sync="fromDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fromDate">
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalFromDate = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogFromDate.save(fromDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialogToDate"
                      v-model="modalToDate"
                      :return-value.sync="toDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="toDate"
                          label="To Date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="toDate">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalToDate = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogToDate.save(toDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-slider
                      label="Min"
                      hint="Minimum Deal Value"
                      max="1500"
                      min="0"
                      thumb-label="always"
                      :thumb-size="24"
                      ticks
                      step="50"
                      v-model="sizeMin"
                    >
                      <template v-slot:append>
                        <v-text-field
                          v-model.number="sizeMin"
                          class="mt-0 pt-0"
                          style="width: 60px"
                        ></v-text-field>
                      </template>
                    </v-slider>
                  </v-col>
                  <v-col>
                    <v-slider
                      label="Max"
                      hint="Maximum Deal Value"
                      max="1500"
                      min="0"
                      thumb-label="always"
                      :thumb-size="24"
                      ticks
                      step="50"
                      v-model="sizeMax"
                    >
                      <template v-slot:prepend>
                        <v-text-field
                          v-model.number="sizeMax"
                          class="mt-0 pt-0"
                          style="width: 60px"
                        ></v-text-field>
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row justify="center">
        <FabButton icon="mdi-magnify" color="success" @click="search"
          >Search</FabButton
        >
        <FabButton
          v-if="deals.length > 0"
          icon="mdi-download"
          color="warning"
          @click="download"
          >Search</FabButton
        >
      </v-row>

      <v-row class="mt-3" v-if="deals.length > 0">
        <v-col>
          <h3>Search Results</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-row v-for="deal in deals" :key="deal.id">
        <v-col cols="12">
          <v-card>
            <v-card-title
              >{{ deal.date }} <v-spacer></v-spacer>
              <span
                v-for="participant in deal.participants"
                :key="participant.id"
                class="mx-3"
              >
                {{ participant.legal_name }}
              </span>
              <v-spacer></v-spacer>
              {{ deal.deal_type }}
            </v-card-title>
            <v-card-subtitle v-if="deal.price_millions">
              Deal Price: €{{ deal.price_millions }}m
            </v-card-subtitle>
            <v-card-subtitle v-if="!deal.price_millions">
              Deal Price: Unknown
            </v-card-subtitle>
            <v-card-text>{{ deal.description }}</v-card-text>
            <v-card-actions>
              <!-- <v-btn color="primary" :to="deal.url">View</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/deals/SideBar.vue";
import Combobox from "@/components/base/Combobox.vue";
import FabButton from "@/components/base/buttons/FabButton.vue";

import Client from "@/api/clients.js";
import Deal from "@/api/deals.js";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
    Combobox,
    FabButton,
  },
  data() {
    return {
      downloadUrl: null,
      searchStr: "",
      fromDate: null,
      toDate: null,
      modalFromDate: false,
      modalToDate: false,
      columns: [
        { text: "ID", value: "id" },
        { text: "Date", value: "date" },
        { text: "Title", value: "title" },
        { text: "View", value: "view" },
      ],

      selections: [],
      deals: [],
      clients: [],
      selectedClients: [],
      sizeMin: null,
      sizeMax: null,
    };
  },
  methods: {
    download() {
      // Deal.download()
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      window.location.assign(this.downloadUrl);
    },
    search() {
      let params = {};
      if (this.searchStr) {
        params.search = this.searchStr;
      }
      if (this.fromDate) {
        params.from_date = this.fromDate;
      }
      if (this.toDate) {
        params.to_date = this.toDate;
      }
      if (
        this.selectedClients instanceof Array &&
        this.selectedClients.length > 0
      ) {
        params.clients = JSON.stringify(this.selectedClients);
      }
      if (this.sizeMin && this.sizeMin >= 0) {
        params.min_size = this.sizeMin;
      }
      if (this.sizeMax && this.sizeMax >= 0) {
        params.max_size = this.sizeMax;
      }

      let url = new URL(`http://localhost/api/deals/download/`);
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key]);
      });
      this.downloadUrl = url;

      Deal.search(params).then((response) => {
        this.deals = response.data;
      });
    },
    // getClientTypes() {
    //   this.$store.dispatch("clients/getClientTypes");
    // },
    getClients() {
      Client.all().then((response) => {
        this.clients = response.data;
      });
    },
    getDeals() {
      Deal.list().then((response) => {
        this.deals = response.data;
      });
    },
    goToDetail(clientId) {
      this.$router.push({ name: "client-detail", params: { id: clientId } });
    },
  },
  computed: {
    clientTypes() {
      return this.$store.state.clients.clientTypes;
    },
  },
  mounted() {
    this.getClients();
    // this.getNews();
  },
};
</script>

<style></style>
