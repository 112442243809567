<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <TextField
          label="Legal Name"
          v-model="clientForm.legal_name"
          :readonly="$attrs.readonly"
          :required="true"
        ></TextField>
        <TextField
          label="Short Name"
          v-model="clientForm.short_name"
          :readonly="$attrs.readonly"
          :required="true"
        ></TextField>
        <Combobox
          label="Client Type"
          v-model="clientForm.client_type"
          :readonly="$attrs.readonly"
          :items="clientTypes"
          item-text="client_type"
          item-value="id"
          :multiple="true"
        >
        </Combobox>

        <TextField
          label="VAT"
          v-model="clientForm.vat_number"
          :readonly="$attrs.readonly"
          :required="true"
        ></TextField>
        <Combobox
          label="Tier"
          v-model="clientForm.tier"
          :readonly="$attrs.readonly"
          :items="tiers"
          item-text="text"
          item-value="value"
          :multiple="false"
        >
        </Combobox>
        <TextArea
          label="Description"
          v-model="clientForm.description"
          :readonly="$attrs.readonly"
          :counter="false"
          outlined
          class="text-subtitle-2"
        >
        </TextArea>
        <TextField
          label="URL"
          v-model="clientForm.url"
          :readonly="$attrs.readonly"
          :required="true"
          append-icon="mdi-magnify"
          @click:append="followUrl"
        ></TextField>
        <v-row justify="left">
          <v-file-input
            v-model="file"
            chips
            show-size
            label="Client Logo"
            class="text-subtitle-2"
          >
          </v-file-input>
        </v-row>
        <v-row justify="space-around">
          <v-btn block color="success black--text" @click="postClient"
            >Create</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextField from "@/components/base/TextField.vue";
import TextArea from "@/components/base/TextArea.vue";
import Combobox from "@/components/base/Combobox.vue";
export default {
  components: {
    TextField,
    TextArea,
    Combobox,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      readonly: true,
      userForm: {},
      clientForm: {
        id: null,
        legal_name: null,
        short_name: null,
        tier: null,
        vat_number: null,
        description: null,
        url: null,
        logo: null,
        address: null,
        client_type: [],
        parent_company: [],
      },
      file: null,

      tiers: [
        { text: "Tier 1", value: "1" },
        { text: "Tier 2", value: "2" },
        { text: "Tier 3", value: "3" },
        { text: "Tier 4", value: "4" },
      ],
    };
  },
  methods: {
    postClient() {
      let form = new FormData();
      Object.keys(this.clientForm).forEach((key) => {
        if (this.clientForm[key]) {
          if (typeof this.clientForm[key] === "object") {
            if (this.clientForm[key].length > 0) {
              this.clientForm[key].forEach((element) => {
                form.append(key, element);
              });
              //   form.append(key, this.clientForm[key]);
            }
          } else {
            form.append(key, this.clientForm[key]);
          }
        }
      });
      if (this.file) {
        form.set("logo", this.file);
        // this.clientForm["logo"] = this.file;
      } else {
        form.delete("logo");
        // this.clientForm["logo"] = null;
      }

      this.$store.dispatch("clients/postClient", form);
      this.readonly = true;
      this.resetClientForm();
      this.$router.push({ name: "client-search" });
    },
    resetClientForm() {
      if (this.clientData)
        Object.keys(this.clientForm).forEach((key) => {
          this.clientForm[key] = this.clientData[key];
        });
    },
    followUrl() {
      window.open(this.clientForm.url, "_blank");
    },
    cancelUpdate() {
      this.resetClientForm();
      this.readonly = true;
    },
  },
  computed: {
    clientTypes() {
      return this.$store.state.clients.clientTypes;
    },
  },
  watch: {
    $route: "resetClientForm",
    clientData: "resetClientForm",
  },
  beforeMount() {
    this.$store.dispatch("clients/getClientTypes");
    this.resetClientForm();
  },
};
</script>

<style></style>
