<template>
  <BaselineLayout>
    <template slot="pageTitle"> <h2>Client Dashboard</h2> </template>

    <v-container slot="main" class="fill-height">
      <ClientsSideBar />
      <v-row justify="center" align="center">
        <v-col cols="5">
          <BarChartCard
            v-if="clientTypesChart"
            title="Clients by Type"
            :data="clientTypesChart"
            source="MGX Advisors"
          ></BarChartCard>
        </v-col>
        <v-col cols="5">
          <BarChartCard
            v-if="clientTierChart"
            title="Clients by Tier"
            :data="clientTierChart"
            source="MGX Advisors"
          ></BarChartCard>
        </v-col>
      </v-row>

      <!-- 
      {{ dashboardData.client_types }} -->
    </v-container>
  </BaselineLayout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import ClientsSideBar from "@/components/clients/ClientsSideBar.vue";
import BarChartCard from "@/components/base/BaseBarChartCard.vue";
import arrayToChart from "@/mixins/arrayToChart.js";
import Client from "@/api/clients.js";

export default {
  components: {
    BaselineLayout,
    ClientsSideBar,
    BarChartCard,
  },
  mixins: [arrayToChart],
  data() {
    return {
      dashboardData: null,
      clientTypesChart: null,
      clientTierChart: null,
    };
  },
  methods: {
    getDashboardSummary() {
      let vm = this;
      Client.clientsDashboard()
        .then((response) => {
          vm.dashboardData = response.data;
          vm.clientTypesChart = vm.arrayToChart(
            vm.dashboardData.client_types,
            "client_type__client_type",
            "id__count",
            "Clients by Type:"
          );
          vm.clientTierChart = vm.arrayToChart(
            vm.dashboardData.tier,
            "tier",
            "id__count",
            "Clients by Tier:"
          );

          //   vm.clientCreatedChart = vm.arrayToChart(
          //     vm.dashboardData.client_types,
          //     "client_type__client_type",
          //     "id__count",
          //     "Clients by Type:"
          //   );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDashboardSummary();
  },
};
</script>

<style></style>
