<template>
  <v-app-bar app dense color="marco" dark>
    <v-app-bar-nav-icon @click="toggleAppDrawer"></v-app-bar-nav-icon>
    <slot></slot>
    <!-- <v-chip small class="ml-3 white marco--text" @click="toggleMenuDrawer"
      >Menu</v-chip
    > -->

    <v-spacer></v-spacer>
    MGX Advisors
    <v-spacer></v-spacer>
    <v-btn v-if="isLoggedIn" dark color="grey" @click="dialog = true"
      >Log Out</v-btn
    >
    <!-- <v-avatar>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="marco" dark v-bind="attrs" v-on="on">
            {{ lang }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in languages"
            :key="index"
            @click="setLanguage(item.value)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-avatar> -->
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar color="primary" class="text-center" dark>Log Out</v-toolbar>
        <v-card-text>
          <div class="text-h3 pa-12">Are you sure you wish to log out?</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn large text color="error" @click="dialog = false">Cancel</v-btn>
          <v-btn large text color="success" @click="logout">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      languages: [
        { text: "English", value: "en" },
        { text: "Español", value: "es" },
      ],
    };
  },
  methods: {
    setLanguage(lang) {
      this.$store.dispatch("setLanguage", lang);
    },
    toggleAppDrawer() {
      this.$store.dispatch("main/toggleAppDrawer");
    },

    async logout() {
      this.dialog = false;
      await this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
    // toggleMenuDrawer() {
    //   this.$store.dispatch("main/toggleMenuDrawer");
    // },
  },
  computed: {
    appDrawer() {
      return this.$store.getters["app/menuDrawer"];
    },
    lang() {
      return this.$store.getters["lang"];
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>

<style></style>
