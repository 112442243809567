<template>
  <v-tab-item :value="1">
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            dense
            calculate-widths
            :headers="headers ? headers : []"
            :items="datatape ? datatape.results : []"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-container>
                <v-row>
                  <v-col justify="center" align="center">
                    <h3>Portfolio Datatape: {{ datatape.count }} Assets</h3>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="4" justify="center" align="center">
                    <v-text-field
                      solo
                      v-model="search"
                      label="Asset ID"
                      class="mx-4"
                      append-outer-icon="mdi-magnify"
                      append-icon="mdi-close"
                      @click:append-outer="searchDatatape"
                      @click:append="search = null"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-pagination
                      v-model="page"
                      :length="numPages"
                      total-visible="8"
                      @input="searchDatatape"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
      </v-row>
    </v-container>
  </v-tab-item>
</template>

<script>
let storeModule = "realEstatePortfolioDashboard";

export default {
  data() {
    return {
      search: null,
      page: 1,
    };
  },
  methods: {
    navigatDatatape() {
      this.$store.dispatch(`${storeModule}/getPortfolioDatatape`, {
        q: this.search,
        page: this.page,
      });
    },
    searchDatatape() {
      this.$store.dispatch(`${storeModule}/getPortfolioDatatape`, {
        q: this.search,
        page: this.page,
      });
    },
  },
  computed: {
    datatape() {
      return this.$store.getters[`${storeModule}/portfolioDatatape`];
    },
    numPages() {
      return Math.ceil(this.datatape.count / 50);
    },
    headers() {
      let headers = [];
      if (this.datatape) {
        if (this.datatape.results.length > 0) {
          for (let [key, value] of Object.entries(this.datatape.results[0])) {
            headers.push({ text: key, value: key, sortable: true });
            value;
          }
        }
      }
      return headers;
    },
  },
};
</script>

<style></style>
