<template>
  <Layout>
    <v-container fluid slot="main">
      <v-toolbar dense class="warning" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title><h2>Hospitality & Leisure</h2></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row justify="center">
        <!-- <v-col justify="center" align="center"> -->
        <v-radio-group
          row
          v-model="userInputs.dashboard"
          justify="center"
          align="center"
        >
          <v-radio label="National" value="national"></v-radio>
          <v-radio label="Regional" value="regional"></v-radio>
        </v-radio-group>
        <!-- </v-col> -->
      </v-row>

      <v-row justify="center" v-if="userInputs.dashboard === 'regional'">
        <!-- <v-col justify="center" align="center"> -->
        <v-radio-group row v-model="userInputs.geoLevel">
          <v-radio label="Comunidad" value="ccaa"></v-radio>
          <v-radio label="Province" value="provincia"></v-radio>
          <v-radio label="Municipality" value="municipio"></v-radio>
          <v-radio label="Zona Turística" value="zona_turistica"></v-radio>
          <v-radio label="Punto Turístico" value="punto_turistico"></v-radio>
        </v-radio-group>
        <!-- </v-col> -->
      </v-row>
      <v-row v-if="userInputs.dashboard === 'regional'" justify="center">
        <v-col v-if="userInputs.geoLevel === 'ccaa'" cols="4">
          <Combobox
            label="Comunidad Autonoma"
            :items="options.comunidades"
            item-value="id_ine"
            item-text="nombre"
            @input="refreshCharts"
            v-model="userInputs.ccaa"
          />
        </v-col>
        <v-col v-if="userInputs.geoLevel === 'provincia'" cols="4">
          <Combobox
            label="Province"
            :items="options.provincias"
            item-value="id_ine"
            item-text="nombre"
            @input="refreshCharts"
            v-model="userInputs.provincia"
          />
        </v-col>
        <v-col v-if="userInputs.geoLevel === 'municipio'" cols="4">
          <Combobox
            label="Municipality"
            item-value="id_ine"
            item-text="nombre"
            :items="options.municipios"
            @input="refreshCharts"
          />
        </v-col>
        <v-col v-if="userInputs.geoLevel === 'punto_turistico'" cols="4">
          <Combobox
            label="Punto Turistico"
            item-value="id_ine"
            item-text="nombre"
            :items="options.puntosTuristicos"
            @input="refreshCharts"
          />
        </v-col>
        <v-col v-if="userInputs.geoLevel === 'zona_turistica'" cols="4">
          <Combobox
            label="Zona Turistica"
            item-value="id_ine"
            item-text="nombre"
            :items="options.zonasTuristicas"
            @input="refreshCharts"
          />
        </v-col>
      </v-row>
      <!-- <v-row v-if="userInputs.dashboard === 'regional'" justify="center">
        {{ dashboardDatasets }}
      </v-row> -->

      <v-row v-if="userInputs.dashboard === 'national'">
        <v-col>
          <v-tabs grow>
            <v-tab v-for="tab in nationalTabs" :key="tab.number">
              {{ tab.name }}
            </v-tab>

            <v-tab-item v-for="tab in nationalTabs" :key="tab.number">
              <v-container fluid>
                <v-row justify="center">
                  <v-col
                    cols="6"
                    v-for="(chart, index) in tab.charts"
                    :key="index"
                  >
                    <v-card>
                      <v-card-text>
                        <IneColumnChart
                          v-if="chart.type === 'column'"
                          :hcTitle="chart.title"
                          :serie="chart.series[0]"
                        />
                        <IneSplineChart
                          v-if="chart.type === 'spline'"
                          :hcTitle="chart.title"
                          :serie1="chart.series[0]"
                          :serie2="chart.series[1]"
                        />
                        <IneMultiColumnChart
                          v-if="chart.type === 'stacked-columns'"
                          :hcTitle="chart.title"
                          :series="chart.series"
                        />
                        <IneMultiSplineChart
                          v-if="chart.type === 'multispline'"
                          :hcTitle="chart.title"
                          :series="chart.series"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row v-if="userInputs.dashboard === 'regional'">
        <v-col>
          <v-tabs grow>
            <v-tab v-for="tab in regionalTabs" :key="tab.number">
              {{ tab.name }}
            </v-tab>

            <v-tab-item v-for="tab in regionalTabs" :key="tab.number">
              <v-container fluid>
                <v-row justify="center">
                  <v-col
                    cols="6"
                    v-for="(chart, index) in tab.charts"
                    :key="index"
                  >
                    <v-card>
                      <v-card-text>
                        <IneColumnChart
                          v-if="chart.type === 'column'"
                          :hcTitle="chart.title"
                          :serie="chart.series[0]"
                        />
                        <IneSplineChart
                          v-if="chart.type === 'spline'"
                          :hcTitle="chart.title"
                          :serie1="chart.series[0]"
                          :serie2="chart.series[1]"
                        />
                        <IneMultiColumnChart
                          v-if="chart.type === 'stacked-columns'"
                          :hcTitle="chart.title"
                          :series="chart.series"
                        />
                        <IneMultiSplineChart
                          v-if="chart.type === 'multispline'"
                          :hcTitle="chart.title"
                          :series="chart.series"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/databank/ine/Layout.vue";
import Combobox from "@/components/base/Combobox.vue";

import IneAPI from "@/api/ine";

import IneColumnChart from "@/components/databank/ine/IneColumnChart.vue";
import IneSplineChart from "@/components/databank/ine/IneSplineChart.vue";
import IneMultiSplineChart from "@/components/databank/ine/IneMultiSplineChart.vue";
import IneMultiColumnChart from "@/components/databank/ine/IneMultiColumnChart.vue";

let varComunidades = 70;
let varProvincias = 115;
let varMunicipios = 19;
let varZonasTuristicas = 95;
let varPuntosTuristicos = 103;

export default {
  //   name: "EsriMap",
  components: {
    Layout,
    Combobox,
    IneColumnChart,
    IneSplineChart,
    IneMultiSplineChart,
    IneMultiColumnChart,
  },
  data() {
    return {
      colSize: 4,
      options: {
        comunidades: [],
        provincias: [],
        municipios: [],
        puntosTuristicos: [],
        zonasTuristicas: [],
      },
      userInputs: {
        dashboard: "national",
        geoLevel: "province",
        ccaa: null,
        provincia: null,
      },
      dashboardDatasets: null,
      nationalTabs: [
        {
          number: 1,
          name: "Travellers",
          charts: [
            {
              type: "column",
              title: "# Travellers: National",
              series: ["EOT1"],
            },
            {
              type: "spline",
              title: "Foreign vs. Domestic Travellers",
              series: ["EOT9", "EOT17"],
            },
            {
              type: "stacked-columns",
              title: "Total Travellers by Hotel Category",
              series: ["EOT2", "EOT3", "EOT4", "EOT5", "EOT6"],
            },
            {
              type: "stacked-columns",
              title: "Spanish Resident Travellers by Hotel Category",
              series: ["EOT10", "EOT11", "EOT12", "EOT13", "EOT14"],
            },
            {
              type: "stacked-columns",
              title: "Foregin Travellers by Hotel Category",
              series: ["EOT18", "EOT19", "EOT20", "EOT21", "EOT22"],
            },
          ],
        },
        {
          number: 2,
          name: "Occupancy",
          charts: [
            {
              type: "spline",
              title: "Occupancy",
              series: ["EOT57", "EOT58"],
            },
          ],
        },
        {
          number: 3,
          name: "Overnight Stays",
          charts: [
            {
              type: "column",
              title: "Accumulated Overnight Stays: National",
              series: ["EOT43198"],
            },
            {
              type: "stacked-columns",
              title: "Overnight Stays: Hotel Category",
              series: ["EOT26", "EOT27", "EOT28", "EOT29", "EOT30"],
            },
            {
              type: "stacked-columns",
              title: "Spanish Resident Overnight Stays: Hotel Category",
              series: ["EOT34", "EOT35", "EOT36", "EOT37", "EOT38"],
            },
            {
              type: "stacked-columns",
              title: "Foreign Traveller Overnight Stays: Hotel Category",
              series: ["EOT42", "EOT43", "EOT44", "EOT45", "EOT45"],
            },
          ],
        },
        {
          number: 4,
          name: "Pricing & Revenue", // Operacion IRSH
          charts: [
            {
              type: "column",
              title: "Average Daily Rate (ADR)",
              series: ["EOT16414"],
            },
            {
              type: "multispline",
              title: "Average Daily Rate (ADR): Hotel Category",
              series: [
                "EOT16456",
                "EOT16458",
                "EOT16460",
                "EOT16462",
                "EOT16464",
              ],
            },
            { type: "column", title: "RevPAR", series: ["EOT16470"] },
            {
              type: "multispline",
              title: "RevPAR: Hotel Category",
              series: [
                "EOT16512",
                "EOT16514",
                "EOT16516",
                "EOT16518",
                "EOT16520",
              ],
            },
          ],
        },
        {
          number: 5,
          name: "Expenditure",
          charts: [
            {
              type: "multispline",
              title: "Trip Length",
              series: ["FREG369"],
            },
            {
              type: "column",
              title: "Avg. Daily Expenditure",
              series: ["FREG373"],
            },
            { type: "column", title: "Avg. Expenditure", series: ["FREG374"] },
            {
              type: "multispline",
              title: "Avg. Daily Expenditure / person: Transport",
              series: ["FREG391", "FREG394", "FREG397", "FREG400"],
            },
            {
              type: "multispline",
              title: "Avg. Daily Expenditure / person: Lodging Segment",
              series: ["FREG418", "FREG421", "FREG424", "FREG400"],
            },
          ],
        },
      ],
    };
  },
  methods: {
    getVariableValores(variable) {
      IneAPI.valoresVariable(variable).then((response) => {
        if (variable === varComunidades) {
          this.options.comunidades = response.data;
        }
        if (variable === varProvincias) {
          this.options.provincias = response.data;
        }
        if (variable === varMunicipios) {
          this.options.municipios = response.data;
        }
        if (variable === varPuntosTuristicos) {
          this.options.puntosTuristicos = response.data;
        }
        if (variable === varZonasTuristicas) {
          this.options.zonasTuristicas = response.data;
        }
      });
    },
    refreshCharts(geoVarCode) {
      let ineVariable;
      if (this.userInputs.geoLevel === "ccaa") {
        ineVariable = varComunidades;
      }
      if (this.userInputs.geoLevel === "provincia") {
        ineVariable = varProvincias;
      }
      if (this.userInputs.geoLevel === "municipio") {
        ineVariable = varMunicipios;
      }
      if (this.userInputs.geoLevel === "zona_turistica") {
        ineVariable = varZonasTuristicas;
      }
      if (this.userInputs.geoLevel === "punto_turistico") {
        ineVariable = varPuntosTuristicos;
      }

      let params = {
        dashboard: "hospitality",
        variable: ineVariable,
        valor: geoVarCode,
      };
      IneAPI.dashboardDatasets(params).then((response) => {
        this.dashboardDatasets = response.data;
      });
    },
  },

  computed: {
    regionalTabs() {
      let regionalTabs;
      if (this.dashboardDatasets) {
        regionalTabs = [
          {
            number: 1,
            name: "Travellers",
            charts: [
              {
                type: "column",
                title: "# Travellers: National",
                series: this.dashboardDatasets.travellers.viajeros
                  ? [this.dashboardDatasets.travellers.viajeros]
                  : [],
              },
            ],
          },
          {
            number: 2,
            name: "Occupancy",
            charts: [
              {
                type: "spline",
                title: "Occupancy",
                series: [
                  this.dashboardDatasets.occupancy
                    .ocupacion_por_plazas_fin_de_semana,
                ],
              },
            ],
          },
          {
            number: 3,
            name: "Overnight Stays",
            charts: [
              {
                type: "column",
                title: "Accumulated Overnight Stays: National",
                series: [this.dashboardDatasets.overnight_stays.pernoctaciones],
              },
            ],
          },
          {
            number: 4,
            name: "Pricing & Revenue", // Operacion IRSH
            charts: [],
          },
          {
            number: 5,
            name: "Expenditure",
            charts: [
              {
                type: "multispline",
                title: "Trip Length",
                series: [this.dashboardDatasets.expenditure.estancia_media],
              },
            ],
          },
        ];
      } else {
        regionalTabs = [];
      }

      return regionalTabs;
    },
  },
  mounted() {
    this.getVariableValores(varComunidades);
    this.getVariableValores(varProvincias);
    this.getVariableValores(varMunicipios);
    this.getVariableValores(varPuntosTuristicos);
    this.getVariableValores(varZonasTuristicas);
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
