<template>
  <v-app>
    <baseline-layout>
      <v-container slot="main">
        <v-row justify="center">
          <v-col align="center">
            <h1>Microsoft Word</h1>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-container>
    </baseline-layout>
  </v-app>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";

export default {
  components: {
    BaselineLayout,
  },
};
</script>

<style></style>
