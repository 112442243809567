<template>
  <v-tab-item :value="tabNum">
    <v-container>
      <v-row>
        <v-col align="center" justify="center">
          <h3>Portfolio Top Assets</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            dense
            calculate-widths
            :headers="portfolioAssetsTable ? portfolioAssetsTable.headers : []"
            :items="portfolioAssetsTop ? portfolioAssetsTop.results : []"
            item-key="asset_id"
            class="elevation-1"
            :options="portfolioAssetsTable.options"
          >
            <template v-slot:top>
              <v-container>
                <v-row justify="center">
                  <v-col cols="4">
                    <Combobox
                      solo
                      label="Number of Items"
                      :items="topX"
                      v-model="n"
                      @input="getTopAssets"
                    />
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="portfolioAssetsTop">
                  <v-col cols="3">
                    <strong>Appraisal Value: </strong
                    >{{ portfolioAssetsTop.value }} ({{
                      portfolioAssetsTop.percent_value
                    }}%)
                  </v-col>
                  <v-col cols="3">
                    <strong>Units:</strong
                    >{{ portfolioAssetsTop.percent_assets }}%
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <!-- <template v-slot:item.raw_data="{ item }">
              <v-icon @click="getPortfolioAssetRawData(item)"
                >mdi-information-outline</v-icon
              >
            </template> -->
            <template v-slot:item.cadastral_reference_exists="{ item }">
              <v-icon
                color="green"
                v-if="item.fk_referencia_catastral || item.fk_referencia_finca"
                >mdi-check</v-icon
              >
              <v-icon
                color="red"
                v-if="
                  !item.fk_referencia_catastral && !item.fk_referencia_finca
                "
                >mdi-close</v-icon
              >
            </template>
            <template v-slot:item.cadastral_detail="{ item }">
              <v-icon
                v-if="item.fk_referencia_catastral || item.fk_referencia_finca"
                @click="getCatastroDetail(item)"
                >mdi-information-outline</v-icon
              >
            </template>
            <template v-slot:item.is_geolocated="{ item }">
              <v-icon color="green" v-if="item.geo_lng && item.geo_lat"
                >mdi-check</v-icon
              >
              <v-icon color="red" v-if="!(item.geo_lng && item.geo_lat)"
                >mdi-close</v-icon
              >
            </template>
            <template v-slot:item.units="{ item }">
              <div v-if="item.is_parking">€ / Unit</div>
              <div v-if="!item.is_parking">€ / m2</div>
            </template>
            <template v-slot:item.view_ask_comps_buy="{ item }">
              <v-chip
                small
                color="green"
                v-if="item.ask_comps_buy.length > 0"
                @click="openDialogAskBuy(item)"
                >{{ item.num_comps_ask_buy }}</v-chip
              >
            </template>
            <template v-slot:item.view_ask_comps_rent="{ item }">
              <v-chip
                small
                color="yellow"
                v-if="item.ask_comps_rent.length > 0"
                @click="openDialogAskRent(item)"
                >{{ item.num_comps_ask_rent }}</v-chip
              >
            </template>
            <template v-slot:item.view_closing_comps="{ item }">
              <v-chip
                small
                color="red"
                v-if="item.closing_comps.length > 0"
                @click="openDialogClosing(item)"
              >
                {{ item.num_comps_closing }}</v-chip
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>
    </v-container>
    <!-- DIALOG ASK COMPS BUY -->
    <v-dialog v-model="dialogAskBuy">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-if="portfolioAsset"
              dense
              show-select
              v-model="portfolioAssetSelections"
              :headers="portfolioAskCompsBuyTable.headers"
              :items="portfolioAsset[0].ask_comps_buy"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="marco white--text">
                  <v-toolbar-title>Asking Comps: Buy</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >{{ portfolioAsset[0].asset_id }} -
                    {{ portfolioAsset[0].asset_type }} -
                    {{ portfolioAsset[0].address }}</v-toolbar-title
                  >
                </v-toolbar>
              </template>
              <template #item.des_urlanuncio="{item}">
                <v-btn
                  x-small
                  class="primary"
                  :href="item.des_urlanuncio"
                  target="_blank"
                  >View</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <!-- DIALOG ASK COMPS RENT -->
    <v-dialog v-model="dialogAskRent">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-if="portfolioAsset"
              dense
              show-select
              v-model="portfolioAssetSelections"
              :headers="portfolioAskCompsRentTable.headers"
              :items="portfolioAsset[0].ask_comps_rent"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="marco white--text">
                  <v-toolbar-title>Asking Comps: Rent</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >{{ portfolioAsset[0].asset_id }} -
                    {{ portfolioAsset[0].asset_type }} -
                    {{ portfolioAsset[0].address }}</v-toolbar-title
                  >
                </v-toolbar>
              </template>
              <template #item.des_urlanuncio="{item}">
                <v-btn
                  x-small
                  class="primary"
                  :href="item.des_urlanuncio"
                  target="_blank"
                  >View</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <!-- DIALOG CLOSING COMPS -->
    <v-dialog v-model="dialogClosing">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-if="portfolioAsset"
              dense
              show-select
              v-model="portfolioAssetSelections"
              :headers="portfolioClosingCompsTable.headers"
              :items="portfolioAsset[0].closing_comps"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="marco white--text">
                  <v-toolbar-title>Closing Comps</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >{{ portfolioAsset[0].asset_id }} -
                    {{ portfolioAsset[0].asset_type }} -
                    {{ portfolioAsset[0].address }}</v-toolbar-title
                  >
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <!-- DIALOG CATASTRO DETAIL -->
    <v-dialog v-model="dialogCatastro" v-if="catastroDetail">
      <CatastroDetailCard :catastroData="catastroDetail" />
    </v-dialog>
  </v-tab-item>
</template>

<script>
import Combobox from "@/components/base/Combobox.vue";

import CatastroDetailCard from "@/components/catastro/CardCatastroDetail.vue";
let storeModule = "realEstatePortfolioDashboard";

export default {
  props: ["tabNum"],
  components: { Combobox, CatastroDetailCard },
  data() {
    return {
      n: 5,
      topX: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
      dialogAskBuy: false,
      dialogAskRent: false,
      dialogClosing: false,
      dialogCatastro: false,

      portfolioAssetsPage: 1,
      expanded: [],
      singleExpand: true,
      portfolioAsset: null,
      portfolioAssetSelections: null,
      portfolioAssetsTable: {
        headers: [
          { text: "", value: "data-table-expand" },
          {
            text: "Data",
            align: "center",
            sortable: false,
            value: "raw_data",
          },
          {
            text: "Asset ID",
            align: "start",
            sortable: false,
            value: "asset_id",
          },
          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "asset_type",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "address",
          },
          {
            text: "Area",
            align: "end",
            sortable: true,
            value: "area",
          },
          {
            text: "Appraisal Value",
            align: "end",
            sortable: true,
            value: "appraisal_value",
          },
          {
            text: "Cadastral Reference",
            align: "start",
            sortable: true,
            value: "cadastral_reference",
          },
          {
            text: "Cadastral Info",
            align: "center",
            sortable: true,
            value: "cadastral_detail",
          },
          {
            text: "Valid Cadastral Reference",
            align: "center",
            sortable: true,
            value: "cadastral_reference_exists",
          },
          {
            text: "Geolocation",
            align: "center",
            sortable: true,
            value: "is_geolocated",
          },
          {
            text: "Units",
            align: "center",
            sortable: false,
            value: "units",
          },
          {
            text: "Value",
            align: "center",
            sortable: true,
            value: "eur_sqm",
          },
          {
            text: "Ask",
            align: "center",
            sortable: true,
            value: "eur_sqm_ask_buy",
          },
          {
            text: "Close",
            align: "center",
            sortable: true,
            value: "eur_sqm_closing",
          },

          {
            text: "Ask Comps: Buy",
            align: "start",
            sortable: true,
            value: "view_ask_comps_buy",
          },
          {
            text: "Ask Comps: Rent",
            align: "start",
            sortable: true,
            value: "view_ask_comps_rent",
          },
          {
            text: "Closing Comps",
            align: "start",
            sortable: true,
            value: "view_closing_comps",
          },
        ],
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        search: null,
      },
      portfolioAskCompsBuyTable: {
        headers: [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha_fuente",
          },

          {
            text: "Source",
            align: "start",
            sortable: false,
            value: "fuente",
          },
          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "des_tipologia",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "des_direccion",
          },
          {
            text: "Area",
            align: "start",
            sortable: true,
            value: "num_supconstruida",
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "num_precioventa",
          },
          {
            text: "€ / m2",
            align: "start",
            sortable: true,
            value: "eur_sqm",
          },
          {
            text: "URL",
            align: "start",
            sortable: true,
            value: "des_urlanuncio",
          },
        ],
      },
      portfolioAskCompsRentTable: {
        headers: [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha_fuente",
          },

          {
            text: "Source",
            align: "start",
            sortable: false,
            value: "fuente",
          },
          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "des_tipologia",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "des_direccion",
          },
          {
            text: "Area",
            align: "start",
            sortable: true,
            value: "num_supconstruida",
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "num_precioalquiler",
          },
          {
            text: "€ / m2",
            align: "start",
            sortable: true,
            value: "eur_sqm",
          },
          {
            text: "URL",
            align: "start",
            sortable: true,
            value: "des_urlanuncio",
          },
        ],
      },
      portfolioClosingCompsTable: {
        headers: [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "f_escritura_corta",
          },

          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "tipo",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "calle_api",
          },
          {
            text: "Area",
            align: "start",
            sortable: true,
            value: "superficie",
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "valor",
          },
          {
            text: "€ / m2",
            align: "start",
            sortable: true,
            value: "valor_m",
          },
          {
            text: "Social Housing",
            align: "start",
            sortable: true,
            value: "id_vpo",
          },
        ],
      },
    };
  },
  methods: {
    openDialogAskBuy(item) {
      console.log(item);
      this.portfolioAsset = [item];
      this.dialogAskBuy = true;
    },
    openDialogAskRent(item) {
      console.log(item);
      this.portfolioAsset = [item];
      this.dialogAskRent = true;
    },
    openDialogClosing(item) {
      this.portfolioAsset = [item];
      this.dialogClosing = true;
    },
    getPortfolioAssets(pageNum) {
      this.$store.dispatch(`${storeModule}/getPortfolioAssets`, pageNum);
    },
    async getPortfolioAssetRawData(item) {
      await this.$store.dispatch(
        `${storeModule}/getPortfolioAssetRawData`,
        item.asset_id
      );
      this.dialogRawData = true;
    },

    async getCatastroDetail(item) {
      await this.$store.dispatch(
        `${storeModule}/getCatastroDetail`,
        item.cadastral_reference
      );
      this.dialogCatastro = true;
    },
    async getTopAssets() {
      await this.$store.dispatch(
        `${storeModule}/getPortfolioAssetsTop`,
        this.n
      );
    },

    searchPortfolioAssets() {
      this.$store.dispatch(
        `${storeModule}/searchPortfolioAssets`,
        this.portfolioAssetsTable.search
      );
    },
  },
  computed: {
    catastroDetail() {
      return this.$store.getters[`${storeModule}/catastroDetail`];
    },
    portfolio() {
      return this.$store.getters[`${storeModule}/portfolio`];
    },
    portfolioAssets() {
      return this.$store.getters[`${storeModule}/portfolioAssets`];
    },
    portfolioAssetsTop() {
      return this.$store.getters[`${storeModule}/portfolioAssetsTop`];
    },
    portfolioAssetRawData() {
      return this.$store.getters[`${storeModule}/portfolioAssetRawData`];
    },
    // portfolioAssetsTable() {
    //   return this.$store.getters[`${storeModule}/portfolioAssetsTable`];
    // },
    portfolioAssetsPages() {
      if (this.portfolioAssets) {
        return Math.ceil(this.portfolioAssets.count / 50);
      } else {
        return 0;
      }
    },
  },
};
</script>

<style></style>
