import Api from "./Api";

export default {
  deleteReportItem(itemId) {
    return Api.delete(`qoe/report-items/${itemId}/`);
  },
  reports() {
    return Api.get("qoe/reports");
  },
  postReport(form) {
    let id = form.get("id");
    if (id) {
      return Api.put(`qoe/reports/${id}/`, form);
    } else {
      return Api.post("qoe/reports/", form);
    }
  },
  postReportItem(form) {
    return Api.post(`qoe/report-items/`, form);
  },
  groups() {
    return Api.get("qoe/groups");
  },
  subgroups() {
    return Api.get("qoe/subgroups");
  },
  items() {
    return Api.get("qoe/items");
  },
  reportItems(reportId) {
    return Api.get(`qoe/report/${reportId}/items/`);
  },
  reportText(reportId) {
    return Api.get(`qoe/report-text/${reportId}`);
  },
  reportTextVariables(reportId) {
    return Api.get(`qoe/report-text-variables/${reportId}`);
  },
  reportTreeview(reportId) {
    return Api.get(`qoe/report-text-treeview/${reportId}`);
  },
  fillVars(reportId, params) {
    return Api.get(`qoe/report-text-full/${reportId}/`, { params: params });
  },
};
