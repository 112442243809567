<template>
  <v-textarea v-bind="$attrs" v-on="$listeners" class="text-subtitle-2">
  </v-textarea>
</template>

<script>
export default {};
</script>

<style></style>
