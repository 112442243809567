<template>
  <v-btn v-bind="$attrs" v-on="$listeners" fab small class="ma-3"
    ><v-icon>{{ icon }}</v-icon></v-btn
  >
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
