import Api from "./Api.js";
import store from "@/store/store.js";

Api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters["auth/accessToken"]}`;
  return config;
});

export default {
  createCatastroReport(portfolioId) {
    return Api.get(
      `real-estate/portfolio/${portfolioId}/tasks/create-catastro-report/`
    );
  },
  createCatastroAssets(portfolioId) {
    return Api.get(
      `real-estate/portfolio/${portfolioId}/tasks/load-catastro-references/`
    );
  },
  createPortfolioReport(portfolioId, report) {
    return Api.get(
      `real-estate/portfolio/${portfolioId}/tasks/create-${report}-report/`
    );
  },
  createStratsReport(portfolioId) {
    return Api.get(
      `real-estate/portfolio/${portfolioId}/tasks/create-strats-report/`
    );
  },
  createCompsReport(portfolioId) {
    return Api.get(
      `real-estate/portfolio/${portfolioId}/tasks/create-comps-report/`
    );
  },
  deletePortfolio(portfolioId) {
    return Api.delete(`real-estate/portfolios/${portfolioId}/`);
  },
  postPortfolio(form) {
    return Api.post("real-estate/portfolios/", form);
  },
  getPortfolios() {
    return Api.get("real-estate/portfolios/");
  },
  getPortfolio(portfolioId) {
    return Api.get(`real-estate/portfolios/${portfolioId}`);
  },
  getPortfolioDatatape(portfolioId, params) {
    return Api.get(`real-estate/portfolio/${portfolioId}/datatape/`, {
      params: params,
    });
  },
  getPortfolioAnalyisis(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/analysis/`);
  },
  getPortfolioAssetRawData(portfolioId, assetId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/assets/${assetId}`);
  },
  getPortfolioAssets(portfolioId, page) {
    let params = {};
    if (page) {
      params.page = page;
    }
    return Api.get(`real-estate/portfolio/${portfolioId}/assets/`, {
      params: params,
    });
  },
  getPortfolioAssetsFull(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/assets-full/`);
  },
  getPortfolioAssetsTop(portfolioId, n) {
    let params = {};
    if (n) {
      params.top_x = n;
    }
    return Api.get(`real-estate/portfolio/${portfolioId}/top-assets/`, {
      params: params,
    });
  },
  getPortfolioFields(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/columns/`);
  },
  getPortfolioStrats(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/strats/`);
  },
  getPortfolioFieldStrat(portfolioId, params) {
    console.log(params);
    return Api.get(`real-estate/portfolio/${portfolioId}/strat-field/`, {
      params: params,
    });
  },
  getPortfolioAssetTypes(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/asset-types/`);
  },
  getPortfolioAssetMap(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/asset-map/`);
  },
  getPortfolioFieldMap(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/field-map/`);
  },
  postPortfolioFieldMap(form) {
    let id = form.get("id");
    if (id != null) {
      return Api.put(`real-estate/portfolio-fieldmaps/${id}/`, form);
    } else {
      form.delete("id");
      return Api.post(`real-estate/portfolio-fieldmaps/`, form);
    }
  },
  postPortfolioAssetMap(form) {
    console.log(form);
    let id = JSON.parse(form.get("id"));

    let postForm = new FormData();
    postForm.append("file", form.get("file"));
    postForm.append("asset_map", form.get("asset_map"));
    console.log("ASSET MAP API", id);
    if (id) {
      return Api.put(`real-estate/portfolio-asset-maps/${id}/`, form);
    } else {
      form.delete("id");
      return Api.post(`real-estate/portfolio-asset-maps/`, form);
    }
  },
  patchPortfolioOptions(form) {
    let id = JSON.parse(form.get("id"));
    let postForm = new FormData();
    postForm.append("portfolio_options", form.get("portfolio_options"));

    if (id) {
      return Api.patch(`real-estate/portfolios/${id}/`, form);
    }
  },
  //   geolocatePortfolioAssets(portfolioId) {
  //     return Api.get(`real-estate/portfolio/${portfolioId}/tasks/geolocate/`);
  //   },
  //   getPortfolioComps(portfolioId) {
  //     return Api.get(`real-estate/portfolio/${portfolioId}/tasks/get-comps/`);
  //   },
  //   createPortfolioAssets(portfolioId) {
  //     return Api.get(`real-estate/portfolio/${portfolioId}/tasks/create-assets/`);
  //   },
  runPortfolioTask(portfolioId, task) {
    return Api.get(`real-estate/portfolio/${portfolioId}/tasks/${task}/`);
  },
  searchPortfolioAssets(portfolioId, q) {
    let params = {};
    if (q) {
      params.q = q;
    }
    return Api.get(`real-estate/portfolio/${portfolioId}/assets/`, {
      params: params,
    });
  },
  getPortfolioTasks(portfolioId) {
    return Api.get(`real-estate/portfolio/${portfolioId}/task-list/`);
  },
  downloadReport(portfolioId, report_name) {
    return Api.get(
      `real-estate/portfolio/${portfolioId}/download/${report_name}`
    );
  },
};
