<template>
  <div v-if="hcData">
    <highcharts class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import BellCurve from "highcharts/modules/histogram-bellcurve";
import { Chart } from "highcharts-vue";
BellCurve(Highcharts);

export default {
  props: ["hcTitle", "hcSubtitle", "hcData"],
  components: { highcharts: Chart },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column", // line / spline / area / areaspline / column / bar / pie / scatter / gauge / arearange / areasplinerange / columnrange
          scrollablePlotArea: {
            // minWidth: 700,
            scrollPositionX: 1,
            zoomType: "xy",
          },
        },
        title: {
          text: this.hcTitle,
        },
        subtitle: {
          text: this.hcSubtitle,
        },
        credits: {
          enabled: false,
        },
        series: this.hcData,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  enabled: false,
                },
              },
            },
          ],
        },
        tooltip: {
          backgroundColor: "#DDFCFF",
          borderColor: "black",
          borderRadius: 10,
          borderWidth: 1,
          crosshairs: [true, true],
          formatter: function() {
            return (
              "The value for <b>" +
              this.x +
              "</b> is <b>" +
              this.y +
              "</b>, in series " +
              this.series.name
            );
          },
        },
        xAxis: [
          {
            title: { text: "Data" },
            alignTicks: false,
          },
          {
            title: { text: "Histogram" },
            alignTicks: false,
            opposite: true,
          },
        ],
        yAxis: [
          {
            title: { text: "Data" },
          },
          {
            title: { text: "Histogram" },
            opposite: true,
          },
        ],
        plotOptions: {
          histogram: {
            accessibility: {
              pointDescriptionFormatter: function(point) {
                var ix = point.index + 1,
                  x1 = point.x.toFixed(3),
                  x2 = point.x2.toFixed(3),
                  val = point.y;
                return ix + ". " + x1 + " to " + x2 + ", " + val + ".";
              },
            },
          },
        },
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #42b983;
}
.hc {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
</style>
