<template>
  <v-navigation-drawer :value="drawer" app>
    <v-list dense>
      <v-list-item class="px-2">
        <v-spacer></v-spacer>
        <h2 class="marco--text">{{ currentUser }}</h2>
        <v-spacer></v-spacer>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item
        link
        v-for="item in sidebarItems"
        :key="item.title"
        router
        :to="item.link"
        class="app-sidebar-item"
      >
        <v-list-item-action>
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block>
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      sidebarItems: [
        {
          title: "Company",
          icon: "mdi-wrench",
          link: { name: "company-setup" },
        },
        {
          title: "Clients",
          icon: "mdi-account",
          link: { name: "client-dashboard" },
        },
        {
          title: "Contacts",
          icon: "mdi-account-box-multiple",
          link: { name: "contact-dashboard" },
        },
        {
          title: "Credentials",
          icon: "mdi-trophy",
          link: { name: "credentials" },
        },
        {
          title: "News",
          icon: "mdi-newspaper-variant-multiple-outline",
          link: { name: "news" },
        },
        { title: "Deals", icon: "mdi-handshake", link: { name: "deals" } },
        {
          title: "Workbenches",
          icon: "mdi-desktop-classic",
          link: { name: "workbench" },
        },
        {
          title: "Financials",
          icon: "mdi-finance",
          link: { name: "financials" },
        },
        // { title: "Real Estate", icon: "mdi-home", link: "./real-estate" },
        // { title: "Report Maker", icon: "mdi-file", link: { name: "reports" } },
        {
          title: "Data Bank",
          icon: "mdi-database",
          link: { name: "databank" },
        },
        {
          title: "GIS",
          icon: "mdi-earth",
          link: { name: "gis" },
        },
        {
          title: "Cloud Loader",
          icon: "mdi-cloud-upload",
          link: { name: "uploader" },
        },
        {
          title: "My Files",
          icon: "mdi-folder",
          link: { name: "files" },
        },
        {
          title: "Testing",
          icon: "mdi-test-tube",
          link: { name: "testing" },
        },
      ],
    };
  },
  computed: {
    drawer() {
      return this.$store.state.drawer;
    },
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },
};
</script>

<style scoped>
.app-sidebar-item {
  font-size: 8pt;
}
</style>
