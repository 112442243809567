<template>
  <Layout>
    <v-container slot="main" fluid>
      <v-row>
        <v-col v-for="card in cards" cols="4" :key="card.number">
          <v-card>
            <v-card-title class="marco white--text">{{
              card.title
            }}</v-card-title>
            <v-card-text>
              <v-data-table
                dense
                show-select
                single-select
                v-model="userSelections[card.value]"
                :items="apiData[card.value] ? apiData[card.value] : []"
                :headers="headers[card.value]"
                :hide-default-footer="true"
                @input="card.onSelect"
              >
                <template
                  v-if="card.value === 'employees'"
                  v-slot:item.detail="{ item }"
                >
                  <v-icon small color="marco" @click="employeeDetail(item)"
                    >mdi-eye-outline</v-icon
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/company/Layout.vue";
import CompanyAPI from "@/api/company";
export default {
  components: { Layout },
  data() {
    return {
      apiData: {
        parentCompanies: null,
        childCompanies: null,
        divisions: null,
        linesOfService: null,
        employeeCategories: null,
        employees: null,
      },
      headers: {
        parentCompanies: [
          { text: "ID", value: "id" },
          { text: "Name", value: "name_long" },
          { text: "Abrev.", value: "name_short" },
        ],
        childCompanies: [
          { text: "ID", value: "id" },
          { text: "Legal Name", value: "legal_name" },
          { text: "Abrev.", value: "short_name" },
        ],
        divisions: [
          { text: "Name", value: "name" },
          { text: "Description", value: "description" },
        ],
        linesOfService: [
          { text: "Abrev.", value: "short_name" },
          { text: "Name", value: "long_name" },
          { text: "Description", value: "description" },
        ],
        employeeCategories: [{ text: "Category", value: "name" }],
        employees: [
          { text: "Division", value: "division_name" },
          { text: "LoS", value: "los_name" },
          { text: "Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Detail", value: "detail" },
        ],
      },
      userSelections: {
        parentCompanies: [],
        childCompanies: [],
        divisions: [],
        linesOfService: [],
        employeeCategories: [],
        employees: [],
      },
      cards: [
        {
          number: 1,
          title: "Parent Companies",
          value: "parentCompanies",
          onSelect: this.onSelectParent,
        },
        {
          number: 2,
          title: "Child Companies",
          value: "childCompanies",
          onSelect: this.onSelectChild,
        },
        {
          number: 3,
          title: "Divisions",
          value: "divisions",
          onSelect: this.onSelectDivision,
        },
        {
          number: 4,
          title: "Lines of Service",
          value: "linesOfService",
          onSelect: this.onSelectLineOfService,
        },
        {
          number: 5,
          title: "Employee Categories",
          value: "employeeCategories",
          onSelect: this.onSelectEmployeeCategory,
        },
        {
          number: 6,
          title: "Employees",
          value: "employees",
          onSelect: this.onSelectEmployee,
        },
      ],
    };
  },
  methods: {
    employeeDetail(item) {
      alert(item.last_name);
    },
    getParentCompanies() {
      CompanyAPI.parentCompanies()
        .then((response) => {
          this.apiData.parentCompanies = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChildCompanies() {
      CompanyAPI.childCompanies()
        .then((response) => {
          this.apiData.childCompanies = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDivisions() {
      CompanyAPI.divisions()
        .then((response) => {
          this.apiData.divisions = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getLinesOfService() {
      CompanyAPI.linesOfService()
        .then((response) => {
          this.apiData.linesOfService = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getEmployeeCategories() {
      CompanyAPI.employeeCategories()
        .then((response) => {
          this.apiData.employeeCategories = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getEmployees() {
      CompanyAPI.employees()
        .then((response) => {
          this.apiData.employees = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSelectParent(item) {
      console.log(item[0].id);
      this.getChildCompanies();
    },
    onSelectChild(item) {
      console.log(item[0].id);
      this.getDivisions();
    },
    onSelectDivision(item) {
      console.log(item[0].id);
      this.getLinesOfService();
    },
    onSelectLineOfService(item) {
      console.log(item[0].id);
      this.getEmployeeCategories();
    },
    onSelectEmployeeCategory() {
      this.getEmployees();
    },
    onSelectEmployee(item) {
      console.log(item[0].id);
    },
  },
  computed: {},
  mounted() {
    this.getParentCompanies();
  },
};
</script>

<style></style>
