<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Client Search</h2> </template>
    <v-container slot="main">
      <clients-sidebar />
      <v-row>
        <v-col cols="12">
          <v-combobox
            multiple
            chips
            deletable-chips
            clearable
            label="Client Type"
            :items="clientTypes"
            item-text="client_type"
            item-value="id"
            v-model="selectedClientTypes"
            :change="comboChange"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <!-- CLIENT SEARCH -->
        <v-text-field
          label="Client Name"
          v-model="client"
          append-icon="mdi-magnify"
          @click:append="search"
        >
        </v-text-field>
      </v-row>
      <v-row justify="center">
        <v-col>
          <!-- CLIENT RESULTS -->
          <v-data-table
            v-model="selections"
            dense
            show-select
            :items="results"
            :item-key="'id'"
            :headers="columns"
          >
            <template #item.view="{item}">
              <v-btn x-small class="primary" @click="goToDetail(item.id)"
                >View</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row> </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import ClientsSideBar from "@/components/clients/ClientsSideBar.vue";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    "clients-sidebar": ClientsSideBar,
  },
  data() {
    return {
      client: "",
      selectedClientTypes: [],
      columns: [
        { text: "ID", value: "id" },
        { text: "Legal Name", value: "legal_name" },
        { text: "Tier", value: "tier" },
        { text: "VAT", value: "vat_number" },
        { text: "View", value: "view" },
      ],
      selections: [],
    };
  },
  methods: {
    search() {
      let payload = {
        name: this.client,
        types: this.selectedClientTypes.map((item) => item.id),
      };
      console.log(payload);
      this.$store.dispatch("clients/searchClients", payload);
    },
    comboChange() {
      alert(`Combobox changed!`);
    },
    getClientTypes() {
      this.$store.dispatch("clients/getClientTypes");
    },

    goToDetail(clientId) {
      this.$router.push({ name: "client-detail", params: { id: clientId } });
    },

    // getClientInfo(clientId) {
    //   this.$store.dispatch("clients/getClientInfo", clientId);
    //   this.$store.dispatch("clients/setComponent", "client-detail");
    // },
  },
  computed: {
    clientTypes() {
      return this.$store.state.clients.clientTypes;
    },
    results() {
      return this.$store.state.clients.searchResults;
    },
  },
  mounted() {
    this.getClientTypes();
  },
};
</script>

<style></style>
