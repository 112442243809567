<template>
  <v-container>
    <v-form v-if="searchResults.geo">
      <v-row dense justify="center">
        <v-col cols="6">
          <v-text-field
            dense
            outlined
            readonly
            label="Address"
            :value="
              searchResults.geo
                ? searchResults.geo.results.google_data.address
                : null
            "
          />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Longitude"
            :value="
              searchResults.geo
                ? searchResults.geo.results.google_data.lng
                : null
            "
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Latitude"
            :value="
              searchResults.geo
                ? searchResults.geo.results.google_data.lat
                : null
            "
          />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="CCAA"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.ccaa_name
                : null
            "
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Province"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.provincia_name
                : null
            "
          />
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Municipio"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.municipio_name
                : null
            "
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Distrito"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.distrito
                : null
            "
          />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Seccion Censal"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.seccion
                : null
            "
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// import DialogCatastroDetail from "@/components/real-estate/DialogCatastroDetail.vue";
export default {
  components: {
    // "z-dialog-catastro": DialogCatastroDetail,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    searchResults() {
      return this.$store.getters["realEstate/searchResults"];
    },
  },
};
</script>

<style></style>
