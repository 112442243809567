<template>
  <AppLayout>
    <!-- SLOT: NAVBAR MENU -->
    <NavbarMenu slot="navbar-menu" />
    <!-- SLOT: MENU DRAWER -->
    <MenuDrawer slot="menu-drawer" />
    <!-- SLOT: TOOL DRAWER -->
    <ToolDrawer slot="tool-drawer" />

    <slot name="main" slot="main"></slot>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";
import NavbarMenu from "@/components/company/NavbarMenu.vue";
import MenuDrawer from "@/components/company/MenuDrawer.vue";
import ToolDrawer from "@/components/company/ToolDrawer.vue";

export default {
  components: {
    AppLayout,
    NavbarMenu,
    MenuDrawer,
    ToolDrawer,
  },
};
</script>

<style></style>
