<template>
  <Layout>
    <v-container fluid slot="main">
      <v-toolbar dense class="warning">
        <v-spacer></v-spacer>
        <v-toolbar-title
          ><h2>
            Instituto Nacional de Estadística (INE) Wizard
          </h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row justify="center">
        <v-col align="center">
          <v-card>
            <v-card-title class="marco white--text"
              >INE Operations</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                      dense
                      fixed-header
                      show-select
                      single-select
                      height="600px"
                      :items="ineOperationsList ? ineOperationsList : []"
                      :headers="headers.ineOperations"
                      :items-per-page="-1"
                      hide-default-footer
                      v-model="userSelections.operations"
                      @input="onSelectOperation"
                      :search="search.operations"
                    >
                      <template v-slot:top>
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              outlined
                              label="Search Operations"
                              v-model="search.operations"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col align="center">
          <v-card>
            <v-card-title class="marco white--text">INE Datasets</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-overlay :absolute="true" :value="isBusy.seriesOperacion">
                      <v-progress-circular
                        indeterminate
                        color="marco"
                      ></v-progress-circular>
                    </v-overlay>

                    <v-tabs>
                      <v-tab
                        >Operacion:
                        <span v-if="userSelections.operations">{{
                          userSelections.operations[0].codigo
                        }}</span></v-tab
                      >
                      <v-tab
                        >Selected ({{ userSelections.dataset.length }})</v-tab
                      >

                      <v-tab-item>
                        <v-data-table
                          dense
                          fixed-header
                          show-select
                          v-model="userSelections.dataset"
                          height="500px"
                          :items="
                            apiData.seriesOperacion
                              ? apiData.seriesOperacion.results
                              : []
                          "
                          :headers="headers.seriesOperacion"
                          :items-per-page="-1"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <v-row justify="center">
                              <v-col cols="6">
                                <v-text-field
                                  label="Search Datasets"
                                  v-model="search.datasets"
                                  append-icon="mdi-magnify"
                                  append-outer-icon="mdi-format-list-numbered"
                                  @click:append="searchDatasets"
                                  @click:append-outer="viewAllDatasets"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col>
                                <h3 v-if="apiData.seriesOperacion">
                                  Results: {{ apiData.seriesOperacion.count }}
                                </h3>

                                <v-spacer></v-spacer>
                                <v-pagination
                                  v-model="userSelections.datasetPage"
                                  :length="numPages"
                                  :total-visible="7"
                                  circle
                                  small
                                  @input="navigateDatasets"
                                >
                                </v-pagination>
                                <v-spacer></v-spacer>
                              </v-col>
                            </v-row>
                          </template>

                          <template v-slot:item.chart="{ item }">
                            <v-icon @click="viewChart(item)"
                              >mdi-chart-line</v-icon
                            >
                          </template>
                        </v-data-table>
                      </v-tab-item>
                      <v-tab-item>
                        <v-data-table
                          dense
                          fixed-header
                          show-select
                          v-model="userSelections.dataset"
                          height="500px"
                          :items="
                            userSelections.dataset ? userSelections.dataset : []
                          "
                          :headers="headers.seriesOperacion"
                          :items-per-page="-1"
                          hide-default-footer
                        >
                          <template v-slot:top>
                            <v-row justify="center">
                              <v-col cols="6">
                                <v-text-field
                                  label="Search Datasets"
                                  v-model="search.datasets"
                                  append-icon="mdi-magnify"
                                  @click:append="searchDatasets"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col>
                                <v-btn
                                  small
                                  @click="clearSelectedDatasets"
                                  class="mx-3"
                                >
                                  <v-icon small>mdi-clear</v-icon>
                                  Clear</v-btn
                                >
                                <v-btn
                                  small
                                  @click="downloadDatasets"
                                  class="mx-3"
                                >
                                  <v-icon small>mdi-download</v-icon>
                                  Download</v-btn
                                >
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col>
                                <h2>
                                  Results: {{ userSelections.dataset.length }}
                                </h2>
                                <v-spacer></v-spacer>
                                <v-pagination
                                  v-model="userSelections.selectionsPage"
                                  :length="null"
                                  :total-visible="7"
                                  circle
                                  small
                                  @input="navigateDatasets"
                                >
                                </v-pagination>
                                <v-spacer></v-spacer>
                              </v-col>
                            </v-row>
                          </template>

                          <template v-slot:item.chart="{ item }">
                            <v-icon @click="viewChart(item)"
                              >mdi-chart-line</v-icon
                            >
                          </template>
                        </v-data-table>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="chartDialog.dialog" max-width="700px">
        <v-card>
          <v-card-title class="marco white--text">{{
            chartDialog.title
          }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col justify="center" align="center">
                  <IneColumnChart :hcTitle="null" :serie="chartDialog.serie" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="marco" dark @click="chartDialog.dialog = false"
              >Ok</v-btn
            >
            <v-btn
              color="success"
              dark
              @click="downloadDataset(chartDialog.serie)"
              >Download</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/databank/ine/Layout.vue";

import IneAPI from "@/api/ine";

import IneColumnChart from "@/components/databank/ine/IneColumnChart.vue";

export default {
  components: {
    Layout,
    IneColumnChart,
  },
  data() {
    return {
      chartDialog: {
        dialog: false,
        serie: null,
        title: null,
      },
      tab: 0,
      tabs: ["Search", "Selections", "Stat Groups"],

      operations: null,
      columns: ["cod", "nombre_eng", "view"],
      selectedDatasets: [],
      userSelections: {
        operations: null,
        variable: null,
        datasets: null,
        dataset: [],
        datasetPage: 1,
        selectionsPage: 1,
      },
      userInputs: {
        searchStr: null,
        apiQueryPageStart: 1,
      },
      search: {
        datasets: null,
        operations: null,
      },
      apiData: {
        seriesOperacion: null,
      },
      isBusy: {
        seriesOperacion: false,
      },
      headers: {
        seriesOperacion: [
          { text: "Code", value: "cod" },
          { text: "Name", value: "nombre" },
          { text: "View", value: "chart" },
        ],
        ineOperations: [
          { text: "Code", value: "codigo" },
          { text: "Name", value: "nombre" },
        ],
      },
    };
  },
  methods: {
    downloadOperacionDatasets(operacion) {
      let params = {
        operacion: operacion.codigo,
        start_page: this.userInputs.apiQueryPageStart,
      };
      IneAPI.loader("series-operacion", params).then((response) => {
        console.log(response);
      });
    },
    getValoresVariables(operacion) {
      let params = {
        operacion: operacion.codigo,
      };
      IneAPI.loader("valores-variables-series-operacion", params).then(
        (response) => {
          console.log(response);
        }
      );
    },
    loader(task, params) {
      IneAPI.loader(task, params);
    },
    async onSelectOperation() {
      let operacion = this.userSelections.operations[0].codigo;
      IneAPI.variablesOperacion(operacion).then((response) => {
        this.apiData.variablesOperacion = response.data.variable;
      });

      this.isBusy.seriesOperacion = true;
      await IneAPI.seriesOperacion(operacion).then((response) => {
        this.apiData.seriesOperacion = response.data;
      });
      this.isBusy.seriesOperacion = false;
    },

    getOperations() {
      this.$store.dispatch("databank/getOperations");
    },
    viewAllDatasets() {
      this.search.datasets = null;
      this.searchDatasets();
    },
    async searchDatasets() {
      let params = {};
      params.ine_operations = this.userSelections.operations[0].codigo;
      if (this.search.datasets) {
        params.q = this.search.datasets;
      }
      this.isBusy.seriesOperacion = true;
      await IneAPI.datasets(params).then((response) => {
        this.apiData.seriesOperacion = response.data;
      });
      this.isBusy.seriesOperacion = false;
    },
    async navigateDatasets(pageNum = null) {
      let params = {};
      params.ine_operations = this.userSelections.operations[0].codigo;
      if (this.search.datasets) {
        params.q = this.search.datasets;
      }
      if (pageNum) {
        params.page = pageNum;
      }

      this.isBusy.seriesOperacion = true;
      await IneAPI.datasets(params).then((response) => {
        this.apiData.seriesOperacion = response.data;
      });
      this.isBusy.seriesOperacion = false;
    },
    clearSelectedDatasets() {
      this.userSelections.dataset = [];
    },
    downloadDataset(serie) {
      let url = `http://localhost/ine/dataset/download/${serie}`;
      window.location.assign(url);
    },
    downloadDatasets() {
      let codList = [];
      this.userSelections.dataset.forEach((element) => {
        codList.push(element.cod);
      });
      let url = `http://localhost:8000/ine/datasets/download/?cod_list=${codList}`;
      window.location.assign(url);
    },
    viewChart(item) {
      this.chartDialog.title = item.nombre;
      this.chartDialog.serie = item.cod;
      this.chartDialog.dialog = true;
    },
  },

  computed: {
    ineOperationsList() {
      return this.$store.state.databank.ineOperationsList;
    },
    numPages() {
      if (this.apiData.seriesOperacion) {
        return Math.ceil(this.apiData.seriesOperacion.count / 100);
      } else {
        return 0;
      }
    },
  },
  created() {
    this.getOperations();
    this.getVariables();
  },
};
</script>

<style></style>
