<template>
  <v-container>
    <v-divider></v-divider>

    <v-row justify="center">
      <v-col cols="9">
        <v-data-table dense :items="employees" :headers="tableHeaders">
          <template v-slot:top>
            <v-row justify="center">
              <v-col cols="10">
                <h2 class="marco--text">Employees</h2>
              </v-col>
              <v-col cols="2">
                <v-btn small color="success" @click="createNewItem()"
                  >New</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template #item.edit="{item}">
            <v-icon small color="green" @click="setItem(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template #item.delete="{item}">
            <v-icon small color="red" @click="deleteObject(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="60%">
      <v-card>
        <v-card-title class="marco white--text"
          >Create New Employee</v-card-title
        >
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="2">
                <v-img
                  v-if="employeeForm.photo"
                  :src="employeeForm.photo"
                  height="150"
                  width="150"
                ></v-img>
              </v-col>
              <v-col cols="1" align-self="end">
                <v-file-input
                  v-model="employeeForm.photo"
                  label="Photo"
                  hide-input
                ></v-file-input>
              </v-col>
              <v-col cols="6" justify="end">
                <v-combobox
                  :items="linesOfServiceOptions"
                  v-model="employeeForm.los"
                  label="Line of Service"
                ></v-combobox>
                <v-combobox
                  :items="employeeCategories"
                  item-text="name"
                  item-value="id"
                  v-model="employeeForm.category"
                  label="Employee Category"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="4"
                v-for="userInput in userInputs"
                :key="userInput.key"
              >
                <v-text-field
                  v-if="userInput.type === 'text'"
                  :key="userInput.text"
                  v-model="employeeForm[userInput.variable]"
                  :counter="255"
                  :label="userInput.text"
                  required
                ></v-text-field>
                <v-textarea
                  v-if="userInput.type === 'textarea'"
                  v-model="employeeForm[userInput.variable]"
                  :counter="255"
                  :label="userInput.text"
                  required
                ></v-textarea>
                <v-menu
                  v-if="userInput.type === 'date'"
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="true"
                  :return-value.sync="datePicker"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="employeeForm[userInput.variable]"
                      :label="userInput.text"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datePicker"
                    no-title
                    reactive
                    scrollable
                    @input="employeeForm[userInput.variable] = datePicker"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- <v-row>{{ employeeForm }}</v-row> -->
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" v-on:click="dialog = false">Cancel</v-btn>
          <v-btn
            v-if="employeeForm.id"
            text
            color="warning"
            v-on:click="postEmployee()"
            >Update</v-btn
          >
          <v-btn
            v-if="!employeeForm.id"
            text
            color="success"
            v-on:click="postEmployee()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import CompanyAPI from "@/api/company";
export default {
  data() {
    return {
      apiData: {
        employee: null,
      },
      dialog: false,
      dateMenu: false,
      datePicker: null,
      date: null,
      options: [
        { value: "new", text: "Create New" },
        { value: "list", text: "List" },
      ],

      selectedObject: null,
      employeePhoto: null,

      employeeForm: {
        id: null,
        los: null,
        losOption: null,
        category: null,
        first_name: null,
        last_name: null,
        email: null,
        birthday: null,
        date_joined: null,
        date_leave: null,
        photo: null,
      },
      showTableColumns: [
        "id",
        "company",
        "category_name",
        "last_name",
        "first_name",
        "email",
        "edit",
        "delete",
      ],

      userInputs: [
        { key: 1, text: "First Name", variable: "first_name", type: "text" },
        { key: 2, text: "Last Name(s)", variable: "last_name", type: "text" },
        { key: 3, text: "Email", variable: "email", type: "text" },
        { key: 4, text: "Birthday", variable: "birthday", type: "date" },
        { key: 5, text: "Date Joined", variable: "date_joined", type: "date" },
        { key: 6, text: "Date Leave", variable: "date_leave", type: "date" },
      ],
    };
  },
  methods: {
    // async getEmployee(employeeId) {
    //   await CompanyAPI.getEmployee(employeeId).then((response) => {
    //     this.employeeForm = response.data;
    //   });
    //   this.dialog = true;
    // },
    postEmployee: function() {
      let form = new FormData();
      form.append("id", this.employeeForm.id);
      form.append("los", this.employeeForm.los.value);
      form.append("category", this.employeeForm.category.id);
      form.append("first_name", this.employeeForm.first_name);
      form.append("last_name", this.employeeForm.last_name);
      form.append("email", this.employeeForm.email);
      form.append("birthday", this.employeeForm.birthday);
      form.append("date_joined", this.employeeForm.date_joined);
      form.append("date_leave", this.employeeForm.date_leave);
      if (this.employeePhoto) {
        form.append("photo", this.employeePhoto);
      }
      this.$store.dispatch("company/postEmployee", form);
      this.dialog = false;
    },
    deleteObject(item) {
      this.$store.dispatch("company/deleteEmployee", item.id);
    },
    createNewItem: function() {
      this.clearItem();
      this.dialog = true;
    },
    async setItem(item) {
      await this.$store.dispatch("company/setEmployee", item);
      this.employeeForm = {
        id: item.id,
        los: null,
        category: item.category,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        birthday: item.birthday,
        date_joined: item.date_joined,
        date_leave: item.date_leave,
        photo: item.photo,
      };
      let txt =
        item.company + " - " + item.division_name + " - " + item.los_name;
      this.employeeForm.los = { text: txt, value: item.id };
      this.employeeForm.category = {
        name: item.category_name,
        id: item.category,
      };
      this.dialog = true;
    },
    clearItem: function() {
      this.selectedObject = null;
      this.employeeForm = {
        id: null,
        los: null,
        losOption: null,
        category: null,
        first_name: null,
        last_name: null,
        email: null,
        birthday: null,
        date_joined: null,
        date_leave: null,
      };
    },
  },
  computed: {
    employees() {
      return this.$store.state.company.employees;
    },
    employeeCategories() {
      return this.$store.state.company.employeeCategories;
    },
    linesOfService() {
      return this.$store.state.company.linesOfService;
    },
    linesOfServiceOptions() {
      let data = this.linesOfService;
      let options = [];
      data.forEach((item) => {
        let txt =
          item.division_company +
          " - " +
          item.division_name +
          " - " +
          item.long_name;
        options.push({ text: txt, value: item.id });
      });
      console.log(options);
      return options;
    },

    tableHeaders() {
      let headers = [];
      let data = this.employees;
      let tableColumns = this.showTableColumns;

      console.log(data[0]);
      if (data[0]) {
        let fields = Object.keys(data[0]);
        fields.push("edit");
        fields.push("delete");
        fields.forEach((field) => {
          if (tableColumns.includes(field)) {
            let field_txt = field.replace("_", " ").split(" ");
            console.log(field_txt);
            field_txt = field_txt.map((f) => {
              return f.charAt(0).toUpperCase() + f.slice(1);
            });
            field_txt = field_txt.join(" ");
            headers.push({ text: field_txt, value: field });
          }
        });
      }
      return headers;
    },
  },
  mounted: function() {
    this.$store.dispatch("company/getLinesOfService");
    this.$store.dispatch("company/getEmployeeCategories");
    this.$store.dispatch("company/getEmployees");
  },
};
</script>

<style scoped>
.controlButton {
  width: 120px;
  height: 50px;
  border-radius: 25px;
  margin: 10px;
}

.pane {
  width: 100vh;
  /* border: 1px solid lightgrey; */
  padding: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.user-form-input {
  margin: 10px;
}

.app-card {
  width: 300px;
}
.app-card img {
  width: 200px;
  align-content: center;
}
</style>
