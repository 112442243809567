<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Create New Contact</h2> </template>
    <v-container slot="main">
      <v-row>
        <SideBar />
        <v-col>
          <ContactForm :readonly="false" />
        </v-col>
      </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/contacts/SideBar.vue";

import ContactForm from "@/components/clients/ContactForm.vue";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
    ContactForm,
  },
  data() {
    return {
      clientData: null,
    };
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {},
};
</script>

<style></style>
