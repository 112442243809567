<template>
  <div>
    <highcharts class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import { Chart } from "highcharts-vue";
exportingInit(Highcharts);

export default {
  props: ["hcTitle", "hcSubtitle", "hcData"],
  components: { highcharts: Chart },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "pie",
          scrollablePlotArea: {
            scrollPositionX: 1,
            zoomType: "xy",
          },
        },
        title: {
          text: this.hcTitle,
        },
        subtitle: {
          text: this.hcSubtitle,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: this.hcData,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  enabled: false,
                },
              },
            },
          ],
        },
        tooltip: {
          backgroundColor: "#DDFCFF",
          borderColor: "black",
          borderRadius: 10,
          borderWidth: 1,
          crosshairs: [true, true],
          formatter: function() {
            return (
              "<b>" +
              this.point.name +
              "</b>: " +
              this.y +
              "(" +
              this.percentage +
              ")"
            );
          },
        },
        xAxis: {
          title: {
            text: "My X Axis",
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
              return this.value;
            },
          },
        },
        yAxis: {
          title: {
            text: "My Y Axis",
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
              return this.value;
            },
          },
        },
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #42b983;
}
.hc {
  align-self: center;
  justify-self: center;
  /* width: 100%; */
  /* height: 100%; */
}
</style>
