import axios from "axios";

import GeoAPI from "@/api/geo";
import RealEstateAPI from "@/api/real-estate";

export default {
  namespaced: true,
  state: {
    // Real Estate Application
    optionsTransaction: [
      { text: "Buy", value: "Compra" },
      { text: "Rent", value: "Alquiler" },
    ],
    optionsAssetType: [],
    optionsNumComps: [
      { text: 10, value: 10 },
      { text: 25, value: 25 },
      { text: 50, value: 50 },
      { text: 100, value: 100 },
      { text: 250, value: 250 },
    ],
    optionsBedrooms: [
      { text: 1, value: 1 },
      { text: 2, value: 2 },
      { text: 3, value: 3 },
      { text: 4, value: 4 },
      { text: 5, value: 5 },
      { text: 6, value: 6 },
      { text: 7, value: 7 },
      { text: 8, value: 8 },
    ],
    optionsArea: [
      { text: 50, value: 50 },
      { text: 75, value: 75 },
      { text: 100, value: 100 },
      { text: 125, value: 125 },
      { text: 150, value: 150 },
      { text: 175, value: 175 },
      { text: 200, value: 200 },
      { text: 225, value: 225 },
      { text: 250, value: 250 },
      { text: 275, value: 275 },
      { text: 300, value: 300 },
      { text: ">300", value: 999 },
    ],
    pageView: "search",
    searchParams: {
      address: null,
      tx: null,
      asset_type: null,
      num_comps: null,
      bedrooms: null,
      date_min: null,
      date_max: null,
      area_min: null,
      area_max: null,
      id_vpo: null,
    },
    searchResults: {
      geo: null,
      askCompsDetail: [],
      askCompsSummary: null,
      askCompsHistograms: null,
      askCompsEurSqm: null,
      closeCompsDetail: [],
      closeCompsSummary: null,
      catastroFincaData: null,
      catastroRefData: null,
    },
    // searchResults: {
    // 	geo: null,
    // 	asking:{},
    // 	closing:{},
    // 	catastro:{},
    //   },
    userSelections: {
      selectedCompsAsk: [],
      selectedCompsClose: [],
      eurSqm: {},
    },
    isBusy: {
      geo: false,
      askCompsDetail: false,
      askCompsSummary: false,
      askCompsHistograms: false,
      askCompsEurSqm: false,
      closeCompsDetail: false,
      closeCompsSummary: false,
      catastroFincaData: false,
      catastroRefData: false,
    },
    isError: {
      geo: false,
      askCompsDetail: false,
      askCompsSummary: false,
      askCompsHistograms: false,
      askCompsEurSqm: false,
      closeCompsDetail: false,
      closeCompsSummary: false,
      catastroFincaData: false,
      catastroRefData: false,
    },
  },
  getters: {
    fillData(state) {
      let labels = [];
      let backgroundColor = [];
      let borderColor = [];
      let chartData = [];
      let datacollection;
      if (state.pricingSummary) {
        datacollection = {};
        for (var [key, value] in Object.entries(
          state.searchResults.pricingSummary.hist_buy
        )) {
          labels.push(value);
          backgroundColor.push("rgba(0, 52, 89, 0.8)");
          borderColor.push("rgba(0, 52, 89, 1)");
          chartData.push(key);
        }

        datacollection["buy"] = {
          labels: Object.keys(state.pricingSummary.hist_buy),
          datasets: [
            {
              label: "Asking Price - Buy",
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              data: Object.values(state.pricingSummary.hist_buy),
              fill: false,
            },
          ],
        };

        datacollection["rent"] = {
          labels: Object.keys(state.pricingSummary.hist_rent),
          datasets: [
            {
              label: "Asking Price - Rent",
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              data: Object.values(state.pricingSummary.hist_rent),
              fill: false,
            },
          ],
        };
      }
      return datacollection;
    },
    pageView(state) {
      return state.pageView;
    },
    searchResults(state) {
      return state.searchResults;
    },
    askCompsDetail(state) {
      return state.searchResults.askCompsDetail;
    },
    askCompsSummary(state) {
      return state.searchResults.askCompsSummary;
    },
    closeCompsDetail(state) {
      return state.searchResults.closeCompsDetail;
    },
    mapCenter(state) {
      if (state.searchResults.geo) {
        return {
          lat: state.searchResults.geo.results.lat,
          lng: state.searchResults.geo.results.lng,
        };
      } else {
        return { lat: 40.416775, lng: -3.70379 };
      }
    },
  },
  mutations: {
    CLEAR_SEARCH_RESULTS(state) {
      state.searchResults = {
        geo: null,
        askCompsDetail: [],
        askCompsSummary: null,
        askCompsHistograms: null,
        askCompsEurSqm: null,
        closeCompsDetail: [],
        closeCompsSummary: null,
        catastroFincaData: null,
        catastroRefData: null,
      };
      state.userSelections = {
        selectedCompsAsk: [],
        selectedCompsClose: [],
      };
    },
    GET_ASSET_TYPES(state, optionsAssetType) {
      state.optionsAssetType = optionsAssetType;
    },
    GET_ASK_COMPS_DETAIL(state, askingComps) {
      state.searchResults.askCompsDetail = askingComps;
    },
    GET_ASK_COMPS_HISTOGRAM(state, histograms) {
      state.searchResults.askCompsHistograms = histograms;
    },
    GET_ASK_COMPS_SUMMARY(state, summary) {
      state.searchResults.askCompsSummary = summary;
    },
    GET_CLOSE_COMPS_DETAIL(state, closingComps) {
      state.searchResults.closeCompsDetail = closingComps;
    },
    GET_CLOSE_COMPS_HISTOGRAM(state, histograms) {
      state.searchResults.closePricingHistograms = histograms;
    },
    GET_CLOSE_COMPS_SUMMARY(state, summary) {
      state.searchResults.closeCompsSummary = summary;
    },
    getAskCompsPricingSummary(state, pricingSummary) {
      state.pricingSummary = pricingSummary;
    },
    GEOLOCATE_ADDRESS(state, geo_data) {
      state.searchResults.geo = geo_data;
    },
    getCatastroFincaData(state, catastroFincaData) {
      state.catastroFincaData = catastroFincaData;
    },
    getCatastroRefData(state, catastroRefData) {
      state.catastroRefData = catastroRefData;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
      state.searchParams = searchParams;
    },
    SET_PAGE_VIEW(state, view) {
      state.pageView = view;
    },
    UPDATE_SELECTED_COMPS(state, compsObject) {
      if (compsObject.compsGroup === "asking") {
        state.userSelections.selectedCompsAsk = compsObject.selectedComps;
      } else if (compsObject.compsGroup === "closing") {
        state.userSelections.selectedCompsClose = compsObject.selectedComps;
      }
    },
    UPDATE_COMPS_EUR_SQM(state, eurSqm) {
      state.userSelections.eurSqm = eurSqm;
    },
    IS_BUSY(state, params) {
      state.isBusy[params.process] = params.isBusy;
    },
    IS_ERROR(state, params) {
      state.isError[params.process] = params.isError;
    },
  },
  actions: {
    clearSearchResults(context) {
      context.commit("CLEAR_SEARCH_RESULTS");
    },
    getAssetTypes(context) {
      //
      RealEstateAPI.assetTypes()
        .then((response) => {
          context.commit("GET_ASSET_TYPES", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async search(context) {
      await context.dispatch("geolocateAddress");
      await context.dispatch("getAskComps");
      await context.dispatch("getCloseComps");
      //   context.dispatch("getCatastroFincaData");
    },

    async geolocateAddress(context) {
      context.commit("IS_BUSY", { process: "geo", isBusy: true });
      await GeoAPI.geolocateAddress(context.state.searchParams.address)
        .then((response) => {
          context.commit("GEOLOCATE_ADDRESS", response.data);
          context.commit("IS_BUSY", { process: "geo", isBusy: false });
        })
        .catch(() => {})
        .then(() => {
          context.commit("IS_BUSY", { process: "geo", isBusy: false });
        });
    },
    async getAskComps(context) {
      let params = {
        tx: context.state.searchParams.tx,
        asset_type: context.state.searchParams.asset_type,
        cusec: context.state.searchResults.geo.results.geo_data.seccion,
        lat: context.state.searchResults.geo.results.lat,
        lng: context.state.searchResults.geo.results.lng,
        num_obs: context.state.searchParams.num_comps,
        bedrooms: context.state.searchParams.bedrooms,
        area_min: context.state.searchParams.area_min,
        area_max: context.state.searchParams.area_max,
        date_min: context.state.searchParams.date_min,
        date_max: context.state.searchParams.date_max,
      };

      context.commit("IS_BUSY", { process: "askCompsDetail", isBusy: true });
      RealEstateAPI.getAskCompsDetail(params)
        .then((response) => {
          context.commit("GET_ASK_COMPS_DETAIL", response.data);
          context.commit("IS_BUSY", {
            process: "askCompsDetail",
            isBusy: false,
          });
        })
        .catch(() => {})
        .then(() => {
          context.commit("IS_BUSY", {
            process: "askCompsDetail",
            isBusy: false,
          });
        });

      context.commit("IS_BUSY", { process: "askCompsSummary", isBusy: true });
      RealEstateAPI.getAskCompsSummary(params)
        .then((response) => {
          context.commit("GET_ASK_COMPS_SUMMARY", response.data.results);
          context.commit("IS_BUSY", {
            process: "askCompsSummary",
            isBusy: false,
          });
        })
        .catch((error) => {
          console.log(error);
          context.commit("IS_BUSY", {
            process: "askCompsSummary",
            isBusy: false,
          });
        });

      // context.commit("IS_BUSY", {
      //   process: "askCompsHistograms",
      //   isBusy: true,
      // });
      // RealEstateAPI.getAskCompsHistogram(params)
      //   .then((response) => {
      //     context.commit("GET_ASK_COMPS_HISTOGRAM", response.data.results);
      //     context.commit("IS_BUSY", {
      //       process: "askCompsHistograms",
      //       isBusy: false,
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     context.commit("IS_BUSY", {
      //       process: "askCompsHistograms",
      //       isBusy: false,
      //     });
      //   });
    },
    async getCloseComps(context) {
      let params = {
        asset_type: context.state.searchParams.asset_type,
        cusec: context.state.searchResults.geo.results.geo_data.seccion,
        lat: context.state.searchResults.geo.results.lat,
        lng: context.state.searchResults.geo.results.lng,
        num_obs: context.state.searchParams.num_comps,
        id_vpo: context.state.searchParams.id_vpo,
        area_min: context.state.searchParams.area_min,
        area_max: context.state.searchParams.area_max,
        date_min: context.state.searchParams.date_min,
        date_max: context.state.searchParams.date_max,
      };
      console.log(params);

      await RealEstateAPI.getCloseCompsDetail(params)
        .then((response) => {
          context.commit("GET_CLOSE_COMPS_DETAIL", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      await RealEstateAPI.getCloseCompsSummary(params)
        .then((response) => {
          context.commit("GET_CLOSE_COMPS_SUMMARY", response.data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async browseAskingComps(context, direction) {
      let url;
      if (direction == "previous") {
        url = context.state.askingComps.previous;
      } else if (direction == "next") {
        url = context.state.askingComps.next;
      }
      await axios(url)
        .then((response) => {
          context.commit("getAskComps", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCatastroFincaData(context) {
      let url = "http://localhost/api/real-estate/catastro/";
      let params = {
        lat: context.state.googleAddress.lat,
        lng: context.state.googleAddress.lng,
      };
      await axios(url, {
        params: params,
      })
        .then((response) => {
          context.commit("getCatastroFincaData", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCatastroRefData(context, refCatastral) {
      let url = "http://localhost/api/real-estate/catastro/";
      let params = {
        ref: refCatastral,
      };
      await axios(url, {
        params: params,
      })
        .then((response) => {
          context.commit("getCatastroRefData", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSelectedComps(context, compsGroup, selectedComps) {
      context.commit("UPDATE_SELECTED_COMPS", compsGroup, selectedComps);
      //   context.dispatch("askCpdateCompsEurSqm");
    },
    askCpdateCompsEurSqm(context) {
      let eur_sqm = 0;
      let eur_sqm_ask;
      let eur_sqm_close;
      context.state.userSelections.selectedCompsAsk.forEach(function(item) {
        eur_sqm += item.num_precioventa / item.num_supconstruida;
      });
      eur_sqm_ask =
        eur_sqm / context.state.userSelections.selectedCompsAsk.length;

      eur_sqm = 0;
      context.state.userSelections.selectedCompsClose.forEach(function(item) {
        eur_sqm += item.valor / item.superficie;
      });
      eur_sqm_close =
        eur_sqm / context.state.userSelections.selectedCompsClose.length;

      context.commit("UPDATE_COMPS_EUR_SQM", {
        ask: eur_sqm_ask,
        close: eur_sqm_close,
      });
    },
    setSearchParams(context, searchParams) {
      context.commit("SET_SEARCH_PARAMS", searchParams);
    },
    setPageView(context, view) {
      context.commit("SET_PAGE_VIEW", view);
    },
  },
};
