<template>
  <Layout>
    <v-container fluid slot="main">
      <v-toolbar dense class="warning">
        <v-spacer></v-spacer>
        <v-toolbar-title
          ><h2>Instituto Nacional de Estadística (INE)</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row justify="center">
        <v-col align="center" cols="6">
          <v-card>
            <v-card-title class="marco white--text">INE Reports</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                      dense
                      fixed-header
                      height="500px"
                      :items="apiData.reports ? apiData.reports : []"
                      :headers="headers.reports"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                      <template v-slot:item.file="{ item }">
                        <v-icon
                          small
                          color="success"
                          @click="downloadReport(item.id)"
                          >mdi-file-excel</v-icon
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/databank/ine/Layout.vue";
import IneAPI from "@/api/ine";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      apiData: {
        reports: null,
      },

      selectedDatasets: [],
      userSelections: {
        operations: null,
        datasets: null,
      },
      headers: {
        reports: [
          { text: "Report", value: "nombre" },
          { text: "File", value: "file" },
        ],
      },
      reports: [{ name: "Population", value: "population" }],
    };
  },
  methods: {
    downloadReport(reportId) {
      let url = `http://localhost/ine/reports/${reportId}/download/`;
      window.location.assign(url);
    },
    getReports() {
      IneAPI.seriesCollections().then((response) => {
        this.apiData.reports = response.data.results;
      });
    },
  },

  computed: {},
  mounted() {
    this.getReports();
  },
};
</script>

<style></style>
