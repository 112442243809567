<template>
  <!-- PORTFOLIO OVERVIEW -->
  <v-tab-item :value="tabNum">
    <v-card flat>
      <v-card-text class="text--center">
        <v-container>
          <v-row justify="center" align="center">
            <v-col justify="center" align="center">
              <Btn
                v-for="btn in buttons"
                :key="btn.number"
                :color="btn.color"
                dark
                @click="btn.action"
              >
                {{ btn.text }}
              </Btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-if="userPortfolios">
        <v-data-table
          dense
          show-select
          v-model="selectedPortfolio"
          :single-select="portfolioTable ? portfolioTable.singleSelect : false"
          :headers="portfolioTable ? portfolioTable.headers : []"
          :items="userPortfolios ? userPortfolios : []"
          :search="portfolioTable.search"
          item-text="filename"
          item-value="id"
          @item-selected="selectPortfolio"
        >
          <template v-slot:top>
            <h3>
              User Portfolios
              <v-icon @click="refreshPortfolioInformation">mdi-refresh</v-icon>
            </h3>
          </template>
          <template v-slot:item.options="{ item }">
            <v-icon @click="getFileInfo(item)">mdi-information-outline</v-icon>
          </template>
          <template v-slot:item.completed_assets="{ item }">
            <v-icon color="green" v-if="item.completed_assets"
              >mdi-check-bold</v-icon
            >
            <v-icon color="red" v-if="!item.completed_assets">mdi-close</v-icon>
          </template>
          <template v-slot:item.completed_geolocation="{ item }">
            <v-icon color="green" v-if="item.completed_geolocation"
              >mdi-check-bold</v-icon
            >
            <v-icon color="red" v-if="!item.completed_geolocation"
              >mdi-close</v-icon
            >
          </template>
          <template v-slot:item.completed_catastro="{ item }">
            <v-icon color="green" v-if="item.completed_catastro"
              >mdi-check-bold</v-icon
            >
            <v-icon color="red" v-if="!item.completed_catastro"
              >mdi-close</v-icon
            >
          </template>
          <template v-slot:item.completed_comps="{ item }">
            <v-icon color="green" v-if="item.completed_comps"
              >mdi-check-bold</v-icon
            >
            <v-icon color="red" v-if="!item.completed_comps">mdi-close</v-icon>
          </template>
          <template v-slot:item.strat_report_file="{ item }">
            <v-icon
              color="green"
              v-if="item.strat_report_file"
              @click="downloadStratReport(item)"
              >mdi-file-excel</v-icon
            >
          </template>
          <template v-slot:item.comps_report_file="{ item }">
            <v-icon
              color="green"
              v-if="item.comps_report_file"
              @click="downloadCompsReport(item)"
              >mdi-file-excel</v-icon
            >
          </template>
          <template v-slot:item.cadastre_report_file="{ item }">
            <v-icon
              color="green"
              v-if="item.cadastre_report_file"
              @click="downloadCadastreReport(item)"
              >mdi-file-excel</v-icon
            >
          </template>
          <template v-slot:item.errors_report_file="{ item }">
            <v-icon
              color="green"
              v-if="item.errors_report_file"
              @click="downloadErrorsReport(item)"
              >mdi-file-excel</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card v-if="portfolioStrats">
      <v-card-text v-for="item of portfolioStrats" :key="item.field">
        <!-- {{ item.field }} -->
        <!-- {{ item.strat }} -->
        <!-- {{ stratData }} -->
        <StratWaterfallChart :stratData="item" />
        <!-- <StratChart
          :hcTitle="item.field"
          hcSubtitle="Appraisal Value"
          :stratData="item.strat"
          :xAxisTitle="null"
          yAxisTitle="Appraisal Value"
        /> -->
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete Portfolio?
        </v-card-title>
        <v-card-text
          >Are you sure you wish to delete portfolio
          <template v-if="selectedPortfolio">{{
            selectedPortfolio[0].id
          }}</template
          >. This action cannot be undone and all portfolio information will be
          lost</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn color="red darken-1" text @click="deletePortfolio">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG FILE INFO -->
    <v-dialog v-model="dialogFileInfo">
      <v-container v-if="selectedPortfolio">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Portfolio File Details</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="2"
                      ><strong>Size:</strong
                      >{{ selectedPortfolio[0].size / 1000000 }} MB</v-col
                    >
                    <v-col cols="2"
                      ><strong>Rows:</strong>
                      {{ selectedPortfolio[0].num_rows }}</v-col
                    >
                    <v-col cols="2"
                      ><strong>Columns:</strong>
                      {{ selectedPortfolio[0].num_cols }}</v-col
                    >
                    <v-col cols="2"
                      ><Combobox
                        label="Columns"
                        :items="selectedPortfolio[0].columns"
                    /></v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Portfolio Options</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-if="selectedPortfolio[0].portfolio_options.geolocation"
                    >
                      <strong>Geolocate using:</strong>
                      {{ selectedPortfolio[0].portfolio_options.geolocation }}
                    </v-col>
                    <v-col>
                      <strong>Asking Comps:</strong>
                      {{ selectedPortfolio[0].portfolio_options.asking }}
                    </v-col>
                    <v-col>
                      <strong>Closing Comps:</strong>
                      {{ selectedPortfolio[0].portfolio_options.closing }}
                    </v-col>
                    <v-col>
                      <strong>Cadastre Search:</strong>
                      {{ selectedPortfolio[0].portfolio_options.cadastre }}
                    </v-col>
                    <v-col>
                      <strong>Comps Search Criteria:</strong>
                      {{
                        selectedPortfolio[0].portfolio_options.search_criteria
                      }}
                    </v-col>
                    <v-col
                      v-if="
                        selectedPortfolio[0].portfolio_options.area_tolerance
                      "
                    >
                      <strong>Comps Search Area Tolerance:</strong>
                      {{
                        selectedPortfolio[0].portfolio_options.area_tolerance
                      }}%
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Portfolio Strat Fields</v-card-title>
              <v-card-text v-if="selectedPortfolio[0]">
                <strong>Strat Fields:</strong>
                {{ selectedPortfolio[0].strat_fields.join(", ") }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-tab-item>
</template>

<script>
import Btn from "@/components/base/buttons/Btn.vue";
import Combobox from "@/components/base/Combobox.vue";

// import StratChart from "@/components/real-estate/charts/StratChart.vue";
import StratWaterfallChart from "@/components/workbench/real-estate/portfolio-dashboard/charts/StratWaterfall.vue";

import Api from "@/api/Api";

let storeModule = "realEstatePortfolioDashboard";

export default {
  components: { Btn, Combobox, StratWaterfallChart }, // StratChart
  props: ["tabNum"],
  data() {
    return {
      buttons: [
        {
          number: 0,
          color: "marco",
          text: "Refresh Portfolio Assets",
          action: this.createPortfolioAssets,
        },
        {
          number: 1,
          color: "marco",
          text: "Create Catastro Assets",
          action: this.createCatastroAssets,
        },
        {
          number: 2,
          color: "marco",
          text: "Geolocate Assets",
          action: this.geolocatePortfolioAssets,
        },
        {
          number: 3,
          color: "marco",
          text: "Refresh Comps",
          action: this.getPortfolioComps,
        },
        {
          number: 4,
          color: "marco",
          text: "Create Strats Report",
          action: this.createStratsReport,
        },
        {
          number: 5,
          color: "marco",
          text: "Create Catastro Report",
          action: this.createCatastroReport,
        },
        {
          number: 6,
          color: "marco",
          text: "Create Comps Report",
          action: this.createCompsReport,
        },
        {
          number: 7,
          color: "marco",
          text: "Errors Report",
          action: this.createErrorsReport,
        },
        {
          number: 8,
          color: "red",
          text: "Delete",
          action: this.toggleDialog,
        },
      ],
      dialog: false,
      dialogFileInfo: false,
      selectedPortfolio: null,
    };
  },
  methods: {
    async getFileInfo(item) {
      this.selectedPortfolio = [item];
      await this.$store.dispatch(`${storeModule}/selectPortfolio`, item.id);
      this.dialogFileInfo = true;
    },
    selectPortfolio(event) {
      this.$store.dispatch(`${storeModule}/selectPortfolio`, event.item.id);
    },
    createPortfolioAssets() {
      this.$store.dispatch(`${storeModule}/runPortfolioTask`, "create-assets");
    },
    createCatastroAssets() {
      this.$store.dispatch(
        `${storeModule}/runPortfolioTask`,
        "load-catastro-references"
      );
    },
    toggleDialog() {
      if (this.selectedPortfolio) {
        this.dialog = !this.dialog;
      } else {
        alert(
          "No Portfolio has been selected! Please Select a Portfolio to delete"
        );
      }
      //   this.$store.dispatch(`${storeModule}/deletePortfolio`);
    },
    deletePortfolio() {
      this.$store.dispatch(`${storeModule}/deletePortfolio`);
    },
    geolocatePortfolioAssets() {
      this.$store.dispatch(`${storeModule}/runPortfolioTask`, "geolocate");
    },
    getPortfolioComps() {
      this.$store.dispatch(`${storeModule}/runPortfolioTask`, "get-comps");
    },
    createCatastroReport() {
      this.$store.dispatch(
        `${storeModule}/runPortfolioTask`,
        "create-catastro-report"
      );
    },
    createCompsReport() {
      this.$store.dispatch(
        `${storeModule}/runPortfolioTask`,
        "create-comps-report"
      );
    },
    createErrorsReport() {
      this.$store.dispatch(
        `${storeModule}/runPortfolioTask`,
        "create-errors-report"
      );
    },
    createStratsReport() {
      this.$store.dispatch(
        `${storeModule}/runPortfolioTask`,
        "create-strats-report"
      );
    },

    async downloadCadastreReport(item) {
      let download_link = `${Api.defaults.baseURL}/real-estate/portfolio/${item.id}/download/cadastre-report/`;
      window.location.href = download_link;
    },
    async downloadCompsReport(item) {
      let download_link = `${Api.defaults.baseURL}/real-estate/portfolio/${item.id}/download/comps-report/`;
      window.location.href = download_link;
    },
    async downloadErrorsReport(item) {
      let download_link = `${Api.defaults.baseURL}/real-estate/portfolio/${item.id}/download/errors-report/`;
      window.location.href = download_link;
    },
    async downloadStratReport(item) {
      let download_link = `${Api.defaults.baseURL}/real-estate/portfolio/${item.id}/download/strats-report/`;
      window.location.href = download_link;
    },
    refreshPortfolioInformation() {
      this.$store.dispatch(`${storeModule}/refreshPortfolioInformation`);
    },
  },
  computed: {
    userPortfolios() {
      return this.$store.getters[`${storeModule}/userPortfolios`];
    },
    portfolioTable() {
      return this.$store.getters[`${storeModule}/portfolioTable`];
    },
    portfolioStrats() {
      return this.$store.getters[`${storeModule}/portfolioStrats`];
    },
  },
};
</script>

<style></style>
