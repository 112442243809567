<template>
  <v-app>
    <Layout>
      <v-container slot="main">
        <v-toolbar dense class="warning">
          <v-spacer></v-spacer>
          <v-toolbar-title><h2>Catastro Online</h2></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row justify="center">
          <v-radio-group row v-model="userInputs.searchType" justify="center">
            <v-radio label="Address" value="address"></v-radio>
            <v-radio label="Cadastral Reference" value="reference"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row justify="center">
          <v-col cols="6">
            <TextField
              outlined
              label="Search Address"
              v-model="userInputs.address"
              v-if="userInputs.searchType === 'address'"
              append-icon="mdi-magnify"
              append-outer-icon="mdi-file-excel"
              @click:append="search"
              @click:append-outer="downloadReport"
            />
            <TextField
              outlined
              label="Cadastral Reference"
              v-model="userInputs.referenciaCatastralSearch"
              v-if="userInputs.searchType === 'reference'"
              append-icon="mdi-magnify"
              append-outer-icon="mdi-file-excel"
              @click:append="search"
              @click:append-outer="downloadReport"
            />
          </v-col>
        </v-row>

        <!-- SEARCH RESULTS -->
        <!-- <v-row>
          <v-col>
            {{ datosNoProtegidosReferencia }}
          </v-col>
          <v-col>
            {{ datosNoProtegidosFinca }}
          </v-col>
        </v-row> -->
        <v-row
          class="mt-4"
          justify="center"
          v-if="
            userInputs.searchType === 'reference' ||
              userInputs.searchType === 'address'
          "
        >
          <v-col>
            <v-card elevation="16">
              <v-card-title
                >Results:
                <v-spacer></v-spacer>
                Finca: {{ referenciaCatastralFinca }}
                <v-spacer></v-spacer>
                Referencia: {{ referenciaCatastral }}
              </v-card-title>

              <v-card-text>
                <v-tabs fixed-tabs background-color="marco" dark v-model="tab">
                  <!-- <v-tab>
                  Summary
                </v-tab> -->
                  <v-tab>
                    Finca
                  </v-tab>
                  <v-tab>
                    Referencia
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <!-- <v-tab-item tab="0"> </v-tab-item> -->
                  <v-tab-item tab="1">
                    <v-container
                      v-if="
                        datosNoProtegidosFinca && datosNoProtegidosFinca.results
                      "
                    >
                      <v-row justify="center">
                        <v-data-table
                          show-select
                          single-select
                          v-model="selectedReferenciaCatastral"
                          item-key="referencia_catastral"
                          :items="
                            datosNoProtegidosFinca
                              ? datosNoProtegidosFinca.results
                              : []
                          "
                          :headers="headers"
                          hide-default-footer
                          :items-per-page="-1"
                        >
                        </v-data-table>
                      </v-row>
                      <v-row>
                        <Button
                          text="Search"
                          color="success"
                          @click="searchSelected"
                        />
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item tab="2">
                    <v-container v-if="datosNoProtegidosReferencia">
                      <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel>
                          <v-expansion-panel-header color="marco white--text"
                            >Domicilio Tributario</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="mt-2">
                            <strong>Domicilio Tributario:</strong>
                            {{
                              datosNoProtegidosReferencia.results
                                .domicilio_tributario
                            }}
                          </v-expansion-panel-content>
                          <v-expansion-panel-content class="mt-2">
                            <strong>Municipio:</strong>
                            {{
                              datosNoProtegidosReferencia.results.localizacion
                                .nombre_municipio
                            }}
                          </v-expansion-panel-content>
                          <v-expansion-panel-content class="mt-2">
                            <strong>Provincia:</strong>
                            {{
                              datosNoProtegidosReferencia.results.localizacion
                                .nombre_provincia
                            }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header color="marco white--text"
                            >Datos Economicos</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="mt-2">
                            <strong>Uso Principal:</strong>
                            {{
                              datosNoProtegidosReferencia.results
                                .datos_economicos.uso_principal
                            }}
                          </v-expansion-panel-content>
                          <v-expansion-panel-content class="mt-2">
                            <strong>Superficie:</strong>
                            {{
                              datosNoProtegidosReferencia.results
                                .datos_economicos.superficie
                            }}
                          </v-expansion-panel-content>
                          <v-expansion-panel-content class="mt-2">
                            <strong>Coeficiente de Participación:</strong>
                            {{
                              datosNoProtegidosReferencia.results
                                .datos_economicos.coef_particip
                            }}
                          </v-expansion-panel-content>
                          <v-expansion-panel-content class="mt-2">
                            <strong>Antiguedad:</strong>
                            {{
                              datosNoProtegidosReferencia.results
                                .datos_economicos.antiguedad
                            }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header color="marco white--text"
                            >Unidades Constructivas</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="mt-2">
                            <v-data-table
                              :items="
                                datosNoProtegidosReferencia.results
                                  .uds_constructivas
                              "
                              :headers="headersUnidadesConstructivas"
                            >
                            </v-data-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                          v-if="
                            datosNoProtegidosReferencia.results.subparcelas
                              .length > 0
                          "
                        >
                          <v-expansion-panel-header color="marco white--text"
                            >Subparcelas</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="mt-2">
                            <!-- <v-data-table
                        :items="datosNoProtegidos.results.uds_constructivas"
                        :headers="headersUnidadesConstructivas"
                      >
                      </v-data-table> -->
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </Layout>
  </v-app>
</template>

<script>
import Layout from "@/components/databank/catastro/Layout.vue";
// import Combobox from "@/components/base/Combobox.vue";
import Button from "@/components/base/buttons/Button.vue";
import TextField from "@/components/base/TextField.vue";

import Catastro from "@/api/catastro.js";
// import Google from "@/api/google.js";
import GeoAPI from "@/api/geo.js";

// import { saveAs } from "file-saver";

export default {
  components: {
    Layout,
    Button,
    // Combobox,
    TextField,
  },
  data() {
    return {
      userInputs: {
        address: null,
        referenciaCatastralSearch: null,
        searchType: "reference",
      },
      apiData: {
        geoAddress: null,
      },
      catastroData: null,
      datosNoProtegidosFinca: null,
      datosNoProtegidosReferencia: null,
      headers: [
        { text: "Reference", value: "referencia_catastral" },
        { text: "Bloque", value: "localizacion_urbana.bloque" },
        { text: "Escalera", value: "localizacion_urbana.escalera" },
        { text: "Planta", value: "localizacion_urbana.planta" },
        { text: "Puerta", value: "localizacion_urbana.puerta" },
      ],
      headersUnidadesConstructivas: [
        { text: "Uso", value: "uso" },
        { text: "Escalera", value: "escalera" },
        { text: "Portal", value: "portal" },
        { text: "Puerta", value: "puerta" },
        { text: "Superficie", value: "superficie" },
      ],
      panel: [0, 1, 2],

      referenciaCatastral: null,
      referenciaCatastralFinca: null,
      selectedReferenciaCatastral: null,

      tab: 0,
    };
  },
  methods: {
    downloadReport() {
      Catastro.referenciaReport(this.userInputs.referenciaCatastralSearch).then(
        (response) => {
          window.location.assign(
            response.config.baseURL + "/" + response.config.url
          );
        }
      );
    },
    enrichDatatape() {
      let form = new FormData();

      if (this.datatapeFileLoad !== null) {
        form.append("id", this.datatapeFileLoad);
      } else {
        form.append("file", this.datatapeFile);
      }
      form.append("cadRefCol", this.cadRefCol);
      Catastro.datatapeReport(form).then(() => {
        this.$store.dispatch("successSnackbar", {
          text: "Datatape report added to task Queue!!!",
        });
      });
    },
    getDatatapeColumns() {
      let form = new FormData();
      form.append("file", this.datatapeFile);
      Catastro.datatapeColumns(form).then((response) => {
        this.datatapeColumns = response.data;
      });
    },
    getDNP(referenciaCatastral) {
      Catastro.referenciaCatastralDNP(referenciaCatastral).then((response) => {
        if (response.data.num_inmuebles === 1) {
          this.datosNoProtegidosReferencia = response.data;
          this.referenciaCatastral = referenciaCatastral;
          this.referenciaCatastralFinca = referenciaCatastral.substring(0, 14);
          this.tab = 1;
          this.getDNPFinca(this.referenciaCatastralFinca);
        } else {
          this.datosNoProtegidosFinca = response.data;
          this.referenciaCatastralFinca = referenciaCatastral;
          this.tab = 0;
        }
      });
    },
    getDNPFinca(referenciaCatastral) {
      Catastro.referenciaCatastralDNP(referenciaCatastral).then((response) => {
        this.datosNoProtegidosFinca = response.data;
        this.referenciaCatastralFinca = referenciaCatastral;
        this.tab = 0;
      });
    },
    getUserDatatapes() {
      Catastro.getUserDatatapes().then((response) => {
        this.datatapesUser = response.data;
      });
    },
    geolocateAddress() {
      GeoAPI.geolocateAddress(this.userInputs.address).then((response) => {
        this.apiData.geoAddress = response.data.results.google_data;
        this.getReferenciaFromCoords();
      });
    },
    getReferenciaFromCoords() {
      Catastro.referenciaFromCoords({
        lat: this.apiData.geoAddress.lat,
        lng: this.apiData.geoAddress.lng,
      }).then((response) => {
        this.catastroData = response.data;
        this.referenciaCatastralFinca = this.catastroData.referencia_catastral;
        this.getDNP(this.referenciaCatastralFinca);
      });
    },
    loadDatatapeObject() {
      let form = new FormData();
      form.append("id", this.datatapeFileLoad);
      Catastro.datatapeColumns(form).then((response) => {
        this.datatapeColumns = response.data;
      });
    },
    search() {
      if (this.userInputs.searchType === "reference") {
        this.getDNP(this.userInputs.referenciaCatastralSearch);
      } else if (this.userInputs.searchType === "address") {
        this.geolocateAddress();
      }
    },
    searchSelected() {
      this.referenciaCatastral = this.selectedReferenciaCatastral[0].referencia_catastral;
      Catastro.referenciaCatastral(this.referenciaCatastral).then(
        (response) => {
          this.datosNoProtegidosReferencia = response.data;
          this.tab = 1;
        }
      );
    },
  },
  mounted() {
    this.getUserDatatapes();
  },
};
</script>

<style></style>
