<template>
  <v-container slot="main">
    <v-row><h3>Client List View</h3></v-row>
    <v-row>{{ $router.params }}</v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
