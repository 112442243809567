<template>
  <v-container>
    <v-form v-if="searchResults.geo">
      <v-row dense justify="center">
        <v-col cols="6">
          <v-text-field
            dense
            outlined
            label="Client Name (Long)"
            v-model="reportInputs.clientNameLong"
          />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            label="Client Name (Short)"
            v-model="reportInputs.clientNameShort"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            label="Report Date"
            v-model="reportInputs.date"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            label="Engament Leader 1"
            v-model="reportInputs.engagementLeader1"
          />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            label="Engament Leader 2"
            v-model="reportInputs.engagementLeader2"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Province"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.provincia_name
                : null
            "
          />
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Municipio"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.municipio_name
                : null
            "
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Distrito"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.distrito
                : null
            "
          />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="3">
          <v-text-field
            dense
            outlined
            readonly
            label="Seccion Censal"
            :value="
              searchResults.geo
                ? searchResults.geo.results.geo_data.seccion
                : null
            "
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// import DialogCatastroDetail from "@/components/real-estate/DialogCatastroDetail.vue";
export default {
  components: {
    // "z-dialog-catastro": DialogCatastroDetail,
  },
  data() {
    return {
      reportInputs: {
        date: null,
        clientNameLong: null,
        clientNameShort: null,
        engagementLeader1: null,
        engagementLeader2: null,
        context: null,
      },
    };
  },
  methods: {},
  computed: {
    searchResults() {
      return this.$store.getters["realEstate/searchResults"];
    },
  },
};
</script>

<style></style>
