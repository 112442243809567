<template>
  <v-card dense elevation="12" height="300" width="300" class="mx-auto">
    <v-card-text>
      <v-container>
        <v-row dense justify="center">
          <v-col align="center">
            <v-img :src="img" height="150" width="200"></v-img>
          </v-col>
          <!-- <v-col>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-subtitle v-if="subtitle">{{ subtitle }}</v-card-subtitle>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn block text color="marco accent-4" :to="to">
        Enter
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
  <!-- <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline">{{ title }}</v-list-item-title>
        <v-list-item-subtitle v-if="subtitle">{{
          subtitle
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->
  <!-- <v-card-text>
      {{ text }}
    </v-card-text> -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default() {
        return "Card Title";
      },
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    img: {
      required: false,
      default() {
        return require("@/assets/NPL.png");
      },
    },
    text: {
      type: String,
      required: false,
      default() {
        return "Card Text";
      },
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
