<template>
  <v-container>
    <v-divider></v-divider>

    <v-row justify="center">
      <v-col cols="8">
        <v-data-table dense :items="linesOfService" :headers="tableHeaders">
          <template v-slot:top>
            <v-row>
              <v-col cols="10">
                <h2 class="marco--text">Lines of Service</h2>
              </v-col>
              <v-col cols="2">
                <v-btn small color="success" @click="createNewItem()"
                  >New</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template #item.edit="{item}">
            <v-icon small color="green" @click="editLineOfService(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template #item.delete="{item}">
            <v-icon small color="red" @click="deleteObject(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="marco white--text"
          >Create Line of Service</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row class="my-1">
              <v-col cols="12">
                <v-combobox
                  :items="divisionOptions"
                  v-model="losForm.division"
                  label="Division"
                ></v-combobox>
                <div v-for="i in userInputs.length" :key="i">
                  <v-text-field
                    v-if="userInputs[i - 1].type === 'text'"
                    :key="userInputs[i - 1].text"
                    v-model="losForm[userInputs[i - 1].variable]"
                    :counter="255"
                    :label="userInputs[i - 1].text"
                    required
                  ></v-text-field>
                  <v-textarea
                    v-if="userInputs[i - 1].type === 'textarea'"
                    v-model="losForm[userInputs[i - 1].variable]"
                    :counter="255"
                    :label="userInputs[i - 1].text"
                    required
                    outlined
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" v-on:click="dialog = false">Cancel</v-btn>
          <v-btn
            v-if="losForm.id"
            text
            color="warning"
            v-on:click="postLineOfService()"
            >Update</v-btn
          >
          <v-btn
            v-if="!losForm.id"
            text
            color="success"
            v-on:click="postLineOfService()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      endpointDivision: "http://localhost/api/company/division/",
      getEndpoint: "http://localhost/api/company/line-of-service/",
      selected: "list",
      options: [
        { value: "new", text: "Create New" },
        { value: "list", text: "List" },
      ],
      losForm: {
        id: null,
        long_name: null,
        short_name: null,
        description: null,
        division: null,
        divisionOption: null,
      },
      userInputs: [
        // { text: "id", variable: "id" },
        { text: "Long Name", variable: "long_name", type: "text" },
        { text: "Short Name", variable: "short_name", type: "text" },
        { text: "Description", variable: "description", type: "textarea" },
      ],
      showTableColumns: [
        "id",
        "division_company",
        "division_name",
        "long_name",
        "short_name",
        "edit",
        "delete",
      ],
    };
  },
  methods: {
    async postLineOfService() {
      let form = new FormData();
      form.append("id", this.losForm.id);
      form.append("long_name", this.losForm.long_name);
      form.append("short_name", this.losForm.short_name);
      form.append("description", this.losForm.description);
      form.append("division", this.losForm.division.value);

      await this.$store.dispatch("company/postLineOfService", form);
      this.dialog = false;
    },
    deleteObject(item) {
      this.$store.dispatch("company/deleteLineOfService", item.id);
    },
    createNewItem: function() {
      this.clearItem();
      this.dialog = true;
    },
    async editLineOfService(item) {
      await this.$store.dispatch("company/setLineOfService", item);
      this.losForm = {
        id: this.selectedLineOfService.id,
        long_name: this.selectedLineOfService.long_name,
        short_name: this.selectedLineOfService.short_name,
        description: this.selectedLineOfService.description,
        division: this.selectedLineOfService.division,
        divisionOption: null,
      };
      let txt =
        this.selectedLineOfService.division_company +
        " - " +
        this.selectedLineOfService.division_name;
      this.losForm.division = {
        text: txt,
        value: this.selectedLineOfService.id,
      };
      //   this.losForm.division = this.losForm.divisionOption.text;
      this.dialog = true;
    },
    clearItem: function() {
      this.$store.dispatch("company/setLineOfService", null);
      this.losForm = {
        id: null,
        long_name: null,
        short_name: null,
        description: null,
        division: null,
      };
    },
  },
  computed: {
    divisions() {
      return this.$store.state.company.divisions;
    },
    divisionOptions() {
      let data = this.divisions;
      let options = [];
      data.forEach((item) => {
        let txt = item.company_legal_name + " - " + item.name;
        options.push({ text: txt, value: item.id });
      });
      console.log(options);
      return options;
    },
    linesOfService() {
      return this.$store.state.company.linesOfService;
    },
    selectedLineOfService() {
      return this.$store.state.company.selectedLineOfService;
    },
    tableHeaders() {
      let headers = [];
      let data = this.linesOfService;
      let tableColumns = this.showTableColumns;

      console.log(data[0]);
      if (data[0]) {
        let fields = Object.keys(data[0]);
        fields.push("edit");
        fields.push("delete");

        console.log(fields);
        fields.forEach((field) => {
          if (tableColumns.includes(field)) {
            let field_txt = field.replace("_", " ").split(" ");
            console.log(field_txt);
            field_txt = field_txt.map((f) => {
              return f.charAt(0).toUpperCase() + f.slice(1);
            });
            field_txt = field_txt.join(" ");
            headers.push({ text: field_txt, value: field });
          }
        });
      }
      console.log(headers);
      return headers;
    },
  },
  mounted: function() {
    this.$store.dispatch("company/getDivisions");
    this.$store.dispatch("company/getLinesOfService");
  },
};
</script>

<style scoped>
.controlButton {
  width: 120px;
  height: 50px;
  border-radius: 25px;
  margin: 10px;
}

.pane {
  width: 100vh;
  /* border: 1px solid lightgrey; */
  padding: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.app-card {
  width: 300px;
}
.app-card img {
  width: 200px;
  align-content: center;
}
</style>
