<template>
  <AppLayout>
    <!-- SLOT: NAVBAR MENU -->
    <NavbarMenu slot="navbar-menu" />
    <!-- SLOT: MENU DRAWER -->
    <MenuDrawer slot="menu-drawer" />
    <!-- SLOT: TOOL DRAWER -->
    <ToolDrawer slot="tool-drawer" />

    <v-container fluid slot="main">
      <v-row>
        <v-col>
          <v-btn @click="createSnackbar">Create Snackbar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";
import NavbarMenu from "@/components/app/NavbarMenu.vue";
import MenuDrawer from "@/components/app/MenuDrawer.vue";
import ToolDrawer from "@/components/app/ToolDrawer.vue";

export default {
  //   name: "EsriMap",
  components: { AppLayout, NavbarMenu, MenuDrawer, ToolDrawer },
  methods: {
    createSnackbar() {
      let msg = "Shiiiiiiiiiit!";
      this.$store.dispatch("snackbarError", msg);
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
