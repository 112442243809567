<template>
  <Layout>
    <v-container slot="main" fluid>
      <!-- <v-row justify="center">
        <v-col justify="center" align="center">
          <h2 class="marco--text">Company Setup</h2>
        </v-col>
      </v-row> -->
      <v-row justify="center">
        <Btn @click="selectView('parent')">Parent Companies</Btn>
        <Btn @click="selectView('child')">Child Companies</Btn>
        <Btn @click="selectView('division')">Divisions</Btn>
        <Btn @click="selectView('los')">Lines of Service</Btn>
        <Btn @click="selectView('employee-categories')"
          >Employee Categories</Btn
        >
        <Btn @click="selectView('employees')">Employees</Btn>
        <Btn @click="selectView('cv')">Curriculum</Btn>
      </v-row>
      <ParentCompanySetup v-if="view === 'parent'" />
      <ChildCompanySetup v-if="view === 'child'" />
      <DivisionSetup v-if="view === 'division'" />
      <LineOfServiceSetup v-if="view === 'los'" />
      <EmployeeCategorySetup v-if="view === 'employee-categories'" />
      <EmployeeSetup v-if="view === 'employees'" />
      <CurriculumSetup v-if="view === 'cv'" />
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/company/Layout.vue";

import Btn from "@/components/base/Btn.vue";
// import BaselineLayout from "@/layouts/BaselineLayout.vue";

// import SideBar from "@/components/company/SideBar.vue";

import ParentCompanySetup from "@/components/company/ParentCompanySetup.vue";
import ChildCompanySetup from "@/components/company/ChildCompanySetup.vue";
import DivisionSetup from "@/components/company/DivisionSetup.vue";
import LineOfServiceSetup from "@/components/company/LineOfServiceSetup.vue";
import EmployeeCategorySetup from "@/components/company/EmployeeCategorySetup.vue";
import EmployeeSetup from "@/components/company/EmployeeSetup.vue";
import CurriculumSetup from "@/components/company/CurriculumSetup.vue";
// import ChildCompanySetup from "@/components/company/ChildCompanySetup.vue";

// import CompanyDataLoader from "../components/company-setup/CompanyDataLoader.vue";

export default {
  components: {
    Layout,
    Btn,
    ParentCompanySetup,
    ChildCompanySetup,
    DivisionSetup,
    LineOfServiceSetup,
    EmployeeCategorySetup,
    EmployeeSetup,
    CurriculumSetup,
  },
  data() {
    return {};
  },
  methods: {
    selectView(view) {
      return this.$store.dispatch("company/selectView", view);
    },
  },
  computed: {
    view() {
      return this.$store.state.company.view;
    },
  },
};
</script>

<style></style>
