<template>
  <v-container>
    <v-row dense>
      <v-col>
        <h3 class="marco--text">Investment Strategies</h3>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <h3 class="marco--text">Product Types</h3>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ClientsAPI from "@/api/clients.js";

export default {
  components: {},
  props: [],
  data() {
    return {};
  },

  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style></style>
