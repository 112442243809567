import Deal from "@/api/deals";

export default {
  namespaced: true,
  state: {
    clientDeals: [],
  },
  getters: {},
  mutations: {
    getClientDeals(state, deals) {
      state.clientDeals = deals;
    },
  },
  actions: {
    getClientDeals(context, clientId) {
      context;
      return Deal.clientDeals(clientId);
      // .then((response) => {
      //   context.commit("getClientDeals", response.data);
      // })
      // .catch((error) => {
      //   context.dispach(
      //     "errorSnackbar",
      //     { text: `Client News Search: ${error}` },
      //     { root: true }
      //   );
      // });
    },
  },
};
