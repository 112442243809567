<template>
  <v-container>
    <v-row dense>
      <v-col>
        <v-btn small @click="openDialogContactForm">New Contact</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <!-- <v-col>
        <v-data-table
          dense
          :items="clientContacts"
          :headers="headers.contacts"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:item.image="{ item }">
            <v-avatar> <img :src="item.image" /><img /> </v-avatar>
          </template>
          <template v-slot:item.view="{ item }">
            <v-icon @click="viewContact(item.id)">mdi-eye</v-icon>
          </template>
        </v-data-table>
      </v-col> -->
      <v-col v-for="(contact, index) in clientContacts" :key="index" cols="3">
        <v-card>
          <v-img height="250" :src="contact.image"></v-img>
          <v-card-title
            >{{ contact.first_name }} {{ contact.last_name }}</v-card-title
          >
          <v-card-subtitle>{{ contact.position }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="viewContact(contact.id)">View</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog :value="dialog" width="60%" @input="onDialogInput">
      <v-container>
        <v-row dense>
          <v-col>
            <ContactForm
              :clientId="clientId"
              :contactId="contactId"
            ></ContactForm>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import ContactForm from "@/components/clients/ContactForm.vue";
// import ClientsAPI from "@/api/clients.js";

export default {
  components: { ContactForm },
  props: ["clientId"],
  data() {
    return {
      contactId: null,
      headers: {
        contacts: [
          { text: "Image", value: "image" },
          { text: "Position", value: "position" },
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Email", value: "email" },
          { text: "Phone", value: "phone_number" },
          { text: "View", value: "view" },
        ],
      },
    };
  },

  methods: {
    onDialogInput(event) {
      this.$store.dispatch("clients/setDialog", {
        dialog: "contactForm",
        view: event,
      });
    },
    openDialogContactForm() {
      this.$store.dispatch("clients/setDialog", {
        dialog: "contactForm",
        view: true,
      });
    },
    closeDialogContactForm() {
      this.$store.dispatch("clients/setDialog", {
        dialog: "contactForm",
        view: false,
      });
    },
    viewContact(contactId) {
      this.contactId = contactId;
      this.openDialogContactForm();
    },
  },
  computed: {
    dialog() {
      return this.$store.getters["clients/dialogContactForm"];
    },
    clientContacts() {
      return this.$store.getters["clients/clientContacts"];
    },
  },
  mounted() {
    this.closeDialogContactForm();
  },
};
</script>

<style></style>
