<template>
  <div>
    <highcharts class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  props: [
    "hcTitle",
    "hcSubtitle",
    "hcData",
    "AxisCategories",
    "xAxisTitle",
    "yAxisTitle",
    "dataLabels",
  ],
  components: { highcharts: Chart },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            // minWidth: 700,
            scrollPositionX: 1,
            zoomType: "xy",
          },
        },
        title: {
          text: this.hcTitle,
        },
        subtitle: {
          text: this.hcSubtitle,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: this.dataLabels,
              format: "{point.y:,.0f}",
              style: {
                fontSize: "9px",
              },
            },
            enableMouseTracking: true,
          },
        },
        series: this.hcData,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  enabled: false,
                },
              },
            },
          ],
        },
        tooltip: {
          backgroundColor: "#DDFCFF",
          borderColor: "black",
          borderRadius: 10,
          borderWidth: 1,
          crosshairs: [true, true],
          formatter: function() {
            return "<b>" + this.x + ":</b>" + this.y;
          },
        },
        xAxis: {
          categories: this.AxisCategories,
          title: {
            text: this.xAxisTitle,
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
              return this.value;
            },
          },
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
              return this.value;
            },
          },
        },
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #42b983;
}
.hc {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
</style>
