<template>
  <transition name="slide" mode="out-in">
    <router-view :key="$route.fullPath"></router-view>
  </transition>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

export default {
  title: "MGX Advisors",
  name: "App",

  components: {
    // HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
body {
  font-size: 14pt;
}

.controlButton {
  width: 120px;
  height: 50px;
  border-radius: 25px;
  margin: 10px;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s;
  /* transform 0.5s; */
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  /* transform: translateX(-50%); */
}
</style>
