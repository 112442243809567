import Api from "./Api";

export default {
  ccaa(ccaaCode) {
    return Api.get(`shapes/ccaa/${ccaaCode}`);
  },
  provincias(provinciaCode) {
    return Api.get(`shapes/provincias/${provinciaCode}`);
  },
  municipios(natcode) {
    return Api.get("shapes/municipios/", { params: { natcode: natcode } });
  },
  seccionesCensales(cusec) {
    return Api.get(`shapes/secciones-censales/`, {
      params: {
        cusec: cusec,
      },
    });
  },
};
