<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :color="color" small>
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  computed: {
    color() {
      let color =
        typeof this.$attrs.color !== "undefined"
          ? this.$attrs.color
          : "marco white--text mx-1";
      return color;
    },
  },
};
</script>

<style></style>
