import CatastroApi from "@/api/catastro";

export default {
  namespaced: true,
  state: {
    provincias: null,
    municipios: null,
    vias: null,
    numeros: null,
  },
  getters: {
    provincias(state) {
      return state.provincias;
    },
    municipios(state) {
      return state.municipios;
    },
    vias(state) {
      return state.vias;
    },
  },
  mutations: {
    GET_PROVINCIAS(state, provincias) {
      state.provincias = provincias;
    },
    GET_MUNICIPIOS(state, municipios) {
      state.municipios = municipios;
    },
    GET_VIAS(state, vias) {
      state.vias = vias;
    },
    GET_NUMEROS(state, numeros) {
      state.numeros = numeros;
    },
  },
  actions: {
    getProvincias(context) {
      CatastroApi.provincias()
        .then((response) => {
          context.commit("GET_PROVINCIAS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMunicipios(context, provinciaId) {
      CatastroApi.municipios(provinciaId)
        .then((response) => {
          context.commit("GET_MUNICIPIOS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVias(context, params) {
      CatastroApi.vias(params)
        .then((response) => {
          context.commit("GET_VIAS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    scrapeviaData(context, via_id) {
      context;
      CatastroApi.scrapeviaData(via_id)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchVias(context, params) {
      CatastroApi.vias(params)
        .then((response) => {
          context.commit("GET_VIAS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNumeros(context) {
      CatastroApi.numeros()
        .then((response) => {
          context.commit("GET_NUMEROS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
