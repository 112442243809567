import Api from "./Api";

export default {
  provincias() {
    return Api.get(`catastro/provincias/`);
  },
  municipios(provinciaId) {
    let params = { provincia: provinciaId };
    return Api.get(`catastro/municipios/`, { params: params });
  },
  vias(params) {
    return Api.get(`catastro/vias/`, { params: params });
  },
  viaDetail(viaId) {
    return Api.get(`catastro/vias/${viaId}/`);
  },
  viaSummary(viaId) {
    return Api.get(`catastro/vias/${viaId}/summary/`);
  },
  viaFincas(viaId) {
    return Api.get(`catastro/vias/${viaId}/fincas/`);
  },
  fincaSummary(fincaId) {
    return Api.get(`catastro/fincas/${fincaId}/summary/`);
  },
  fincaBienesInmuebles(fincaId) {
    return Api.get(`catastro/fincas/${fincaId}/bienes-inmuebles/`);
  },
  numeros() {
    return Api.get(`catastro/numeros/`);
  },

  getDatatape(datatapeId) {
    return Api.get(`catastro/datatapes/${datatapeId}/`);
  },
  getDatatapeColumns(datatapeId) {
    return Api.get(`catastro/datatapes/${datatapeId}/columns/`);
  },
  postDatatape(form) {
    return Api.post(`catastro/datatapes/`, form);
  },
  patchDatatape(form) {
    let datatapeId = form.get("id");
    return Api.patch(`catastro/datatapes/${datatapeId}/`, form);
  },
  downloadExcelReport(ref) {
    return Api.get(`catastro/reports/`, { params: { ref: ref } });
  },
  downloadDatatape(datatapeId) {
    return Api.get(`catastro/datatapes/${datatapeId}/download/`);
  },
  createDatatapeCatastroReport(form) {
    return Api.post(`catastro/datatape-catastro-report/`, form);
  },
  downloadDatatapeCatastro(form) {
    return Api.post(`catastro/datatape-catastro-download/`, form);
  },
  downloadDatatapeCatastroReport(datatapeId) {
    return Api.get(`catastro/datatapes/${datatapeId}/download-report/`);
  },
  datatapeColumns(form) {
    return Api.post(`catastro/datatape-columns/`, form);
  },

  deleteDatatape(datatapeId) {
    return Api.delete(`catastro/datatapes/${datatapeId}/`);
  },
  getUserDatatapes() {
    return Api.get(`catastro/user-datatapes/`);
  },

  getCatastroDetail(referenciaCatastral) {
    return Api.get(`catastro/referencia/${referenciaCatastral}/`);
  },
  referenciaCatastralDNP(referenciaCatastral) {
    return Api.get(`catastro/datos-no-protegidos/${referenciaCatastral}/`);
  },
  referenciaFromCoords(coords) {
    return Api.get(`catastro/referencia-de-coordenadas/`, {
      params: { lat: coords.lat, lng: coords.lng },
    });
  },
  referenciaReport(referenciaCatastral) {
    return Api.get(`catastro/download-report/${referenciaCatastral}`);
  },
  scrapeviaData(viaId) {
    return Api.get(`catastro/vias/${viaId}`);
  },
};
