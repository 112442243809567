<template>
  <Layout>
    <v-container fluid class="full-height" slot="main">
      <v-toolbar class="text-center" color="warning" dense>
        <v-tooltip right color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              @click="$router.push({ name: 'databank-catastro-wizard' })"
              >Wizard</v-btn
            >
          </template>

          <span>Return to Street Search</span>
        </v-tooltip>

        <!-- <v-icon @click="$router.push({ name: 'databank-catastro-wizard' })"
          >mdi-arrow-left-circle</v-icon
        > -->
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-center" v-if="viaDetail">
          <h2 class="text-center">
            {{ viaDetail.tipo_via }} {{ viaDetail.nombre_via }},
            {{ viaDetail.fk_municipio.nombre }} ({{
              viaDetail.fk_municipio.fk_provincia.nombre
            }})
            <v-icon @click="refresh">mdi-refresh</v-icon>
          </h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip left color="green">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" v-bind="attrs" v-on="on"
              >mdi-file-download</v-icon
            >
          </template>
          <span>Download Full Street Report</span>
        </v-tooltip>
      </v-toolbar>
      <v-container fluid>
        <v-row dense>
          <v-col cols="4" align="center">
            <v-card>
              <v-card-title class="marco white--text"
                >Street Summary
              </v-card-title>
              <v-card-text>
                <v-tabs>
                  <v-tab>Chart</v-tab>
                  <v-tab>Table</v-tab>

                  <v-tab-item>
                    <PieChart
                      :hcTitle="null"
                      :hcSubtitle="null"
                      :hcData="viaSummaryChart"
                    />
                  </v-tab-item>

                  <v-tab-item>
                    <v-simple-table fixed-header height="400px" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Type
                            </th>
                            <th class="text-right">
                              % Total
                            </th>
                            <th class="text-right">
                              Avg. Area
                            </th>
                            <!-- <th class="text-right">
                              Use
                            </th> -->
                            <th class="text-right">
                              # Units
                            </th>
                            <th class="text-right">
                              Total Area
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in viaSummary"
                            :key="item.grupo_o_uso_bienes_inmuebles"
                          >
                            <td>
                              <v-icon color="marco">
                                {{
                                  assetIcons[item.grupo_o_uso_bienes_inmuebles]
                                }}
                              </v-icon>
                            </td>
                            <td class="text-right">
                              {{ item.percent_total }}%
                            </td>
                            <td class="text-right">
                              {{ item.avg_superficie }}
                            </td>
                            <!-- <td>{{ item.grupo_o_uso_bienes_inmuebles }}</td> -->
                            <td class="text-right">{{ item.count }}</td>
                            <td class="text-right">
                              {{ item.total_superficie }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card>
              <v-card-title class="marco white--text"
                >Street Numbers</v-card-title
              >
              <v-card-text>
                <v-data-table
                  show-select
                  :single-select="true"
                  fixed-header
                  dense
                  height="450px"
                  :items="fincas.results ? fincas.results : []"
                  item-key="id"
                  :headers="headers.fincas"
                  :items-per-page="-1"
                  :hide-default-footer="true"
                  @input="onSelectFinca"
                >
                  <template v-slot:item.report="{ item }">
                    <v-icon
                      color="green"
                      @click="downloadReport(item.parcela_catastral)"
                      >mdi-file-download-outline</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card>
              <v-card-title class="marco white--text"
                >Finca Summary</v-card-title
              >
              <v-card-text>
                <v-tabs>
                  <v-tab>Chart</v-tab>
                  <v-tab>Table</v-tab>
                  <v-tab-item>
                    <PieChart
                      :hcTitle="null"
                      :hcSubtitle="null"
                      :hcData="fincaSummaryChart"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <v-simple-table fixed-header height="400px" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Type
                            </th>
                            <th class="text-right">
                              % Total
                            </th>
                            <th class="text-right">
                              Avg. Area
                            </th>
                            <th class="text-right">
                              # Units
                            </th>
                            <th class="text-right">
                              Total Area
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in fincaSummary"
                            :key="item.grupo_o_uso_bienes_inmuebles"
                          >
                            <td>
                              <v-icon color="marco">
                                {{
                                  assetIcons[item.grupo_o_uso_bienes_inmuebles]
                                }}
                              </v-icon>
                            </td>
                            <td class="text-right">
                              {{ item.percent_total }}%
                            </td>
                            <td class="text-right">
                              {{ item.avg_superficie }}
                            </td>

                            <td class="text-right">{{ item.count }}</td>
                            <td class="text-right">
                              {{ item.total_superficie }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="marco white--text"
                >Referencias Catastrales Finca</v-card-title
              >
              <v-card-text>
                <v-data-table
                  show-select
                  fixed-header
                  single-select
                  dense
                  height="300px"
                  :items="fincaBienesInmuebles ? fincaBienesInmuebles : []"
                  :headers="headers.fincaBienesInmuebles"
                  :items-per-page="-1"
                  :hide-default-footer="true"
                  @input="onSelectReferencia"
                >
                  <template v-slot:item.report="{ item }">
                    <v-icon
                      color="green"
                      @click="downloadReport(item.referencia_catastral)"
                      >mdi-file-download-outline</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card
              ><v-card-title class="marco white--text"
                >Construction Units</v-card-title
              >
              <v-card-text>
                <v-overlay
                  :absolute="true"
                  :value="isBusy.elementos_constructivos"
                >
                  <v-progress-circular
                    indeterminate
                    color="blue-grey"
                  ></v-progress-circular>
                </v-overlay>

                <v-data-table
                  dense
                  fixed-header
                  height="200px"
                  :items="rcData ? rcData.elementos_constructivos : []"
                  :headers="headers.elementosConstructivos"
                  :items-per-page="-1"
                  :hide-default-footer="true"
                >
                </v-data-table> </v-card-text
            ></v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </Layout>
</template>

<script>
import Api from "@/api/Api";
import Layout from "@/components/databank/catastro/Layout.vue";
import PieChart from "@/components/charts/highcharts/PieChart.vue";
// import GoogleMapCatastro from "@/components/maps/google/CatastroBrowserMap.vue";

import CatastroAPI from "@/api/catastro";

export default {
  components: {
    Layout,
    PieChart,
    // GoogleMapCatastro,
  },
  data() {
    return {
      assetIcons: {
        A: "mdi-parking",
        V: "mdi-home",
        I: "mdi-factory",
        O: "mdi-office-building",
        C: "mdi-cart",
        K: "mdi-basketball",
        T: "mdi-theater",
        G: "mdi-glass-cocktail",
        Y: "mdi-hospital-box-outline",
        E: "mdi-account-group",
        R: "mdi-cross-outline",
        M: "mdi-account-hard-hat",
        P: "mdi-office-building-marker-outline",
        B: "mdi-silo",
        J: "mdi-tractor",
        Z: "mdi-barn",
      },
      isBusy: {
        elementos_constructivos: false,
      },
      fincaSummary: null,
      fincaBienesInmuebles: null,
      rcData: null,
      viaSummary: null,
      viaDetail: null,
      fincas: {},
      headers: {
        fincas: [
          { text: "Report", value: "report" },
          { text: "Street Number", value: "num_policia_1" },
          { text: "Reference", value: "parcela_catastral" },
        ],
        fincaBienesInmuebles: [
          { text: "Report", value: "report" },
          { text: "Reference", value: "referencia_catastral" },
          { text: "Class", value: "clase" },
          { text: "Total Area", value: "superficie_asociada_inmueble" },
          { text: "Built Area", value: "superficie_elementos_constructivos" },
          { text: "Year", value: "antiguedad" },
          //   { text: "Address", value: "domicilio_tributario" },
          { text: "Tipo Via", value: "tipo_via_publica" },
          { text: "Via Name", value: "nom_via_publica" },
          { text: "Via Number", value: "num_policia_1" },
          { text: "Bloque", value: "bloque" },
          { text: "Portal", value: "portal" },
          { text: "Escalera", value: "escalera" },
          { text: "Planta", value: "planta" },
          { text: "Puerta", value: "puerta" },
          { text: "% Ownership", value: "coef_propiedad" },

          { text: "Finca Registral", value: "num_finca_registral" },
          { text: "Poligono", value: "cod_poligono" },
          { text: "Parcela", value: "cod_parcela" },
          { text: "Paraje", value: "nom_paraje_dgc" },
        ],
        elementosConstructivos: [
          { text: "Ref.", value: "referencia_catastral" },
          { text: "Use", value: "uso" },
          { text: "Clase", value: "clase" },
          { text: "Modalidad", value: "modalidad" },
          { text: "Bloque", value: "bloque" },
          { text: "Escalera", value: "escalera" },
          { text: "Portal", value: "portal" },
          { text: "Puerta", value: "puerta" },
          { text: "Año", value: "anyo_antiguedad_efectiva" },
          { text: "Sup. Total", value: "superficie_local_total" },
          {
            text: "Sup. Terrazas",
            value: "superficie_local_terrazas",
          },
          {
            text: "Sup. Imputable",
            value: "superficie_local_imputable",
          },
        ],
        subparcelas: [],
      },
      radioButtons: {
        viaSummary: null,
        fincaSummary: null,
      },
    };
  },
  methods: {
    clickAlert(item) {
      alert(item.referencia);
    },
    getAssetIcons(codGrupo) {
      return this.assetIcons[codGrupo];
    },
    downloadViaReport(ref) {
      let endpoint = `${Api.defaults.baseURL}/catastro/reports/?ref=${ref}`;
      console.log(endpoint);
      window.location.replace(endpoint);
    },
    downloadReport(ref) {
      let endpoint = `${Api.defaults.baseURL}/catastro/reports/?ref=${ref}`;
      console.log(endpoint);
      window.location.replace(endpoint);
    },
    getViaDetail() {
      CatastroAPI.viaDetail(this.$route.params.viaId).then((response) => {
        this.viaDetail = response.data;
      });
    },
    getViaSummary() {
      CatastroAPI.viaSummary(this.$route.params.viaId).then((response) => {
        console.log(response.data);
        this.viaSummary = response.data;
      });
    },
    getViaFincas() {
      CatastroAPI.viaFincas(this.$route.params.viaId).then((response) => {
        this.fincas = response.data;
      });
    },
    getviaReferencias() {
      console.log(this.$route.params.viaId);
    },
    onSelectFinca(selections) {
      if (selections !== []) {
        CatastroAPI.fincaSummary(selections[0].id).then((response) => {
          this.fincaSummary = response.data;
        });
        CatastroAPI.fincaBienesInmuebles(selections[0].id).then((response) => {
          this.fincaBienesInmuebles = response.data;
        });
        this.rcData = null;
      }
    },
    onSelectReferencia(selections) {
      if (selections !== []) {
        this.isBusy.elementos_constructivos = true;
        CatastroAPI.getCatastroDetail(selections[0].referencia_catastral)
          .then((response) => {
            this.rcData = response.data;
            this.isBusy.elementos_constructivos = false;
          })
          .catch((error) => {
            this.isBusy.elementos_constructivos = false;
            console.log(error);
          });
      } else {
        this.rcData = null;
      }
    },
    refresh() {
      this.getViaDetail();
      this.getViaSummary();
      this.getViaFincas();
    },
  },
  computed: {
    viaSummaryChart() {
      if (this.viaSummary) {
        let data = this.viaSummary.map((item) => {
          return {
            name: item.grupo_o_uso_bienes_inmuebles,
            y: item.total_superficie,
          };
        });
        let chartData = [
          {
            name: "Area by Use",
            data: data,
          },
        ];
        return chartData;
      } else {
        return [];
      }
    },
    fincaSummaryChart() {
      if (this.fincaSummary) {
        let data = this.fincaSummary.map((item) => {
          return {
            name: item.grupo_o_uso_bienes_inmuebles,
            y: item.total_superficie,
          };
        });
        let chartData = [
          {
            name: "Area by Use",
            data: data,
          },
        ];
        return chartData;
      } else {
        return [];
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(this.$route.params);
      console.log(to);
      console.log(from);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>
.table-chip {
  font-size: 8pt;
  padding: 6pt;
  padding-top: 3pt;
  padding-bottom: 3pt;
}
</style>
