<template>
  <v-container>
    <v-row justify="center">
      <div v-if="selectedCompsAsk"></div>
    </v-row>
    <v-row>
      <v-col>
        <h2>
          Asking Comps:
          <!-- <div v-if="selectedCompsAsk">{{ round(eurSqm.ask, 0) }}€/m2</div> -->
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-data-table
          dense
          :items="selectedCompsAsk"
          :headers="tableHeadersAsk"
          v-if="selectedCompsAsk"
        >
          <template #item.des_urlanuncio="{item}">
            <v-btn
              x-small
              class="primary"
              :href="item.des_urlanuncio"
              target="_blank"
              >View</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>
          Closing Comps:
          <!-- <div v-if="selectedCompsClose">{{ round(eurSqm.close, 0) }}€/m2</div> -->
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-data-table
          dense
          :items="selectedCompsClose"
          :headers="tableHeadersClose"
          v-if="selectedCompsClose"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import roundMixin from "../../mixins/roundMixin";
// import InfoCard from "../base/BaseInfoCard.vue";

export default {
  mixins: [roundMixin],
  components: {},
  data() {
    return {
      selectedAskDiscount: 0,
      optionsAskDiscount: [0, 2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20],
      tableColumnsAsk: [
        "fuente",
        "fecha_fuente",
        "des_tipologia",
        "des_direccion",
        "num_supconstruida",
        "num_precioventa",
        "eur_sqm",
        "des_urlanuncio",
      ],
      tableColumnsClose: [
        "f_escritura_corta",
        "descripcion",
        "tipo",
        "estado",
        "calle_api",
        "codigo_postal",
        "anyo_construccion",
        "id_vpo",
        "superficie",
        "valor",
        "valor_m",
      ],
    };
  },
  computed: {
    selectedCompsAsk() {
      return this.$store.state.realEstate.userSelections.selectedCompsAsk;
    },
    selectedCompsClose() {
      return this.$store.state.realEstate.userSelections.selectedCompsClose;
    },
    // eurSqm() {
    //   return this.$store.state.realEstate.userSelections.eurSqm;
    // },
    adjEurSqmAsk() {
      let eur_sqm = 0;
      this.selectedCompsAsk.forEach(function(item) {
        eur_sqm += item.num_precioventa / item.num_supconstruida;
      });

      let eur_sqm_comps =
        (eur_sqm / this.selectedCompsAsk.length) *
        (1 - this.selectedAskDiscount / 100);

      eur_sqm_comps = this.round(eur_sqm_comps, 0);
      return eur_sqm_comps;
    },
    tableHeadersAsk() {
      let headers = [];
      let data = this.selectedCompsAsk;

      if (data[0]) {
        let fields = Object.keys(data[0]);
        let tableColumns = this.tableColumnsAsk;

        fields.forEach((field) => {
          if (tableColumns.includes(field)) {
            let field_txt = field.replace("_", " ").split(" ");
            field_txt = field_txt.map((f) => {
              return f.charAt(0).toUpperCase() + f.slice(1);
            });
            field_txt = field_txt.join(" ");
            headers.push({ text: field_txt, value: field });
          }
        });
      }
      return headers;
    },
    tableHeadersClose() {
      let headers = [];
      let data = this.selectedCompsClose;

      if (data[0]) {
        let fields = Object.keys(data[0]);
        let tableColumns = this.tableColumnsClose;

        fields.forEach((field) => {
          if (tableColumns.includes(field)) {
            let field_txt = field.replace("_", " ").split(" ");
            field_txt = field_txt.map((f) => {
              return f.charAt(0).toUpperCase() + f.slice(1);
            });
            field_txt = field_txt.join(" ");
            headers.push({ text: field_txt, value: field });
          }
        });
      }
      return headers;
    },
  },
};
</script>

<style></style>
