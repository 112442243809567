<template>
  <Layout>
    <v-container slot="main">
      <v-row justify="center">
        <v-col cols="6" align="center">
          <v-card elevation="6">
            <v-card-title class="marco white--text">
              Import Instructions:
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row justify="start">
                  <v-col cols="7" align="start"
                    ><h2>1) Download the Template File</h2></v-col
                  >
                  <v-col cols="5" align="start">
                    <v-btn large>
                      <v-icon large color="green" @click="downloadTemplate">
                        mdi-file-excel
                      </v-icon>
                      Download Template
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-row justify="start">
                  <v-col cols="7" align="start">
                    <h2>
                      2) Fill out the template mainting the same file structure
                    </h2>
                  </v-col>
                  <v-col cols="5" align="start"> </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="start">
                  <v-col cols="7" align="start">
                    <h2>3) Upload</h2>
                  </v-col>
                  <v-col cols="5" align="start">
                    <v-file-input label="Company Data File"> </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/company/Layout.vue";
export default {
  components: { Layout },
  methods: {
    downloadTemplate() {},
  },
};
</script>

<style></style>
