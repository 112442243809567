<template>
  <v-app>
    <Layout>
      <v-container slot="main">
        <v-toolbar dense class="warning">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <h2>Cadastre Datatapes</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row justify="center">
          <v-col>
            <v-card>
              <v-toolbar dense dark color="marco">
                <v-toolbar-title>Datatapes</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-data-table
                  dense
                  show-select
                  single-select
                  v-model="datatapeFileLoad"
                  :items="userOptions.datatapes"
                  :headers="headersDatapes"
                >
                  <template v-slot:top="{}">
                    <v-row>
                      <v-spacer></v-spacer>
                      <Btn
                        text="New Datatape"
                        small
                        color="error"
                        @click="dialogs.create = true"
                      ></Btn>
                    </v-row>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-icon small color="marco" @click="editDatatape(item.id)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <template v-slot:item.datatape="{ item }">
                    <v-icon
                      small
                      color="green"
                      @click="downloadDatatape(item.id)"
                      >mdi-file-excel</v-icon
                    >
                  </template>
                  <template v-slot:item.download="{ item }">
                    <v-icon
                      small
                      color="marco"
                      @click="downloadCatastroData(item.id)"
                      >mdi-download</v-icon
                    >
                  </template>
                  <template v-slot:item.report="{ item }">
                    <v-icon
                      v-if="item.report"
                      small
                      color="green"
                      @click="downloadCatastroReport(item.id)"
                      >mdi-file-excel</v-icon
                    >
                    <v-icon
                      small
                      color="marco"
                      @click="createCatastroReport(item)"
                      >mdi-autorenew</v-icon
                    >
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-icon small color="error" @click="deleteDatatape(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-card>
              <v-toolbar dense dark color="marco">
                <v-toolbar-title>Catastro References</v-toolbar-title>
              </v-toolbar>
              <v-card-text> </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-toolbar dense dark color="marco">
              <v-toolbar-title>Summary</v-toolbar-title>
            </v-toolbar>
          </v-col>
        </v-row>

        <!-- DIALOG: CREATE -->
        <v-dialog v-model="dialogs.create" max-width="600px">
          <v-card>
            <v-card-title class="marco white--text">New Datatape</v-card-title>
            <v-card-title>Select File</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row v-if="errors.noFileSelected" class="error--text">
                  <v-col>
                    Please select a valid file to upload
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      v-model="userInputs.datatapeTitle"
                      label="Datatape Title"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input
                      show-size
                      truncate-length="15"
                      label="Select Datatape"
                      v-model="datatapeFile"
                      append-outer-icon="mdi-upload"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="marco" @click="post">Create</v-btn>
            </v-card-actions>

            <v-card-title v-if="datatapeColumns">Select Columns</v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="datatapeColumns">
              <v-container>
                <v-row v-if="errors.noFileSelected" class="error--text">
                  <v-col>
                    Please select a valid column
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <Combobox
                      v-if="datatapeColumns"
                      label="Cadastral Reference Column"
                      v-model="userInputs.cadref_col"
                      :items="datatapeColumns"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Btn
                v-if="searchType === 'datatape' && userInputs.cadref_col"
                small
                text="Save"
                color="marco white--text"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DIALOG: EDIT -->
        <v-dialog v-model="dialogs.edit" max-width="600px" persistent>
          <v-card v-if="apiData.datatape">
            <v-toolbar color="marco" dense dark>
              <v-toolbar-title>
                Edit Datatape
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      outlined
                      label="Title"
                      v-model="userInputs.title"
                    />
                    <v-text-field
                      outlined
                      label="Datatape File"
                      readonly
                      v-model="userInputs.filename"
                    />

                    <Combobox
                      outlined
                      label="Cadastral Reference Column"
                      v-model="userInputs.cadref_col"
                      :items="apiData.datatapeColumns"
                    />
                    <v-divider></v-divider>

                    <v-file-input
                      show-size
                      truncate-length="15"
                      label="Select New Datatape"
                      v-model="datatapeFile"
                      append-outer-icon="mdi-upload"
                      @click:append-outer="updateDatatapeFile"
                    ></v-file-input>
                    <p class="error--text" v-if="errors.noFileSelected">
                      Please select a valid file to upload
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="dialogs.edit = false"
                >Cancel</v-btn
              >
              <v-btn text color="marco" @click="update">Save</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </Layout>
  </v-app>
</template>

<script>
import Layout from "@/components/databank/catastro/Layout.vue";
import Combobox from "@/components/base/Combobox.vue";
import Btn from "@/components/base/buttons/Button.vue";
// import TextField from "@/components/base/TextField.vue";

import Catastro from "@/api/catastro.js";

export default {
  components: {
    Layout,
    Btn,
    Combobox,
    // TextField,
  },
  data() {
    return {
      apiData: {
        datatape: null,
        datatapeColumns: [],
      },
      userInputs: {
        id: null,
        datatapeTitle: null,
        filename: null,
        cadref_col: null,
      },
      datatapeFile: null,
      userOptions: {
        datatapes: [],
        datatapeColumns: [],
      },

      datatapeColumns: null,
      datatapeFileLoad: null,
      dialogs: {
        create: false,
        edit: false,
      },
      errors: {
        noFileSelected: false,
      },
      geoAddress: null,
      headersDatapes: [
        { text: "ID", value: "id" },
        { text: "Created", value: "created" },
        { text: "Title", value: "title" },
        { text: "Filename", value: "filename" },
        { text: "Reference Column", value: "cadref_col" },
        { text: "Edit", value: "edit" },
        { text: "File", value: "datatape" },
        { text: "Download Catastro", value: "download" },
        { text: "Report", value: "report" },
        { text: "Delete", value: "delete" },
      ],
      searchType: "datatape",
      tab: 0,
    };
  },
  methods: {
    post() {
      let form = new FormData();
      form.append("title", this.userInputs.datatapeTitle);
      form.append("file", this.datatapeFile);
      Catastro.postDatatape(form)
        .then(() => {
          this.$store.dispatch(
            "snackbarSuccess",
            "Portfolio Created Successfully!"
          );
          this.dialogs.create = false;
          this.getUserDatatapes();
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
    },
    async update() {
      let form = new FormData();
      form.append("id", this.apiData.datatape.id);
      form.append("title", this.userInputs.title);
      form.append("cadref_col", this.userInputs.cadref_col);
      if (this.datatapeFile) {
        form.append("file", this.datatapeFile);
      }
      await Catastro.patchDatatape(form)
        .then(() => {
          this.$store.dispatch(
            "snackbarSuccess",
            "Portfolio Created Successfully!"
          );
          this.getUserDatatapes();
          this.dialogs.edit = false;
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
    },
    loadUserInputs() {
      this.userInputs = {
        id: this.apiData.datatape.id,
        title: this.apiData.datatape.title,
        filename: this.apiData.datatape.filename,
        cadref_col: this.apiData.datatape.cadref_col,
      };
    },
    resetUserInputs() {
      this.userInputs = {
        id: null,
        title: null,
        filename: null,
        cadref_col: null,
      };
    },
    downloadCatastroData(datatapeId) {
      let form = new FormData();
      form.append("id", datatapeId);
      Catastro.downloadDatatapeCatastro(form)
        .then(() => {
          this.$store.dispatch(
            "snackbarSuccess",
            "Catastro Download Task Initiated!"
          );
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
    },
    downloadCatastroReport(datatapeId) {
      let url = `http://localhost/api/catastro/datatapes/${datatapeId}/download-report/`;
      window.location.assign(url);
    },
    downloadDatatape(datatapeId) {
      let url = `http://localhost/api/catastro/datatapes/${datatapeId}/download/`;
      window.location.assign(url);
    },
    createCatastroReport(item) {
      let msg = `Datatape ${item.id} Column ${item.cadref_col}`;
      alert(msg);

      let form = new FormData();
      form.append("id", item.id);
      form.append("source", "API");
      Catastro.createDatatapeCatastroReport(form)
        .then((response) => {
          this.$store.dispatch("snackbarSuccess", response.data);
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
    },
    async editDatatape(datatapeId) {
      await Catastro.getDatatape(datatapeId)
        .then((response) => {
          this.apiData.datatape = response.data;
          this.loadUserInputs();
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
      await Catastro.getDatatapeColumns(datatapeId)
        .then((response) => {
          this.apiData.datatapeColumns = response.data;
        })
        .catch((error) => {
          this.$store.dispatch("snackbarError", error);
        });
      this.dialogs.edit = true;
    },
    deleteDatatape(item) {
      Catastro.deleteDatatape(item.id).then(() => {
        let msg = `Portfolio ${item.id} deleted!`;
        this.$store.dispatch("snackbarSuccess", msg);
        this.getUserDatatapes();
      });
    },
    updateDatatapeFile() {
      if (this.datatapeFile) {
        let form = new FormData();
        form.append("id", this.userInputs.id);
        form.append("file", this.datatapeFile);
        Catastro.patchDatatape(form)
          .then(() => {
            this.editDatatape(this.userInputs.id);
            this.datatapeFile = null;
            this.$store.dispatch(
              "snackbarSuccess",
              "Datatape Updated Successfully!"
            );
          })
          .catch((error) => {
            this.$store.dispatch("snackbarError", error);
          });
      } else {
        this.errors.noFileSelected = true;
      }
    },
    getUserDatatapes() {
      Catastro.getUserDatatapes().then((response) => {
        this.userOptions.datatapes = response.data;
      });
    },
  },
  mounted() {
    this.getUserDatatapes();
  },
};
</script>

<style></style>
