<template>
  <v-container v-if="askComps">
    <v-row justify="center">
      <v-btn
        v-if="askComps.previous"
        v-on:click="browseAskingComps('previous')"
        class="success"
        >Previous</v-btn
      >
      <v-btn
        v-if="askComps.next"
        v-on:click="browseAskingComps('next')"
        class="success"
        >Next</v-btn
      >
    </v-row>
    <v-row>
      <v-data-table
        dense
        show-select
        :single-select="false"
        :items="askComps"
        :item-selected="updateSelectedComps('asking')"
        item-key="id"
        :headers="tableHeaders"
        v-if="askComps"
        v-model="selectedComps"
        :hide-default-footer="false"
        :options="tableOptions"
      >
        <template #item.photos="{item}">
          <v-icon
            v-if="item.photos"
            small
            color="marco"
            @click="viewPhotos(item)"
            >mdi-camera</v-icon
          >
        </template>
        <template #item.des_urlanuncio="{item}">
          <v-btn
            x-small
            class="primary"
            :href="item.des_urlanuncio"
            target="_blank"
            >View</v-btn
          >
        </template>
      </v-data-table>
    </v-row>
    <v-dialog v-model="dialog" width="60vw">
      <v-card>
        <v-toolbar dense class="marco" dark>
          {{ photoSelection.des_tipologia }}
          {{ photoSelection.num_supconstruida }}m<sup>2</sup>
          <v-spacer></v-spacer>
          {{ photoSelection.des_direccion }}
          <v-spacer></v-spacer>
          € {{ photoSelection.num_precioventa }} ({{ photoSelection.eur_sqm }}
          €/m<sup>2</sup>)
        </v-toolbar>
        <v-carousel
          width="60vw"
          height="60vh"
          v-if="photoSelection.photos !== []"
        >
          <v-carousel-item
            v-for="(photo, i) in photoSelection.photos"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <img :src="photo" style="width:60vw;height:60vh;" />
          </v-carousel-item>
        </v-carousel>
        <v-card-text>
          <v-container>
            <v-row dense justify="center">
              <v-col cols="3">
                <strong>Source: </strong> {{ photoSelection.fuente }}
              </v-col>
              <v-col cols="3">
                <strong>Date: </strong> {{ photoSelection.fecha_fuente }}
              </v-col>
              <v-col cols="3">
                <strong>Transaction: </strong>
                {{ photoSelection.des_operacion }}
              </v-col>
              <v-col cols="3">
                <strong>Bedrooms: </strong>
                {{ photoSelection.num_dormitorios }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedComps: [],
      showTableColumns: [
        "distance",
        "fuente",
        "fecha_fuente",
        "des_operacion",
        "des_tipologia",
        "des_direccion",
        "num_supconstruida",
        "num_dormitorios",
        "num_precioventa",
        "num_precioalquiler",
        "eur_sqm",
        "photos",
        "des_urlanuncio",
      ],
      tableHeaders: [
        { text: "Distance (m)", value: "distance" },
        { text: "Source", value: "fuente" },
        { text: "Date", value: "fecha_fuente" },
        { text: "Transaction", value: "des_operacion" },
        { text: "Type", value: "des_tipologia" },
        { text: "Address", value: "des_direccion" },
        { text: "Surface", value: "num_supconstruida" },
        { text: "# Bedrooms", value: "num_dormitorios" },
        { text: "Selling Price", value: "num_precioventa" },
        { text: "Rental Price", value: "num_precioalquiler" },
        { text: "€/m2", value: "eur_sqm" },
        { text: "Photos", value: "photos" },
        { text: "Link", value: "des_urlanuncio" },
      ],

      tableOptions: {
        itemsPerPage: 25,
      },
      photoSelection: {},
      photos: [],
      //   pageCount: 1,
      //   page: 1,
    };
  },
  methods: {
    updateSelectedComps(compsGroup) {
      let compsObject = {
        compsGroup: compsGroup,
        selectedComps: this.selectedComps,
      };
      this.$store.dispatch("realEstate/updateSelectedComps", compsObject);
    },
    browseAskingComps: function(direction) {
      this.$store.dispatch("realEstate/browseAskingComps", direction);
    },
    viewPhotos(item) {
      this.photoSelection = item;
      this.photos = item.photos;
      this.dialog = true;
    },
  },
  computed: {
    askComps() {
      return this.$store.getters["realEstate/askCompsDetail"];
    },
  },
};
</script>

<style></style>
