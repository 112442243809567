<template>
  <PortfolioDashboard />
</template>

<script>
// Core imports
import PortfolioDashboard from "@/components/workbench/real-estate/portfolio-dashboard/Index";

export default {
  components: {
    PortfolioDashboard,
  },
  data() {
    return {};
  },
  methods: {},
  mounted: async function() {
    await this.$store.dispatch(
      "realEstatePortfolioDashboard/getUserPortfolios"
    );
  },
  computed: {
    userPortfolios() {
      return this.$store.state.realEstatePortfolioDashboard.userPortfolios;
    },
  },
};
</script>

<style scoped></style>
