<template>
  <v-app id="inspire">
    <AppSideBar />
    <AppNavbar>
      <slot name="navbarContent"> </slot>
    </AppNavbar>

    <v-main>
      <slot name="isBusy"> </slot>
      <slot name="main"> </slot>
    </v-main>
    <v-footer class="app-footer" color="#065a82" app>
      <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer></v-spacer>
      <span class="white--text">MGX Advisors, S.L.</span>
    </v-footer>

    <v-snackbar
      v-for="(snackbar, index) in snackbars.filter((s) => s.showing)"
      :key="index"
      v-model="snackbar.showing"
      :color="snackbar.color"
      :style="`margin-bottom: ${index * 60}`"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppSideBar from "@/components/base/AppSideBar.vue";
import AppNavbar from "@/components/base/AppNavbar.vue";

export default {
  components: {
    AppSideBar,
    AppNavbar,
  },
  props: {
    source: String,
  },
  data: () => ({}),
  methods: {},
  computed: {
    snackbars() {
      return this.$store.state.snackbars;
    },
  },
};
</script>

<style scoped>
.app-footer {
  font-size: 9pt;
}
</style>
