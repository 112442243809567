import Api from "./Api.js";

export default {
  languages() {
    return Api.get("red-electrica/languages/");
  },
  categories() {
    return Api.get("red-electrica/categories/");
  },
  widgets(category) {
    return Api.get(`red-electrica/categories/${category}/widgets/`);
  },
  get(queryParams) {
    let params = {
      start_date: queryParams.start_date,
      end_date: queryParams.end_date,
      time_trunc: queryParams.time_trunc,
      data_format: queryParams.data_format,
    };

    return Api.get(
      `red-electrica/${queryParams.lang}/datos/${queryParams.category}/${queryParams.widget}/`,
      { params: params }
    );
  },
};
