<template>
  <div>
    <highcharts class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import { Chart } from "highcharts-vue";
exportingInit(Highcharts);

export default {
  props: [
    "hcTitle",
    "hcSubtitle",
    "hcData",
    "xAxisTitle",
    "yAxisTitle",
    "AxisCategories",
  ],
  components: { highcharts: Chart },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "spline", // line / spline / area / areaspline / column / bar / pie / scatter / gauge / arearange / areasplinerange / columnrange
          scrollablePlotArea: {
            // minWidth: 700,
            scrollPositionX: 1,
          },
          zoomType: "xy",
        },
        title: {
          text: this.hcTitle,
        },
        subtitle: {
          text: this.hcSubtitle,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          spline: {
            dataLabels: {
              enabled: false,
            },
            enableMouseTracking: true,
            marker: {
              radius: 2,
            },
          },
        },
        series: this.hcData,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  enabled: false,
                },
              },
            },
          ],
        },
        tooltip: {
          backgroundColor: "#DDFCFF",
          borderColor: "black",
          borderRadius: 10,
          borderWidth: 1,
          crosshairs: [true, true],
          formatter: function() {
            return "<b>" + this.x + "</b>: <b>" + this.y + "</b> ";
          },
        },
        xAxis: {
          title: {
            text: this.xAxisTitle,
          },
          gridLineWidth: 0,
          categories: this.AxisCategories,
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
              return this.value;
            },
          },
        },
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #42b983;
}
.hc {
  align-self: center;
  justify-self: center;
  /* width: 40%; */
  /* height: 20%; */
}
</style>
