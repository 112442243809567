<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Deals Dashboard</h2> </template>
    <v-container slot="main">
      <SideBar />
      <v-row justify="center">
        <v-col align="center">
          {{ data }}
        </v-col>
      </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/deals/SideBar.vue";

import Deal from "@/api/deals.js";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    getDashboardData() {
      Deal.dashboard().then((response) => {
        this.data = response.data;
      });
    },
  },
  created() {
    this.getDashboardData();
  },
};
</script>

<style></style>
