import { render, staticRenderFns } from "./BaselineLayout.vue?vue&type=template&id=2db0214c&scoped=true&"
import script from "./BaselineLayout.vue?vue&type=script&lang=js&"
export * from "./BaselineLayout.vue?vue&type=script&lang=js&"
import style0 from "./BaselineLayout.vue?vue&type=style&index=0&id=2db0214c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db0214c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VFooter,VMain,VSnackbar,VSpacer})
