import axios from "axios";
import store from "@/store/store.js";
import AuthAPI from "@/api/auth";
import router from "@/router/router";

const Api = axios.create({
  baseURL: "http://localhost/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor to Add the access token in the request header
Api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters["auth/accessToken"]}`;
  return config;
});

const errorInterceptor = (error) => {
  const originalRequest = error.config;
  const { status } = error.response;
  if (status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    AuthAPI.refreshToken(store.getters["auth/refreshToken"])
      .then((response) => {
        store.dispatch("auth/setTokens", response.data);
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        return Api(originalRequest);
      })
      .catch((error) => {
        console.log(error);
        // if token refresh fails, logout the user to avoid potential security risks.
        store.dispatch("auth/logout");
        router.push({ name: "login" });
      });
  } else {
    store.dispatch("snackbarError", error);
  }
};

Api.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => errorInterceptor(error) // handle the request
);

export default Api;
