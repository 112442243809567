var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{attrs:{"value":_vm.tabNum}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('h3',[_vm._v("Portfolio Top Assets")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","calculate-widths":"","headers":_vm.portfolioAssetsTable ? _vm.portfolioAssetsTable.headers : [],"items":_vm.portfolioAssetsTop ? _vm.portfolioAssetsTop.results : [],"item-key":"asset_id","options":_vm.portfolioAssetsTable.options},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('Combobox',{attrs:{"solo":"","label":"Number of Items","items":_vm.topX},on:{"input":_vm.getTopAssets},model:{value:(_vm.n),callback:function ($$v) {_vm.n=$$v},expression:"n"}})],1)],1),(_vm.portfolioAssetsTop)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('strong',[_vm._v("Appraisal Value: ")]),_vm._v(_vm._s(_vm.portfolioAssetsTop.value)+" ("+_vm._s(_vm.portfolioAssetsTop.percent_value)+"%) ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('strong',[_vm._v("Units:")]),_vm._v(_vm._s(_vm.portfolioAssetsTop.percent_assets)+"% ")])],1):_vm._e()],1)]},proxy:true},{key:"item.cadastral_reference_exists",fn:function(ref){
var item = ref.item;
return [(item.fk_referencia_catastral || item.fk_referencia_finca)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(
                !item.fk_referencia_catastral && !item.fk_referencia_finca
              )?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.cadastral_detail",fn:function(ref){
              var item = ref.item;
return [(item.fk_referencia_catastral || item.fk_referencia_finca)?_c('v-icon',{on:{"click":function($event){return _vm.getCatastroDetail(item)}}},[_vm._v("mdi-information-outline")]):_vm._e()]}},{key:"item.is_geolocated",fn:function(ref){
              var item = ref.item;
return [(item.geo_lng && item.geo_lat)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(!(item.geo_lng && item.geo_lat))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.units",fn:function(ref){
              var item = ref.item;
return [(item.is_parking)?_c('div',[_vm._v("€ / Unit")]):_vm._e(),(!item.is_parking)?_c('div',[_vm._v("€ / m2")]):_vm._e()]}},{key:"item.view_ask_comps_buy",fn:function(ref){
              var item = ref.item;
return [(item.ask_comps_buy.length > 0)?_c('v-chip',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.openDialogAskBuy(item)}}},[_vm._v(_vm._s(item.num_comps_ask_buy))]):_vm._e()]}},{key:"item.view_ask_comps_rent",fn:function(ref){
              var item = ref.item;
return [(item.ask_comps_rent.length > 0)?_c('v-chip',{attrs:{"small":"","color":"yellow"},on:{"click":function($event){return _vm.openDialogAskRent(item)}}},[_vm._v(_vm._s(item.num_comps_ask_rent))]):_vm._e()]}},{key:"item.view_closing_comps",fn:function(ref){
              var item = ref.item;
return [(item.closing_comps.length > 0)?_c('v-chip',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.openDialogClosing(item)}}},[_vm._v(" "+_vm._s(item.num_comps_closing))]):_vm._e()]}}])})],1)],1),_c('v-row',[_c('v-col')],1)],1),_c('v-dialog',{model:{value:(_vm.dialogAskBuy),callback:function ($$v) {_vm.dialogAskBuy=$$v},expression:"dialogAskBuy"}},[_c('v-container',[_c('v-row',[_c('v-col',[(_vm.portfolioAsset)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","show-select":"","headers":_vm.portfolioAskCompsBuyTable.headers,"items":_vm.portfolioAsset[0].ask_comps_buy,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"marco white--text",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Asking Comps: Buy")]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.portfolioAsset[0].asset_id)+" - "+_vm._s(_vm.portfolioAsset[0].asset_type)+" - "+_vm._s(_vm.portfolioAsset[0].address))])],1)]},proxy:true},{key:"item.des_urlanuncio",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"x-small":"","href":item.des_urlanuncio,"target":"_blank"}},[_vm._v("View")])]}}],null,false,804887579),model:{value:(_vm.portfolioAssetSelections),callback:function ($$v) {_vm.portfolioAssetSelections=$$v},expression:"portfolioAssetSelections"}}):_vm._e()],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialogAskRent),callback:function ($$v) {_vm.dialogAskRent=$$v},expression:"dialogAskRent"}},[_c('v-container',[_c('v-row',[_c('v-col',[(_vm.portfolioAsset)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","show-select":"","headers":_vm.portfolioAskCompsRentTable.headers,"items":_vm.portfolioAsset[0].ask_comps_rent,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"marco white--text",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Asking Comps: Rent")]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.portfolioAsset[0].asset_id)+" - "+_vm._s(_vm.portfolioAsset[0].asset_type)+" - "+_vm._s(_vm.portfolioAsset[0].address))])],1)]},proxy:true},{key:"item.des_urlanuncio",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"x-small":"","href":item.des_urlanuncio,"target":"_blank"}},[_vm._v("View")])]}}],null,false,175302520),model:{value:(_vm.portfolioAssetSelections),callback:function ($$v) {_vm.portfolioAssetSelections=$$v},expression:"portfolioAssetSelections"}}):_vm._e()],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialogClosing),callback:function ($$v) {_vm.dialogClosing=$$v},expression:"dialogClosing"}},[_c('v-container',[_c('v-row',[_c('v-col',[(_vm.portfolioAsset)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","show-select":"","headers":_vm.portfolioClosingCompsTable.headers,"items":_vm.portfolioAsset[0].closing_comps,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"marco white--text",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Closing Comps")]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.portfolioAsset[0].asset_id)+" - "+_vm._s(_vm.portfolioAsset[0].asset_type)+" - "+_vm._s(_vm.portfolioAsset[0].address))])],1)]},proxy:true}],null,false,1302658720),model:{value:(_vm.portfolioAssetSelections),callback:function ($$v) {_vm.portfolioAssetSelections=$$v},expression:"portfolioAssetSelections"}}):_vm._e()],1)],1)],1)],1),(_vm.catastroDetail)?_c('v-dialog',{model:{value:(_vm.dialogCatastro),callback:function ($$v) {_vm.dialogCatastro=$$v},expression:"dialogCatastro"}},[_c('CatastroDetailCard',{attrs:{"catastroData":_vm.catastroDetail}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }