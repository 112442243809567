<template>
  <WaterfallChart
    :hcTitle="hcTitle"
    hcSubtitle="Appraisal Value"
    :hcData="hcData"
  />
</template>

<script>
import Highcharts from "highcharts";
import WaterfallChart from "@/components/charts/highcharts/Waterfall.vue";

export default {
  props: ["stratData"],
  components: { WaterfallChart },
  computed: {
    hcTitle() {
      if (this.stratData) {
        return this.stratData.field;
      }
      return null;
    },
    hcSubtitle() {
      return "Subtitle";
    },
    hcData() {
      let chartData = [
        {
          name: "Appraisal Value",
          upColor: "#004355",
          color: "#E6E6E6",
          data: [],
          dataLabels: {
            enabled: true,
            formatter: function() {
              return Highcharts.numberFormat(this.y / 1000, 0, ",") + "k";
            },
            style: { fontWeight: "bold" },
          },
          pointPadding: 0,
        },
      ];
      if (this.stratData) {
        for (let [key, value] of Object.entries(this.stratData.strat)) {
          console.log(key, value);
          chartData[0].data.push({ name: key, y: value });
        }
        chartData[0].data.push({
          name: "Total",
          isSum: true,
          color: "#E6E6E6",
        });
      }

      return chartData;
    },
  },
};
</script>

<style></style>
