<template>
  <!-- Add Pie Chart to the mix -->
  <div class="ma-5">
    <!-- <div v-for="[key, val] of Object.entries(stratData)" :key="key">
      <div>KEY:{{ key }}</div>
      <div>Value:{{ val }}</div>
    </div> -->
    <PieChart
      v-if="numCategories < 10"
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="pieData"
    />
    <!-- <VBarChart
      v-if="numCategories < 7 && numCategories > 5"
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="hcData"
      :AxisCategories="AxisCategories"
      :xAxisTitle="xAxisTitle"
      :yAxisTitle="yAxisTitle"
    /> -->
    <HBarChart
      v-if="numCategories >= 10"
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="hcData"
      :AxisCategories="AxisCategories"
      :xAxisTitle="xAxisTitle"
      :yAxisTitle="yAxisTitle"
    />
  </div>
  <!-- </v-card-text>
  </v-card> -->
</template>

<script>
// import VBarChart from "@/components/charts/highcharts/VBarChart.vue";
// VBarChart
import HBarChart from "@/components/charts/highcharts/HBarChart.vue";
import PieChart from "@/components/charts/highcharts/PieChart.vue";

export default {
  props: ["hcTitle", "hcSubtitle", "xAxisTitle", "yAxisTitle", "stratData"],
  components: { HBarChart, PieChart },
  data() {
    return {};
  },
  computed: {
    numCategories() {
      if (this.stratData) {
        return Object.keys(this.stratData).length;
      }
      return 0;
    },
    AxisCategories() {
      return Object.keys(this.stratData);
    },
    hcData() {
      return [
        {
          name: "Appraisal Value",
          color: "#18515E",
          data: Object.values(this.stratData), // sample data
        },
      ];
    },
    pieData() {
      let data = [];
      console.log("STRAT DATA", this.stratData.strat);
      for (let [key, val] of Object.entries(this.stratData)) {
        data.push({
          name: key,
          y: val,
          sliced: true,
        });
      }

      let pieDtata = [
        {
          name: this.hcTitle,
          colorByPoint: true,
          data: data,
        },
      ];
      return pieDtata;
    },
  },
};
</script>

<style></style>
