<template>
  <BaselineLayout>
    <web-map slot="main"> </web-map>
  </BaselineLayout>
</template>

<script>
import WebMap from "@/components/esri/webmap.vue";
import BaselineLayout from "@/layouts/BaselineLayout.vue";

export default {
  name: "EsriMap",
  components: { WebMap, BaselineLayout },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#app {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
