import CompanyAPI from "@/api/company";

export default {
  namespaced: true,
  state: {
    view: "parent",
    selectedView: "list",
    selectedParentCompany: null,
    selectedChildCompany: null,
    selectedDivision: null,
    selectedLineOfService: null,
    selectedEmployeeCategory: null,
    selectedEmployee: null,
    parentCompanies: [],
    childCompanies: [],
    divisions: [],
    linesOfService: [],
    employeeCategories: [],
    employees: [],
  },
  getters: {},
  mutations: {
    selectView(state, view) {
      state.view = view;
    },
    getParentCompanies(state, parentCompanies) {
      state.parentCompanies = parentCompanies;
    },
    setParentCompany(state, parentCompany) {
      state.selectedParentCompany = parentCompany;
    },
    getChildCompanies(state, childCompanies) {
      state.childCompanies = childCompanies;
    },
    setChildCompany(state, childCompany) {
      state.selectedChildCompany = childCompany;
    },

    getDivisions(state, divisions) {
      state.divisions = divisions;
    },
    setDivision(state, division) {
      state.selectedDivision = division;
    },
    getLinesOfService(state, linesOfService) {
      state.linesOfService = linesOfService;
    },
    setLineOfService(state, los) {
      state.selectedLineOfService = los;
    },

    getEmployeeCategories(state, employeeCategories) {
      state.employeeCategories = employeeCategories;
    },
    setEmployeeCategory(state, employeeCategory) {
      state.selectedEmployeeCategory = employeeCategory;
    },

    getEmployees(state, employees) {
      state.employees = employees;
    },
    setEmployee(state, employee) {
      state.selectedEmployee = employee;
    },

    GET_CURRICULUMS(state, curriculums) {
      state.curriculums = curriculums;
    },
  },
  actions: {
    selectView(context, view) {
      context.commit("selectView", view);
    },
    // PARENT COMPANIES
    getParentCompanies(context) {
      CompanyAPI.parentCompanies()
        .then((response) => {
          context.commit("getParentCompanies", response.data);
        })
        .catch((error) => {
          let snackbar = {
            showing: true,
            text: `Parent Companies Fetch: ${error}`,
            color: "error",
          };
          context.dispatch("setSnackbar", snackbar, { root: true });
        });
    },
    postParentCompany(context, form) {
      let id = JSON.parse(form.get("id"));
      // Determine Rquest Method

      if (id !== null) {
        CompanyAPI.updateParent(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getParentCompanies");
          });
      } else {
        CompanyAPI.postParent(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getParentCompanies");
          });
      }
    },
    searchParentCompany(context, q) {
      CompanyAPI.searchParentCompany(q)
        .then((response) => {
          context.commit("getParentCompanies", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setParentCompany(context, parentCompany) {
      context.commit("setParentCompany", parentCompany);
    },
    deleteParentCompany(context, parentCompanyID) {
      let userInput = confirm(
        `Are you sure you wish to delete Company${parentCompanyID}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to Company ${parentCompanyID} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteParent(parentCompanyID)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              let snackbar = {
                showing: true,
                text: `Parent Company Delete: ${error}`,
                color: "error",
              };
              context.dispatch("setSnackbar", snackbar, { root: true });
            })
            .then(() => {
              context.dispatch("getParentCompanies");
            });
        }
      }
    },

    // CHILD COMPANIES
    getChildCompanies(context) {
      CompanyAPI.childCompanies()
        .then((response) => {
          context.commit("getChildCompanies", response.data);
        })
        .catch((error) => {
          let snackbar = {
            showing: true,
            text: `Child Companies Fetch: ${error}`,
            color: "error",
          };
          context.dispatch("setSnackbar", snackbar, { root: true });
        });
    },
    postChildCompany(context, form) {
      let id = JSON.parse(form.get("id"));
      // Determine Rquest Method
      if (id !== null) {
        CompanyAPI.updateChild(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getChildCompanies");
          });
      } else {
        CompanyAPI.postChild(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getChildCompanies");
          });
      }
    },
    setChildCompany(context, childCompany) {
      context.commit("setChildCompany", childCompany);
    },
    deleteChildCompany(context, childCompanyID) {
      let userInput = confirm(
        `Are you sure you wish to delete Child Company ${childCompanyID}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to Child Company ${childCompanyID} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteChild(childCompanyID)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              context.dispatch("getChildCompanies");
            });
        }
      }
    },

    // DIVISIONS
    getDivisions(context) {
      CompanyAPI.divisions()
        .then((response) => {
          context.commit("getDivisions", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postDivision(context, form) {
      // Determine Rquest Method
      let id = JSON.parse(form.get("id"));
      console.log("Division ID:", id);
      if (id !== null) {
        CompanyAPI.updateDivision(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getDivisions");
          });
      } else {
        CompanyAPI.postDivision(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getDivisions");
          });
      }
    },
    setDivision(context, division) {
      context.commit("setDivision", division);
    },
    deleteDivision(context, divisionId) {
      let userInput = confirm(
        `Are you sure you wish to delete Division ${divisionId}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to Division ${divisionId} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteDivision(divisionId)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              context.dispatch("getDivisions");
            });
        }
      }
    },

    // LINES OF SERVICE
    getLinesOfService(context) {
      CompanyAPI.linesOfService()
        .then((response) => {
          context.commit("getLinesOfService", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postLineOfService(context, form) {
      // Determine Rquest Method
      let id = JSON.parse(form.get("id"));
      if (id !== null) {
        CompanyAPI.updateLineOfService(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getLinesOfService");
          });
      } else {
        CompanyAPI.postLineOfService(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getLinesOfService");
          });
      }
    },
    setLineOfService(context, los) {
      context.commit("setLineOfService", los);
    },
    deleteLineOfService(context, losId) {
      let userInput = confirm(
        `Are you sure you wish to delete Line of Service: ${losId}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to the Line of Service: ${losId} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteLineOfService(losId)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              context.dispatch("getLinesOfService");
            });
        }
      }
    },

    // EMPLOYEE CATEGORIES
    getEmployeeCategories(context) {
      CompanyAPI.employeeCategories()
        .then((response) => {
          context.commit("getEmployeeCategories", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postEmployeeCategory(context, form) {
      // Determine Rquest Method
      let id = JSON.parse(form.get("id"));
      if (id !== null) {
        CompanyAPI.updateEmployeeCategory(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getEmployeeCategories");
          });
      } else {
        CompanyAPI.postEmployeeCategory(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getEmployeeCategories");
          });
      }
    },
    setEmployeeCategory(context, los) {
      context.commit("setEmployeeCategory", los);
    },
    deleteEmployeeCategory(context, employeeCategoryId) {
      let userInput = confirm(
        `Are you sure you wish to delete Employee Category: ${employeeCategoryId}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to the Employee Category: ${employeeCategoryId} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteEmployeeCategory(employeeCategoryId)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              context.dispatch("getEmployeeCategories");
            });
        }
      }
    },

    // EMPLOYEES
    getEmployees(context) {
      CompanyAPI.employees()
        .then((response) => {
          context.commit("getEmployees", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postEmployee(context, form) {
      // Determine Rquest Method);
      let id = JSON.parse(form.get("id"));
      if (id !== null) {
        CompanyAPI.updateEmployee(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getEmployees");
          });
      } else {
        CompanyAPI.postEmployee(form)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getEmployees");
          });
      }
    },
    deleteEmployee(context, employeeId) {
      let userInput = confirm(
        `Are you sure you wish to delete Employee : ${employeeId}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to the Employee : ${employeeId} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteEmployee(employeeId)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              context.dispatch("getEmployees");
            });
        }
      }
    },
    setEmployee(context, los) {
      context.commit("setEmployee", los);
    },

    // Curriculums
    getCurriculums(context) {
      CompanyAPI.curriculums()
        .then((response) => {
          context.commit("GET_CURRICULUMS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postCurriculum(context, form) {
      // Determine Rquest Method);
      let id = JSON.parse(form.get("id"));
      if (id !== null) {
        CompanyAPI.updateCurriculum(form)
          .then((response) => {
            console.log(response.data);
            let msg = "Curriculum updated Successfully!";
            context.dispatch("snackbarSuccess", msg, { root: true });
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getCurriculums");
          });
      } else {
        CompanyAPI.postCurriculum(form)
          .then(() => {
            let msg = "Curriculum updated Successfully!";
            context.dispatch("snackbarSuccess", msg, { root: true });
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            context.dispatch("getCurriculums");
          });
      }
    },
    deleteCurriculum(context, CurriculumId) {
      let userInput = confirm(
        `Are you sure you wish to delete Curriculum : ${CurriculumId}?`
      );
      if (userInput) {
        let userInput2 = confirm(
          `Are you sure you wish to delete? This will delete ALL the stored information relating to the Curriculum : ${CurriculumId} and cannot be undone`
        );
        if (userInput2) {
          CompanyAPI.deleteCurriculum(CurriculumId)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            })
            .then(() => {
              context.dispatch("getCurriculums");
            });
        }
      }
    },
    setCurriculum(context, los) {
      context.commit("setCurriculum", los);
    },
  },
};
