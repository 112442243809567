<template>
  <v-btn v-bind="$attrs" v-on="$listeners" class="ma-3">{{ text }}</v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
