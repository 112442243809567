<template>
  <v-card>
    <Toolbar />
    <v-tabs v-model="tab">
      <v-tab left v-for="tab in tabs" :key="tab.number">
        <v-icon left>
          {{ tab.icon }}
        </v-icon>
        {{ tab.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <OverviewTab :tabNum="0" />
      <DatatapeTab :tabNum="1" />
      <StratsTab :tabNum="2" />
      <TopX :value="3" />
      <AssetsTab :tabNum="4" />
      <v-tab-item :value="5">VALUATION</v-tab-item>
      <v-tab-item :value="6">OUTLIERS</v-tab-item>
      <!-- <v-tab-item :value="6">TAB 6</v-tab-item> -->
      <AnalysisTab :tabNum="7" />
      <TasksTab :tabNum="8" />
      <GoogleMapTab :tabNum="9" />
    </v-tabs-items>
  </v-card>
</template>

<script>
import Toolbar from "@/components/workbench/real-estate/portfolio-dashboard/Toolbar.vue";
import OverviewTab from "@/components/workbench/real-estate/portfolio-dashboard/Overview.vue";
import AnalysisTab from "@/components/workbench/real-estate/portfolio-dashboard/Analysis.vue";
import AssetsTab from "@/components/workbench/real-estate/portfolio-dashboard/Assets.vue";
import StratsTab from "@/components/workbench/real-estate/portfolio-dashboard/Strats.vue";
import TasksTab from "@/components/workbench/real-estate/portfolio-dashboard/Tasks.vue";
import DatatapeTab from "@/components/workbench/real-estate/portfolio-dashboard/Datatape.vue";
import TopX from "@/components/workbench/real-estate/portfolio-dashboard/TopX.vue";
import GoogleMapTab from "@/components/workbench/real-estate/portfolio-dashboard/GoogleMapAssets.vue";
export default {
  components: {
    Toolbar,
    DatatapeTab,
    OverviewTab,
    AnalysisTab,
    AssetsTab,
    StratsTab,
    TasksTab,
    TopX,
    GoogleMapTab,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        { number: 0, icon: "mdi-folder-multiple", text: "Portfolios" },
        { number: 1, icon: "mdi-table", text: "Datatape" },
        { number: 2, icon: "mdi-chart-bar", text: "Strats" },
        {
          number: 3,
          icon: "mdi-format-vertical-align-top",
          text: "Top Assets",
        },
        { number: 4, icon: "mdi-home", text: "Assets" },
        // { number: 4, icon: "mdi-google-maps", text: "Maps" },
        { number: 5, icon: "mdi-currency-eur", text: "Valuation" },
        // { number: 6, icon: "mdi-file-multiple", text: "Reports" },
        { number: 6, icon: "mdi-set-right", text: "Outliers" },
        { number: 7, icon: "mdi-alert", text: "Errors" },
        { number: 8, icon: "mdi-calendar-check", text: "Tasks" },
        { number: 9, icon: "mdi-google-maps", text: "Maps" },
      ],
    };
  },
};
</script>

<style></style>
