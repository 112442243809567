import Api from "./Api.js";

export default {
  datasets(params) {
    return Api.get(`ine/datasets/`, { params: params });
  },
  dashboardDatasets(params) {
    return Api.get(`ine/dashboard/datasets`, { params: params });
  },
  loader(task, params) {
    return Api.get(`ine/loader/${task}/`, { params: params });
  },
  operaciones() {
    return Api.get(`ine/operaciones/`);
  },
  reports(params) {
    return Api.get(`ine/reports/`, { params: params });
  },
  series(params) {
    return Api.get(`ine/operaciones/`, { params: params });
  },
  seriesCollections(params) {
    return Api.get(`ine/collections/`, { params: params });
  },

  seriesOperacion(operacion) {
    return Api.get(`ine/operacion/${operacion}/series/`);
  },
  seriesData(dataset) {
    return Api.get(`ine/datasets/data/${dataset}/`);
  },
  seriesVarVals(datasetId) {
    return Api.get(`ine/datasets/${datasetId}/valores-variables/`);
  },
  seriesFamily(datasetId) {
    return Api.get(`ine/datasets/${datasetId}/family/`);
  },

  variables() {
    return Api.get(`ine/variables/`);
  },
  variablesOperacion(operacion) {
    return Api.get(`ine/operacion/${operacion}/variables/`);
  },
  valoresVariable(variableId) {
    return Api.get(`ine/variables/${variableId}/valores/`);
  },
};
