<template>
  <baseline-layout>
    <v-container slot="main">
      <SideBar />

      <v-row justify="center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search Filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container class="pa-4">
                <v-row>
                  <!-- NEWS SEARCH -->
                  <v-text-field
                    label="Search"
                    v-model="searchStr"
                    append-icon="mdi-magnify"
                  >
                  </v-text-field>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialogFromDate"
                      v-model="modalFromDate"
                      :return-value.sync="fromDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fromDate">
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalFromDate = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogFromDate.save(fromDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialogToDate"
                      v-model="modalToDate"
                      :return-value.sync="toDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="toDate"
                          label="To Date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="toDate">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalToDate = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogToDate.save(toDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <Combobox
                    multiple
                    label="Clients"
                    v-model="selectedClients"
                    :items="clients"
                    item-text="legal_name"
                    item-value="id"
                  />
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row justify="center">
        <FabButton icon="mdi-magnify" color="success" @click="search"
          >Search</FabButton
        >
        <!-- <FabButton
          v-if="deals.length > 0"
          icon="mdi-download"
          color="warning"
          @click="download"
          >Search</FabButton
        > -->
      </v-row>

      <v-row v-for="article in news" :key="article.id">
        <v-col cols="12">
          <v-card>
            <v-card-title
              >{{ article.title }} <v-spacer></v-spacer> {{ article.clients }}
            </v-card-title>
            <v-card-subtitle>{{ article.date }}</v-card-subtitle>
            <v-card-text>{{ article.description }}</v-card-text>
            <v-card-actions>
              <v-btn color="primary" :to="article.url">View</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row justify="center"> -->

      <!-- NEWS RESULTS -->
      <!-- <v-data-table
          v-model="selections"
          dense
          show-select
          :items="news"
          :item-key="'id'"
          :headers="columns"
        >
          <template #item.view="{item}">
            <v-btn x-small class="primary" @click="goToDetail(item.id)"
              >View</v-btn
            >
          </template>
        </v-data-table> -->
      <!-- </v-row> -->
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/news/SideBar.vue";
import Combobox from "@/components/base/Combobox.vue";
import FabButton from "@/components/base/buttons/FabButton.vue";

import Client from "@/api/clients.js";
import Credential from "@/api/credentials.js";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
    Combobox,
    FabButton,
  },
  data() {
    return {
      searchStr: "",
      fromDate: null,
      toDate: null,
      modalFromDate: false,
      modalToDate: false,

      selections: [],
      credentials: [],
      clients: [],
      selectedClients: [],
    };
  },
  methods: {
    search() {
      let params = {};
      //   let params = {
      //     search: this.searchStr,
      //     from_date: this.fromDate,
      //     to_date: this.toDate,
      //     clients: JSON.stringify(this.selectedClients),
      //   };
      if (this.searchStr) {
        params.search = this.searchStr;
      }
      if (this.fromDate) {
        params.from_date = this.fromDate;
      }
      if (this.toDate) {
        params.to_date = this.toDate;
      }
      if (
        this.selectedClients instanceof Array &&
        this.selectedClients.length > 0
      ) {
        params.clients = JSON.stringify(this.selectedClients);
      }

      Credential.search(params).then((response) => {
        this.credentials = response.data;
      });
    },
    // getClientTypes() {
    //   this.$store.dispatch("clients/getClientTypes");
    // },
    getClients() {
      Client.all().then((response) => {
        this.clients = response.data;
      });
    },
    goToDetail(clientId) {
      this.$router.push({ name: "client-detail", params: { id: clientId } });
    },
  },
  computed: {
    clientTypes() {
      return this.$store.state.clients.clientTypes;
    },
    results() {
      return this.$store.state.clients.searchResults;
    },
  },
  mounted() {
    this.getClients();
    // this.getNews();
  },
};
</script>

<style></style>
