<template>
  <v-card width="80%" elevation="24">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="8">
          <v-form>
            <Combobox
              label="Language"
              :items="languages"
              prepend-icon="mdi-account-voice"
              v-model="form.language"
              :readonly="isReadonly"
            >
            </Combobox>

            <!-- <DatePicker :date="form.date" /> -->

            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="form.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date"
                  label="Date"
                  hint="MM/DD/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :readonly="isReadonly"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.date)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>

            <TextField
              label="Title"
              prepend-icon="mdi-format-title"
              v-model="form.title"
              :readonly="isReadonly"
            ></TextField>
            <TextArea
              label="Description"
              v-model="form.description"
              prepend-icon="mdi-text-subject"
              :readonly="isReadonly"
            ></TextArea>
            <TextField
              label="URL"
              prepend-icon="mdi-web"
              v-model="form.url"
              :readonly="isReadonly"
            ></TextField>
            <Combobox
              label="Clients"
              :items="clients"
              item-text="legal_name"
              item-value="id"
              prepend-icon="mdi-account"
              multiple
              v-model="form.clients"
              :readonly="isReadonly"
            >
            </Combobox>
            <v-row><v-divider></v-divider></v-row>
            <v-row justify="center">
              <IconBtn
                v-if="!isReadonly"
                color="success"
                icon="mdi-submit"
                text="Submit"
                @click="post"
              />
              <IconBtn
                v-if="isReadonly"
                color="warning"
                icon="mdi-pencil"
                text="Edit"
                @click="isReadonly = false"
              />
              <IconBtn
                v-if="articleId"
                color="error"
                icon="mdi-delete"
                text="Delete"
                @click="deleteItem()"
              />
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
        {{ article }}
      </v-row> -->
    </v-container>
  </v-card>
</template>

<script>
import TextField from "@/components/base/TextField.vue";
import TextArea from "@/components/base/TextArea.vue";
// import Btn from "@/components/base/Btn.vue";
import Combobox from "@/components/base/Combobox.vue";
import IconBtn from "@/components/base/buttons/IconButton.vue";
// import DatePicker from "@/components/base/DatePicker.vue";

import Client from "@/api/clients";
import News from "@/api/news";

export default {
  components: {
    IconBtn,
    Combobox,
    // DatePicker,
    TextArea,
    TextField,
  },
  props: {
    articleId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      isReadonly: false,
      modal: false,
      article: null,
      IdArticle: null,
      languages: [
        { text: "Español", value: "es" },
        { text: "English", value: "en" },
      ],
      form: {
        id: null,
        language: null,
        date: null,
        title: null,
        description: null,
        url: null,
        clients: null,
      },
      clients: null,
    };
  },
  methods: {
    deleteItem() {
      News.delete(this.articleId);
    },
    getArticle(IdArticle) {
      News.article(IdArticle).then((response) => {
        this.article = response.data;
        this.populateForm();
      });
    },

    getClients() {
      Client.all().then((response) => {
        this.clients = response.data;
      });
    },
    post() {
      let form = new FormData();
      Object.keys(this.form).forEach((key) => {
        console.log(key);
        if (this.form[key] instanceof Array) {
          this.form[key].forEach((item) => {
            form.append(key, item);
          });
        } else {
          form.append(key, this.form[key]);
        }
      });
      //
      News.post(form)
        .then((response) => {
          let text;
          if (JSON.parse(form.get("id"))) {
            text = "Article Updated Successfuly!";
          } else {
            text = "Article Created Successfuly!";
          }
          this.$store.dispatch("successSnackbar", {
            text: text,
          });
          this.isReadonly = true;
          this.IdArticle = response.data.id;
          this.getArticle(this.IdArticle);
          this.populateForm();
        })
        .catch((error) => {
          this.$store.dispatch("errorSnackbar", {
            text: `${JSON.stringify(error.response.data)}`,
          });
        });
    },
    populateForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.article[key];
      });
    },
  },
  beforeMount() {
    this.getClients();
    if (this.articleId) {
      this.IdArticle = this.articleId;
      this.getArticle(this.IdArticle);
      this.isReadonly = true;
    }
  },
};
</script>

<style></style>
