<template>
  <v-tab-item :value="tabNum">
    <v-card flat>
      <v-card-title>Portfolio Stratifications</v-card-title>
      <v-card-text>
        <v-container v-if="portfolio">
          <v-row justify="center" align="center">
            <v-col cols="3" align="center" justify="center">
              <Combobox
                v-model="strats.params.strat_field"
                label="Strat Field"
                :items="portfolio.columns"
                width="200"
              />
            </v-col>
            <v-col cols="3" align="center" justify="center">
              <Combobox
                v-model="strats.params.value_field"
                label="Value Field"
                :items="portfolio.columns"
              />
            </v-col>
            <v-col cols="2" align="center" justify="center">
              <Btn @click="getPortfolioFieldStrat">
                Refresh
              </Btn>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="portfolioFieldStrat">
            <StratChart
              :hcTitle="strats.params.strat_field"
              :hcSubtitle="null"
              :stratData="portfolioFieldStrat.strat"
              :xAxisTitle="null"
              :yAxisTitle="strats.params.value_field"
            />
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import Btn from "@/components/base/buttons/Btn.vue";
import Combobox from "@/components/base/Combobox.vue";
import StratChart from "@/components/real-estate/charts/StratChart.vue";

let storeModule = "realEstatePortfolioDashboard";
export default {
  props: ["tabNum"],
  components: { Btn, Combobox, StratChart },
  data() {
    return {
      strats: { params: { strat_field: null, value_field: null } },
    };
  },
  methods: {
    getPortfolioFieldStrat() {
      this.$store.dispatch(
        `${storeModule}/getPortfolioFieldStrat`,
        this.strats.params
      );
    },
  },
  computed: {
    portfolio() {
      return this.$store.getters[`${storeModule}/portfolio`];
    },
    portfolioFieldStrat() {
      return this.$store.getters[`${storeModule}/portfolioFieldStrat`];
    },
  },
};
</script>

<style></style>
