<template>
  <div>
    <highcharts class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import drilldownInit from "highcharts/modules/drilldown";
import { Chart } from "highcharts-vue";
drilldownInit(Highcharts);

export default {
  props: [
    "hcTitle",
    "hcSubtitle",
    "series",
    "drilldown",
    "AxisCategories",
    "xAxisTitle",
    "yAxisTitle",
  ],
  components: { highcharts: Chart },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column",
        },
        title: {
          text: this.hcTitle,
        },
        xAxis: {
          type: "category",
        },

        legend: {
          enabled: false,
        },

        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },

        series: this.series,
        drilldown: this.drilldown,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #42b983;
}
.hc {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
</style>
