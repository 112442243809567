import Api from "./Api";

export default {
  getClients() {
    return Api.get("clients/companies");
  },
  getClient(clientId) {
    return Api.get(`clients/companies/${clientId}`);
  },

  deleteContact(contactId) {
    return Api.delete(`clients/contacts/${contactId}`);
  },
  postClient(clientForm) {
    return Api.post(`clients/companies/`, clientForm);
  },
  patchClient(clientForm) {
    let clientId = clientForm.get("id");
    return Api.patch(`clients/companies/${clientId}/`, clientForm);
  },
  getClientContacts(clientId) {
    return Api.get(`clients/companies/${clientId}/contacts`);
  },
  getContact(contactId) {
    return Api.get(`clients/contacts/${contactId}/`);
  },
  getContacts() {
    return Api.get("clients/contacts");
  },
  searchContacts(searchStr) {
    return Api.get("clients/contacts/", { params: { searchStr: searchStr } });
  },

  clientsDashboard() {
    return Api.get("clients/clients-dashboard");
  },
  contactsDashboard() {
    return Api.get("clients/contacts-dashboard");
  },

  deleteAll() {
    return Api.get(`clients/delete-all/`);
  },

  clientInvestmentStrategies(clientId) {
    return Api.get(`clients/${clientId}/strategy/`);
  },
  investmentStrategies() {
    return Api.get(`clients/investment-strategies/`);
  },
  loadFromFile(form) {
    return Api.post(`clients/load/`, form);
  },

  postContact(contactForm) {
    return Api.post(`clients/contacts/`, contactForm);
  },

  putContact(contactForm) {
    let contactId = contactForm.get("id");
    return Api.put(`clients/contacts/${contactId}/`, contactForm);
  },

  search(payload) {
    return Api.get("clients/companies", payload);
  },
  types() {
    return Api.get("clients/types");
  },
  //   post(payload) {
  //     return Api.get("clients/companies", payload);
  //   },
};
