<template>
  <v-card elevation="16">
    <v-toolbar color="marco" dark>
      <v-toolbar-title
        >{{ form.first_name }} {{ form.last_name }}</v-toolbar-title
      >
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row dense justify="center" v-if="form.image">
          <img
            class="ma-4"
            style="height:160px"
            :src="form.image"
            alt="logo imgage"
          />
        </v-row>

        <v-divider></v-divider>

        <v-row dense justify="center">
          <IconBtn
            v-if="!form.id && !readonly"
            color="success white--text"
            @click="postContact"
            text="Create"
            icon="mdi-plus"
          />
          <IconBtn
            v-if="form.id && !readonly"
            color="error white--text"
            @click="cancelUpdate"
            text="Cancel"
            icon="mdi-cancel"
          />
          <IconBtn
            v-if="form.id && !readonly"
            color="success white--text"
            @click="postContact"
            text="Update"
            icon="mdi-check"
          />

          <IconBtn
            v-if="form.id && readonly"
            color="warning white--text"
            @click="readonly = false"
            text="Edit"
            icon="mdi-pencil"
          />
          <IconBtn
            v-if="form.id && !readonly"
            color="error white--text"
            @click="deleteContact(contactId)"
            text="Delete"
            icon="mdi-delete"
          />
        </v-row>
        <v-row dense class="my-4">
          <v-divider></v-divider>
        </v-row>
        <v-row dense>
          <v-col>
            <Combobox
              label="Client"
              v-model="form.client"
              :items="clients"
              item-text="legal_name"
              item-value="id"
              :readonly="clientReadonly || readonly"
              :filled="!readonly"
            />
          </v-col>
          <v-col>
            <TextField
              label="Position"
              v-model="form.position"
              :required="true"
              :readonly="readonly"
              :filled="!readonly"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <TextField
              label="First Name"
              v-model="form.first_name"
              :required="true"
              :readonly="readonly"
              :filled="!readonly"
            />
          </v-col>
          <v-col>
            <TextField
              label="Last Name(s)"
              v-model="form.last_name"
              :required="true"
              :readonly="readonly"
              :filled="!readonly"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <TextField
              label="Email"
              v-model="form.email"
              :required="true"
              :readonly="readonly"
              :filled="!readonly"
            />
          </v-col>
          <v-col>
            <TextField
              label="Phone Number"
              v-model="form.phone_number"
              :required="true"
              :readonly="readonly"
              :filled="!readonly"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-file-input
              v-if="!readonly"
              v-model="file"
              chips
              show-size
              label="Photograph"
              class="text-subtitle-2"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialogContactForm">Cancel</v-btn>
      <v-btn @click="postContact">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TextField from "@/components/base/TextField.vue";
import Combobox from "@/components/base/Combobox.vue";
import IconBtn from "@/components/base/buttons/IconButton.vue";
import ClientsAPI from "@/api/clients.js";

export default {
  components: {
    TextField,
    Combobox,
    IconBtn,
  },
  props: {
    contactId: {
      required: false,
    },
    clientId: {
      required: false,
    },
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        this.refreshForm();
      },
    },
  },
  data() {
    return {
      clientReadonly: false,
      contactData: {},
      form: {
        id: null,
        client: null,
        first_name: null,
        last_name: null,
        position: null,
        email: null,
        phone_number: null,
        image: null,
      },
      file: null,
      readonly: false,
    };
  },
  methods: {
    async cancelUpdate() {
      await this.refreshForm();
      this.readonly = true;
    },
    closeDialogContactForm() {
      this.$store.dispatch("clients/setDialog", {
        dialog: "contactForm",
        view: false,
      });
    },
    clearContactForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },
    deleteContact(contactId) {
      let r = confirm(
        `Are you sure you want to delete ${this.form.first_name} - ${this.form.last_name}!`
      );
      if (r == true) {
        ClientsAPI.deleteContact(contactId).then(() => {
          this.clearContactForm();
          this.$store.dispatch("snackbarSuccess", "Client Deleted!");
          this.$store.dispatch("clients/getClientData", this.$route.params.id);
          this.$store.dispatch("clients/setDialog", {
            dialog: "contactForm",
            view: false,
          });
          this.readonly = false;
        });
      }
    },
    getClient(clientId) {
      ClientsAPI.getClient(clientId).then((response) => {
        this.form.client = response.data.id;
      });
    },
    async getContact() {
      await ClientsAPI.getContact(this.contactId).then((response) => {
        this.contactData = response.data;
      });
    },
    postContact() {
      let form = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) {
          form.append(key, this.form[key]);
        }
      });
      if (this.file) {
        form.set("image", this.file);
      } else {
        form.delete("image");
      }
      console.log("form.id", form.get("id"));
      if (!form.get("id")) {
        ClientsAPI.postContact(form).then((response) => {
          this.$store.dispatch(
            "snackbarSuccess",
            "Client Created Successfuly!"
          );
          this.contactId = response.data.id;
          this.getContact();
          this.refreshForm();
          this.refreshClientData();
          this.readonly = true;
          this.$store.dispatch("clients/setDialog", {
            dialog: "contactForm",
            view: false,
          });
        });
      } else {
        ClientsAPI.putContact(form).then((response) => {
          this.$store.dispatch(
            "snackbarSuccess",
            "Client Updated Successfuly!"
          );
          this.contactId = response.data.id;
          this.getContact();
          this.refreshForm();
          this.refreshClientData();
          this.readonly = true;
          this.$store.dispatch("clients/setDialog", {
            dialog: "contactForm",
            view: false,
          });
        });
      }
    },
    setForm() {
      console.log("Setting FormForm");
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.contactData[key];
      });
    },
    async refreshForm() {
      if (this.contactId) {
        await this.getContact();
        this.setForm();
      }
    },

    async refreshClientData() {
      this.$store.dispatch("clients/getClientData", this.$route.params.id);
    },
  },
  computed: {
    clients() {
      return this.$store.getters["clients/clients"];
    },
  },
  mounted() {
    this.$store.dispatch("clients/getClients");
    if (this.contactId) {
      this.readonly = true;
    }
  },
};
</script>

<style></style>
