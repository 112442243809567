import Api from "./Api.js";

export default {
  getArticles() {
    return Api.get("news/");
  },
  article(articleId) {
    return Api.get(`news/${articleId}/`);
  },

  searchArticles(params) {
    return Api.get("news/", { params: params });
  },
  clientNews(clientId) {
    return Api.get("news/", { params: { clients: clientId } });
  },
  post(form) {
    let id = JSON.parse(form.get("id"));
    console.log("ARTICLE ID", id, typeof id);
    if (id) {
      return Api.put(`news/${id}/`, form);
    } else {
      return Api.post("news/", form);
    }
  },
  delete(articleId) {
    return Api.delete(`news/${articleId}`);
  },
};
