<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Client Manager
              </h1>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col v-for="(icon, index) in icons" :key="index" cols="4">
                    <v-card elevation="16" @click="$router.push(icon.url)">
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-icon color="marco" size="120">{{
                          icon.icon
                        }}</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text>
                        <h1 class="marco--text">
                          {{ icon.title }}
                        </h1></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/clients/Layout.vue";

export default {
  components: { AppLayout },
  data() {
    return {
      icons: [
        // {
        //   title: "Client Firms",
        //   icon: "mdi-domain",
        //   url: { name: "client-dashboard" },
        // },
        // {
        //   title: "Client Contacts",
        //   icon: "mdi-card-account-phone",
        //   url: { name: "contact-dashboard" },
        // },
        {
          title: "Client Firms",
          icon: "mdi-domain",
          url: { name: "client-companies" },
        },
        {
          title: "Client Contacts",
          icon: "mdi-card-account-phone",
          url: { name: "client-contacts" },
        },
      ],
    };
  },
};
</script>

<style scoped>
#main-container {
  background-image: url("../../assets/skyscraper3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
