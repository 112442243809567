import Api from "./Api";
import store from "@/store/store.js";

export default {
  parentCompanies() {
    return Api.get("company/parent-company");
  },
  postParent(form) {
    return Api.post(`company/parent-company/`, form);
  },
  searchParentCompany(q) {
    return Api.get(`company/parent-company/search/`, { params: { q: q } });
  },
  updateParent(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/parent-company/${id}/`, form);
  },
  deleteParent(parentCompanyId) {
    return Api.delete(`company/parent-company/${parentCompanyId}`);
  },
  childCompanies() {
    return Api.get("company/child-company");
  },
  postChild(form) {
    return Api.post(`company/child-company/`, form);
  },
  updateChild(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/child-company/${id}/`, form);
  },
  deleteChild(childCompanyId) {
    return Api.delete(`company/child-company/${childCompanyId}`);
  },
  divisions() {
    return Api.get("company/division/");
  },
  postDivision(form) {
    return Api.post("company/division/", form);
  },
  updateDivision(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/division/${id}/`, form);
  },
  deleteDivision(divisionId) {
    return Api.delete(`company/division/${divisionId}/`);
  },
  linesOfService() {
    return Api.get("company/line-of-service/");
  },
  postLineOfService(form) {
    return Api.post("company/line-of-service/", form);
  },
  updateLineOfService(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/line-of-service/${id}/`, form);
  },
  deleteLineOfService(losId) {
    return Api.delete(`company/line-of-service/${losId}/`);
  },
  employeeCategories() {
    return Api.get("company/employee-category");
  },
  postEmployeeCategory(form) {
    return Api.post("company/employee-category/", form);
  },
  updateEmployeeCategory(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/employee-category/${id}/`, form);
  },
  deleteEmployeeCategory(employeeCategoryId) {
    return Api.delete(`company/employee-category/${employeeCategoryId}/`);
  },
  employees() {
    return Api.get("company/employee");
  },
  getEmployee(employeeId) {
    return Api.get(`company/employee/${employeeId}`);
  },
  postEmployee(form) {
    return Api.post("company/employee/", form);
  },
  updateEmployee(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/employee/${id}/`, form);
  },
  deleteEmployee(employeeId) {
    return Api.delete(`company/employee/${employeeId}/`);
  },

  employeeCV(employeeId) {
    let lang = store.getters["lang"];
    return Api.get(`company/employee/${employeeId}/cv/${lang}/`);
  },

  curriculums() {
    return Api.get("company/curriculum");
  },
  postCurriculum(form) {
    return Api.post("company/curriculum/", form);
  },
  updateCurriculum(form) {
    let id = JSON.parse(form.get("id"));
    return Api.put(`company/curriculum/${id}/`, form);
  },
  deleteCurriculum(curriculumId) {
    return Api.delete(`company/curriculum/${curriculumId}/`);
  },
};
