<template>
  <AppLayout>
    <v-container slot="main" fluid id="main-container" dense>
      <v-row justify="center" dense align="center">
        <v-col cols="12">
          <v-card height="800" width="100%" elevation="6">
            <v-toolbar dense color="marco">
              <v-toolbar-title
                ><h2 class="text-center white--text" v-if="clientDetail">
                  {{ clientDetail.legal_name }}
                </h2>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn>New Client</v-btn>
            </v-toolbar>
            <v-tabs color="marco" centered grow icons-and-text v-model="tab">
              <v-tab>General Information<v-icon>mdi-phone</v-icon></v-tab>
              <v-tab>Contacts<v-icon>mdi-account</v-icon></v-tab>
              <v-tab>Investment Strategy<v-icon>mdi-strategy</v-icon></v-tab>
              <v-tab>Recent News<v-icon>mdi-newspaper</v-icon></v-tab>
              <v-tab>Deals<v-icon>mdi-handshake</v-icon></v-tab>
              <v-tab>Assets<v-icon>mdi-currency-eur</v-icon></v-tab>
            </v-tabs>
            <v-divider></v-divider>

            <v-tabs-items v-model="tab">
              <v-tab-item :value="0">
                <ClientForm :clientId="clientId" />
              </v-tab-item>
              <v-tab-item :value="1">
                <ClientContacts :clientId="clientId" />
              </v-tab-item>

              <!-- TAB: CLIENT INVESTMENT STRATEGY -->
              <v-tab-item :value="2">
                <ClientInvestmentStrategies />
              </v-tab-item>
              <!-- TAB: CLIENT NEWS -->
              <v-tab-item :value="3">
                <news-timeline :articles="clientNews"></news-timeline>
              </v-tab-item>
              <!-- TAB: CLIENT DEALS -->
              <v-tab-item :value="4">
                <v-card flat>
                  <deals-timeline :deals="clientDeals"></deals-timeline>
                </v-card>
              </v-tab-item>
              <!-- TAB: CLIENT ASSETS -->
              <v-tab-item :value="5">
                <v-card flat>
                  <v-card-text>
                    <!-- <Combobox
                :items="assetSubtypes"
                item-text="asset_subtype"
                item-value="fk_asset_subtype"
              ></Combobox> -->
                    <!-- <v-chip v-for="item in clientAssets" :key="item.id">{{
                item.asset_subtype
              }}</v-chip> -->
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <AssetSubtypeSelectorDialog :clientAssetSubtypes="clientAssetSubtypes"></AssetSubtypeSelectorDialog> -->
                  </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/clients/Layout.vue";

import ClientForm from "@/components/clients/ClientForm.vue";
import ClientContacts from "@/components/clients/ClientContacts.vue";
import ClientInvestmentStrategies from "@/components/clients/ClientInvestmentStrategies.vue";
// import ContactCard from "@/components/clients/ContactCard.vue";

// // import AssetSubtypeSelectorDialog from "@/components/real-estate/AssetSubtypeSelectorDialog.vue";
// import ClientAssetSubtypesDialog from "@/components/clients/ClientAssetSubtypesDialog.vue";

import NewsTimeline from "@/components/clients/NewsTimeline.vue";
import DealsTimeline from "@/components/clients/DealsTimeline.vue";
// import InvestmentStrategyDialog from "@/components/clients/InvestmentStrategyDialog.vue";
// import InvestmentStrategyForm from "@/components/clients/InvestmentStrategyForm.vue";
import Client from "@/api/clients.js";
import News from "@/api/news.js";
import Credential from "@/api/credentials.js";
// import Asset from "@/api/assets.js";
import Deal from "@/api/deals.js";
import RealEstate from "@/api/real-estate.js";

export default {
  components: {
    AppLayout,
    ClientForm,
    ClientContacts,
    ClientInvestmentStrategies,
    "news-timeline": NewsTimeline,
    "deals-timeline": DealsTimeline,
  },

  data() {
    return {
      assetSubtypes: [],
      assetSubtypesMutated: false,
      clientAssets: null,
      clientContacts: null,
      clientCredentials: null,
      clientDeals: null,
      clientInvestmentStrategies: null,
      clientAssetSubtypes: null,
      clientNews: null,
      dialog: false,
      hasAssetSubtypes: false,
      headersStrategies: [
        { text: "Strategy", value: "strategy" },
        { text: "Min Ticket", value: "ticket_min" },
        { text: "Max Ticket", value: "ticket_max" },
        { text: "Units", value: "ticket_units" },
        { text: "Edit", value: "edit" },
      ],
      selectedAssetSubtypes: [],
      tab: 0,
    };
  },
  methods: {
    getAssetSubtypes() {
      RealEstate.assetSubtypes().then((response) => {
        this.assetSubtypes = response.data;
      });
    },
    getClientContacts() {
      Client.contacts(this.$route.params.id).then((response) => {
        this.clientContacts = response.data;
      });
    },
    getClientAssetSubtypes() {
      RealEstate.clientAssetSubtypes(this.$route.params.id).then((response) => {
        this.clientAssetSubtypes = response.data;
        if (this.clientAssetSubtypes[0]) {
          this.hasAssetSubtypes = true;
        } else {
          this.hasAssetSubtypes = false;
        }
        // this.clientAssetSubtypes.forEach((element) => {
        //   this.selectedAssetSubtypes.push(element.asset_subtype);
        // });
      });
    },
    getClientInvestmentStrategies() {
      Client.clientInvestmentStrategies(this.$route.params.id).then(
        (response) => {
          this.clientInvestmentStrategies = response.data;
        }
      );
    },
    getClientCredentials() {
      Credential.clientCredentials(this.$route.params.id).then((response) => {
        this.clientContacts = response.data;
      });
    },
    getClientDeals() {
      Deal.clientDeals(this.$route.params.id).then((response) => {
        this.clientDeals = response.data;
      });
    },

    getClientData() {
      this.$store.dispatch("clients/getClientData", this.$route.params.id);
    },
    getClientNews() {
      News.clientNews(this.$route.params.id).then((response) => {
        this.clientNews = response.data;
      });
    },
    editStrategy(item) {
      alert(JSON.stringify(item));
    },
  },
  computed: {
    clientId() {
      return parseInt(this.$route.params.id);
    },
    clientDetail() {
      return this.$store.getters["clients/clientDetail"];
    },

    // clientAssets() {
    //   return this.$store.state.clients.clientAssets;
    // },
    // clientDeals() {
    //   return this.$store.state.clients.clientDeals;
    // },
    // clientContacts() {
    //   return this.$store.state.clients.clientContacts;
    // },
  },
  //   watch: {
  //     $route: { handler: "getClientInfo", immediate: true },
  //   },
  created() {
    this.getAssetSubtypes();
    this.getClientData();
  },
};
</script>

<style scoped>
#main-container {
  /* background-image: url("../@/assets/skyscraper3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}
</style>
