import Api from "./Api";

export default {
  geolocateAddress(address) {
    return Api.get("geo/geolocate/", { params: { address: address } });
  },
  geolocateCoords(coordinates) {
    return Api.get("geo/geolocate/", {
      params: { lat: coordinates.lat, lng: coordinates.lng },
    });
  },
  getLocationStats(address) {
    return Api.get("geo/geolocate/data/", { params: { address: address } });
  },
  comunidades() {
    return Api.get("geo/ccaa/");
  },
  provincias() {
    return Api.get("geo/provincias/");
  },
  municipios(provincia) {
    return Api.get("geo/municipios/", { params: { prov: provincia } });
  },
  seccionesCensales(municipio) {
    return Api.get("geo/secciones-censales/", { params: { muni: municipio } });
  },
};
