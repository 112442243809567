<template>
  <BaselineLayout>
    <v-container slot="main">
      <v-row>
        <v-col>
          <h1>Red Eléctrica de España</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Combobox
            v-model="queryParams.lang"
            :items="languages"
            label="Language"
            :rules="rules"
          />
        </v-col>
        <v-col>
          <Combobox
            v-model="queryParams.category"
            :items="categories"
            label="Category"
            :rules="rules"
            @input="getWidgets()"
          />
        </v-col>
        <v-col>
          <Combobox
            v-model="queryParams.widget"
            :items="widgets"
            :rules="rules"
            label="Widget"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-dialog
            ref="dialogStartDate"
            v-model="modal"
            :return-value.sync="queryParams.start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="queryParams.start_date"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="queryParams.start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogStartDate.save(queryParams.start_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="3">
          <v-dialog
            ref="dialogEndDate"
            v-model="modalEndDate"
            :return-value.sync="queryParams.end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="queryParams.end_date"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="queryParams.end_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalEndDate = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogEndDate.save(queryParams.end_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <Combobox
            v-model="queryParams.time_trunc"
            :items="timeTrunc"
            :rules="rules"
            label="Time Interval"
          />
        </v-col>
        <v-col>
          <v-btn class="m-0" @click="getData">Search</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="results">
        <v-col>
          <GenerationChart :apiData="results" />
          <!-- <StackedChart /> -->
        </v-col>
      </v-row>
    </v-container>
  </BaselineLayout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import Combobox from "@/components/base/Combobox.vue";
import GenerationChart from "@/components/databank/red-electrica/charts/Generation.vue";
// import StackedChart from "@/components/charts/highcharts/StackedColumnChart2.vue";
let storeModule = "redElectrica";

export default {
  components: { BaselineLayout, Combobox, GenerationChart }, //StackedChart
  data() {
    return {
      modal: false,
      modalEndDate: false,
      queryParams: {
        lang: "en",
        category: "demanda",
        widget: null,
        start_date: new Date().toISOString().slice(0, 10),
        end_date: new Date().toISOString().slice(0, 10),
        time_trunc: null,
        geo_trunc: null,
        geo_limit: null,
        geo_ids: null,
      },
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    getWidgets() {
      this.$store.dispatch(
        `${storeModule}/getWidgets`,
        this.queryParams.category
      );
    },
    getData() {
      this.$store.dispatch(`${storeModule}/getData`, this.queryParams);
    },
  },
  mounted() {
    this.$store.dispatch(`${storeModule}/getLanguages`);
    this.$store.dispatch(`${storeModule}/getCategories`);
  },
  computed: {
    chart() {
      //   let series = [];
      let chartData = {};
      if (this.results) {
        chartData = {
          title: this.results.data.attributes.title,
          lastUpdate: this.results.data.attributes["last-update"],
          included: this.results.included.map((item) => {
            return {
              title: item.type,
              series: item.attributes.content.map(function(content) {
                return {
                  name: content.attributes.title,
                  color: content.attributes.color,
                  data: content.attributes.values.map((value) => {
                    return value.value;
                  }),
                };
              }),
            };
          }),
        };
      }
      return chartData;
    },
    languages() {
      return this.$store.getters[`${storeModule}/languages`];
    },
    categories() {
      return this.$store.getters[`${storeModule}/categories`];
    },
    timeTrunc() {
      return this.$store.getters[`${storeModule}/timeTrunc`];
    },
    widgets() {
      return this.$store.getters[`${storeModule}/widgets`];
    },
    results() {
      return this.$store.getters[`${storeModule}/results`];
    },
  },
};
</script>

<style></style>
