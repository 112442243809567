<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Client Firms
              </h1>
              <v-spacer></v-spacer>
              <v-btn small @click="setDialog(true)">New Client</v-btn>
              <v-btn small @click="refresh">Refresh</v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col>
                    <v-data-table
                      v-model="userSelections.client"
                      dense
                      show-select
                      :items="clients"
                      :item-key="'id'"
                      :headers="headers.clients"
                      :hide-default-footer="true"
                      :items-per-page="-1"
                    >
                      <template #item.view="{item}">
                        <v-btn
                          x-small
                          class="primary"
                          @click="goToDetail(item.id)"
                          >View</v-btn
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- <DialogNewClient
          :dialog="dialogClient"
          @changeDialog="dialog = $event"
          @saveClient="getClients"
        /> -->
        <DialogNewClient :dialog="dialogClient" />
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/clients/Layout.vue";
import DialogNewClient from "@/components/clients/DialogNewClient.vue";
import ClientAPI from "@/api/clients.js";
// DialogNewClient
export default {
  components: { AppLayout, DialogNewClient },
  data() {
    return {
      clients: [],
      userSelections: { client: [] },
      headers: {
        clients: [
          { text: "ID", value: "id" },
          { text: "Legal Name", value: "legal_name" },
          { text: "Tier", value: "tier" },
          { text: "VAT", value: "vat_number" },
          { text: "View", value: "view" },
        ],
      },
    };
  },
  methods: {
    setDialog(state) {
      let params = {
        dialog: "clientForm",
        view: state,
      };
      this.$store.dispatch("clients/setDialog", params);
    },
    getClients() {
      ClientAPI.getClients().then((response) => {
        this.clients = response.data;
      });
    },
    goToDetail(clientId) {
      this.$router.push({ name: "client-detail", params: { id: clientId } });
    },
    search() {
      let payload = {
        name: this.client,
        types: this.selectedClientTypes.map((item) => item.id),
      };
      console.log(payload);
      this.$store.dispatch("clients/searchClients", payload);
    },
    refresh() {
      this.getClients();
      this.$store.dispatch("clients/getClientTypes");
    },
  },
  computed: {
    dialogClient() {
      return this.$store.getters["clients/dialogClient"];
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
#main-container {
  background-image: url("../../assets/skyscraper3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
