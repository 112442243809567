import axios from "axios";
import IneAPI from "@/api/ine";

export default {
  namespaced: true,
  state: {
    ineDataset: null,
    ineDatasetData: [],
    ineDatasetSelections: [],

    ineOperation: null,
    ineOperationsList: [],
    ineOperationSelections: [],

    ineDatasetResults: {},
  },
  getters: {},
  mutations: {
    getDatasetData(state, datasetData) {
      state.ineDatasetData = datasetData;
    },
    getOperations(state, operations) {
      state.ineOperationsList = operations;
    },
    getOperationDatasets(state, datasets) {
      state.ineDatasetResults = datasets;
    },
    clearSelectedDatasets(state) {
      state.ineDatasetSelections = [];
    },
    searchDatasets(state, datasets) {
      state.ineDatasetResults = datasets;
    },
    setOperations(state, operations) {
      state.ineOperationSelections = operations;
    },
    // setDataset(state, dataset) {
    //   state.ineDataset = dataset;
    // },
  },
  actions: {
    getDatasetData(context, dataset) {
      let url = `http://localhost/api/ine/datasets/data/${dataset.cod}/`;
      axios
        .get(url)
        .then((response) => {
          context.commit("getDatasetData", response.data);
        })
        .catch(function(error) {
          context.commit(
            "errorSnackbar",
            { text: `INE Dataset Data: ${error}` },
            { root: true }
          );
        });
    },
    getOperationDatasets(context, operations) {
      let params = {
        ine_operations: JSON.stringify(operations),
      };

      IneAPI.datasets(params)
        .then((response) => {
          context.commit("getOperationDatasets", response.data);
        })

        .catch(function(error) {
          context.commit(
            "errorSnackbar",
            { text: `INE Datasets: ${error}` },
            { root: true }
          );
        });
    },
    getOperations(context) {
      IneAPI.operaciones()
        .then((response) => {
          context.commit("getOperations", response.data);
        })
        .catch(function(error) {
          context.commit(
            "errorSnackbar",
            { text: `INE Operations: ${error}` },
            { root: true }
          );
        });
    },
    setOperations(context, operations) {
      context.commit("setOperations", operations);
    },

    navigateDatasets: function(direction) {
      let vm = this;
      let url;
      if (direction == "previous") {
        url = vm.datasets.previous;
      } else if (direction == "next") {
        url = vm.datasets.next;
      }
      axios
        .get(url)
        .then(function(response) {
          return response.data;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function(data) {
          // always executed
          vm.datasets = data;
        });
    },
    clearSearch: function() {
      this.q = null;
      this.getDatasets();
    },
    // updateLocalStorage: function() {
    //   localStorage.setItem(
    //     INE_DATASETS_STORAGE_KEY,
    //     JSON.stringify(this.selectedDatasets)
    //   );
    // },
    // getLocalStorageData: function() {
    //   this.datasetSelections = JSON.parse(
    //     localStorage.getItem(INE_DATASETS_STORAGE_KEY) || "[]"
    //   );
    //   this.datasetSelectionsCodes = [];
    //   this.datasetSelections.forEach((element) => {
    //     this.datasetSelectionsCodes.push(element.cod);
    //   });
    //   // console.log("MODEL ITEMS", this.datasetSelectionsCodes.length)
    //   // console.log("LOCAL STORAGE ITEMS", this.datasetSelections.length)
    // },

    searchDatasets(context, payload) {
      let params = {};
      if (payload.searchStr) {
        params["q"] = payload.searchStr;
      }
      if (payload.operations) {
        params["ine_operations"] = JSON.stringify(payload.operations);
      }

      axios
        .get("http://localhost:8000/api/ine/datasets/", { params: params })
        .then((response) => {
          context.commit("searchDatasets", response.data);
        })
        .catch(function(error) {
          context.commit(
            "errorSnackbar",
            { text: `INE Dataset Search: ${error}` },
            { root: true }
          );
        });
    },
    getDatasetDetail(context, dataset) {
      context.dispatch("setDataset", dataset);
      context.dispatch("getDatasetData", dataset);
      context.dispatch("setDatasetChartData");
    },
    clearSelectedDatasets(context) {
      context.commit("clearSelectedDatasets");
      //   this.updateLocalStorage();
    },
    // setDataset(context, dataset) {
    //   context.commit("setDataset", dataset);
    // },
  },
};
