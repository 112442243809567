// import axios from "axios";
import store from "@/store/store";
import Api from "@/api/Api";

export default {
  loginUser(form) {
    return Api.post(`${process.env.VUE_APP_URL?process.env.VUE_APP_URL:''}api-token/`, form);
  },
  logoutUser() {
    store.dispatch(`${process.env.VUE_APP_URL?process.env.VUE_APP_URL:''}auth/logout`);
    Api.defaults.headers.Authorization = "";
  },
  refreshToken(refreshToken) {
    return Api.post(`${process.env.VUE_APP_URL?process.env.VUE_APP_URL:''}api-token-refresh/`, { refresh: refreshToken });
  },
};
