<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Contact Search</h2> </template>
    <v-container slot="main">
      <SideBar />
      <v-row>
        <v-col cols="12">
          <!-- <v-combobox
            multiple
            chips
            deletable-chips
            clearable
            label="Contact"
            :items="contacts"
            item-text="client_type"
            item-value="id"
            v-model="selectedContacts"
            :change="comboChange"
          >
          </v-combobox> -->
        </v-col>
      </v-row>
      <v-row>
        <!-- CONTACT SEARCH -->
        <v-text-field
          label="Contact Name"
          v-model="contact"
          append-icon="mdi-magnify"
          @click:append="search"
        >
        </v-text-field>
      </v-row>
      <v-row justify="center">
        <v-col>
          <!-- CLIENT RESULTS -->
          <v-data-table
            v-model="selections"
            dense
            show-select
            :items="contacts"
            :item-key="'id'"
            :headers="columns"
          >
            <template #item.view="{item}">
              <v-btn x-small class="primary" @click="goToDetail(item.id)"
                >View</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row> </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/contacts/SideBar.vue";

import Client from "@/api/clients.js";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
    // "clients-sidebar": ClientsSideBar,
  },
  data() {
    return {
      contact: "",
      contacts: [],
      selectedClientTypes: [],
      columns: [
        { text: "Last Name", value: "last_name" },
        { text: "First Name", value: "first_name" },
        { text: "Position", value: "position" },
        { text: "Email", value: "email" },
        { text: "View", value: "view" },
      ],
      selections: [],
    };
  },
  methods: {
    search() {
      Client.searchContacts(this.contact).then((response) => {
        this.contacts = response.data;
      });
    },
    getContacts() {
      Client.contacts().then((response) => {
        this.contacts = response.data;
      });
    },

    goToDetail(contactId) {
      this.$router.push({ name: "contact-detail", params: { id: contactId } });
    },

    // getClientInfo(clientId) {
    //   this.$store.dispatch("clients/getClientInfo", clientId);
    //   this.$store.dispatch("clients/setComponent", "client-detail");
    // },
  },
  computed: {},
  mounted() {
    this.getContacts();
  },
};
</script>

<style></style>
