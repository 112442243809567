import PortfolioAPI from "@/api/real-estate-portfolios";
import CatastroAPI from "@/api/catastro";

export default {
  namespaced: true,
  state: {
    isBusy: false,
    dialog: false,
    tab: 0,

    catastroDetail: null,
    myPortfolio: {
      id: null,
      datatape: null,
      info: null,
      analysis: null,
      assets: null,
      assetsFull: null,
      topAssets: null,
      strats: null,
    },

    portfolio: null,
    portfolioId: null,

    portfolioAnalysis: null,
    portfolioAssetDetail: {
      rawData: null,
    },
    portfolioAssets: null,
    portfolioAssetsPage: 1,
    portfolioAssetsTable: {
      isBusy: false,
      expanded: [],
      fields: [
        "asset_id",
        "asset_type",
        "address",
        "area",
        "appraisal_value",
        "cadastral_reference",
      ],
    },
    portfolioStrats: null,
    portfolioTable: {
      headers: [
        { text: "ID", value: "id", filterable: true },
        { text: "Project", value: "project_name", filterable: true },
        { text: "Filename", value: "filename", filterable: true },
        { text: "Options", value: "options", filterable: true },
        {
          text: "Assets Loaded",
          value: "completed_assets",
          filterable: true,
        },
        {
          text: "Catastro Loaded",
          value: "completed_catastro",
          filterable: true,
        },
        {
          text: "Geolocation",
          value: "completed_geolocation",
          filterable: true,
        },
        { text: "Comps", value: "completed_comps", filterable: true },
        {
          text: "Strat Report",
          value: "strat_report_file",
          filterable: true,
        },
        {
          text: "Comps Report",
          value: "comps_report_file",
          filterable: true,
        },
        {
          text: "Cadastre Report",
          value: "cadastre_report_file",
          filterable: true,
        },
        {
          text: "Errors Report",
          value: "errors_report_file",
          filterable: true,
        },
      ],
      search: "",
      selected: null,
      singleSelect: true,
    },
    portfolioTasks: [],
    portfolioTaskTable: {
      headers: [
        { text: "Task", value: "task", filterable: true },
        { text: "Name", value: "name", filterable: true },
        { text: "Status", value: "status", filterable: true },
        { text: "Results", value: "results", filterable: true },
        { text: "ID", value: "task_id", filterable: true },
        { text: "Created", value: "date_created", filterable: true },
        { text: "Completed", value: "date_done", filterable: true },
      ],
      search: "",
      selected: null,
      singleSelect: true,
    },
    portfolioErrorsTable: {
      headers: [
        {
          text: "Error Type",
          value: "error_type",
          filterable: true,
        },
        { text: "Count", value: "count", filterable: true },
        {
          text: "Percentage Value",
          value: "percentage_value",
          filterable: true,
        },
      ],
      fields: ["error_type", "count", "portfolio_value", "percentage_value"],
      isBusy: false,
    },
    strats: {
      params: { strat_field: null, value_field: null },
      results: null,
    },
    userPortfolios: null,
  }, //End State
  getters: {
    portfolio(state) {
      return state.myPortfolio.info;
    },
    catastroDetail(state) {
      return state.catastroDetail;
    },
    userPortfolios(state) {
      return state.userPortfolios;
    },
    portfolioAnalysis(state) {
      return state.myPortfolio.analysis;
    },
    portfolioAssets(state) {
      return state.myPortfolio.assets;
    },
    portfolioAssetsFull(state) {
      return state.myPortfolio.assetsFull;
    },
    portfolioAssetsTop(state) {
      return state.myPortfolio.topAssets;
    },
    portfolioDatatape(state) {
      return state.myPortfolio.datatape;
    },

    portfolioAssetRawData(state) {
      return state.portfolioAssetDetail.rawData;
    },

    portfolioAssetsTable(state) {
      return state.portfolioAssetsTable;
    },
    portfolioFieldStrat(state) {
      return state.strats.results;
    },
    portfolioTable(state) {
      return state.portfolioTable;
    },
    portfolioErrorsTable(state) {
      return state.portfolioErrorsTable;
    },
    portfolioStrats(state) {
      return state.myPortfolio.strats;
    },
    portfolioTaskTable(state) {
      return state.portfolioTaskTable;
    },
    portfolioTasks(state) {
      return state.myPortfolio.tasks;
    },
  }, //End Getters
  mutations: {
    GET_CATASTRO_DETAIL(state, catastroDetail) {
      state.catastroDetail = catastroDetail;
    },
    GET_USER_PORTFOLIOS(state, userPortfolios) {
      state.userPortfolios = userPortfolios;
    },
    GET_PORTFOLIO_ASSETS(state, assets) {
      state.myPortfolio.assets = assets;
    },
    GET_PORTFOLIO_ASSETS_FULL(state, assets) {
      state.myPortfolio.assetsFull = assets;
    },

    GET_PORTFOLIO_ASSETS_TOP(state, assets) {
      state.myPortfolio.topAssets = assets;
    },

    GET_PORTFOLIO_ASSET_RAW_DATA(state, datatapeData) {
      state.portfolioAssetDetail.rawData = datatapeData;
    },
    GET_PORTFOLIO_ANALYSIS(state, results) {
      state.myPortfolio.analysis = results;
    },
    GET_PORTFOLIO_DATATAPE(state, results) {
      state.myPortfolio.datatape = results;
    },
    GET_PORTFOLIO_FIELD_STRAT(state, results) {
      state.strats.results = results;
    },
    GET_PORTFOLIO_INFO(state, userPortfolios) {
      state.myPortfolio.info = userPortfolios;
    },
    GET_PORTFOLIO_STRATS(state, strats) {
      state.myPortfolio.strats = strats;
    },
    GET_PORTFOLIO_TASKS(state, tasks) {
      state.myPortfolio.tasks = tasks;
    },
    SELECT_PORTFOLIO(state, portfolioId) {
      state.myPortfolio.id = portfolioId;
    },
    TOGGLE_IS_BUSY_ASSETS_TABLE(state) {
      state.portfolioAssetsTable.isBusy = !state.portfolioAssetsTable.isBusy;
    },
  }, //End Mutations
  actions: {
    runPortfolioTask(context, task) {
      // Tasks: create-assets, create-comps-report,create-strats-report, load-catastro-references, create-catastro-report, get-comps, geolocate
      PortfolioAPI.runPortfolioTask(context.state.myPortfolio.id, task)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePortfolio(context, item) {
      console.log(item.id);
      context;
      //   PortfolioAPI.deletePortfolio(context.state.myPortfolio.id)
      //     .then(() => {
      //       context.dispatch("getUserPortfolios");
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    },
    geolocatePortfolioAssets(context) {
      context;
    },
    getCatastroDetail(context, referenciaCatastral) {
      context;
      CatastroAPI.getCatastroDetail(referenciaCatastral)
        .then((response) => {
          console.log(response.data);
          context.commit("GET_CATASTRO_DETAIL", response.data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolio(context) {
      PortfolioAPI.getPortfolio(context.state.myPortfolio.id)
        .then((response) => {
          context.commit("GET_PORTFOLIO_INFO", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioDatatape(context, params) {
      PortfolioAPI.getPortfolioDatatape(context.state.myPortfolio.id, params)
        .then((response) => {
          context.commit("GET_PORTFOLIO_DATATAPE", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioAnalysis(context) {
      PortfolioAPI.getPortfolioAnalyisis(context.state.myPortfolio.id)
        .then((response) => {
          context.commit("GET_PORTFOLIO_ANALYSIS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioAssetRawData(context, assetId) {
      PortfolioAPI.getPortfolioAssetRawData(
        context.state.myPortfolio.id,
        assetId
      )
        .then((response) => {
          context.commit("GET_PORTFOLIO_ASSET_RAW_DATA", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioAssets(context, pageNum) {
      context.commit("TOGGLE_IS_BUSY_ASSETS_TABLE");
      PortfolioAPI.getPortfolioAssets(context.state.myPortfolio.id, pageNum)
        .then((response) => {
          context.commit("GET_PORTFOLIO_ASSETS", response.data);
          context.commit("TOGGLE_IS_BUSY_ASSETS_TABLE");
        })
        .catch((error) => {
          context.commit("TOGGLE_IS_BUSY_ASSETS_TABLE");
          console.log(error);
        });
    },
    getPortfolioAssetsFull(context) {
      PortfolioAPI.getPortfolioAssetsFull(context.state.myPortfolio.id)
        .then((response) => {
          context.commit("GET_PORTFOLIO_ASSETS_FULL", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioAssetsTop(context, n) {
      PortfolioAPI.getPortfolioAssetsTop(context.state.myPortfolio.id, n)
        .then((response) => {
          context.commit("GET_PORTFOLIO_ASSETS_TOP", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioFieldStrat(context, params) {
      PortfolioAPI.getPortfolioFieldStrat(context.state.myPortfolio.id, params)
        .then((response) => {
          //   console.log(response.data);
          context.commit("GET_PORTFOLIO_FIELD_STRAT", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioStrats(context) {
      PortfolioAPI.getPortfolioStrats(context.state.myPortfolio.id)
        .then((response) => {
          context.commit("GET_PORTFOLIO_STRATS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPortfolioTasks(context) {
      PortfolioAPI.getPortfolioTasks(context.state.myPortfolio.id)
        .then((response) => {
          context.commit("GET_PORTFOLIO_TASKS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserPortfolios(context) {
      PortfolioAPI.getPortfolios()
        .then((response) => {
          context.commit("GET_USER_PORTFOLIOS", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      context;
    },
    async refreshPortfolioInformation(context) {
      await context.dispatch("getPortfolio");
      context.dispatch("getPortfolioAssets");
      context.dispatch("getPortfolioAssetsTop", 10);
      context.dispatch("getPortfolioAnalysis");
      context.dispatch("getPortfolioStrats");
      context.dispatch("getPortfolioTasks");
      context.dispatch("getPortfolioDatatape", {
        params: { q: null, page: 1 },
      });
      context.dispatch("getUserPortfolios");
      context.dispatch("getPortfolioAssetsFull");
    },
    searchPortfolioAssets(context, q) {
      context.commit("TOGGLE_IS_BUSY_ASSETS_TABLE");
      PortfolioAPI.searchPortfolioAssets(context.state.myPortfolio.id, q)
        .then((response) => {
          context.commit("GET_PORTFOLIO_ASSETS", response.data);
          context.commit("TOGGLE_IS_BUSY_ASSETS_TABLE");
        })
        .catch((error) => {
          context.commit("TOGGLE_IS_BUSY_ASSETS_TABLE");
          console.log(error);
        });
    },
    async selectPortfolio(context, portfolioId) {
      await context.commit("SELECT_PORTFOLIO", portfolioId);
      await context.dispatch("refreshPortfolioInformation");
    },
  }, //End Actions
};
