<template>
  <v-card width="80%" elevation="24">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="8">
          <v-form v-model="form">
            <Combobox
              label="Sector"
              :items="sectors"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-source-branch"
              v-model="deal.sector"
              :readonly="readonly"
            >
            </Combobox>

            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="deal.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="deal.date"
                  label="Deal Date"
                  hint="MM/DD/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :readonly="readonly"
                ></v-text-field>
              </template>
              <v-date-picker v-model="deal.date">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(deal.date)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>

            <v-switch
              prepend-icon="mdi-volume-off"
              label="Confidential"
              v-model="deal.confidential"
            >
            </v-switch>

            <Combobox
              label="Deal Type"
              :items="dealTypes"
              prepend-icon="mdi-handshake"
              v-model="deal.deal_type"
              :readonly="readonly"
            >
            </Combobox>

            <Combobox
              label="Participants"
              :items="clients"
              item-value="id"
              item-text="legal_name"
              prepend-icon="mdi-account-voice"
              v-model="deal.participants"
              multiple
              :readonly="readonly"
            >
            </Combobox>

            <TextArea
              label="Description"
              v-model="deal.description"
              prepend-icon="mdi-text-subject"
              :readonly="readonly"
            ></TextArea>
            <Combobox
              label="Price Units"
              :items="priceUnits"
              prepend-icon="mdi-ruler-square"
              v-model="deal.price_units"
              :readonly="readonly"
            >
            </Combobox>

            <TextField
              label="Price (€m)"
              prepend-icon="mdi-currency-eur"
              v-model.number="deal.price_millions"
              :readonly="readonly"
            ></TextField>

            <Btn v-if="readonly" color="warning" block @click="readonly = false"
              >Edit</Btn
            >
            <Btn v-if="!readonly" color="success" block @click="post"
              >Submit</Btn
            >
          </v-form>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
        <v-col align="center">
          {{ deal }}
        </v-col>
      </v-row> -->
    </v-container>
  </v-card>
</template>

<script>
import TextField from "@/components/base/TextField.vue";
import TextArea from "@/components/base/TextArea.vue";
import Btn from "@/components/base/Btn.vue";
import Combobox from "@/components/base/Combobox.vue";
// import DatePicker from "@/components/base/DatePicker.vue";

import Client from "@/api/clients";
import Deal from "@/api/deals";
import Sector from "@/api/sectors";

export default {
  props: {
    dealId: {
      type: Number,
      required: false,
    },
  },
  components: {
    Btn,
    Combobox,
    // DatePicker,
    TextArea,
    TextField,
  },
  data() {
    return {
      modal: false,
      form: null,
      languages: [
        { text: "Español", value: "es" },
        { text: "English", value: "en" },
      ],
      deal: {
        id: null,
        sector: null,
        date: null,
        deal_type: null,
        participants: null,
        description: null,
        price_units: null,
        price_millions: null,
        confidential: null,
      },
      clients: null,
      dealTypes: [
        { value: "asset", text: "Asset Deal" },
        { value: "equity", text: "Share Deal" },
        { value: "debt", text: "Financing" },
      ],
      priceUnits: [
        { text: "Thousands", value: "thousands" },
        { text: "Millions", value: "millions" },
      ],
      readonly: false,
      sectors: [],
    };
  },
  methods: {
    getDeal() {
      Deal.get(this.dealId).then((response) => {
        this.deal = response.data;
      });
    },
    getClients() {
      Client.all().then((response) => {
        this.clients = response.data;
      });
    },
    getSectors() {
      Sector.all().then((response) => {
        this.sectors = response.data;
      });
    },
    post() {
      let form = new FormData();
      Object.keys(this.deal).forEach((key) => {
        console.log(key);
        if (this.deal[key] instanceof Array) {
          this.deal[key].forEach((item) => {
            form.append(key, item);
          });
        } else {
          form.append(key, this.deal[key]);
        }
      });
      //
      Deal.post(form)
        .then(() => {
          this.$store.dispatch("successSnackbar", {
            text: "Deal Created Successfuly!",
          });
          this.readonly = true;
        })
        .catch((error) => {
          this.$store.dispatch("errorSnackbar", {
            text: `${JSON.stringify(error.response.data)}`,
          });
        });
    },
  },
  beforeMount() {
    this.getSectors();
    this.getClients();
    if (this.dealId) {
      this.getDeal();
      this.readonly = true;
    }
  },
};
</script>

<style></style>
