import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "@/store/store.js";
import router from "@/router/router";

import "@/scss/variables.scss";

// import HighchartsVue from 'highcharts-vue'

// Vue.use(HighchartsVue)
// Highcharts.setOptions({
// 	// options here
//   })

// import * as VueGoogleMaps from "vue2-google-maps";
// Vue.use(VueGoogleMaps, {
// 	load: {
// 	  key: "AIzaSyAjOMBZd3zLahf_epuzVf_MRp-ht05UNK0",
// 	  libraries: "visualization", // This is required if you use the Autocomplete plugin
// 	  // OR: libraries: 'places,drawing'
// 	  // OR: libraries: 'places,drawing,visualization'
// 	  // (as you require)

// 	  //// If you want to set the version, you can do so:
// 	  // v: '3.26',
// 	},

// 	//// If you intend to programmatically custom event listener code
// 	//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
// 	//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
// 	//// you might need to turn this on.
// 	// autobindAllEvents: false,

// 	//// If you want to manually install components, e.g.
// 	//// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
// 	//// Vue.component('GmapMarker', GmapMarker)
// 	//// then disable the following:
// 	// installComponents: true,
//   });

import titleMixin from "@/mixins/titleMixin";
Vue.mixin(titleMixin);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store: store,
  router: router,
  render: (h) => h(App),
}).$mount("#app");
