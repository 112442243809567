<template>
  <AppLayout>
    <!-- SLOT: NAVBAR MENU -->
    <NavbarMenu slot="navbar-menu" />
    <!-- SLOT: MENU DRAWER -->
    <MenuDrawer slot="menu-drawer" />
    <!-- SLOT: TOOL DRAWER -->
    <!-- <ToolDrawer slot="tool-drawer" /> -->

    <slot name="main" slot="main"></slot>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";
import NavbarMenu from "@/components/databank/catastro/NavbarMenu.vue";
import MenuDrawer from "@/components/databank/catastro/MenuDrawer.vue";
// import ToolDrawer from "@/components/databank/catastro/ToolDrawer.vue";

export default {
  components: {
    AppLayout,
    NavbarMenu,
    MenuDrawer,
    // ToolDrawer,
  },
};
</script>

<style></style>
