<template>
  <v-data-table
    v-if="results"
    dense
    :headers="headers"
    :items="results"
    :loading="isBusy"
    loading-text="Loading... Please wait"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
    <template v-slot:item.summary="{ item }">
      <v-chip small @click="gotoCalleDetail(item)">View</v-chip>
    </template>
    <template v-slot:item.getData="{ item }">
      <v-chip small @click="scrapeCalleData(item)">Scrape</v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["title", "tableData", "fields", "isBusy"],
  data() {
    return {};
  },
  methods: {
    gotoCalleDetail(item) {
      this.$router.push({
        name: "databank-catastro-street-detail",
        params: { viaId: item.id },
      });
    },
    scrapeCalleData(item) {
      this.$store.dispatch("catastro/scrapeCalleData", item.id);
    },
  },
  computed: {
    headers() {
      let headers = [];
      for (var [key, value] of Object.entries(this.tableData.results[0])) {
        value;
        if (this.fields) {
          if (this.fields.includes(key)) {
            headers.push({
              text: key.replace("_", " "),
              value: key,
              filterable: true,
            });
          }
        } else {
          headers.push({
            text: key.replace("_", " "),
            value: key,
            filterable: true,
          });
        }
      }
      if (headers !== []) {
        headers.push({ text: "Summary", value: "summary" });
        headers.push({ text: "Data", value: "getData" });
      }
      return headers;
    },
    count() {
      return this.tableData.count;
    },
    next() {
      return this.tableData.next;
    },
    previous() {
      return this.tableData.previous;
    },

    results() {
      if (this.tableData) {
        return this.tableData.results;
      }
      return null;
    },
  },
};
</script>

<style>
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
} */
</style>
