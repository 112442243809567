export default {
  namespaced: true,
  state: {
    appDrawer: false,
    menuDrawer: false,
    toolDrawer: false,
    dialogMenu: false,
    dialogFullscreen: false,
  },
  getters: {
    appDrawer(state) {
      return state.appDrawer;
    },
    menuDrawer(state) {
      return state.menuDrawer;
    },
    toolDrawer(state) {
      return state.toolDrawer;
    },
    dialogMenu(state) {
      return state.dialogMenu;
    },
    dialogFullscreen(state) {
      return state.dialogFullscreen;
    },
  },
  mutations: {
    TOGGLE_APP_DRAWER(state) {
      state.appDrawer = !state.appDrawer;
    },
    TOGGLE_MENU_DRAWER(state) {
      state.menuDrawer = !state.menuDrawer;
    },
    TOGGLE_TOOL_DRAWER(state) {
      state.toolDrawer = !state.toolDrawer;
    },
    TOGGLE_DIALOG_MENU(state) {
      state.dialogMenu = !state.dialogMenu;
    },
    TOGGLE_DIALOG_FULLSCREEN(state) {
      state.dialogFullscreen = !state.dialogFullscreen;
    },
    CLOSE_DRAWERS(state) {
      state.appDrawer = false;
      state.menuDrawer = false;
      state.toolDrawer = false;
    },
  },
  actions: {
    toggleAppDrawer(context) {
      context.commit("TOGGLE_APP_DRAWER");
    },
    toggleMenuDrawer(context) {
      context.commit("TOGGLE_MENU_DRAWER");
    },
    toggleToolDrawer(context) {
      context.commit("TOGGLE_TOOL_DRAWER");
    },
    toggleDialogMenu(context) {
      context.commit("TOGGLE_DIALOG_MENU");
    },
    toggleDialogFullscreen(context) {
      context.commit("TOGGLE_DIALOG_FULLSCREEN");
    },
    closeDrawers(context) {
      context.commit("CLOSE_DRAWERS");
    },
  },
};
