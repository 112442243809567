<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Real Estate
              </h1>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-for="(icon, index) in icons" :key="index" cols="4">
                    <v-card elevation="16" @click="$router.push(icon.url)">
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-icon color="marco" size="120">{{
                          icon.icon
                        }}</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text>
                        <h1 class="marco--text">
                          {{ icon.title }}
                        </h1></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/real-estate/Layout.vue";

export default {
  components: { AppLayout },
  data() {
    return {
      icons: [
        {
          title: "Single Asset",
          icon: "mdi-home-map-marker",
          url: { name: "real-estate-asset-search" },
        },
        {
          title: "Portfolios",
          icon: "mdi-home-group",
          url: { name: "real-estate-portfolio" },
        },
        {
          title: "Maps",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-map-marker-radius",
          url: { name: "real-estate-portfolio-dashboark" },
        },
        {
          title: "Yield Map",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-percent",
          url: { name: "real-estate-portfolio-dashboark" },
        },
      ],
    };
  },
};
</script>

<style scoped>
#main-container {
  background-image: url("../../../assets/skyscraper4.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
