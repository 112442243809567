<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Advisory Workbench
              </h1>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-for="(card, index) in cards" :key="index" cols="4">
                    <v-card elevation="16" @click="$router.push(card.url)">
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-img
                          width="200"
                          :aspect-ratio="16 / 9"
                          :src="card.img"
                        ></v-img>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text>
                        <h1 class="marco--text">
                          {{ card.title }}
                        </h1></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";

export default {
  components: {
    AppLayout,
  },
  data() {
    return {
      cards: [
        {
          title: "GeoSpacial",
          img: require("@/assets/logo-geo.jpeg"),
          text: "This is my Geo text",
          url: { name: "geo" },
        },
        {
          title: "Engagement Letter Wizard",
          img: require("@/assets/logo-m&a.png"),
          text: "This is my Real Estate text",
          url: { name: "engagement-letters" },
        },
        {
          title: "Real Estate",
          img: require("@/assets/real-estate-background.jpg"),
          text: "This is my Real Estate text",
          url: { name: "real-estate-home" },
        },
        {
          title: "Hospitality & Leisure",
          img: require("@/assets/logo-hotels.jpg"),
          text: "This is my Hospitality & Leisure text",
          url: "./hospitality",
        },
        {
          title: "M&A Process Wizard",
          img: require("@/assets/logo-m&a2.jpg"),
          text: "This is my Mergers & Acquisitions text",
          url: "./workbench",
        },
        {
          title: "NPL & REO Portfolios",
          img: require("@/assets/NPL.png"),
          text: "This is my Portfolios text",
          url: "./workbench",
        },
        {
          title: "Land Valuation",
          img: require("@/assets/land-valuation.jpeg"),
          text: "This is my Land Valuation text",
          url: "./workbench",
        },
        {
          title: "Reporting",
          img: require("@/assets/logo-reporting.png"),
          text: "This is my Reporting text",
          url: "./reporting",
        },
      ],
    };
  },
};
</script>

<style scoped>
#main-container {
  background-image: url("../assets/background-workbench.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
