<template>
  <v-app>
    <baseline-layout>
      <v-container slot="main">
        <v-row justify="center">
          <v-col cols="12">
            <v-card elevation="16" class="pa-5">
              <v-card-title>Hospitality Search</v-card-title>
              <hr />
              <v-card-text>
                <v-container>
                  <v-row justify="center">
                    <v-radio-group v-model="geoInputs.searchType" row>
                      <v-radio label="Address" value="address"></v-radio>
                      <v-radio label="Region" value="region"></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-row v-if="geoInputs.searchType === 'address'">
                    <TextField
                      label="Search Address"
                      append-icon="mdi-magnify"
                      v-model="geoInputs.address"
                      @click:append="search"
                    />
                  </v-row>
                  <v-row v-if="geoInputs.searchType === 'region'">
                    <v-col>
                      <Combobox
                        label="Com. Autonoma"
                        :items="comunidades"
                        item-text="name"
                        item-value="code"
                        v-model="geoInputs.ccaa"
                        @input="onChangeCCAA"
                      />
                    </v-col>
                    <v-col>
                      <Combobox
                        label="Provincia"
                        :items="provincias"
                        item-text="name"
                        item-value="code"
                        v-model="geoInputs.provincia"
                        @input="onChangeProv"
                      />
                    </v-col>
                    <v-col>
                      <Combobox
                        label="Municipio"
                        :items="municipios"
                        item-text="name"
                        item-value="code"
                        v-model="geoInputs.municipio"
                        :loading="loading.municipios"
                        @input="onChangeMuni"
                      />
                    </v-col>
                    <v-col>
                      <Combobox
                        label="Seccion Censal"
                        :items="secciones"
                        item-text="code"
                        item-value="code"
                        v-model="geoInputs.seccion"
                        :loading="loading.secciones"
                        @input="onChangeSeccion"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <FabButton icon="mdi-magnify" color="success" />
                  </v-row>
                  <v-row>
                    {{ geoInputs }}
                  </v-row>
                  <v-row>
                    {{ address }}
                  </v-row>
                  <v-row> </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="16" class="pa-5">
              <v-card-title
                >Search Results
                <v-spacer></v-spacer>
                <div v-if="hotelData.hotels">
                  {{ hotelData.count.municipio }}
                </div>
              </v-card-title>
              <hr />
              <v-card-text>
                <v-tabs centered fill v-model="tab">
                  <v-tabs-slider color="marco"></v-tabs-slider>
                  <v-tab>Summary</v-tab>
                  <v-tab>Hotels</v-tab>
                  <v-tab>Players</v-tab>
                  <v-tab>Transactions</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item :value="0">
                    <h3>
                      # Hotels in Municipio:
                      <span v-if="hotelData.count">
                        {{ hotelData.count.municipio }}
                      </span>
                    </h3>
                    <h3>
                      # Hotels in Seccion Censal:
                      <span v-if="hotelData.count">
                        {{ hotelData.count.seccion_censal }}
                      </span>
                    </h3>

                    <h3>
                      # Owners:
                      <span v-if="playersData.owners">
                        {{ playersData.owners.count }}
                      </span>
                    </h3>
                    <h3>
                      # Operators:
                      <span v-if="playersData.operators">
                        {{ playersData.operators.count }}
                      </span>
                    </h3>
                    <h3>
                      # Managers:
                      <span v-if="playersData.managers">
                        {{ playersData.managers.count }}
                      </span>
                    </h3>
                  </v-tab-item>
                  <v-tab-item :value="1">
                    <!-- {{ hotelData }} -->

                    <v-card>
                      <v-data-table
                        v-if="hotelData.hotels"
                        :items="hotelData.hotels.municipio"
                        :headers="headers"
                        item-text="name"
                        item-value="id"
                      >
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="2"
                    ><v-card>
                      <v-container>
                        <v-row justify="center">
                          <v-radio-group v-model="playerTable" row>
                            <v-radio label="Owners" value="owner"></v-radio>
                            <v-radio label="Managers" value="manager"></v-radio>
                            <v-radio
                              label="Operators"
                              value="operator"
                            ></v-radio>
                          </v-radio-group>
                        </v-row>
                        <v-row justify="center">
                          <v-data-table
                            v-if="playerTable === 'owner' && playersData.owners"
                            :items="playersData.owners.results"
                            :headers="playerTableHeaders"
                            item-text="name"
                            item-value="id"
                          >
                          </v-data-table>
                          <v-data-table
                            v-if="
                              playerTable === 'manager' && playersData.managers
                            "
                            :items="playersData.managers.results"
                            :headers="playerTableHeaders"
                            item-text="name"
                            item-value="id"
                          >
                          </v-data-table>
                          <v-data-table
                            v-if="
                              playerTable === 'operator' &&
                                playersData.operators
                            "
                            :items="playersData.operators.results"
                            :headers="playerTableHeaders"
                            item-text="name"
                            item-value="id"
                          >
                          </v-data-table>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="3"
                    ><v-card> Hotels List</v-card></v-tab-item
                  >
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </baseline-layout>
  </v-app>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import Combobox from "@/components/base/Combobox.vue";
import TextField from "@/components/base/TextField.vue";
import FabButton from "@/components/base/buttons/FabButton.vue";

import GEO from "@/api/geo.js";
import Hotel from "@/api/real-estate-hospitality.js";

export default {
  components: {
    BaselineLayout,
    Combobox,
    TextField,
    FabButton,
  },
  data() {
    return {
      address: null,
      searchType: "address",
      comunidades: [],
      provincias: [],
      municipios: [],
      secciones: [],
      geoInputs: {
        searchType: "address",
        ccaa: null,
        provincia: null,
        municipio: null,
        seccion: null,
        gelevel: this.geoLevel,
      },
      geoResults: {},
      hotelData: [],
      loading: {
        ccaa: false,
        provincias: false,
        municipios: false,
        secciones: false,
      },
      tab: 0,
      headers: [
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "# Rooms", value: "num_rooms" },
      ],
      playerTable: "owner",
      playerTableHeaders: [
        { text: "Name", value: "short_name" },
        { text: "VAT #", value: "vat_number" },
      ],
      playersData: {},
    };
  },
  methods: {
    addressSearch() {
      GEO.searchAddress(this.geoInputs.address).then((response) => {
        this.address = response.data;
        this.getHotels();
        this.getPlayers();
      });
    },
    getHotels() {
      let seccionCensal;
      if (this.searchType === "address") {
        seccionCensal = this.address.seccion;
      }
      Hotel.seccionCensalAssets(seccionCensal).then((response) => {
        this.hotelData = response.data;
      });
    },
    getPlayers() {
      let seccionCensal;
      if (this.searchType === "address") {
        seccionCensal = this.address.seccion;
      }
      Hotel.seccionCensalPlayers(seccionCensal).then((response) => {
        this.playersData = response.data;
      });
    },
    geoSearch() {
      GEO.searchAddress().then((response) => {
        this.address = response.data;
      });
    },
    getCCAA() {
      GEO.comunidades().then((response) => {
        this.comunidades = response.data;
      });
    },
    getProvincias() {
      GEO.provincias().then((response) => {
        this.provincias = response.data;
      });
    },
    getMunicipios() {
      GEO.municipios(this.geoInputs.provincia).then((response) => {
        this.municipios = response.data;
        this.loading.municipios = false;
      });
    },
    getSecciones() {
      GEO.seccionesCensales(this.geoInputs.municipio).then((response) => {
        this.secciones = response.data;
        this.loading.secciones = false;
      });
    },
    onChangeCCAA() {
      this.checkGeoLevel();
    },
    onChangeProv() {
      this.loading.municipios = true;
      this.getMunicipios();
      this.checkGeoLevel();
    },
    onChangeMuni() {
      this.loading.secciones = true;
      this.getSecciones();
      this.checkGeoLevel();
    },
    onChangeSeccion() {
      this.checkGeoLevel();
    },
    checkGeoLevel() {
      if (this.geoInputs.seccion) {
        this.geoInputs.level = "secc";
      } else if (this.geoInputs.municipio) {
        this.geoInputs.level = "muni";
      } else if (this.geoInputs.provincia) {
        this.geoInputs.level = "prov";
      } else if (this.geoInputs.ccaa) {
        this.geoInputs.level = "ccaa";
      } else {
        this.geoInputs.level = null;
      }
    },
    search() {
      if (this.searchType === "address") {
        this.addressSearch();
      }
    },
  },
  computed: {},
  mounted() {
    this.getCCAA();
    this.getProvincias();
  },
};
</script>

<style></style>
