export default {
  methods: {
    arrayToChart: function(array, labelField, valueField, title) {
      let labels = [];
      let chartData = [];
      let backgroundColor = "rgba(0, 126, 168, 0.5)";
      let borderColor = "#007ea7";

      array.forEach((item) => {
        labels.push(item[labelField]);
        chartData.push(item[valueField]);
      });

      chartData = {
        labels: labels,
        datasets: [
          {
            label: title,
            borderColor: borderColor,
            backgroundColor: backgroundColor,
            data: chartData,
            borderWidth: 1,
            fill: "origin",
          },
        ],
      };
      return chartData;
    },
  },
};
