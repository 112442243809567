<template>
  <AppLayout>
    <DialogLoading slot="isBusy" />

    <!-- MAP -->
    <v-container
      fluid
      slot="main"
      v-if="pageView === 'search'"
      id="main-container"
    >
      <v-tabs centered icons-and-text v-model="tab">
        <v-tab>
          Search Criteria
          <v-icon>mdi-magnify</v-icon>
        </v-tab>
        <v-tab
          >Results
          <v-icon>mdi-home-analytics</v-icon>
        </v-tab>
        <v-tab
          >Map
          <v-icon>mdi-google-maps</v-icon>
        </v-tab>
        <v-tab
          >Report
          <v-icon>mdi-file-powerpoint</v-icon>
        </v-tab>
      </v-tabs>
      <!-- <SideBar /> -->

      <!-- SEARCH PANE -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container>
            <v-form width="80vw">
              <v-row dense justify="center">
                <v-col align="center" cols="6">
                  <!-- <SearchCriteria /> -->
                  <v-text-field
                    dense
                    outlined
                    label="Address"
                    v-model="searchParams.address"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col cols="2">
                  <v-combobox
                    dense
                    outlined
                    height="16"
                    v-model="searchParams.tx"
                    :items="optionsTransaction"
                    item-text="text"
                    item-value="value"
                    label="Transaction"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-combobox
                    dense
                    outlined
                    v-model="searchParams.asset_type"
                    :items="optionsAssetType"
                    item-value="id"
                    item-text="asset_type_ENG"
                    label="Asset Type"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-combobox
                    dense
                    outlined
                    v-model="searchParams.num_comps"
                    :items="optionsNumComps"
                    label="# Comps"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col cols="2">
                  <v-combobox
                    dense
                    outlined
                    v-model="searchParams.bedrooms"
                    :items="optionsBedrooms"
                    label="Bedrooms"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-combobox
                    dense
                    outlined
                    v-model="searchParams.area_min"
                    :items="optionsArea"
                    label="Area Min"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-combobox
                    dense
                    outlined
                    v-model="searchParams.area_max"
                    :items="optionsArea"
                    label="Area Max"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col align="center" cols="2">
                  <v-checkbox
                    v-model="searchParams.vpo"
                    label="Social housing"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="searchParams.date_min"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="searchParams.date_min"
                        label="From"
                        prepend-icon="mdi-calendar"
                        :readonly="false"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchParams.date_min"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(searchParams.date_min)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="searchParams.date_max"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="searchParams.date_max"
                        label="To"
                        prepend-icon="mdi-calendar"
                        :readonly="false"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchParams.date_max"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(searchParams.date_max)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col align-self="center" cols="2">
                  <v-btn small @click="clear">
                    <v-icon small>mdi-delete</v-icon> Clear</v-btn
                  >
                </v-col>
                <v-col align-self="center" cols="2">
                  <v-btn small @click="search">
                    <v-icon small>mdi-magnify</v-icon> Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-row dense justify="center">
            <v-col>
              <SearchResults />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row dense justify="center">
            <v-col>
              <GoogleMap />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row dense justify="center">
            <v-col>
              <SearchResultsReport />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/real-estate/Layout.vue";
// import SideBar from "@/components/real-estate/SearchSideBar.vue";
import DialogLoading from "@/components/base/BaseDialogIsLoading.vue";

// import SearchCriteria from "@/components/real-estate/SearchCriteria.vue";
import SearchResults from "@/components/real-estate/SearchResults.vue";
import SearchResultsReport from "@/components/real-estate/SearchResultsReport.vue";
import GoogleMap from "@/components/maps/google/GoogleMap.vue";
// import GoogleMap from "../components/base/BaseGoogleMap.vue";

export default {
  components: {
    AppLayout,
    // SideBar,
    // SearchCriteria,
    SearchResults,
    DialogLoading,
    GoogleMap,
    SearchResultsReport,
  },
  data() {
    return {
      tab: null,
      searchParams: {
        address: null,
        tx: null,
        asset_type: null,
        num_comps: null,
        date_min: null,
        date_max: null,
        bedrooms: null,
        area_min: null,
        area_max: null,
        vpo: false,
      },
      menu: false,
      menu2: false,
      menu_date_min: false,
      menu_date_max: false,
      view: "search",
      navItems: [
        { name: "Address Search", href: { name: "real-estate-search" } },
        { name: "Geo Summary", href: { name: "real-estate-geo" } },
        {
          name: "Datatape Enrichment",
          href: { name: "real-estate-enrichment" },
        },
        { name: "Testing", href: { name: "test" } },
      ],
    };
  },
  methods: {
    async clear() {
      await this.$store.dispatch("realEstate/clearSearchResults");
    },
    async search() {
      let searchParams = {
        tx: this.searchParams.tx.value,
        asset_type: this.searchParams.asset_type.id,
        num_comps: this.searchParams.num_comps
          ? this.searchParams.num_comps.value
          : 50,
        address: this.searchParams.address,
        bedrooms: this.searchParams.bedrooms
          ? this.searchParams.bedrooms.value
          : null,
        area_max: this.searchParams.area_max
          ? this.searchParams.area_max.value
          : null,
        area_min: this.searchParams.area_min
          ? this.searchParams.area_min.value
          : null,
        id_vpo: this.searchParams.vpo,
        date_max: this.searchParams.date_max
          ? this.searchParams.date_max
          : null,
        date_min: this.searchParams.date_min
          ? this.searchParams.date_min
          : null,
      };
      await this.$store.dispatch("realEstate/setSearchParams", searchParams);
      this.tab = 1;
      await this.$store.dispatch("realEstate/search");
    },
  },
  mounted: function() {
    this.clear();
    this.$store.dispatch("realEstate/getAssetTypes");
  },
  computed: {
    pageView() {
      return this.$store.getters["realEstate/pageView"];
    },
    optionsTransaction() {
      return this.$store.state.realEstate.optionsTransaction;
    },
    optionsAssetType() {
      return this.$store.state.realEstate.optionsAssetType;
    },
    optionsNumComps() {
      return this.$store.state.realEstate.optionsNumComps;
    },
    optionsBedrooms() {
      return this.$store.state.realEstate.optionsBedrooms;
    },
    optionsArea() {
      return this.$store.state.realEstate.optionsArea;
    },
  },
};
</script>

<style scoped>
#main-container {
  /* background-image: url("../../@/assets/background-RE-search.jpeg"); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: cover; */
}
</style>
