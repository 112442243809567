<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Contact Manager
              </h1>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col> </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/real-estate/Layout.vue";

export default {
  components: { AppLayout },
  data() {
    return {};
  },
};
</script>

<style scoped>
#main-container {
  background-image: url("../../assets/skyscraper3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
