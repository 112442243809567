<template>
  <AppLayout>
    <template slot="pageTitle"> <h2>News Dashboard</h2> </template>
    <v-container slot="main">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title
          ><h2 class="marco--text">News Room</h2></v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-btn small @click="createArticle">New Article</v-btn>
      </v-toolbar>
      <v-row dense justify="center">
        <v-col cols="9" align="center">
          <v-text-field
            dense
            outlined
            v-model="userInputs.search"
            label="Search News"
            append-icon="mdi-magnify"
            append-outer-icon="mdi-close"
            @click:append="searchNews"
            @click:append-outer="clearSearch"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(article, index) in articles.results"
          :key="index"
          cols="4"
        >
          <v-card dense height="30vh" elevation="6">
            <v-toolbar dense flat>
              <v-toolbar-title small>
                {{ article.date }}
              </v-toolbar-title>
              <v-spacer> </v-spacer>
              <v-btn text small color="marco">View</v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text height="40"
              ><h2 class="marco--text">{{ article.title }}</h2>
              <br />{{ article.description }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/news/Layout.vue";

import NewsAPI from "@/api/news";

export default {
  components: {
    AppLayout,
  },
  data() {
    return {
      articles: [],
      userInputs: { search: null },
    };
  },
  methods: {
    clearSearch() {
      this.userInputs.search = null;
      this.getArticles();
    },
    createArticle() {
      alert("Create News!");
    },

    getArticles() {
      NewsAPI.getArticles().then((response) => {
        this.articles = response.data;
      });
    },
    searchNews() {
      let params = {
        search: this.userInputs.search,
      };
      NewsAPI.searchArticles(params).then((response) => {
        this.articles = response.data;
      });
    },
  },
  mounted() {
    this.getArticles();
  },
};
</script>

<style></style>
