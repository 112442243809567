<template>
  <v-tab-item :value="tabNum">
    <v-container>
      <v-row>
        <v-col> </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels popout multiple>
            <v-expansion-panel>
              <v-expansion-panel-header> Maps</v-expansion-panel-header>
              <v-expansion-panel-content align="center">
                <GoogleMapComps
                  :center="googleMap.center"
                  :askComps="
                    portfolioAsset ? portfolioAsset[0].ask_comps_buy : []
                  "
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Pricing Charts</v-expansion-panel-header
              >

              <v-expansion-panel-content>
                <v-container>
                  <v-row v-if="portfolioAsset">
                    <v-col
                      v-if="portfolioAsset[0].ask_comps_buy.length > 0"
                      cols="6"
                    >
                      <PricingChart
                        hcTitle="Asking Price"
                        :hcSubtitle="
                          `${portfolioAsset[0].eur_sqm_ask_buy} ${
                            portfolioAsset[0].is_parking ? '€/unit' : '€/m2'
                          }`
                        "
                        :comps="portfolioAsset[0].ask_comps_buy"
                        valueField="eur_sqm"
                      />
                    </v-col>
                    <v-col
                      v-if="portfolioAsset[0].closing_comps.length > 0"
                      cols="6"
                    >
                      <PricingChart
                        hcTitle="Closing Price"
                        :hcSubtitle="
                          `${portfolioAsset[0].eur_sqm_closing} ${
                            portfolioAsset[0].is_parking ? '€/unit' : '€/m2'
                          }`
                        "
                        :comps="portfolioAsset[0].closing_comps"
                        valueField="valor_m"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Portfolio Assets</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-data-table
                  dense
                  calculate-widths
                  v-model="portfolioAsset"
                  show-select
                  single-select
                  :headers="portfolioAssetsTable.headers"
                  :items="
                    portfolioAssets.results ? portfolioAssets.results : []
                  "
                  item-key="asset_id"
                  class="elevation-1"
                  :options="portfolioAssetsTable.options"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-text-field
                        v-model="portfolioAssetsTable.search"
                        label="Search"
                        append-icon="mdi-magnify"
                        @click:append="searchPortfolioAssets"
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-pagination
                        v-model="portfolioAssetsPage"
                        :length="portfolioAssetsPages"
                        @input="getPortfolioAssets(portfolioAssetsPage)"
                        total-visible="8"
                      ></v-pagination>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.raw_data="{ item }">
                    <v-icon @click="getPortfolioAssetRawData(item)"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <template v-slot:item.cadastral_reference_exists="{ item }">
                    <v-icon
                      color="green"
                      v-if="
                        item.fk_referencia_catastral || item.fk_referencia_finca
                      "
                      >mdi-check</v-icon
                    >
                    <v-icon
                      color="red"
                      v-if="
                        !item.fk_referencia_catastral &&
                          !item.fk_referencia_finca
                      "
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-slot:item.cadastral_detail="{ item }">
                    <v-icon
                      v-if="
                        item.fk_referencia_catastral || item.fk_referencia_finca
                      "
                      @click="getCatastroDetail(item)"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <template v-slot:item.is_geolocated="{ item }">
                    <v-icon color="green" v-if="item.geo_lng && item.geo_lat"
                      >mdi-check</v-icon
                    >
                    <v-icon color="red" v-if="!(item.geo_lng && item.geo_lat)"
                      >mdi-close</v-icon
                    >
                  </template>
                  <template v-slot:item.units="{ item }">
                    <div v-if="item.is_parking">€ / Unit</div>
                    <div v-if="!item.is_parking">€ / m2</div>
                  </template>
                  <template v-slot:item.view_ask_comps_buy="{ item }">
                    <v-chip
                      small
                      color="green"
                      v-if="item.ask_comps_buy.length > 0"
                      @click="openDialogAskBuy(item)"
                      >{{ item.num_comps_ask_buy }}</v-chip
                    >
                  </template>
                  <template v-slot:item.view_ask_comps_rent="{ item }">
                    <v-chip
                      small
                      color="yellow"
                      v-if="item.ask_comps_rent.length > 0"
                      @click="openDialogAskRent(item)"
                      >{{ item.num_comps_ask_rent }}</v-chip
                    >
                  </template>
                  <template v-slot:item.view_closing_comps="{ item }">
                    <v-chip
                      small
                      color="red"
                      v-if="item.closing_comps.length > 0"
                      @click="openDialogClosing(item)"
                    >
                      {{ item.num_comps_closing }}</v-chip
                    >
                  </template>
                  <template v-slot:item.view_pricing_charts="{ item }">
                    <v-icon @click="openDialogClosing(item)">
                      mdi-line-graph</v-icon
                    >
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>
    </v-container>
    <!-- DIALOG ASK COMPS BUY -->
    <v-dialog v-model="dialogAskBuy">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-if="portfolioAsset"
              dense
              show-select
              v-model="portfolioAssetSelections"
              :headers="portfolioAskCompsBuyTable.headers"
              :items="portfolioAsset[0].ask_comps_buy"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="marco white--text">
                  <v-toolbar-title>Asking Comps: Buy</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >{{ portfolioAsset[0].asset_id }} -
                    {{ portfolioAsset[0].asset_type }} -
                    {{ portfolioAsset[0].address }}</v-toolbar-title
                  >
                </v-toolbar>
              </template>
              <template #item.des_urlanuncio="{item}">
                <v-btn
                  x-small
                  class="primary"
                  :href="item.des_urlanuncio"
                  target="_blank"
                  >View</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <!-- DIALOG ASK COMPS RENT -->
    <v-dialog v-model="dialogAskRent">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-if="portfolioAsset"
              dense
              show-select
              v-model="portfolioAssetSelections"
              :headers="portfolioAskCompsRentTable.headers"
              :items="portfolioAsset[0].ask_comps_rent"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="marco white--text">
                  <v-toolbar-title>Asking Comps: Rent</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >{{ portfolioAsset[0].asset_id }} -
                    {{ portfolioAsset[0].asset_type }} -
                    {{ portfolioAsset[0].address }}</v-toolbar-title
                  >
                </v-toolbar>
              </template>
              <template #item.des_urlanuncio="{item}">
                <v-btn
                  x-small
                  class="primary"
                  :href="item.des_urlanuncio"
                  target="_blank"
                  >View</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <!-- DIALOG CLOSING COMPS -->
    <v-dialog v-model="dialogClosing">
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              v-if="portfolioAsset"
              dense
              show-select
              v-model="portfolioAssetSelections"
              :headers="portfolioClosingCompsTable.headers"
              :items="portfolioAsset[0].closing_comps"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat class="marco white--text">
                  <v-toolbar-title>Closing Comps</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title
                    >{{ portfolioAsset[0].asset_id }} -
                    {{ portfolioAsset[0].asset_type }} -
                    {{ portfolioAsset[0].address }}</v-toolbar-title
                  >
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <!-- DIALOG CATASTRO DETAIL -->
    <v-dialog v-model="dialogCatastro">
      <CatastroDetailCard :catastroData="catastroDetail" />
    </v-dialog>
  </v-tab-item>
</template>

<script>
// import PaginatedTable from "@/components/base/tables/PaginatedTable.vue";

import PricingChart from "@/components/workbench/real-estate/portfolio-dashboard/charts/PricingChart.vue";
import GoogleMapComps from "@/components/maps/google/CompsMap.vue";
import CatastroDetailCard from "@/components/catastro/CardCatastroDetail.vue";
let storeModule = "realEstatePortfolioDashboard";

export default {
  props: ["tabNum"],
  components: { PricingChart, GoogleMapComps, CatastroDetailCard },
  data() {
    return {
      dialogAskBuy: false,
      dialogAskRent: false,
      dialogClosing: false,
      dialogCatastro: false,

      portfolioAssetsPage: 1,
      expanded: [],
      singleExpand: true,
      portfolioAsset: null,
      portfolioAssetSelections: null,
      portfolioAssetsTable: {
        headers: [
          { text: "", value: "data-table-expand" },
          {
            text: "Data",
            align: "center",
            sortable: false,
            value: "raw_data",
          },
          {
            text: "Asset ID",
            align: "start",
            sortable: false,
            value: "asset_id",
          },
          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "asset_type",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "address",
          },
          {
            text: "Area",
            align: "end",
            sortable: true,
            value: "area",
          },
          {
            text: "Appraisal Value",
            align: "end",
            sortable: true,
            value: "appraisal_value",
          },
          {
            text: "Cadastral Reference",
            align: "start",
            sortable: true,
            value: "cadastral_reference",
          },
          {
            text: "Cadastral Info",
            align: "center",
            sortable: true,
            value: "cadastral_detail",
          },
          {
            text: "Valid Cadastral Reference",
            align: "center",
            sortable: true,
            value: "cadastral_reference_exists",
          },
          {
            text: "Geolocation",
            align: "center",
            sortable: true,
            value: "is_geolocated",
          },
          {
            text: "Units",
            align: "center",
            sortable: false,
            value: "units",
          },
          {
            text: "Value",
            align: "center",
            sortable: true,
            value: "eur_sqm",
          },
          {
            text: "Ask",
            align: "center",
            sortable: true,
            value: "eur_sqm_ask_buy",
          },
          {
            text: "Close",
            align: "center",
            sortable: true,
            value: "eur_sqm_closing",
          },

          {
            text: "Ask Comps: Buy",
            align: "start",
            sortable: true,
            value: "view_ask_comps_buy",
          },
          {
            text: "Ask Comps: Rent",
            align: "start",
            sortable: true,
            value: "view_ask_comps_rent",
          },
          {
            text: "Closing Comps",
            align: "start",
            sortable: true,
            value: "view_closing_comps",
          },
          {
            text: "Pricing Charts",
            align: "start",
            sortable: true,
            value: "view_pricing_charts",
          },
        ],
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        search: null,
      },
      portfolioAskCompsBuyTable: {
        headers: [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha_fuente",
          },

          {
            text: "Source",
            align: "start",
            sortable: false,
            value: "fuente",
          },
          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "des_tipologia",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "des_direccion",
          },
          {
            text: "Area",
            align: "start",
            sortable: true,
            value: "num_supconstruida",
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "num_precioventa",
          },
          {
            text: "€ / m2",
            align: "start",
            sortable: true,
            value: "eur_sqm",
          },
          {
            text: "URL",
            align: "start",
            sortable: true,
            value: "des_urlanuncio",
          },
        ],
      },
      portfolioAskCompsRentTable: {
        headers: [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha_fuente",
          },

          {
            text: "Source",
            align: "start",
            sortable: false,
            value: "fuente",
          },
          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "des_tipologia",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "des_direccion",
          },
          {
            text: "Area",
            align: "start",
            sortable: true,
            value: "num_supconstruida",
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "num_precioalquiler",
          },
          {
            text: "€ / m2",
            align: "start",
            sortable: true,
            value: "eur_sqm",
          },
          {
            text: "URL",
            align: "start",
            sortable: true,
            value: "des_urlanuncio",
          },
        ],
      },
      portfolioClosingCompsTable: {
        headers: [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "f_escritura_corta",
          },

          {
            text: "Asset Type",
            align: "start",
            sortable: true,
            value: "tipo",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "calle_api",
          },
          {
            text: "Area",
            align: "start",
            sortable: true,
            value: "superficie",
          },
          {
            text: "Price",
            align: "start",
            sortable: true,
            value: "valor",
          },
          {
            text: "€ / m2",
            align: "start",
            sortable: true,
            value: "valor_m",
          },
          {
            text: "Social Housing",
            align: "start",
            sortable: true,
            value: "id_vpo",
          },
        ],
      },
    };
  },
  methods: {
    openDialogAskBuy(item) {
      console.log(item);
      this.portfolioAsset = [item];
      this.dialogAskBuy = true;
    },
    openDialogAskRent(item) {
      console.log(item);
      this.portfolioAsset = [item];
      this.dialogAskRent = true;
    },
    openDialogClosing(item) {
      this.portfolioAsset = [item];
      this.dialogClosing = true;
    },
    getPortfolioAssets(pageNum) {
      this.$store.dispatch(`${storeModule}/getPortfolioAssets`, pageNum);
    },
    async getPortfolioAssetRawData(item) {
      await this.$store.dispatch(
        `${storeModule}/getPortfolioAssetRawData`,
        item.asset_id
      );
      this.dialogRawData = true;
    },

    async getCatastroDetail(item) {
      await this.$store.dispatch(
        `${storeModule}/getCatastroDetail`,
        item.cadastral_reference
      );
      this.dialogCatastro = true;
    },
    searchPortfolioAssets() {
      this.$store.dispatch(
        `${storeModule}/searchPortfolioAssets`,
        this.portfolioAssetsTable.search
      );
    },
  },
  computed: {
    googleMap() {
      let googleMap = {};
      if (this.portfolioAsset) {
        googleMap = {
          center: {
            lat: this.portfolioAsset[0].geo_lat,
            lng: this.portfolioAsset[0].geo_lng,
          },
        };
      } else {
        googleMap = {
          center: { lat: 40.416775, lng: -3.70379 },
        };
      }
      return googleMap;
    },
    catastroDetail() {
      return this.$store.getters[`${storeModule}/catastroDetail`];
    },
    portfolio() {
      return this.$store.getters[`${storeModule}/portfolio`];
    },
    portfolioAssets() {
      return this.$store.getters[`${storeModule}/portfolioAssets`];
    },
    portfolioAssetRawData() {
      return this.$store.getters[`${storeModule}/portfolioAssetRawData`];
    },
    // portfolioAssetsTable() {
    //   return this.$store.getters[`${storeModule}/portfolioAssetsTable`];
    // },
    portfolioAssetsPages() {
      if (this.portfolioAssets) {
        return Math.ceil(this.portfolioAssets.count / 50);
      } else {
        return 0;
      }
    },
  },
};
</script>

<style></style>
