<template>
  <v-app>
    <baseline-layout>
      <v-container slot="main">
        <v-row justify="center">
          <v-col align="center">
            <h1>Engagement Letters</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                  Engagement Information
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  Scope of Services
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">
                  Team
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 4" step="4">
                  Calendar
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 5" step="5">
                  Fees
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 5" step="6">
                  Terms of Business
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12" :height="cardHeight">
                    <v-card-text>
                      <v-radio-group v-model="engagementLetter.language" row>
                        <v-radio label="English" value="ENG" selected></v-radio>
                        <v-radio label="Español" value="ESP"></v-radio>
                      </v-radio-group>
                      <Combobox
                        outlined
                        label="Client"
                        v-model="engagementLetter.client"
                      ></Combobox>
                      <Combobox
                        outlined
                        label="Contact"
                        v-model="engagementLetter.addressee"
                      ></Combobox>
                      <v-textarea
                        outlined
                        label="Context"
                        v-model="engagementLetter.context"
                      ></v-textarea>
                    </v-card-text>
                  </v-card>

                  <v-btn color="primary" @click="e1 = e1 + 1">
                    Continue
                  </v-btn>

                  <!-- <v-btn text @click="e1 = e1 - 1">
                    Cancel
                  </v-btn> -->
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="mb-12" :height="cardHeight">
                    <v-card-text>
                      <v-radio-group v-model="engagementLetter.phases" row>
                        <v-radio
                          label="Single"
                          value="single"
                          selected
                        ></v-radio>
                        <v-radio label="Multiple" value="multiple"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                    <v-card-text>
                      <h2 class="title mb-2">
                        Select Services
                      </h2>

                      <v-chip-group v-model="includedServices" column multiple>
                        <v-chip
                          v-for="service in services"
                          :key="service.value"
                          filter
                          outlined
                        >
                          {{ service.text }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                    <v-card-text>{{ includedServices }}</v-card-text>
                    <v-card-text>{{ servicesText }}</v-card-text>

                    <!-- <Combobox
                      outlined
                      label="Engagement Type"
                      v-model="engagementLetter.engagement_type"
                    ></Combobox>
                    <Combobox
                      outlined
                      label="Included Services"
                      v-model="engagementLetter.included_services"
                    ></Combobox>
                    <Combobox
                      outlined
                      label="Services Not Included"
                      v-model="engagementLetter.not_included_services"
                    ></Combobox> -->
                  </v-card>

                  <v-btn color="primary" @click="e1 = e1 + 1">
                    Continue
                  </v-btn>

                  <v-btn text @click="e1 = e1 - 1">
                    Cancel
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-card class="mb-12" :height="cardHeight">
                    <Combobox
                      outlined
                      label="Engagement Leaders"
                      v-model="engagementLetter.engagement_leaders"
                    ></Combobox>
                    <Combobox
                      outlined
                      label="Engagement Managers"
                      v-model="engagementLetter.engagement_managers"
                    ></Combobox>
                  </v-card>

                  <v-btn color="primary" @click="e1 = e1 + 1">
                    Continue
                  </v-btn>

                  <v-btn text @click="e1 = e1 - 1">
                    Cancel
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-card class="mb-12" :height="cardHeight"></v-card>

                  <v-btn color="primary" @click="e1 = e1 + 1">
                    Continue
                  </v-btn>

                  <v-btn text @click="e1 = e1 - 1">
                    Cancel
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <v-card class="mb-12" :height="cardHeight"></v-card>

                  <v-btn color="primary" @click="e1 = e1 + 1">
                    Continue
                  </v-btn>

                  <v-btn text @click="e1 = e1 - 1">
                    Cancel
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="6">
                  <v-card class="mb-12" :height="cardHeight"></v-card>

                  <v-btn color="primary" @click="e1 = 1">
                    Continue
                  </v-btn>

                  <v-btn text @click="e1 = e1 - 1">
                    Cancel
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
        <v-row>
          {{ engagementLetter }}
        </v-row>
      </v-container>
    </baseline-layout>
  </v-app>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import Combobox from "@/components/base/Combobox.vue";

export default {
  components: {
    BaselineLayout,
    Combobox,
  },
  data() {
    return {
      e1: 1,
      cardHeight: "400px",
      engagementLetter: {
        language: "ENG",
      },
      amenities: [1, 4],
      services: [
        { text: "Service 1", value: 1 },
        { text: "Service 2", value: 2 },
        { text: "Service 3", value: 3 },
        { text: "Service 4", value: 4 },
      ],
      includedServices: [],
    };
  },
  computed: {
    servicesText() {
      let servicesText = [];
      this.includedServices.forEach((element) => {
        servicesText.push(this.services[element].text);
      });
      return servicesText;
    },
  },
};
</script>

<style></style>
