import AuthAPI from "@/api/auth";

export default {
  namespaced: true,
  state: {
    // token: localStorage.getItem("token") || null,
    accessToken: null,
    refreshToken: null,
    currentUser: null,
    userId: null,
    isLoggedIn: false,
    loginError: false,
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    accessToken(state) {
      return state.accessToken;
    },
    refreshToken(state) {
      return state.refreshToken;
    },
    currentUser(state) {
      return state.currentUser;
    },
  },
  mutations: {
    CLEAR_TOKENS(state) {
      state.accessToken = null;
      state.refreshToken = null;
    },
    SET_TOKENS(state, tokens) {
      state.accessToken = tokens.access ? tokens.access : state.accessToken;
      state.refreshToken = tokens.refresh ? tokens.refresh : state.refreshToken;
    },

    SET_IS_LOGGED_IN(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    SET_LOGIN_ERROR(state, loginError) {
      state.loginError = loginError;
    },
    retrieveTokens(state, tokens) {
      state.accessToken = tokens.access;
      state.refreshToken = tokens.refresh;
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user.username;
      state.userId = user.userId;
    },
    LOG_OUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoggedIn = false;
    },
  },
  actions: {
    clearTokens(context) {
      context.commit("CLEAR_TOKENS");
    },
    setCurrentUser(context, user) {
      context.commit("SET_CURRENT_USER", user);
    },
    logout(context) {
      context.commit("LOG_OUT");
    },
    async login(context, user) {
      await AuthAPI.loginUser(user)
        .then((response) => {
          context.dispatch("setLoginError", false);
          context.commit("SET_CURRENT_USER", {
            username: response.data.username,
            userId: response.data.id,
          });
          context.dispatch("setTokens", response.data);
          context.commit("SET_IS_LOGGED_IN", true);
        })
        .catch(() => {
          context.dispatch("setLoginError", true);
        });
    },
    refreshToken(context) {
      AuthAPI.refreshToken(context.state.refreshToken).then((response) => {
        context.dispatch("setTokens", response.data);
      });
    },
    setTokens(context, tokens) {
      context.commit("SET_TOKENS", tokens);
    },
    setLoginError(context, loginError) {
      context.commit("SET_LOGIN_ERROR", loginError);
    },
  },
};

// retrieveToken(context, credentials) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post("http://localhost/api-token-auth/", {
//         username: credentials.username,
//         password: credentials.password,
//       })
//       .then(function(response) {
//         const token = response.data.token;
//         localStorage.setItem("token", token);
//         context.commit("retrieveToken", token);
//         context.commit("setCurrentUser", credentials.username);
//         resolve(response);
//       })
//       .catch(function(error) {
//         reject(error);
//       })
//   });
// },
