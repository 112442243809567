<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Contact Dashboard</h2> </template>

    <v-container slot="main" class="fill-height">
      <SideBar />
      <v-row justify="center" align="center">
        <v-card>
          <v-card-title>
            {{ dashboardData.count }} Contacts in Database</v-card-title
          >
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text color="success" :to="{ name: 'contact-create' }"
              >Add</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn text color="marco" :to="{ name: 'contact-search' }"
              >Search</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- <v-col cols="5">
          <bar-chart-card
            v-if="clientTypesChart"
            title="Clients by Type"
            :data="clientTypesChart"
            source="MGX Advisors"
          ></bar-chart-card>
        </v-col>
        <v-col cols="5">
          <bar-chart-card
            v-if="clientTierChart"
            title="Clients by Tier"
            :data="clientTierChart"
            source="MGX Advisors"
          ></bar-chart-card>
        </v-col> -->
      </v-row>

      <!-- 
      {{ dashboardData.client_types }} -->
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/contacts/SideBar.vue";
// import BarChartCard from "@/components/base/BaseBarChartCard.vue";
import arrayToChart from "@/mixins/arrayToChart.js";
import Client from "@/api/clients.js";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
    // "clients-sidebar": ClientsSideBar,
    // "bar-chart-card": BarChartCard,
  },
  mixins: [arrayToChart],
  data() {
    return {
      dashboardData: null,
      clientTypesChart: null,
      clientTierChart: null,
    };
  },
  methods: {
    getDashboardSummary() {
      let vm = this;
      Client.contactsDashboard()
        .then((response) => {
          vm.dashboardData = response.data;
          //   vm.clientTypesChart = vm.arrayToChart(
          //     vm.dashboardData.client_types,
          //     "client_type__client_type",
          //     "id__count",
          //     "Clients by Type:"
          //   );
          //   vm.clientTierChart = vm.arrayToChart(
          //     vm.dashboardData.tier,
          //     "tier",
          //     "id__count",
          //     "Clients by Tier:"
          //   );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDashboardSummary();
  },
};
</script>

<style></style>
