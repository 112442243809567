<template>
  <v-footer class="app-footer" color="#065a82" app>
    <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
    <v-spacer></v-spacer>
    <span class="white--text">MGX Advisors, S.L.</span>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
