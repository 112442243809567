<template>
  <v-app>
    <baseline-layout>
      <v-container slot="main">
        <v-row justify="center">
          <v-col align="center">
            <h1>Quality of Earnings Reports</h1>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-stepper v-model="stepper.step">
              <v-stepper-header>
                <v-stepper-step :complete="stepper.step > 1" step="1">
                  Select Report
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="stepper.step > 2" step="2">
                  Report Items
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="stepper.step > 3" step="3">
                  User Inputs
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="stepper.step > 4" step="4">
                  Preview & Export
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="ma-5">
                    <v-container>
                      <v-row justify="center">
                        <v-radio-group v-model="reportRadio" row>
                          <v-radio label="New" value="new"></v-radio>
                          <v-radio label="Existing" value="existing"></v-radio>
                        </v-radio-group>
                      </v-row>
                      <v-row>
                        <v-container v-if="reportRadio === 'new'">
                          <TextField
                            label="Title"
                            v-model="form.name"
                            :required="true"
                          />
                        </v-container>
                      </v-row>
                      <v-row>
                        <v-container v-if="reportRadio === 'existing'">
                          <Combobox
                            label="Search Report"
                            v-model="reportId"
                            :readonly="false"
                            :items="qoeReports"
                            item-text="name"
                            item-value="id"
                            :multiple="false"
                          />
                        </v-container>
                      </v-row>
                    </v-container>
                  </v-card>

                  <v-btn color="primary" @click="handleStep1">
                    Next
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card class="ma-5">
                    <v-card-title>Report Item Manager</v-card-title>

                    <v-container>
                      <v-row justify="center">
                        <Button color="marco white--text" text="Create Group" />
                        <Button
                          color="marco white--text"
                          text="Create Subgroup"
                        />
                        <Button
                          color="marco white--text"
                          text="Create Report Item"
                        />
                      </v-row>
                      <Combobox
                        label="Group"
                        :items="qoeGroups"
                        item-text="name"
                        item-value="id"
                        :chips="false"
                        icon="mdi-new-box"
                        v-model="reportItemForm.groupId"
                      />
                      <Combobox
                        label="Subgroup"
                        :items="qoeSubgroups"
                        item-text="name"
                        item-value="id"
                        :chips="false"
                        v-model="reportItemForm.subgroupId"
                      />
                      <Combobox
                        label="Report Item"
                        :items="qoeItems"
                        item-text="name"
                        item-value="id"
                        :chips="false"
                        v-model="reportItemForm.itemId"
                      />
                      <TextField label="order" v-model="reportItemForm.order" />
                      <v-row>
                        <v-spacer></v-spacer>
                        <Button
                          color="success"
                          text="Add Report Item"
                          @click="postReportItem"
                        />
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card class="ma-5">
                    <v-card-title>Report Treeview</v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-treeview
                            v-if="reportTreeview"
                            dense
                            hoverable
                            :items="reportTreeview"
                            open-all
                            return-object
                            v-model="treeviewSelections"
                          ></v-treeview>
                        </v-col>
                      </v-row>

                      <!-- <v-treeview :items="treeviewItems"></v-treeview> -->
                    </v-container>
                  </v-card>

                  <v-card class="ma-5" v-if="reportItems">
                    <v-card-title>Report Items</v-card-title>
                    <v-data-table
                      v-if="reportItems"
                      :items="reportItems"
                      :headers="headers"
                    >
                    </v-data-table>
                  </v-card>

                  <v-btn color="primary" @click="handleStep2">
                    Next
                  </v-btn>

                  <v-btn text @click="stepper.step = stepper.step - 1">
                    Back
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-card class="ma-5">
                    <v-card-title
                      >Complete Report Text User Inputs</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <TextField
                          v-for="item in reportTextVariables"
                          :label="item"
                          :key="item"
                          v-model="reportTextVariableValues[item]"
                        />
                      </v-container>
                    </v-card-text>
                    <v-card-text>{{ reportTextVariableValues }}</v-card-text>
                  </v-card>

                  <v-btn color="primary" @click="handleStep3">
                    Next
                  </v-btn>

                  <v-btn text @click="stepper.step = stepper.step - 1">
                    Back
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-card class="ma-5">
                    <v-card-title>Report Text Preview</v-card-title>
                    <v-card-text v-if="reportText">
                      <div
                        v-for="(group, groupIndex) in Object.keys(reportText)"
                        :key="groupIndex"
                      >
                        <h2 class="marco--text">{{ group }}</h2>
                        <v-divider></v-divider>
                        <div
                          v-for="(subgroup, subgroupIndex) in reportText[group]"
                          :key="subgroupIndex"
                        >
                          <div
                            v-for="(subgroup_key, index) in Object.keys(
                              subgroup
                            )"
                            :key="index"
                          >
                            <h3 class="mt-4 blue--text">
                              {{ subgroup_key }}
                            </h3>
                            <div
                              v-for="(item, itemIndex) in subgroup[
                                subgroup_key
                              ]"
                              :key="itemIndex"
                            >
                              <div
                                v-for="(itemName, itemNameIndex) in Object.keys(
                                  item
                                )"
                                :key="itemNameIndex"
                              >
                                <p>
                                  <strong>{{ itemName }}</strong>
                                  {{ item[itemName] }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- {{ reportText }} -->
                    </v-card-text>
                  </v-card>
                  <v-btn text @click="stepper.step = stepper.step - 1">
                    Back
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="downloadReport">
                    Export
                  </v-btn>

                  <!-- <v-btn
                    color="primary"
                    @click="stepper.step = stepper.step + 1"
                  >
                    Next
                  </v-btn> -->
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-container>
    </baseline-layout>
  </v-app>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import Button from "@/components/base/buttons/Button.vue";
import Combobox from "@/components/base/Combobox.vue";
import TextField from "@/components/base/TextField.vue";

import Company from "@/api/company";
import QoE from "@/api/qoe";

import axios from "axios";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export default {
  components: {
    BaselineLayout,
    Button,
    Combobox,
    TextField,
  },
  data() {
    return {
      form: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Order", value: "order" },
        { text: "Group", value: "group_name" },
        { text: "Subgroup", value: "subgroup" },
        { text: "Item", value: "item_name" },
      ],
      linesOfService: [],
      reportId: null,
      reportItems: null,
      reportItemForm: {},
      reportRadio: "new",
      reportTypes: [],
      reportText: null,
      reportTextVariables: [],
      reportTextVariableValues: {},
      reportTreeview: [],
      qoeGroups: [],
      qoeReports: [],
      qoeSubgroups: [],
      qoeItems: [],

      stepper: {
        step: 1,
        steps: [{ number: 1, name: "Step 1" }],
      },

      treeviewItems: [
        {
          id: 1,
          name: "Quality of Earnings",
          children: [
            { id: 2, name: "Body" },
            { id: 3, name: "Disclaimers" },
            { id: 4, name: "Signature" },
          ],
        },
        {
          id: 5,
          name: "Quality of Debt",
          children: [
            {
              id: 6,
              name: "vuetify :",
              children: [
                {
                  id: 7,
                  name: "src :",
                  children: [
                    { id: 8, name: "index : ts" },
                    { id: 9, name: "bootstrap : ts" },
                  ],
                },
              ],
            },
            {
              id: 10,
              name: "material2 :",
              children: [
                {
                  id: 11,
                  name: "src :",
                  children: [
                    { id: 12, name: "v-btn : ts" },
                    { id: 13, name: "v-card : ts" },
                    { id: 14, name: "v-window : ts" },
                  ],
                },
              ],
            },
          ],
        },
      ],
      treeviewSelections: null,
    };
  },

  methods: {
    deleteReportItems() {
      this.treeviewSelections.forEach((element) => {
        QoE.deleteReportItem(element.id).then(() => {
          console.log(element.id, "DELETED!");
          this.refreshReportContent();
        });
      });
    },
    downloadReport() {
      // window.location.href = `http://127.0.0.1:8000/qoe/reports/download/${this.reportId}/`;

      let form = new FormData();
      form.append("reportId", this.reportId);
      axios
        .get(`http://127.0.0.1:8000/qoe/reports/download/`, {
          responseType: "blob",
          params: {
            reportId: this.reportId,
          },
        })
        .then((response) => {
          // 2. Get the blob setting file type in the response object returned by the request. Here is excel as an example.
          let blob = new Blob([response.data], {
            // type: "application/vnd.ms-excel",
            type:
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          });
          // 3. Create a temporary url pointing to the blob object
          let url = window.URL.createObjectURL(blob);

          // 4. After creating the url, you can simulate a series of operations on this file object, for example: preview, download
          let a = document.createElement("a");
          a.href = url;
          a.download = "QoE.pptx";
          a.click();
          // 5. Release this temporary object url
          window.URL.revokeObjectURL(url);
        });
    },
    getLinesOfService() {
      Company.linesOfService().then((response) => {
        this.linesOfService = response.data;
      });
    },
    getReports() {
      QoE.reports().then((response) => {
        this.qoeReports = response.data;
      });
    },
    getReportItems() {
      QoE.reportItems(this.reportId)
        .then((response) => {
          this.reportItems = response.data;
          this.$store.dispatch("successSnackbar", {
            text: `Report Items loaded Successfuly!`,
          });
        })
        .catch((error) => {
          this.$store.dispatch("errorSnackbar", {
            text: error.response.data,
          });
        });
    },
    getReportText() {
      QoE.reportText(this.reportId).then((response) => {
        this.reportText = response.data;
      });
    },
    getQoeGroups() {
      QoE.groups().then((response) => {
        this.qoeGroups = response.data;
      });
    },
    getQoeSubgroups() {
      QoE.subgroups().then((response) => {
        this.qoeSubgroups = response.data;
      });
    },
    getQoeItems() {
      QoE.items().then((response) => {
        this.qoeItems = response.data;
      });
    },
    getReportTreeview() {
      QoE.reportTreeview(this.reportId).then((response) => {
        this.reportTreeview = response.data;
      });
    },
    handleStep1() {
      if (this.reportRadio === "new") {
        this.postReport();
      } else {
        this.refreshReportContent();
      }

      this.stepper.step = this.stepper.step + 1;
    },
    handleStep2() {
      alert("Fetching Report Text Variables!");
      QoE.reportTextVariables(this.reportId).then((response) => {
        this.reportTextVariables = response.data;
      });

      this.stepper.step = this.stepper.step + 1;
    },
    handleStep3() {
      alert("Creating Report Text");
      QoE.fillVars(this.reportId, this.reportTextVariableValues).then(() => {
        this.getReportText();
        this.stepper.step = this.stepper.step + 1;
      });
    },
    postReport() {
      let form = new FormData();
      form.append("name", this.form.name);
      QoE.postReport(form)
        .then((response) => {
          this.reportId = response.data.id;
          this.$store.dispatch("successSnackbar", {
            text: `Report ${this.form.name} created Successfuly!`,
          });
        })
        .catch((error) => {
          this.$store.dispatch("errorSnackbar", { text: error.response.data });
        });
    },
    postReportItem() {
      let form = new FormData();
      console.log("REPORT ID", typeof this.reportId, this.reportId);
      form.append("report", this.reportId);
      form.append("item", this.reportItemForm.itemId);
      form.append("group", this.reportItemForm.groupId);
      form.append("order", this.reportItemForm.order);
      QoE.postReportItem(form)
        .then(() => {
          this.$store.dispatch("successSnackbar", {
            text: "Report Item Added Successfuly!",
          });
          this.refreshReportContent();
        })
        .catch((error) => {
          this.$store.dispatch("errorSnackbar", {
            text: error.response,
          });
        });
    },
    refreshReportContent() {
      this.getReportItems();
      this.getReportText();
      this.getReportTreeview();
    },
  },

  mounted() {
    this.getLinesOfService();
    this.getReports();
    this.getQoeGroups();
    this.getQoeSubgroups();
    this.getQoeItems();
  },
};
</script>

<style></style>
