<template>
  <SplineChart
    :hcTitle="apiData ? apiData.title : null"
    :hcSubtitle="apiData ? apiData.last_update : null"
    :hcData="apiData ? apiData.chart_data.values : []"
    :xAxisCategories="apiData ? apiData.chart_data.categories : []"
  />
</template>

<script>
// import StackedChart from "@/components/charts/highcharts/StackedColumnChart.vue";

import SplineChart from "@/components/charts/highcharts/SplineChart.vue";
import RedElectricaAPI from "@/api/red-electrica.js";

export default {
  //   props: ["apiData"],
  components: { SplineChart }, // StackedChart
  data() {
    return {
      apiData: null,
      apiResponse: null,
      queryParams: {
        lang: "en",
        category: "mercados",
        widget: "precios-mercados-tiempo-real",
        start_date: "2021-02-01",
        end_date: new Date().toISOString().slice(0, 10),
        time_trunc: "hour",
        geo_trunc: null,
        geo_limit: null,
        geo_ids: null,
        data_format: "line",
      },
    };
  },
  methods: {
    getApiData() {
      RedElectricaAPI.get(this.queryParams)
        .then((response) => {
          this.apiData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.getApiData();
  },
};
</script>

<style scoped>
.chart-container {
  width: 80%;
  height: 80%;
}
</style>
