<template>
  <div class="google-map" />
</template>

<script>
import gmapsInit from "@/utils/gmaps";

export default {
  props: ["center", "askComps"],
  data() {
    return {
      bounds: null,
      currentMarker: null,
      google: null,
      geocoder: null,
      map: null,
      mapOptions: {
        zoom: 6,
        mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        // mapTypeControlOptions: {
        //   style: this.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        // },
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      address: "Madrid",
      coords: { lat: 40.416775, lng: -3.70379 },
      markers: [],
    };
  },
  methods: {
    addComps() {
      for (let i = 0; i < this.askComps.length; i++) {
        this.addMarkerWithTimeout(
          {
            lat: this.askComps[i].num_latitud,
            lng: this.askComps[i].num_longitud,
          },
          i * 10
        );
      }
    },
    addMarker(coords) {
      let google = this.google;
      this.currentMarker = new google.maps.Marker({
        icon: {
          url:
            "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          size: new this.google.maps.Size(75, 75),
        },
        position: coords,
        draggable: false,
        animation: this.google.maps.Animation.DROP,
        map: this.map,
      });
      this.currentMarker.addListener("click", this.toggleBounce);
      this.currentMarker.setMap(this.map);
    },
    clearMarkers() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },
    addMarkerWithTimeout(position, timeout) {
      window.setTimeout(() => {
        this.markers.push(
          new this.google.maps.Marker({
            position: position,
            map: this.map,
            animation: this.google.maps.Animation.DROP,
          })
        );
      }, timeout);
    },
    toggleBounce() {
      if (this.currentMarker.getAnimation() !== null) {
        this.currentMarker.setAnimation(null);
      } else {
        this.currentMarker.setAnimation(this.google.maps.Animation.BOUNCE);
      }
    },
    removeMarker(marker) {
      marker.setMap(null);
    },
    refreshMapBounds() {
      this.google.maps.event.trigger(this.map, "resize");
      this.map.fitBounds(this.map.getBounds());
    },
    setZoom(zoom) {
      this.map.setZoom(zoom);
    },
  },
  watch: {
    // whenever the center changes, update
    center: function(newCenter, oldCenter) {
      console.log(oldCenter, newCenter);
      this.clearMarkers();
      this.removeMarker(this.currentMarker);
      this.map.setCenter(newCenter);
      this.map.zoom = 18;
      this.addMarker(newCenter);
      this.toggleBounce();
      this.addComps();
      this.refreshMapBounds();
    },
    askComps: function(newComps, oldComps) {
      console.log(newComps);
      console.log(oldComps);
      this.clearMarkers();
      this.addComps();
    },
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      let google = this.google;
      const geocoder = new google.maps.Geocoder();
      this.map = new google.maps.Map(this.$el, this.mapOptions);
      //   this.google.maps.event.addListener(
      //     this.map,
      //     "bounds_changed",
      //     function() {
      //       this.map.fitBounds(this.map.getBounds());
      //     }
      //   );

      geocoder.geocode({ address: this.address }, (results, status) => {
        if (status !== "OK" || !results[0]) {
          throw new Error(status);
        }

        // this.map.setCenter(results[0].geometry.location);
        this.map.setCenter(this.center);
        this.map.fitBounds(results[0].geometry.viewport);

        this.addMarker(this.center);
      });
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.google-map {
  width: 80%;
  height: 400px;
}
</style>
