<template>
  <baseline-layout>
    <template slot="pageTitle"> <h2>Create New Client</h2> </template>
    <v-container slot="main">
      <clients-sidebar />
      <v-row>
        <NewClientForm />
      </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import ClientsSideBar from "@/components/clients/ClientsSideBar.vue";
import NewClientForm from "./NewClientForm.vue";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    "clients-sidebar": ClientsSideBar,
    NewClientForm,
  },
  data() {
    return {
      clientData: null,
    };
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {},
};
</script>

<style></style>
