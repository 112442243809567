<template>
  <v-app id="inspire">
    <v-main id="main-wrapper">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <h1>MGX-ADVISORY.COM</h1>
            <v-card height="400" color="transparent" flat>
              <div class="display-3 mt-5">Page not found.</div>
              <div class="grey--text lighten-5">
                The page you are trying to get to never existed in this reality,
                or has migrated to a parallel universe.
              </div>
              <div class="paragraph-text mt-2">
                <br />
                <br />Try going back to home page and repeating your action. Or,
                contact helpdesk for support.
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-layout row wrap>
<v-flex xs12>

</v-flex>
</v-layout> -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {},
  props: {
    source: String,
  },
  data: () => ({}),
  methods: {
    toggleDrawer() {
      this.$store.dispatch("toggleDrawer");
    },
  },
  computed: {
    drawer() {
      return this.$store.state.drawer;
    },
  },
};
</script>
