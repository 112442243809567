<template>
  <BaselineLayout>
    <template slot="navbarContent">
      <v-toolbar-title>
        <v-btn
          text
          @click="$router.push({ name: 'real-estate-portfolio-dashboard' })"
          >Dashboard</v-btn
        >
      </v-toolbar-title>
      <v-toolbar-title>
        <v-toolbar-title>
          <v-btn text @click="$router.push({ name: 'real-estate-portfolio' })"
            >Workbench</v-btn
          >
        </v-toolbar-title>
      </v-toolbar-title>
    </template>
    <v-container slot="main">
      <SideBar />
      <TabContainer />
    </v-container>
  </BaselineLayout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/real-estate/PortfolioSideBar.vue";
import TabContainer from "@/components/workbench/real-estate/portfolio-dashboard/TabContainer.vue";
export default {
  components: { BaselineLayout, SideBar, TabContainer },
};
</script>

<style></style>
