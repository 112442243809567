import Api from "./Api.js";

export default {
  getUserTasks() {
    return Api.get("tasks/");
  },
  delete(form) {
    return Api.post("tasks/delete/", form);
  },
};
