<template>
  <baseline-layout>
    <template slot="pageTitle">
      <h2>Credentials Dashboard</h2>
    </template>
    <v-container slot="main">
      <SideBar />
      <v-card>
        <v-card-title> Credentials in Database</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="success" :to="{ name: 'contact-create' }"
            >Add</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="marco" :to="{ name: 'contact-search' }"
            >Search</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import SideBar from "@/components/credentials/SideBar.vue";

export default {
  components: {
    "baseline-layout": BaselineLayout,
    SideBar,
  },
};
</script>

<style></style>
