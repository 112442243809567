<template>
  <v-container fluid>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="6">
        <v-data-table dense :items="parentCompanies" :headers="tableHeaders">
          <template v-slot:top>
            <v-row>
              <v-col cols="10">
                <h2 class="marco--text">Parent Company Settings</h2>
              </v-col>
              <v-col cols="2">
                <v-btn small color="success" @click="createNewItem">New</v-btn>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  :counter="255"
                  label="Search Parent Companies"
                  append-icon="mdi-magnify"
                  append-outer-icon="mdi-format-list-numbered"
                  @click:append="searchParentCompany"
                  @click:append-outer="viewAllParentCompanies"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </template>
          <template #item.logo="{item}">
            <v-avatar size="25">
              <img :src="item.logo" :alt="item.name_short + ' Logo'" />
            </v-avatar>
          </template>
          <template #item.edit="{item}">
            <v-icon small color="green" @click="editParentCompany(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template #item.delete="{item}">
            <v-icon small color="red" @click="deleteParentCompany(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card class="pa-3">
        <v-card-title v-if="parentCompanyForm.id" class="marco white--text">
          Update Parent Company
        </v-card-title>
        <v-card-title v-if="!parentCompanyForm.id" class="marco white--text">
          Create Parent Company
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="4" align="center">
                <v-img
                  :src="parentCompanyForm.logo"
                  :alt="parentCompanyForm.name_short + ' logo'"
                  max-height="150"
                  max-width="150"
                ></v-img>
              </v-col>
              <v-col align-self="end">
                <v-file-input dense v-model="parentCompanyLogo" hide-input>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="parentCompanyForm.name_long"
                  :counter="255"
                  label="Long Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="parentCompanyForm.name_short"
                  :counter="255"
                  label="Short Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="error" @click="dialog = false">Cancel</v-btn>
          <v-btn
            v-if="parentCompanyForm.id"
            text
            color="success"
            v-on:click="saveParentCompany()"
            >Update</v-btn
          >
          <v-btn
            v-if="!parentCompanyForm.id"
            text
            color="success"
            @click="saveParentCompany()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import CompanyAPI from "@/api/company.js";

export default {
  components: {},
  mixins: [],
  data() {
    return {
      dialog: false,
      selectedObject: null,
      parentCompanyLogo: null,

      search: null,
      parentCompanyForm: {
        id: null,
        name_long: null,
        name_short: null,
        logo: null,
      },
      tableHeaders: [
        { text: "Logo", value: "logo" },
        { text: "Long Name", value: "name_long" },
        { text: "Short Name", value: "name_short" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
    };
  },
  methods: {
    async searchParentCompany() {
      await this.$store.dispatch("company/searchParentCompany", this.search);
    },
    async viewAllParentCompanies() {
      this.search = null;
      await this.$store.dispatch("company/searchParentCompany", this.search);
    },
    async saveParentCompany() {
      let form = new FormData();
      form.append("id", this.parentCompanyForm.id);
      form.append("name_long", this.parentCompanyForm.name_long);
      form.append("name_short", this.parentCompanyForm.name_short);
      if (this.parentCompanyLogo) {
        form.append("logo", this.parentCompanyLogo);
      }
      await this.$store.dispatch("company/postParentCompany", form);
      this.dialog = false;
    },

    deleteParentCompany(item) {
      //   this.editParentCompany(item);
      this.$store.dispatch("company/deleteParentCompany", item.id);
    },
    createNewItem: function() {
      this.clearForm();
      this.dialog = true;
    },
    async editParentCompany(item) {
      //   console.log(item);
      await this.$store.dispatch("company/setParentCompany", item);
      this.parentCompanyForm.id = this.selectedParentCompany.id;
      this.parentCompanyForm.name_long = this.selectedParentCompany.name_long;
      this.parentCompanyForm.name_short = this.selectedParentCompany.name_short;
      this.parentCompanyForm.logo = this.selectedParentCompany.logo;
      this.dialog = true;
    },
    clearForm() {
      this.$store.dispatch("company/setParentCompany", null);
      this.parentCompanyForm.id = null;
      this.parentCompanyForm.name_long = null;
      this.parentCompanyForm.name_short = null;
      this.parentCompanyLogo = null;
    },
  },
  computed: {
    parentCompanies() {
      return this.$store.state.company.parentCompanies;
    },
    selectedParentCompany() {
      return this.$store.state.company.selectedParentCompany;
    },
  },
  mounted: function() {
    this.$store.dispatch("company/getParentCompanies");
  },
};
</script>

<style scoped>
.pane {
  width: 100vh;
  /* border: 1px solid lightgrey; */
  padding: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.pane-input {
  margin: 10px;
}

.app-card {
  width: 300px;
}
.app-card img {
  width: 200px;
  align-content: center;
}
</style>
