<template>
  <z-layout>
    <v-container slot="main" fluid>
      <v-row align="center" justify="center">
        <v-col v-for="card in cards" class="text-center" :key="card.title">
          <z-card :title="card.title" :img="card.img" :to="card.to"></z-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="text-center"> </v-col>
      </v-row>
    </v-container>
  </z-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import WorkbenchCard from "@/components/workbench/WorkbenchCard.vue";
export default {
  components: {
    "z-layout": BaselineLayout,
    "z-card": WorkbenchCard,
  },
  data() {
    return {
      cards: [
        {
          title: "Quality of Earnings",
          img: require("@/assets/logo-qoe.jpg"),
          to: { name: "reporting-qoe" },
        },
        {
          title: "Microsoft Word",
          img: require("@/assets/logo-word.jpg"),
          to: { name: "reporting-msword" },
        },
        {
          title: "Microsoft Powerpoint",
          img: require("@/assets/logo-powerpoint.jpg"),
          to: "./msppt",
        },
        {
          title: "Microsoft Excel",
          img: require("@/assets/logo-excel.jpg"),
          to: "./msxlsx",
        },
      ],
    };
  },
};
</script>

<style></style>
