<template>
  <v-tab-item :value="tabNum">
    <v-container v-if="portfolioAnalysis" class="pa-5">
      <v-row justify="center">
        <v-col justify="between" align="center">
          <h3># Assets:</h3>
          <p>{{ portfolioAnalysis.assets }}</p>
        </v-col>
        <v-col>
          <h3>Total value:</h3>
          <p>{{ portfolioAnalysis.total_av.text }}</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="3">
          <DataTable
            v-if="portfolioAnalysis"
            title="Portfolio Errors"
            :tableData="portfolioAnalysis.errors"
            :fields="portfolioErrorsTable.fields"
            :isBusy="portfolioErrorsTable.isBusy"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-expansion-panels popout>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assets without Area</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assets without Valid Cadastral
              Reference</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assets without Valid Cadastral
              Reference</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assets without Valid Geolocation</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assets without Asking Comps</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Assets without Closing Comps</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </v-tab-item>
</template>

<script>
import DataTable from "@/components/base/tables/DataTable.vue";

let storeModule = "realEstatePortfolioDashboard";

export default {
  props: ["tabNum"],
  components: {
    DataTable,
  },
  computed: {
    portfolioAnalysis() {
      return this.$store.getters[`${storeModule}/portfolioAnalysis`];
    },
    portfolioErrorsTable() {
      return this.$store.getters[`${storeModule}/portfolioErrorsTable`];
    },
  },
};
</script>

<style></style>
