<template>
  <v-autocomplete
    class="combobox"
    v-bind="$attrs"
    v-on="$listeners"
    :deletable-chips="true"
    :clearable="clearable"
    dense
  >
    <slot> </slot>
  </v-autocomplete>
</template>

<script>
export default {
  computed: {
    filled() {
      if (this.$attrs.readonly) {
        return this.$attrs.readonly;
      } else {
        return false;
      }
    },
    clearable() {
      if (this.$attrs.readonly) {
        return !this.$attrs.readonly;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.combobox {
  font-size: 9pt;
}
</style>
