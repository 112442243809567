<template>
  <div>
    <highcharts class="hc" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  props: [
    "hcTitle",
    "hcSubtitle",
    "hcData",
    "AxisCategories",
    "xAxisTitle",
    "yAxisTitle",
  ],
  components: { highcharts: Chart },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column",
          zoomType: "xy",
        },
        title: {
          text: this.hcTitle,
        },
        // data: {
        //   parseDate: Date.parse,
        // },
        xAxis: {
          categories: this.AxisCategories,
          type: "datetime",
          parseDate: Date.parse,
          gridLineWidth: 0,
          dateTimeLabelFormats: {
            // millisecond: "%H:%M:%S.%L",
            // second: "%H:%M:%S",
            // minute: "%H:%M",
            // hour: "%H:%M",
            // day: "%e. %b",
            // week: "%e. %b",
            month: "%b '%y",
            year: "%Y",
          },
        },
        yAxis: {
          gridLineWidth: 0,
          min: 0,
          title: {
            text: this.yAxisTitle,
          },
          stackLabels: {
            enabled: false,
            // style: {
            //   fontWeight: "bold",
            //   color:
            //     // theme
            //     (Highcharts.defaultOptions.title.style &&
            //       Highcharts.defaultOptions.title.style.color) ||
            //     "gray",
            // },
            // formatter: function() {
            //   return "" + this.point.name + ": " + this.x + "%";
            // },
          },
        },
        legend: {
          align: "center",
          //   x: -30,
          verticalAlign: "bottom",
          //   y: 25,
          floating: false,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "white",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false,
        },
        tooltip: {
          //   headerFormat: "<b>{point.x}</b><br/>",
          pointFormat: "{series.name}: {point.y:,.0f}<br/>",
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
              format: "{point.y:,.2f}%",
              //   formatter: function() {
              //     return this.y + "%";
              //   },
            },
          },
        },
        series: this.hcData,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #42b983;
}
.hc {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
</style>
