<template>
  <v-card class="options-card">
    <v-card-title class="marco white--text">{{ title }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
.options-card {
  /* width: 40vh; */
  height: 250px;
}
</style>
