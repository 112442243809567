var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.searchResults.geo)?_c('v-form',[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Client Name (Long)"},model:{value:(_vm.reportInputs.clientNameLong),callback:function ($$v) {_vm.$set(_vm.reportInputs, "clientNameLong", $$v)},expression:"reportInputs.clientNameLong"}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Client Name (Short)"},model:{value:(_vm.reportInputs.clientNameShort),callback:function ($$v) {_vm.$set(_vm.reportInputs, "clientNameShort", $$v)},expression:"reportInputs.clientNameShort"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Report Date"},model:{value:(_vm.reportInputs.date),callback:function ($$v) {_vm.$set(_vm.reportInputs, "date", $$v)},expression:"reportInputs.date"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Engament Leader 1"},model:{value:(_vm.reportInputs.engagementLeader1),callback:function ($$v) {_vm.$set(_vm.reportInputs, "engagementLeader1", $$v)},expression:"reportInputs.engagementLeader1"}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Engament Leader 2"},model:{value:(_vm.reportInputs.engagementLeader2),callback:function ($$v) {_vm.$set(_vm.reportInputs, "engagementLeader2", $$v)},expression:"reportInputs.engagementLeader2"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Province","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.provincia_name
              : null}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Municipio","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.municipio_name
              : null}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Distrito","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.distrito
              : null}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Seccion Censal","value":_vm.searchResults.geo
              ? _vm.searchResults.geo.results.geo_data.seccion
              : null}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }