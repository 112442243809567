<template>
  <Histogram
    v-if="comps"
    :hcTitle="hcTitle"
    :hcSubtitle="hcSubtitle"
    :hcData="HistogramData"
  />
</template>

<script>
import Histogram from "@/components/charts/highcharts/Histogram.vue";

export default {
  props: ["hcTitle", "hcSubtitle", "comps", "valueField"],
  components: { Histogram },

  data() {
    return {};
  },

  computed: {
    HistogramData() {
      if (this.comps) {
        let data = this.comps.map((item) => {
          return item[this.valueField];
        });
        let HistogramData = [
          {
            name: "Histogram",
            type: "histogram",
            xAxis: 1,
            yAxis: 1,
            baseSeries: "s1",
            zIndex: -1,
          },
          {
            name: "Data",
            type: "scatter",
            data: data,
            id: "s1",
            marker: {
              radius: 1.5,
            },
          },
        ];
        return HistogramData;
      } else {
        return [];
      }
    },
  },
};
</script>

<style></style>
