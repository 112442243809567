<template>
  <AppLayout>
    <v-container class="fill-height" fluid slot="main" id="main-wrapper">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>MGX Advisors Login</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="loginError" class="error--text"
              >Username and/or password does not exist</v-card-text
            >
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Login"
                  name="login"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="username"
                ></v-text-field>

                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";
export default {
  components: { AppLayout },
  props: {
    source: String,
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      let User = new FormData();
      User.append("username", this.username);
      User.append("password", this.password);
      await this.$store.dispatch("auth/login", User);
      if (this.$store.getters["auth/isLoggedIn"]) {
        this.$router.push({ name: "home" });
      }
    },
  }, // Methods Close
  computed: {
    loginError() {
      return this.$store.state.auth.loginError;
    },
    created() {
      return this.$store.dispatch("auth/setLoginError", false);
    },
  },
};
</script>

<style scoped>
#main-wrapper {
  height: 100vh;
  background-image: url("../assets/login-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
