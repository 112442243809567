<template>
  <div>
    <SplineChart
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="hcData"
      :AxisCategories="AxisCategories"
      :xAxisTitle="xAxisTitle"
      :yAxisTitle="yAxisTitle"
    />
  </div>
</template>

<script>
import SplineChart from "@/components/charts/highcharts/SplineChart.vue";
import IneAPI from "@/api/ine";
export default {
  components: { SplineChart },
  props: ["hcTitle", "serie1", "serie2"],
  data() {
    return {
      apiData: { serie1: null, serie2: null },
    };
  },
  methods: {
    getApiData() {
      if (this.serie1) {
        IneAPI.seriesData(this.serie1).then((response) => {
          this.apiData.serie1 = response.data;
        });
      }
      if (this.serie2) {
        IneAPI.seriesData(this.serie2).then((response) => {
          this.apiData.serie2 = response.data;
        });
      }
    },
  },
  watch: {
    $props: {
      handler() {
        this.getApiData();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    AxisCategories() {
      let categories;
      if (this.apiData.serie1) {
        categories = this.apiData.serie1.Data.map((item) => {
          return item.period;
        });
      } else {
        categories = [];
      }
      return categories;
    },
    // hcTitle() {
    //   if (this.apiData.serie1) {
    //     return this.apiData.serie1.Nombre;
    //   } else {
    //     return null;
    //   }
    // },
    hcSubtitle() {
      if (this.apiData.serie1) {
        return this.apiData.serie1.Unidad.nombre_eng;
      } else {
        return null;
      }
    },
    hcData() {
      let series = [];
      if (this.apiData.serie1) {
        series.push({
          name: this.apiData.serie1.Nombre,
          color: "#18515E",
          data: this.apiData.serie1.Data.map((item) => {
            return item.value;
          }),
        });
      }
      if (this.apiData.serie2) {
        series.push({
          name: this.apiData.serie2.Nombre,
          color: "#606061",
          data: this.apiData.serie2.Data.map((item) => {
            return item.value;
          }),
        });
      }
      return series;
    },
    xAxisTitle() {
      return null;
    },
    yAxisTitle() {
      if (this.apiData.serie1) {
        return this.apiData.serie1.Unidad.nombre_eng;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getApiData();
  },
};
</script>

<style></style>
