<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Data Lake
              </h1>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-for="(card, index) in cards" :key="index" cols="4">
                    <v-card elevation="16" @click="$router.push(card.url)">
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-img
                          width="200"
                          :aspect-ratio="16 / 9"
                          :src="card.img"
                        ></v-img>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text>
                        <h1 class="marco--text">
                          {{ card.title }}
                        </h1></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";

export default {
  components: {
    AppLayout,
  },
  data() {
    return {
      cards: [
        {
          title: "GOOGLE",
          subtitle: null,
          img: require("@/assets/logo-google.png"),
          text: "Google Developer APIs",
          url: "./databank/google",
        },
        {
          title: "INE",
          subtitle: "Instituto Nacional de Estadistica",
          img: require("@/assets/logo-ine.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: { name: "databank-ine-browser" },
        },
        {
          title: "BOE",
          subtitle: "Boletín Oficial del Estado",
          img: require("@/assets/logo-boe.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/catastro",
        },
        {
          title: "BORME",
          subtitle: "Boletín Oficial del Registro Mercantil",
          img: require("@/assets/logo-borme.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/catastro",
        },
        {
          title: "Catastro",
          subtitle: "Sede Electrónica del Catastro",
          img: require("@/assets/logo-catastro.jpeg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: { name: "databank-catastro-home" },
        },
        {
          title: "Ministerio de Hacienda",
          subtitle: "Portal de Contratación Publica",
          img: require("@/assets/logo-contratacion-publica.png"),
          text: "Public Sector Licitation Portal in Spain",
          url: "./databank/catastro",
        },

        {
          title: "Sector Reports",
          subtitle: null,
          img: require("@/assets/logo-sector-reports.png"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/reports",
        },
        {
          title: "BBVA Open Platform",
          subtitle: null,
          img: require("@/assets/logo-bbva-openplatform.png"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/bbva",
        },
        {
          title: "WTO",
          subtitle: "World Trade Organization",
          img: require("@/assets/logo-wto.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/wto",
        },
        {
          title: "World Bank",
          subtitle: null,
          img: require("@/assets/logo-world-bank.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/wto",
        },
        {
          title: "Eurostat",
          subtitle: null,
          img: require("@/assets/logo-eurostat.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: "./databank/wto",
        },
        {
          title: "Red Electrica de España",
          subtitle: null,
          img: require("@/assets/logo-REE.jpg"),
          text:
            "Browse, chart, and download from the available statistics in Spain's National Statistics Institute",
          url: { name: "databank-red-electrica-dashboard" },
        },
      ],
    };
  },
};
</script>

<style></style>
