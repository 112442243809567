<template>
  <baseline-layout>
    <v-container slot="main">
      <v-row justify="center">
        <h1>Cloud Loader</h1>
      </v-row>
      <v-row>
        <v-col>
          <h2>Company Data</h2>
        </v-col>
        <v-col>
          <v-file-input
            label="Company Setup File"
            v-model="companySetupFile"
            append-outer-icon="mdi-file-excel"
          >
          </v-file-input>
        </v-col>
        <v-col>{{ companySetupFile }} </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>Clients</h2>
        </v-col>
        <v-col>
          <v-btn color="error" @click="deleteClients">Delete Clients</v-btn>
        </v-col>
        <v-col>
          <v-file-input
            outlined
            label="Select File"
            v-model="clientsFile"
            append-icon="mdi-cloud-upload"
            append-outer-icon="mdi-file-excel"
            @click:append="loadClients"
          >
          </v-file-input>
          <v-progress-linear
            value="50"
            color="yellow darken-2"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col><h3>Hotels</h3> </v-col>
        <v-col><v-btn>Load Hotel Clients</v-btn></v-col>
        <v-col><v-btn>Load Hotel Assets</v-btn></v-col>
      </v-row>
      <v-row>
        <h2>Client Types</h2>
      </v-row>
      <v-row>
        <h2>News</h2>
      </v-row>
      <v-row>
        <h2>Deals</h2>
      </v-row>
    </v-container>
  </baseline-layout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";

import Client from "@/api/clients.js";

export default {
  components: {
    "baseline-layout": BaselineLayout,
  },
  data() {
    return {
      companySetupFile: null,
      clientsFile: null,
    };
  },
  methods: {
    downloadTemplate() {
      alert("DOWNLOADING TEMPLATE!!!!");
    },
    upload() {
      alert("UPLOADING FILE!!!!");
    },
    deleteClients() {
      let r = confirm("Are you sure you want to delete the Clients Database?");
      if (r == true) {
        let r2 = confirm(
          "There is no turning back: Are you completely sure you want to delete the Clients Database?"
        );
        if (r2 == true) {
          Client.deleteAll()
            .then((response) => {
              this.$store.dispatch("successSnackbar", {
                text: response.data.message,
              });
            })
            .catch((error) => {
              this.$store.dispatch("errorSnackbar", {
                text: error.response.data.message,
              });
            });
        }
      }
    },
    loadClients() {
      let r = confirm("Are you sure you want to load the Clients Database?");
      if (r == true) {
        let r2 = confirm(
          "There is no turning back: Are you completely sure you want to load the Clients Database?"
        );
        if (r2 == true) {
          let form = new FormData();
          form.append("file", this.clientsFile);
          Client.loadFromFile(form)
            .then((response) => {
              this.$store.dispatch("successSnackbar", {
                text: response.data.message,
              });
            })
            .catch((error) => {
              this.$store.dispatch("errorSnackbar", {
                text: error.response.data.message,
              });
            });
        }
      }
    },
  },
  computed: {
    component() {
      return this.$store.state.clients.component;
    },
  },
  mounted() {},
};
</script>

<style></style>
