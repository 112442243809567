<template>
  <div>
    <v-navigation-drawer
      slot="menu-drawer"
      v-model="toolDrawer"
      absolute
      temporary
      color="warning"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Tools</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in toolDrawerItems" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toolDrawerItems: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
    };
  },
  methods: {
    // toggletoolDrawer() {
    //   this.$store.dispatch("main/toggletoolDrawer");
    // },
  },
  computed: {
    toolDrawer() {
      return this.$store.getters["main/toolDrawer"];
    },
  },
};
</script>

<style></style>
