import ClientsAPI from "@/api/clients.js";

export default {
  namespaced: true,
  state: {
    dialogs: {
      clientForm: false,
      contactForm: false,
    },
    clients: [],

    clientData: {
      clientTypes: [],
      clientDetail: null,
      clientNews: [],
      clientAssets: [],
      clientDeals: [],
      clientContacts: [],

      searchResults: [],
    },
    qClient: "",
    selectedClient: null,
  },
  getters: {
    clients(state) {
      return state.clients;
    },
    clientData(state) {
      return state.clientData;
    },
    clientDetail(state) {
      return state.clientData.clientDetail;
    },
    clientTypes(state) {
      return state.clientData.clientTypes;
    },
    clientContacts(state) {
      return state.clientData.clientContacts;
    },
    dialogClientForm(state) {
      return state.dialogs.clientForm;
    },
    dialogContactForm(state) {
      return state.dialogs.contactForm;
    },
  },
  mutations: {
    GET_CLIENTS(state, clients) {
      state.clients = clients;
    },
    GET_CLIENT_TYPES(state, clientTypes) {
      state.clientData.clientTypes = clientTypes;
    },
    GET_CLIENT_DETAIL(state, payload) {
      state.clientData.clientDetail = payload;
    },
    GET_CLIENT_NEWS(state, payload) {
      state.clientData.clientNews = payload;
    },
    getClientAssets(state, payload) {
      state.clientData.clientAssets = payload;
    },
    getClientDeals(state, payload) {
      state.clientData.clientDeals = payload;
    },
    GET_CLIENT_CONTACTS(state, payload) {
      state.clientData.clientContacts = payload;
    },
    searchClients(state, results) {
      state.clientData.searchResults = results;
    },
    setComponent(state, payload) {
      state.component = payload.component;
    },
    SET_DIALOG(state, params) {
      // Receives params = {dialog:XXXX,view:true/false}
      state.dialogs[params.dialog] = params.view;
    },
  },
  actions: {
    getClients(context) {
      ClientsAPI.getClients().then((response) => {
        context.commit("GET_CLIENTS", response.data);
      });
    },
    getClientAssets(context, clientId) {
      context;
      let payload = {
        clientId: clientId,
      };
      // context.commit("getClientAssets", payload);
      console.log(clientId, payload);
    },
    getClientCredentials(context, clientId) {
      context.dispatch("credentials/getClientCredentials", clientId, {
        root: true,
      });
    },
    getClientContacts(context, clientId) {
      context;
      return ClientsAPI.getClientContacts(clientId);
    },
    getClientDeals(context, clientId) {
      context.dispatch("deals/getClientDeals", clientId, { root: true });
    },
    getClientDetail(context, clientId) {
      context;
      return ClientsAPI.getClient(clientId);
    },
    async getClientData(context, clientId) {
      await ClientsAPI.getClient(clientId).then((response) => {
        context.commit("GET_CLIENT_DETAIL", response.data);
      });
      await ClientsAPI.getClientContacts(clientId).then((response) => {
        context.commit("GET_CLIENT_CONTACTS", response.data);
      });
      //   await context.dispatch("getClientNews").then((response) => {
      //     context.commit("GET_CLIENT_NEWS", response.data);
      //   });
    },
    getClientNews(context, clientId) {
      return context.dispatch("news/getClientArticles", clientId, {
        root: true,
      });
    },
    getClientTypes(context) {
      ClientsAPI.types()
        .then((response) => {
          context.commit("GET_CLIENT_TYPES", response.data);
        })
        .catch((error) => {
          context.dispatch(
            "snackbarError",
            `Client Types not Loaded: ${error}`,
            { root: true }
          );
        });
    },
    postClient(context, clientForm) {
      if (clientForm.get("id")) {
        ClientsAPI.patchClient(clientForm)
          .then(() => {
            context.dispatch(
              "successSnackbar",
              "Client Updated Successfully!",
              { root: true }
            );
          })
          .catch((error) => {
            context.dispatch("snackbarError", `${error.message}`, {
              root: true,
            });
          });
      } else {
        ClientsAPI.postClient(clientForm)
          .then(() => {
            context.dispatch(
              "successSnackbar",
              "Client Created Successfully!",
              { root: true }
            );
          })
          .catch((error) => {
            context.dispatch("snackbarError", `${error.message}`, {
              root: true,
            });
          });
      }
      //   let request = {
      //     details: {
      //       method: requestMethod,
      //       url: url,
      //       data: clientForm,
      //     },
      //     success: "Client Updated Successfuly!",
      //     error: "Client Update:",
      //   };
      //   context.dispatch("axiosRequest", request, { root: true });
      context.dispatch("GET_CLIENT_DETAIL", clientForm.get("id"));
    },
    searchClients(context, payload) {
      ClientsAPI.search({
        params: {
          q: payload.name,
          clientType: String(payload.types),
        },
      })
        .then((response) => {
          context.commit("searchClients", response.data);
        })
        .catch((error) => {
          let snackbar = {
            showing: true,
            text: `Search Error: ${error}`,
            color: "error",
          };
          context.dispatch("setSnackbar", snackbar, { root: true });
        });

      //   axios
      //     .get("http://localhost/api/clients/companies", {
      //       params: {
      //         q: payload.name,
      //         clientType: String(payload.types),
      //       },
      //     })
      //     .then((response) => {
      //       context.commit("searchClients", response.data);
      //     })
      //     .catch((error) => {
      //       let snackbar = {
      //         showing: true,
      //         text: `Search Error: ${error}`,
      //         color: "error",
      //       };
      //       context.dispatch("setSnackbar", snackbar, { root: true });
      //     });
    },
    setComponent(context, component) {
      let payload = {
        component: component,
      };
      context.commit("setComponent", payload);
    },
    setDialog(context, params) {
      // params = {dialog, view}
      context.commit("SET_DIALOG", params);
    },
  },
};
