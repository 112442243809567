<template>
  <v-container fluid id="map-container">
    <v-row>
      <v-col>
        <v-btn @click="setZoom(10)">Change Zoom</v-btn>
      </v-col>
      <v-col>
        {{ askComps }}
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col>
              <div class="google-map" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import gmapsInit from "@/utils/gmaps";

export default {
  //   props: ["center", "askComps"],
  data() {
    return {
      bounds: null,
      currentMarker: null,
      google: null,
      geocoder: null,
      map: null,
      mapOptions: {
        zoom: 5,
        center: { lat: 40.416775, lng: -3.70379 },
        mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        // mapTypeControlOptions: {
        //   style: this.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        // },
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      address: "Madrid",
      testCoords: { lat: 40.416775, lng: -3.70379 },
      markers: [],
    };
  },
  methods: {
    addComps() {
      this.addCenterMarker();
      if (this.askComps !== []) {
        for (let i = 0; i < this.askComps.length; i++) {
          let currentMarker = this.addMarker(
            {
              lat: this.askComps[i].num_latitud,
              lng: this.askComps[i].num_longitud,
            },
            i * 10
          );
          this.addInfoWindow(this.askComps[i], currentMarker);
        }

        this.setCenter(this.mapCenter);
        this.setZoom(16);
      }
    },
    addCenterMarker() {
      let google = this.google;
      let currentMarker = new google.maps.Marker({
        icon: {
          url:
            "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },
        position: this.mapCenter,
        draggable: false,
        animation: this.google.maps.Animation.DROP,
        map: this.map,
      });
      this.markers.push(currentMarker);
      //   currentMarker.addListener("click", this.toggleBounce);
      currentMarker.setMap(this.map);
      //   this.setCenter(this.mapCenter);
      this.setZoom(16);
    },
    clearMarkers() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },
    addMarker(coords) {
      let google = this.google;
      let currentMarker = new google.maps.Marker({
        // icon: {
        //   url:
        //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        //   size: new this.google.maps.Size(75, 75),
        // },
        position: coords,
        draggable: false,
        animation: this.google.maps.Animation.DROP,
        map: this.map,
      });
      //   this.currentMarker.addListener("click", this.toggleBounce);
      currentMarker.setMap(this.map);
      //   this.addInfoWindow(currentMarker);
      this.markers.push(currentMarker);
      return currentMarker;
    },
    addInfoWindow(comp, marker) {
      let contentString = `
	  <div id="content">
	  	<div id="siteNotice">
		  <h3 id="firstHeading" class="firstHeading">
		  	${comp.des_direccion}
		  </h3>
		  	<div id="bodyContent">
				<p><b>€:</b>${comp.num_precioventa}</p>
			</div>
		  <div id="photo1">
		  	<img src="${comp.photos[0]}" width="300">
			</div>
		  <div id="urlLink">
			  <a href="${comp.des_urlanuncio}" target="_blank">View</a>
			</div>
  		</div>
		</div>`;
      let infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });
    },

    // addMarkerWithTimeout(position, timeout) {
    //   window.setTimeout(() => {
    //     this.markers.push(
    //       new this.google.maps.Marker({
    //         position: position,
    //         map: this.map,
    //         animation: this.google.maps.Animation.DROP,
    //       })
    //     );
    //   }, timeout);
    // },
    // toggleBounce() {
    //   if (this.currentMarker.getAnimation() !== null) {
    //     this.currentMarker.setAnimation(null);
    //   } else {
    //     this.currentMarker.setAnimation(this.google.maps.Animation.BOUNCE);
    //   }
    // },
    // removeMarker(marker) {
    //   marker.setMap(null);
    // },
    refreshMapBounds() {
      this.google.maps.event.trigger(this.map, "resize");
      this.map.fitBounds(this.map.getBounds());
    },
    setCenter(coords) {
      this.map.setCenter(coords);
      this.refreshMapBounds();
    },
    setZoom(zoom) {
      this.map.setZoom(zoom);
    },
  },
  watch: {
    // whenever the center changes, update
    // center: function(newCenter, oldCenter) {
    //   console.log(oldCenter, newCenter);
    //   this.clearMarkers();
    //   this.removeMarker(this.currentMarker);
    //   this.map.setCenter(newCenter);
    //   this.map.zoom = 18;
    //   this.addMarker(newCenter);
    //   this.toggleBounce();
    //   this.addComps();
    //   this.refreshMapBounds();
    // },
    askComps: function(newComps, oldComps) {
      console.log(newComps);
      console.log(oldComps);
      this.clearMarkers();
      this.addCenterMarker();
      this.addComps();
    },
  },
  computed: {
    askComps() {
      return this.$store.getters["realEstate/askCompsDetail"];
    },
    mapCenter() {
      return this.$store.getters["realEstate/mapCenter"];
    },
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      let google = this.google;
      this.map = new google.maps.Map(this.$el, this.mapOptions);
      this.google.maps.event.addListener(
        this.map,
        "bounds_changed",
        function() {
          this.map.fitBounds(this.map.getBounds());
        }
      );
      //   this.addTestMarker();
      this.addComps();
      //   const geocoder = new google.maps.Geocoder();
      //   geocoder.geocode({ address: this.address }, (results, status) => {
      //     if (status !== "OK" || !results[0]) {
      //       throw new Error(status);
      //     }
      //     // this.map.setCenter(results[0].geometry.location);
      //   this.map.setCenter(this.coords);
      //   this.map.fitBounds(this.map.getBounds());
      //   this.map.fitBounds(results[0].geometry.viewport);
      //     this.addMarker(this.center);
      //   });
    } catch (error) {
      console.error(error);
      alert(error);
    }
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#map-container {
  height: 75vh;
  /* width: 100%; */
}

.google-map {
  /* width: 100vw;
  height: 100vh; */
}
</style>
