<template>
  <v-container>
    <v-row v-if="askCompsSummary">
      <v-col sm="4">
        <InfoCard
          title="Asking Price: Buy"
          :body="askCompsSummary.buy.avg_eur_sqm + ' €/m2'"
          :footer="'# Comps:' + askCompsSummary.buy.count"
        >
        </InfoCard>
      </v-col>
      <v-col sm="4">
        <InfoCard
          title="Asking Price: Rent"
          :body="askCompsSummary.rent.avg_eur_sqm + ' €/m2/month'"
          :footer="'# Comps:' + askCompsSummary.rent.count"
        >
        </InfoCard>
      </v-col>
      <v-col sm="4">
        <InfoCard title="Yield: Ask" :body="askCompsSummary.yield.yield + '%'">
        </InfoCard>
      </v-col>
    </v-row>
    <v-row v-if="closeCompsSummary">
      <v-col sm="4">
        <InfoCard
          title="Buy: Closing"
          :body="closeCompsSummary.buy.avg_eur_sqm + ' €/m2'"
          :footer="'# Comps:' + closeCompsSummary.buy.count"
        >
        </InfoCard>
      </v-col>
      <v-col sm="4">
        <InfoCard title="Bid-Ask Spread" :body="bidAskSpread + ' %'">
        </InfoCard>
      </v-col>
    </v-row>

    <v-row v-if="askCompsDetail" justify="center">
      <v-col cols="6">
        <AskingPriceHistogram
          :compsData="askCompsDetail"
          hcTitle="Asking Price"
          hcSubtitle="€/m2"
        />
      </v-col>
      <v-col cols="6">
        <ClosingPriceHistogram
          :compsData="closeCompsDetail"
          hcTitle="Closing Price"
          hcSubtitle="€/m2"
        />
      </v-col>
    </v-row>

    <!-- <v-row v-if="askCompsHistograms" justify="center">
      <v-col sm="6">
        <BarChartCard
          title="Asking Price: BUY"
          :data="askCompsHistograms.buy"
          source="MGX Analysis"
        ></BarChartCard>

        <PriceHistogram
          :compsData="askCompsDetail"
          hcTitle="Asking Price"
          hcSubtitle="€/m2"
        />
      </v-col>
      <v-col>
        <PriceColumnChart />
      </v-col>
      <v-col sm="6">
        <BarChartCard
          title="Asking Price: RENT"
          :data="askCompsHistograms.rent"
          source="MGX Analysis"
        ></BarChartCard>
      </v-col>
    </v-row>
    
      <v-col sm="5">
        <BarChartCard
          title="Asking Price: RENT"
          :data="datacollection.rent"
          source="MGX Analysis"
        ></BarChartCard>

      </v-col> -->
  </v-container>
</template>

<script>
import roundMixin from "../../mixins/roundMixin";

import InfoCard from "../base/BaseInfoCard.vue";

import AskingPriceHistogram from "@/components/real-estate/charts/AskingPriceHistogram.vue";
import ClosingPriceHistogram from "@/components/real-estate/charts/ClosingPriceHistogram.vue";
// import BarChartCard from "@/components/charts/BarChartCard.vue";

// import PriceHistogram from "@/components/real-estate/charts/PriceHistogram.vue";
// import PriceColumnChart from "@/components/real-estate/charts/PriceColumnChart.vue";

export default {
  mixins: [roundMixin],
  components: {
    InfoCard,
    // BarChartCard,
    AskingPriceHistogram,
    ClosingPriceHistogram,
    // PriceColumnChart,
  },
  data() {
    return {};
  },
  computed: {
    askCompsDetail() {
      return this.$store.getters["realEstate/askCompsDetail"];
    },
    askCompsSummary() {
      return this.$store.getters["realEstate/askCompsSummary"];
    },
    // askCompsHistograms() {
    //   return this.$store.state.realEstate.searchResults.askCompsHistograms;
    // },
    closeCompsDetail() {
      return this.$store.getters["realEstate/closeCompsDetail"];
    },
    closeCompsSummary() {
      return this.$store.state.realEstate.searchResults.closeCompsSummary;
    },
    datacollection() {
      return this.$store.getters["realEstate/fillData"];
    },
    bidAskSpread() {
      return this.round(
        (this.closeCompsSummary.buy.avg_eur_sqm /
          this.askCompsSummary.buy.avg_eur_sqm -
          1) *
          100,
        2
      );
    },
  },
};
</script>

<style></style>
