<template>
  <v-card elevation="24" class="infoCard">
    <v-toolbar dense color="marco" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-title>{{ body }}</v-card-title>
    <v-card-subtitle>
      {{ footer }}
    </v-card-subtitle>
    <v-card-text></v-card-text>
  </v-card>
  <!-- <div class="card" id="infoCard">
        <div v-if="body" class="card-body">
            <h3 v-if="title">{{title}}</h3>  
            <h5>{{body}}</h5>  
            <p class="text-muted" v-if="footer">{{footer}}</p>  
        </div>
    </div> -->
</template>

<script>
export default {
  props: ["header", "title", "body", "footer"],
};
</script>

<style>
.infoCard {
  border-left: 3px solid #007ea7;
  height: 130px;
}
</style>
