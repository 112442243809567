<template>
  <v-card elevation="16" class="mx-auto">
    <v-toolbar dense color="marco" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <!-- <v-card-title>{{ body }}</v-card-title> -->
    <!-- <v-card-subtitle>
      {{ footer }}
    </v-card-subtitle>
    <v-card-text></v-card-text> -->

    <v-card-text>
      <bar-chart :chart-data="data" :options="chartOptions"></bar-chart>
    </v-card-text>

    <v-card-text class="caption">
      Source: {{ source }}
      <v-spacer></v-spacer>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "../base/BarChart.js";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        gridLines: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: { display: false },
      },
    };
  },
  components: {
    "bar-chart": BarChart,
  },
};
</script>

<style></style>
