import Api from "./Api";

export default {
  clientDeals(clientId) {
    return Api.get("deals", { params: { client_id: clientId } });
  },
  dashboard() {
    return Api.get(`deals/dashboard/`);
  },
  get(dealId) {
    return Api.get(`deals/${dealId}`);
  },
  post(form) {
    let id = form.get("id");
    console.log("DEAL POST ID", id);
    if (id) {
      return Api.put(`deals/${id}/`, form);
    } else {
      return Api.post("deals/", form);
    }
  },
  list() {
    return Api.get("deals/list");
  },
  search(params) {
    return Api.get("deals/list", { params: params });
  },
};
