import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store";

import HomePage from "@/pages/HomePage.vue";

// Company Setup
import CompanyHome from "@/pages/company/Home.vue";
import CompanySetupPage from "@/pages/company/CompanySetup.vue";
import CompanyDashboard from "@/pages/company/Dashboard.vue";
import CompanyImport from "@/pages/company/CompanyImport.vue";

// Clients
import ClientsHome from "@/pages/clients/Home.vue";
import ClientCompanies from "@/pages/clients/Clients.vue";
import ClientContacts from "@/pages/clients/Contacts.vue";

import ClientDashboard from "@/pages/clients/Dashboard.vue";
import ClientSearch from "@/pages/clients/Search.vue";
import ClientCreate from "@/pages/clients/Create.vue";
import ClientList from "@/pages/clients/List.vue";
import ClientDetail from "@/pages/clients/ClientDetail.vue";

// Contacts
import ContactDashboard from "@/pages/contacts/Dashboard.vue";
import ContactSearch from "@/pages/contacts/Search.vue";
import ContactCreate from "@/pages/contacts/Create.vue";
import ContactDetail from "@/pages/contacts/Detail.vue";

// Credentials
import CredentialDashboard from "@/pages/credentials/Dashboard.vue";
import CredentialSearch from "@/pages/credentials/Search.vue";

// Databank
import DatabankHomePage from "@/pages/DataBankHome.vue";
import IneBrowser from "@/pages/databank/ine/Browser.vue";
import IneWizard from "@/pages/databank/ine/Wizard.vue";
import IneDownloads from "@/pages/databank/ine/Downloads.vue";
import IneDashboardHospitality from "@/pages/databank/ine/DashboardHospitality.vue";
import CatastroHome from "@/pages/databank/catastro/Home.vue";
import CatastroSearch from "@/pages/databank/catastro/Search.vue";
import CatastroDatatapes from "@/pages/databank/catastro/Datatapes.vue";
import CatastroWizard from "@/pages/databank/catastro/Wizard.vue";
import CatastroStreetDetail from "@/pages/databank/catastro/StreetDetail.vue";
import RedElectricaSearch from "@/pages/databank/red-electrica/RedElectricaSearch.vue";
import RedElectricaDashboard from "@/pages/databank/red-electrica/RedElectricaDashboard.vue";

// Deals
import DealsDashboard from "@/pages/deals/Dashboard.vue";
import DealsCreate from "@/pages/deals/Create.vue";
import DealsSearch from "@/pages/deals/Search.vue";

// News
import NewsDashboard from "@/pages/news/Dashboard.vue";
import NewsCreate from "@/pages/news/Create.vue";
import NewsSearch from "@/pages/news/Search.vue";

// GIS
import GIS from "@/pages/gis/GIS.vue";

// Reporting
import ReportingHome from "@/pages/ReportingHome.vue";
import QualityOfEarnings from "@/pages/workbench/reporting/QualityOfEarnings.vue";
import MicrosoftWord from "@/pages/workbench/reporting/word/MicrosoftWord.vue";

import WorkbenchHomePage from "@/pages/WorkbenchHome.vue";
import EngagementLetters from "@/pages/workbench/engagementLetters/EngagementLetters.vue";
import HospitalityPage from "@/pages/workbench/hospitality/Hospitality.vue";

import RealEstateHomePage from "@/pages/workbench/real-estate/Home.vue";
import RealEstateSearchPage from "@/pages/workbench/real-estate/RealEstateSearch.vue";
import RealEstatePortfolioWorkbench from "@/pages/workbench/real-estate/PortfolioWorkbench.vue";
import RealEstatePortfolioDashboard from "@/pages/workbench/real-estate/PortfolioDashboard.vue";
import RealEstatePortfolioMap from "@/pages/workbench/real-estate/PortfolioMap.vue";
import GeoHomePage from "@/pages/geo/HomePage.vue";
import CloudLoader from "@/pages/CloudLoader.vue";

// USER AREA
import Login from "@/pages/Login.vue";
import UserTasks from "@/pages/user-tasks/UserTasks.vue";
import Testing from "@/pages/Testing.vue";
import ViewNotFound from "@/pages/ViewNotFound.vue";

import UserFiles from "@/pages/files/UserFiles.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: HomePage, name: "home", meta: { isLoggedIn: true } },
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: { isLoggedIn: false },
  },

  // CLIENTS & CONTACTAS
  {
    path: "/clients",
    component: ClientsHome,
    name: "client-home",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/companies",
    component: ClientCompanies,
    name: "client-companies",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/contacts",
    component: ClientContacts,
    name: "client-contacts",
    meta: { isLoggedIn: true },
  },

  {
    path: "/clients",
    component: ClientDashboard,
    name: "client-dashboard",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/search",
    component: ClientSearch,
    name: "client-search",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/list",
    component: ClientList,
    name: "client-list",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/create",
    component: ClientCreate,
    name: "client-create",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/:id",
    component: ClientDetail,
    name: "client-detail",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/:id/update",
    component: ClientCreate,
    name: "client-update",
    meta: { isLoggedIn: true },
  },
  {
    path: "/clients/:id/delete",
    component: ClientCreate,
    name: "client-delete",
    meta: { isLoggedIn: true },
  },

  //   CONTACTS
  {
    path: "/contacts",
    component: ContactDashboard,
    name: "contact-dashboard",
    meta: { isLoggedIn: true },
  },
  {
    path: "/contacts/search",
    component: ContactSearch,
    name: "contact-search",
    meta: { isLoggedIn: true },
  },
  {
    path: "/contacts/create",
    component: ContactCreate,
    name: "contact-create",
    meta: { isLoggedIn: true },
  },
  {
    path: "/contacts/:id",
    component: ContactDetail,
    name: "contact-detail",
    meta: { isLoggedIn: true },
  },
  //   { path: "/contacts/list", component: ClientList, name: "client-list" },

  //   CREDENTIALS
  {
    path: "/credentials",
    component: CredentialDashboard,
    name: "credentials",
    meta: { isLoggedIn: true },
  },
  {
    path: "/credentials/search",
    component: CredentialSearch,
    name: "credentials-search",
    meta: { isLoggedIn: true },
  },

  {
    path: "/cloud-loader",
    component: CloudLoader,
    name: "uploader",
    meta: { isLoggedIn: true },
  },

  // COMPANY SETUP

  {
    path: "/company/home",
    component: CompanyHome,
    name: "company-home",
    meta: { isLoggedIn: true },
  },
  {
    path: "/company/setup",
    component: CompanySetupPage,
    name: "company-setup",
    meta: { isLoggedIn: true },
  },
  {
    path: "/company/dashboard",
    component: CompanyDashboard,
    name: "company-dashboard",
    meta: { isLoggedIn: true },
  },
  {
    path: "/company/import",
    component: CompanyImport,
    name: "company-import",
    meta: { isLoggedIn: true },
  },

  {
    path: "/real-estate/",
    component: RealEstateHomePage,
    name: "real-estate-home",
    meta: { isLoggedIn: true },
  },
  {
    path: "/real-estate/asset",
    component: RealEstateSearchPage,
    name: "real-estate-asset-search",
    meta: { isLoggedIn: true },
  },
  {
    path: "/real-estate/portfolio/workbench",
    component: RealEstatePortfolioWorkbench,
    name: "real-estate-portfolio",
    meta: { isLoggedIn: true },
  },
  {
    path: "/real-estate/portfolio/dashboard",
    component: RealEstatePortfolioDashboard,
    name: "real-estate-portfolio-dashboard",
    meta: { isLoggedIn: true },
  },
  {
    path: "/real-estate/portfolio/map",
    component: RealEstatePortfolioMap,
    name: "real-estate-portfolio-map",
    meta: { isLoggedIn: true },
  },

  // DATABANK
  {
    path: "/databank",
    component: DatabankHomePage,
    name: "databank",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/ine/browser",
    component: IneBrowser,
    name: "databank-ine-browser",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/ine/wizard",
    component: IneWizard,
    name: "databank-ine-wizard",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/ine/downloads",
    component: IneDownloads,
    name: "databank-ine-downloads",
    meta: { isLoggedIn: true },
  },

  {
    path: "/databank/ine/dashboards/hospitality",
    component: IneDashboardHospitality,
    name: "ine-dashboard-hospitality",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/ine/dashboards/real-estate",
    component: IneDashboardHospitality,
    name: "ine-dashboard-real-estate",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/catastro/wizard",
    component: CatastroWizard,
    name: "databank-catastro-wizard",
    meta: { isLoggedIn: false },
  },

  {
    path: "/databank/catastro/home",
    component: CatastroHome,
    name: "databank-catastro-home",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/catastro/search",
    component: CatastroSearch,
    name: "databank-catastro-search",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/catastro/datatapes",
    component: CatastroDatatapes,
    name: "databank-catastro-datatapes",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/catastro/street-detail/:viaId",
    component: CatastroStreetDetail,
    name: "databank-catastro-street-detail",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/red-electrica/search",
    component: RedElectricaSearch,
    name: "databank-red-electrica-search",
    meta: { isLoggedIn: true },
  },
  {
    path: "/databank/red-electrica/dashboard",
    component: RedElectricaDashboard,
    name: "databank-red-electrica-dashboard",
    meta: { isLoggedIn: true },
  },

  // DEALS
  {
    path: "/deals",
    component: DealsDashboard,
    name: "deals",
    meta: { isLoggedIn: true },
  },
  {
    path: "/deals/create",
    component: DealsCreate,
    name: "deals-create",
    meta: { isLoggedIn: true },
  },
  {
    path: "/deals/search",
    component: DealsSearch,
    name: "deals-search",
    meta: { isLoggedIn: true },
  },

  // NEWS
  {
    path: "/news",
    component: NewsDashboard,
    name: "news",
    meta: { isLoggedIn: true },
  },
  {
    path: "/news/create",
    component: NewsCreate,
    name: "news-create",
    meta: { isLoggedIn: true },
  },
  {
    path: "/news/search",
    component: NewsSearch,
    name: "news-search",
    meta: { isLoggedIn: true },
  },

  // WORKBENCH: HOME
  {
    path: "/workbench",
    component: WorkbenchHomePage,
    name: "workbench",
    meta: { isLoggedIn: true },
  },

  // WORKBENCH: GEO
  {
    path: "/workbench/geo",
    component: GeoHomePage,
    name: "geo",
    meta: { isLoggedIn: true },
  },

  // WORKBENCH: REPORTING
  {
    path: "/reporting/quality-of-earnings",
    component: QualityOfEarnings,
    name: "reporting-qoe",
    meta: { isLoggedIn: true },
  },

  // WORKBENCH: ENGAGEMENT LETTERS
  {
    path: "/engagement-letters",
    component: EngagementLetters,
    name: "engagement-letters",
    meta: { isLoggedIn: true },
  },
  // WORKBENCH: HOSPITALITY
  {
    path: "/hospitality",
    component: HospitalityPage,
    name: "hospitality",
    meta: { isLoggedIn: true },
  },
  // GIS MAPS
  {
    path: "/gis",
    component: GIS,
    name: "gis",
    meta: { isLoggedIn: true },
  },

  // User pages
  {
    path: "/user-tasks",
    component: UserTasks,
    name: "user-tasks",
    meta: { isLoggedIn: true },
  },
  {
    path: "/testing",
    component: Testing,
    name: "testing",
    meta: { isLoggedIn: false },
  },

  {
    path: "/credentials",
    component: ViewNotFound,
    name: "credentials",
    meta: { isLoggedIn: true },
  },
  {
    path: "/deals",
    component: ViewNotFound,
    name: "deals",
    meta: { isLoggedIn: true },
  },
  {
    path: "/reports",
    component: ViewNotFound,
    name: "reports",
    meta: { isLoggedIn: true },
  },

  {
    path: "/reporting",
    component: ReportingHome,
    name: "reports",
    meta: { isLoggedIn: true },
  },
  {
    path: "/reporting/msword",
    component: MicrosoftWord,
    name: "reporting-msword",
    meta: { isLoggedIn: true },
  },
  {
    path: "/files",
    component: UserFiles,
    name: "files",
    meta: { isLoggedIn: true },
  },

  {
    // catch all 404 - define at the very end
    path: "*",
    component: ViewNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  from;
  if (to.matched.some((record) => record.meta.isLoggedIn)) {
    if (store.getters["auth/isLoggedIn"]) {
      store.dispatch("main/closeDrawers");
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
