<template>
  <v-app-bar app color="#065a82" dark dense>
    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title class="toolbar-title">MGX Advisors</v-toolbar-title>
    <v-spacer></v-spacer>
    <slot></slot>
    <v-spacer></v-spacer>
    <!-- <v-toolbar-title v-if="!isLoggedIn">
      Login
    </v-toolbar-title> -->
    <!-- <v-toolbar-title v-if="isLoggedIn" class="mr-5">
      {{ currentUser }}
    </v-toolbar-title> -->
    <!-- <v-toolbar-title v-if="isLoggedIn" @click="logout">
      <IconBtn icon="mdi-logout" text="Logout" color="marco" @click="logout" />
    </v-toolbar-title> -->

    <!-- <template v-if="extendNavbar" v-slot:extension>
      <v-tabs align-with-title>
        <v-tab>Tab 1</v-tab>
        <v-tab>Tab 2</v-tab>
        <v-tab>Tab 3</v-tab>
      </v-tabs>
    </template> -->
  </v-app-bar>
</template>

<script>
// import IconBtn from "@/components/base/buttons/IconButton";
export default {
  components: {
    // IconBtn,
  },
  data() {
    return {
      extendNavbar: true,
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch("toggleDrawer");
    },
    logout() {
      this.$store.dispatch("auth/LogOut");
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },
};
</script>

<style scoped>
.toolbar-title {
  font-size: 12pt;
}
</style>
