<template>
  <v-container>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="6">
        <v-data-table dense :items="divisions" :headers="tableHeaders">
          <template v-slot:top>
            <v-row justify="center">
              <v-col cols="10">
                <h2 class="marco--text">Company Division Settings</h2>
              </v-col>
              <v-col cols="2">
                <v-btn small color="success" @click="createNewItem()"
                  >New</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template #item.edit="{item}">
            <v-icon small color="green" @click="editDivision(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template #item.delete="{item}">
            <v-icon small color="red" @click="deleteDivision(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-dialog v-model="dialog" persistent max-width="60%" min-width="40%">
          <v-card>
            <v-card-title class="marco white--text"
              >Create Company Division</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row class="my-1">
                  <v-col>
                    <v-combobox
                      :items="childCompanyOptions"
                      v-model="divisionForm.company"
                      label="Company"
                    ></v-combobox>
                    <v-text-field
                      v-for="i in userInputs.length"
                      :key="userInputs[i - 1].text"
                      v-model="divisionForm[userInputs[i - 1].variable]"
                      :counter="255"
                      :label="userInputs[i - 1].text"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row> </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="dialog = false">Cancel</v-btn>
              <v-btn
                v-if="divisionForm.id"
                text
                color="warning"
                @click="postDivision()"
                >Update</v-btn
              >
              <v-btn
                v-if="!divisionForm.id"
                text
                color="success"
                @click="postDivision()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selected: "list",
      divisionForm: {
        id: null,
        name: null,
        description: null,
        company: null,
        companyOption: null,
      },
      userInputs: [
        { text: "Name", variable: "name" },
        { text: "Description", variable: "description" },
      ],
      showTableColumns: ["id", "company_legal_name", "name", "edit", "delete"],
    };
  },
  methods: {
    postDivision: function() {
      let form = new FormData();
      form.append("id", this.divisionForm.id);
      form.append("name", this.divisionForm.name);
      form.append("description", this.divisionForm.description);
      form.append("company", this.divisionForm.company.value);
      this.$store.dispatch("company/postDivision", form);
      this.dialog = false;
    },
    deleteDivision(item) {
      this.$store.dispatch("company/deleteDivision", item.id);
    },
    createNewItem: function() {
      this.clearItem();
      this.dialog = true;
    },
    editDivision: function(item) {
      this.$store.dispatch("company/setDivision", item);
      this.divisionForm = this.selectedDivision;
      this.selectedDivision.company = {
        text: this.selectedDivision.company_legal_name,
        value: this.selectedDivision.id,
      };
      this.divisionForm.company = this.selectedDivision.company;
      this.dialog = true;
    },
    clearItem: function() {
      this.$store.dispatch("company/setDivision", null);
      this.divisionForm = {
        id: null,
        name: null,
        description: null,
        company: null,
        companyOption: null,
      };
    },
  },
  computed: {
    childCompanies() {
      return this.$store.state.company.childCompanies;
    },
    childCompanyOptions() {
      let data = this.childCompanies;
      let options = [];
      data.forEach((item) => {
        options.push({ text: item.legal_name, value: item.id });
      });
      console.log(options);
      return options;
    },
    divisions() {
      return this.$store.state.company.divisions;
    },
    selectedDivision() {
      return this.$store.state.company.selectedDivision;
    },
    tableHeaders() {
      let headers = [];
      let data = this.divisions;
      let tableColumns = this.showTableColumns;

      console.log(data[0]);
      if (data[0]) {
        let fields = Object.keys(data[0]);
        fields.push("edit");
        fields.push("delete");

        console.log(fields);
        fields.forEach((field) => {
          if (tableColumns.includes(field)) {
            let field_txt = field.replace("_", " ").split(" ");
            console.log(field_txt);
            field_txt = field_txt.map((f) => {
              return f.charAt(0).toUpperCase() + f.slice(1);
            });
            field_txt = field_txt.join(" ");
            headers.push({ text: field_txt, value: field });
          }
        });
      }
      console.log(headers);
      return headers;
    },
  },
  mounted: function() {
    this.$store.dispatch("company/getChildCompanies");
    this.$store.dispatch("company/getDivisions");
  },
};
</script>

<style scoped>
.controlButton {
  width: 120px;
  height: 50px;
  border-radius: 25px;
  margin: 10px;
}

.pane {
  width: 100vh;
  /* border: 1px solid lightgrey; */
  padding: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.app-card {
  width: 300px;
}
.app-card img {
  width: 200px;
  align-content: center;
}
</style>
