import RedElectricaAPI from "@/api/red-electrica";

export default {
  namespaced: true,
  state: {
    api: {
      options: {
        languages: null,
        categories: null,
        widgets: null,
        time_trunc: [
          { text: "hour", value: "hour" },
          { text: "day", value: "day" },
          { text: "month", value: "month" },
          { text: "year", value: "year" },
        ],
      },
      results: null,
    },
  },
  getters: {
    languages(state) {
      return state.api.options.languages;
    },
    categories(state) {
      return state.api.options.categories;
    },
    widgets(state) {
      return state.api.options.widgets;
    },
    results(state) {
      return state.api.results;
    },
    timeTrunc(state) {
      return state.api.options.time_trunc;
    },
  },
  mutations: {
    GET_LANGUAGES(state, languages) {
      state.api.options.languages = languages;
    },
    GET_CATEGORIES(state, categories) {
      state.api.options.categories = categories;
    },
    GET_WIDGETS(state, widgets) {
      state.api.options.widgets = widgets;
    },
    GET_RESULTS(state, results) {
      state.api.results = results;
    },
  },
  actions: {
    getLanguages(context) {
      RedElectricaAPI.languages().then((response) => {
        context.commit("GET_LANGUAGES", response.data);
      });
    },
    getCategories(context) {
      RedElectricaAPI.categories().then((response) => {
        context.commit("GET_CATEGORIES", response.data);
      });
    },
    getWidgets(context, category) {
      RedElectricaAPI.widgets(category).then((response) => {
        context.commit("GET_WIDGETS", response.data);
      });
    },
    getData(context, queryParams) {
      RedElectricaAPI.get(queryParams).then((response) => {
        context.commit("GET_RESULTS", response.data);
      });
    },
  },
};
