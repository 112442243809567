<template>
  <v-container fluid>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="6">
        <v-data-table dense :items="childCompanies" :headers="tableHeaders">
          <template v-slot:top>
            <v-row>
              <v-col cols="10">
                <h2 class="marco--text">Child Company List</h2>
              </v-col>
              <v-col cols="2">
                <v-btn small color="success" @click="createNewItem">New</v-btn>
              </v-col>
            </v-row>
          </template>
          <template #item.logo="{item}">
            <v-avatar size="35">
              <v-img :src="item.logo"></v-img>
            </v-avatar>
          </template>
          <template #item.edit="{item}">
            <v-icon small color="green" @click="editChildCompany(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template #item.delete="{item}">
            <v-icon small color="red" @click="deleteObject(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title class="marco white--text"
          >Create Child Company</v-card-title
        >
        <!-- <v-card-text>{{ childCompanyForm }}</v-card-text> -->
        <v-card-text>
          <v-container>
            <v-row class="my-1">
              <v-col cols="4">
                <v-combobox
                  :items="parentCompanyOptions"
                  v-model="childCompanyForm.parent"
                  label="Parent Company"
                ></v-combobox>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.legal_name"
                  :counter="255"
                  label="Legal Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.short_name"
                  :counter="255"
                  label="Short Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.vat_number"
                  :counter="255"
                  label="VAT Number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.address_street"
                  :counter="255"
                  label="Address Street:"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.address_street_num"
                  :counter="255"
                  label="Street Number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.zip_code"
                  :counter="255"
                  label="Zip Code"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.city"
                  :counter="255"
                  label="City"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="childCompanyForm.country"
                  :counter="255"
                  label="Country"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="childCompanyForm.registro_libro"
                  :counter="255"
                  label="Registro Libro"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="childCompanyForm.registro_tomo"
                  :counter="255"
                  label="Registro Tomo"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="childCompanyForm.registro_folio"
                  :counter="255"
                  label="Registro Folio"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="childCompanyForm.registro_hoja"
                  :counter="255"
                  label="Registro Hoja"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  v-model="childCompanyForm.description"
                  :counter="255"
                  label="Description"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col align-self="center" cols="3">
                <h2 class="marco--text">Logo</h2>
                <v-img
                  width="150"
                  height="150"
                  :src="childCompanyForm.logo"
                ></v-img>
                <v-file-input
                  label="Logo File"
                  small-chips
                  v-model="childCompanyLogo"
                  hide-input
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col align-self="center" cols="2"> </v-col>
              <v-col cols="1"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="dialog = false">Cancel</v-btn>
            <v-btn
              v-if="childCompanyForm.id"
              text
              color="warning"
              @click="postChildCompany()"
              >Update</v-btn
            >
            <v-btn
              v-if="!childCompanyForm.id"
              text
              color="success"
              @click="postChildCompany()"
              >Create</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,

      selectedParentCompany: null,
      childCompanyLogo: null,
      childCompanyForm: {
        id: null,
        legal_name: null,
        short_name: null,
        vat_number: null,
        address_street: null,
        address_street_num: null,
        zip_code: null,
        city: null,
        country: null,
        logo: null,
        description: null,
        registro_hoja: null,
        registro_folio: null,
        registro_tomo: null,
        registro_libro: null,
        parent: null,
      },
      showTableColumns: ["id", "legal_name", "short_name", "edit", "delete"],
      tableHeaders: [
        { text: "Logo", value: "logo" },
        { text: "Legal Name", value: "legal_name" },
        { text: "Short Name", value: "short_name" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
    };
  },
  methods: {
    postChildCompany: function() {
      let form = new FormData();
      form.append("id", this.childCompanyForm.id);
      form.append("legal_name", this.childCompanyForm.legal_name);
      form.append("short_name", this.childCompanyForm.short_name);
      form.append("vat_number", this.childCompanyForm.vat_number);
      form.append("address_street", this.childCompanyForm.address_street);
      form.append(
        "address_street_num",
        this.childCompanyForm.address_street_num
      );
      form.append("zip_code", this.childCompanyForm.zip_code);
      form.append("city", this.childCompanyForm.city);
      form.append("country", this.childCompanyForm.country);
      //   form.append("logo", this.childCompanyForm.logo);
      form.append("description", this.childCompanyForm.description);
      form.append("registro_hoja", this.childCompanyForm.registro_hoja);
      form.append("registro_folio", this.childCompanyForm.registro_folio);
      form.append("registro_tomo", this.childCompanyForm.registro_tomo);
      form.append("registro_libro", this.childCompanyForm.registro_libro);
      form.append("parent", this.childCompanyForm.parent.value);

      if (this.childCompanyLogo) {
        form.append("logo", this.childCompanyLogo);
      }

      this.$store.dispatch("company/postChildCompany", form);
      this.dialog = false;
    },
    deleteObject(item) {
      this.$store.dispatch("company/deleteChildCompany", item.id);
    },
    createNewItem: function() {
      this.clearItem();
      this.dialog = true;
    },
    async editChildCompany(item) {
      await this.$store.dispatch("company/setChildCompany", item);
      this.childCompanyForm = item;
      this.childCompanyForm.parent = {
        text: item.parent.name_long,
        value: item.parent.id,
      };
      this.dialog = true;
    },
    clearItem: function() {
      this.$store.dispatch("company/setChildCompany", null);
      this.childCompanyForm = {
        id: null,
        legal_name: null,
        short_name: null,
        vat_number: null,
        address_street: null,
        address_street_num: null,
        zip_code: null,
        city: null,
        country: null,
        logo: null,
        description: null,
        registro_hoja: null,
        registro_folio: null,
        registro_tomo: null,
        registro_libro: null,
        parent: null,
      };
    },
  },
  computed: {
    parentCompanies() {
      return this.$store.state.company.parentCompanies;
    },
    parentCompanyOptions() {
      let data = this.$store.state.company.parentCompanies;
      let options = [];
      data.forEach((item) => {
        options.push({ text: item.name_long, value: item.id });
      });
      console.log(options);
      return options;
    },
    childCompanies() {
      return this.$store.state.company.childCompanies;
    },
    selectedChildCompany() {
      return this.$store.state.company.selectedChildCompany;
    },
    // tableHeaders() {
    //   let headers = [];
    //   let data = this.childCompanies;
    //   let tableColumns = this.showTableColumns;

    //   console.log(data[0]);
    //   if (data[0]) {
    //     let fields = Object.keys(data[0]);
    //     fields.push("edit");
    //     fields.push("delete");

    //     console.log(fields);
    //     fields.forEach((field) => {
    //       if (tableColumns.includes(field)) {
    //         let field_txt = field.replace("_", " ").split(" ");
    //         console.log(field_txt);
    //         field_txt = field_txt.map((f) => {
    //           return f.charAt(0).toUpperCase() + f.slice(1);
    //         });
    //         field_txt = field_txt.join(" ");
    //         headers.push({ text: field_txt, value: field });
    //       }
    //     });
    //   }
    //   console.log(headers);
    //   return headers;
    // },
  },
  mounted: function() {
    this.$store.dispatch("company/getChildCompanies");
  },
};
</script>

<style scoped>
.controlButton {
  width: 120px;
  height: 50px;
  border-radius: 25px;
  margin: 10px;
}

.pane {
  width: 100vh;
  /* border: 1px solid lightgrey; */
  padding: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.user-form-input {
  margin: 10px;
}

.app-card {
  width: 300px;
}
.app-card img {
  width: 200px;
  align-content: center;
}
</style>
