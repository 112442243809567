<template>
  <v-tab-item :value="tabNum">
    <v-card flat>
      <v-card-text>
        <v-data-table
          v-if="portfolioTasks"
          dense
          :headers="portfolioTaskTable.headers"
          :items="portfolioTasks"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="getChipColor(item)" dark small>
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:item.results="{ item }">
            <v-progress-circular
              v-if="item.status === 'PROGRESS'"
              :rotate="-90"
              :size="25"
              :width="5"
              :value="item.results ? item.results.percent : 100"
              color="teal"
            >
            </v-progress-circular>
            <!-- <div v-if="item.status === 'PROGRESS'">
              {{ item.results.current }} / {{ item.results.total }}
            </div> -->
            <v-icon v-if="item.status === 'FAILURE'">
              mdi-information-outline
            </v-icon>
          </template>
          <template v-slot:item.progress="{ item }">
            <div v-if="item.status === 'PROGRESS'">
              {{ item.results.current }} / {{ item.results.total }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="getPortfolioTasks">Refresh Task List</v-btn>
      </v-card-actions>
    </v-card>
  </v-tab-item>
</template>

<script>
let storeModule = "realEstatePortfolioDashboard";
export default {
  props: ["tabNum"],
  data() {
    return {
      portfolioTaskTable: {
        headers: [
          { text: "Task", value: "task", filterable: true },
          { text: "Name", value: "name", filterable: true },
          { text: "Status", value: "status", filterable: true },
          {
            text: "Results",
            value: "results",
            align: "center",
            filterable: true,
          },
          //   { text: "ID", value: "task_id", filterable: true },
          { text: "Progress", value: "progress", filterable: true },
          { text: "Created", value: "date_created", filterable: true },
          { text: "Completed", value: "date_done", filterable: true },
        ],
      },
    };
  },
  methods: {
    getChipColor(task) {
      if (task.status === "SUCCESS") {
        return "green";
      } else if (task.status === "FAILURE") {
        return "red";
      } else {
        return "blue";
      }
    },
    getPortfolioTasks() {
      this.$store.dispatch(`${storeModule}/getPortfolioTasks`);
    },
  },
  computed: {
    // portfolioTaskTable() {
    //   return this.$store.getters[`${storeModule}/portfolioTaskTable`];
    // },
    portfolioTasks() {
      return this.$store.getters[`${storeModule}/portfolioTasks`];
    },
  },
};
</script>

<style></style>
