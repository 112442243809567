import Api from "./Api";

export default {
  clientCredentials(params) {
    return Api.get("credentials", { params: params });
  },
  search(params) {
    return Api.get("credentials", { params: params });
  },
};
