<template>
  <AppLayout>
    <v-container slot="main" fluid id="main-container">
      <v-row align="left" justify="left">
        <v-col align="center" cols="4">
          <v-card elevation="9">
            <v-card-title class="py-2 px-10">
              <h2 class="text-left marco--text">
                Geo Search
              </h2>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-0">
              <v-text-field
                v-model="address"
                style="padding:15px"
                append-icon="mdi-magnify"
                prepend-icon="mdi-earth"
                placeholder="Input Address"
                solo
                @click:append="clickMagnify"
              >
              </v-text-field>
            </v-card-text>

            <!-- <v-container>
                <v-row>
                  <v-col>
                    <v-container id="input-usage" fluid>
                      <v-row justify="center">
                        <v-col cols="9">
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text> -->
          </v-card>
        </v-col>
        <v-col>
          <v-card class="marco--text" elevation="6">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <ol>
                      <li>Demography Score: x/17</li>
                      <li>Income Per Capita Score: x/17</li>
                      <li>Real Estate Score: x/17</li>
                    </ol>
                  </v-col>
                  <v-col>
                    <ol>
                      <li>Demography Score: x/52</li>
                      <li>Income Per Capita Score: x/52</li>
                      <li>Real Estate Score: x/52</li>
                    </ol>
                  </v-col>
                  <v-col>
                    <ol>
                      <li>Demography Score: x/8300</li>
                      <li>Income Per Capita Score: x/8300</li>
                      <li>Real Estate Score: x/8300</li>
                    </ol>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-text> </v-card-text>
            <v-card-text> </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-sheet color="green" elevation="6" height="90%" v-if="ineStats">
            <v-tabs v-model="tab">
              <v-tab v-for="level in Object.keys(ineStats)" :key="level">
                {{ level }}
              </v-tab>
              <v-tab>Selected ({{ selected.length }})</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="level in Object.keys(ineStats)" :key="level">
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="group in Object.keys(ineStats[level])"
                    :key="group"
                  >
                    <v-expansion-panel-header>
                      <h3 class="marco--text">{{ group }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :key="group"
                        :headers="headers"
                        :items="ineStats[level][group]"
                        item-key="id"
                        class="elevation-1"
                        dense
                        show-select
                        :single-select="false"
                        v-model="selected[group]"
                      >
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :headers="headers"
                  :items="selected"
                  item-key="id"
                  class="elevation-1"
                  dense
                  show-select
                  :single-select="false"
                  v-model="selected"
                >
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-col>
        <v-col cols="8">
          <v-sheet color="green" elevation="6" height="90%">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="operacion in Object.keys(selected)"
                :key="operacion"
              >
                <v-expansion-panel-header class="marco white--text">
                  <h3>{{ operacion }}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        v-for="serie in selected[operacion]"
                        :key="serie"
                      >
                        <v-card>
                          <v-toolbar class="marco white--text">
                            <v-toolbar-title>
                              {{ serie.nombre }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-icon>mdi-close</v-icon>
                          </v-toolbar>
                          <v-card-text>
                            {{ serie.nombre }}
                          </v-card-text>
                          <v-card-text>
                            <IneColumnChart
                              :hcTitle="null"
                              :serie="serie.cod"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- {{ apiData }}
            {{ ineStats }} -->
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";
import IneColumnChart from "@/components/databank/ine/IneColumnChart.vue";

import GeoAPI from "@/api/geo.js";

export default {
  components: {
    AppLayout,
    IneColumnChart,
  },
  data() {
    return {
      address: "Raimundo Fernandez Villaverde 26, 28003 Madrid",
      googleApiData: "Raimundo Fernandez Villaverde 26, 28003 Madrid",
      geoApiData: null,
      ineStats: null,
      apiData: {
        demographics: [],
        population: [],
      },
      tab: 0,
      headers: [
        // { text: "Series", value: "cod" },
        { text: "Name", value: "nombre" },
      ],
      selected: {},
    };
  },
  methods: {
    clickMagnify() {
      GeoAPI.geolocateAddress(this.address)
        .then((response) => {
          this.googleApiData = response.data.results.google;
          this.geoApiData = response.data.results.geo;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      GeoAPI.getLocationStats(this.address)
        .then((response) => {
          this.ineStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#main-container {
  /* background-image: url("../../assets/background-workbench.jpeg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
