<template>
  <AppLayout>
    <v-container slot="main" fluid class="fill-height" id="main-container">
      <v-row align="center" justify="center">
        <v-col align="center" cols="6">
          <v-card elevation="6">
            <v-card-title>
              <v-spacer></v-spacer>
              <h1 class="text-center marco--text">
                Welcome to MGX Advisors
              </h1>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-for="(icon, index) in icons" :key="index" cols="3">
                    <v-card
                      elevation="16"
                      height="23vh"
                      @click="$router.push(icon.url)"
                    >
                      <v-card-text>
                        <v-spacer></v-spacer>
                        <v-icon color="marco" size="12vh">{{
                          icon.icon
                        }}</v-icon>
                        <v-spacer></v-spacer>
                      </v-card-text>
                      <v-card-text>
                        <h2 class="marco--text" style="font-size:2vh">
                          {{ icon.title }}
                        </h2></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout0.vue";

export default {
  components: { AppLayout },
  data() {
    return {
      icons: [
        {
          title: "Company Settings",
          icon: "mdi-wrench-outline",
          url: { name: "company-home" },
        },
        {
          title: "Clients",
          icon: "mdi-account-outline",
          url: { name: "client-home" },
        },
        // {
        //   title: "Contacts",
        //   //   icon: "mdi-home-map-marker",
        //   icon: "mdi-card-account-phone",
        //   url: { name: "contact-dashboard" },
        // },

        {
          title: "Deal Tracker",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-handshake",
          url: { name: "deals" },
        },
        {
          title: "News Room",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-newspaper-variant-multiple",
          url: { name: "news" },
        },

        {
          title: "Advisory Workbench",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-desktop-classic",
          url: { name: "workbench" },
        },
        {
          title: "Data Lake",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-database-outline",
          url: { name: "databank" },
        },
        {
          title: "Credentials",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-seal-variant",
          url: { name: "credentials" },
        },
        {
          title: "Tasks",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-calendar-check-outline",
          url: { name: "user-tasks" },
        },
        {
          title: "Marketplace",
          //   icon: "mdi-home-map-marker",
          icon: "mdi-shopping-outline",
          url: { name: "marketplace" },
        },
      ],
    };
  },
};
</script>

<style scoped>
#main-container {
  background-image: url("../assets/skyscraper2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
