<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card max-width="80%">
          <v-card-title class="marco white--text">
            {{ catastroData.referencia.referencia_catastral }}
            <v-spacer></v-spacer>
            {{ catastroData.referencia.domicilio_tributario }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    readonly
                    label="Main Use"
                    :value="catastroData.referencia.uso_principal"
                  ></v-text-field>
                </v-col>
                <v-col v-if="catastroData.referencia.antiguedad">
                  <v-text-field
                    readonly
                    label="Construction Year"
                    :value="catastroData.referencia.antiguedad"
                  ></v-text-field>
                </v-col>
                <v-col v-if="catastroData.referencia.superficie">
                  <v-text-field
                    readonly
                    label="Built Area"
                    :value="catastroData.referencia.superficie"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    label="% Ownership"
                    :value="catastroData.referencia.coef_particip"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="catastroData.unidades_constructivas.length > 0">
                  <v-data-table
                    class="elevation-2"
                    :headers="udsConstrTable.headers"
                    :items="catastroData.unidades_constructivas"
                    :hide-default-footer="true"
                  >
                    <template v-slot:top>
                      <h2 class="ma-3 text-center marco--text">
                        Construction Units
                      </h2>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col v-if="catastroData.subparcelas.length > 0">
                  <v-data-table
                    class="elevation-2"
                    :headers="subparcelasTable.headers"
                    :items="catastroData.subparcelas"
                    :hide-default-footer="true"
                  >
                    <template v-slot:top>
                      <h2 class="ma-3 text-center">Parcel Information</h2>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["catastroData"],
  data() {
    return {
      udsConstrTable: {
        headers: [
          { text: "Use", value: "uso", align: "start" },
          { text: "Portal", value: "portal", align: "end" },
          { text: "Stair", value: "escalera", align: "end" },
          { text: "Door", value: "puerta", align: "end" },
          { text: "Area", value: "superficie", align: "end" },
        ],
      },
      subparcelasTable: {
        headers: [
          { text: "Use", value: "uso", align: "start" },
          { text: "Portal", value: "portal", align: "end" },
          { text: "Stair", value: "escalera", align: "end" },
          { text: "Door", value: "puerta", align: "end" },
          { text: "Area", value: "superficie", align: "end" },
        ],
      },
    };
  },
};
</script>

<style></style>
