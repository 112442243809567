<template>
  <div>
    <div>
      <StackedChart
        :hcTitle="title"
        :hcSubtitle="subtitle"
        :hcData="seriesData"
        :AxisCategories="categories"
        :xAxisTitle="null"
        yAxisTitle="Y-Axis"
      />
    </div>
  </div>
</template>

<script>
// import VBarChart from "@/components/charts/highcharts/VBarChart.vue";
import StackedChart from "@/components/charts/highcharts/StackedColumnChart.vue";

export default {
  props: ["apiData"],
  components: { StackedChart }, // VBarChart
  computed: {
    // categories() {},
    title() {
      return this.apiData.data.attributes.title;
    },
    subtitle() {
      return this.apiData.data.attributes["last-update"];
    },
    categories() {
      let categories;
      if (this.apiData) {
        categories = this.apiData.included[0].attributes.values.map((item) => {
          return item.datetime;
        });
      } else {
        categories = [];
      }
      return categories;
    },
    seriesNames() {
      let categories;
      if (this.apiData) {
        categories = this.apiData.included.map((item) => {
          return item.type;
        });
      } else {
        categories = [];
      }
      return categories;
    },
    seriesData() {
      let series;
      if (this.apiData) {
        series = this.apiData.included.map((item) => {
          return {
            name: item.type,
            color: item.attributes.color,
            data: item.attributes.values.map((element) => {
              return element.percentage * 100;
            }),
          };
        });
      } else {
        series = [];
      }
      return series;
    },
    chartData() {
      return this.apiData.included;
    },
  },
};
</script>

<style></style>
