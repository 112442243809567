<template>
  <BaselineLayout>
    <template slot="navbarContent">
      <v-toolbar-title>
        <v-btn
          text
          @click="$router.push({ name: 'real-estate-portfolio-dashboard' })"
          >Dashboard</v-btn
        >
      </v-toolbar-title>
      <v-toolbar-title>
        <v-toolbar-title>
          <v-btn text @click="$router.push({ name: 'real-estate-portfolio' })"
            >Workbench</v-btn
          >
        </v-toolbar-title>
      </v-toolbar-title>
    </template>
    <div id="container" slot="main">
      <GoogleMapPortfolio
        :assets="portfolioAssetsFull ? portfolioAssetsFull : []"
      />
    </div>
  </BaselineLayout>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
// import SideBar from "@/components/real-estate/SideBar.vue";
import GoogleMapPortfolio from "@/components/maps/google/PortfolioMap.vue";

let storeModule = "realEstatePortfolioDashboard";

export default {
  components: { BaselineLayout, GoogleMapPortfolio }, //SideBar
  computed: {
    portfolioAssetsFull() {
      return this.$store.getters[`${storeModule}/portfolioAssetsFull`];
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}
</style>
