<template>
  <v-toolbar flat color="marco" dark>
    <v-toolbar-title>Portfolio Dashboard</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-title v-if="portfolio">
      <v-icon large @click="refreshPortfolioInformation"
        >mdi-refresh</v-icon
      ></v-toolbar-title
    >
  </v-toolbar>
</template>

<script>
export default {
  methods: {
    refreshPortfolioInformation() {
      this.$store.dispatch[
        "realEstatePortfolioDashboard/refreshPortfolioInformation"
      ];
    },
  },
  computed: {
    portfolio() {
      return this.$store.getters["realEstatePortfolioDashboard/portfolio"];
    },
  },
};
</script>

<style></style>
