<template>
  <div>
    <v-navigation-drawer
      slot="menu-drawer"
      :value="toolDrawer"
      absolute
      temporary
      color="warning"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Dashboards</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in toolDrawerItems"
          :key="item.title"
          link
          router
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toolDrawerItems: [
        {
          title: "Real Estate",
          icon: "mdi-home",
          link: { name: "ine-dashboard-real-estate" },
        },
        {
          title: "Hospitality",
          icon: "mdi-bed",
          link: { name: "ine-dashboard-hospitality" },
        },
      ],
    };
  },
  methods: {
    // toggletoolDrawer() {
    //   this.$store.dispatch("main/toggletoolDrawer");
    // },
  },
  computed: {
    toolDrawer() {
      return this.$store.getters["main/toolDrawer"];
    },
  },
};
</script>

<style></style>
