<template>
  <v-tab-item :value="tabNum">
    <v-container id="container" fluid>
      <v-row justify="center">
        <v-col align="center">
          <GoogleMapPortfolio
            :assets="portfolioAssetsFull ? portfolioAssetsFull : []"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-tab-item>
</template>

<script>
import GoogleMapPortfolio from "@/components/maps/google/PortfolioMap.vue";

let storeModule = "realEstatePortfolioDashboard";
export default {
  props: ["tabNum"],
  components: { GoogleMapPortfolio },
  computed: {
    portfolioAssetsFull() {
      return this.$store.getters[`${storeModule}/portfolioAssetsFull`];
    },
  },
  //   mounted() {
  // 	  this.$store.dispatch(`${storeModule}/getPortfolioAssetsFull`)
  //   },
};
</script>

<style scoped>
#container {
  height: 60%;
  width: 100%;
}
</style>
