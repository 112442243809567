<template>
  <v-container>
    <v-row dense justify="center">
      <IconBtn
        v-if="readonly"
        @click="readonly = false"
        text="Edit"
        icon="mdi-pencil"
        small
      />
      <IconBtn
        v-if="!readonly"
        color="error white--text"
        @click="cancelUpdate"
        text="Cancel"
        icon="mdi-cancel"
        small
      />
      <IconBtn
        v-if="!readonly"
        color="success white--text"
        @click="postClient"
        text="Update"
        icon="mdi-check"
        small
      />
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-row justify="center">
          <img style="height:160px" :src="form.logo" alt="logo imgage" />
        </v-row>
        <v-row justify="start">
          <v-file-input
            v-if="!readonly"
            v-model="file"
            chips
            show-size
            label="Select File"
            class="text-subtitle-2"
          >
          </v-file-input>
        </v-row>
      </v-col>
      <v-col cols="9">
        <v-container>
          <v-row dense>
            <v-col>
              <TextField
                outlined
                label="Legal Name"
                v-model="form.legal_name"
                :readonly="readonly"
                :filled="!readonly"
                :required="true"
              />
            </v-col>
            <v-col>
              <TextField
                outlined
                label="Short Name"
                v-model="form.short_name"
                :readonly="readonly"
                :filled="!readonly"
                :required="true"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <Combobox
                outlined
                label="Client Type"
                v-model="form.client_type"
                :readonly="readonly"
                :filled="!readonly"
                :items="clientTypes"
                item-text="client_type"
                item-value="id"
                :multiple="true"
              />
            </v-col>
            <v-col>
              <TextField
                outlined
                label="VAT"
                v-model="form.vat_number"
                :readonly="readonly"
                :filled="!readonly"
                :required="true"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <Combobox
                outlined
                label="Tier"
                v-model="form.tier"
                :readonly="readonly"
                :filled="!readonly"
                :items="tiers"
                item-text="text"
                item-value="value"
                :multiple="false"
              />
            </v-col>
            <v-col>
              <TextField
                outlined
                label="URL"
                v-model="form.url"
                :readonly="readonly"
                :filled="!readonly"
                :required="true"
                append-outer-icon="mdi-web"
                @click:append-outer="followUrl"
              ></TextField>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <TextArea
                label="Description"
                v-model="form.description"
                :readonly="readonly"
                :filled="!readonly"
                :counter="false"
                outlined
                class="text-subtitle-2"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextField from "@/components/base/TextField.vue";
import TextArea from "@/components/base/TextArea.vue";
import Combobox from "@/components/base/Combobox.vue";

import IconBtn from "@/components/base/buttons/IconButton.vue";

import ClientsAPI from "@/api/clients";

export default {
  components: {
    TextField,
    TextArea,
    Combobox,
    IconBtn,
  },
  props: {
    clientId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    $props: {
      handler() {
        this.handleFormContent();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      readonly: true,
      userForm: {},
      form: {
        id: null,
        legal_name: null,
        short_name: null,
        tier: null,
        vat_number: null,
        description: null,
        url: null,
        logo: null,
        address: null,
        client_type: [],
        parent_company: [],
      },
      file: null,

      tiers: [
        { text: "Tier 1", value: "1" },
        { text: "Tier 2", value: "2" },
        { text: "Tier 3", value: "3" },
        { text: "Tier 4", value: "4" },
      ],
    };
  },
  methods: {
    cancelUpdate() {
      this.resetClientForm();
      this.readonly = true;
    },
    followUrl() {
      window.open(this.form.url, "_blank");
    },
    getClientInfo() {
      ClientsAPI.getClient(this.clientId).then((response) => {
        this.clientData = response.data;
        Object.keys(this.form).forEach((key) => {
          this.form[key] = response.data[key];
        });
      });
    },
    handleFormContent() {
      if (this.clientId) {
        this.getClientInfo();
      } else {
        this.resetClientForm();
      }
    },
    async postClient() {
      let form = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) {
          if (typeof this.form[key] === "object") {
            if (this.form[key].length > 0) {
              this.form[key].forEach((element) => {
                form.append(key, element);
              });
              //   form.append(key, this.form[key]);
            }
          } else {
            form.append(key, this.form[key]);
          }
        }
      });
      if (this.file) {
        form.set("logo", this.file);
        // this.form["logo"] = this.file;
      } else {
        form.delete("logo");
        // this.form["logo"] = null;
      }

      await this.$store.dispatch("clients/postClient", form);
      this.readonly = true;
      this.getClientInfo();
      //   await this.$store.dispatch(
      //     "clients/getClientData",
      //     this.$route.params.id
      //   );
      await this.resetClientForm();
      this.$store.dispatch("snackbarSuccess", "Client Upated Successfuly!");
    },
    resetClientForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.clientData[key];
      });
    },
  },
  computed: {
    clientTypes() {
      return this.$store.state.clients.clientTypes;
    },
    // clientData() {
    //   return this.$store.getters["clients/clientData"];
    // },
  },
  beforeMount() {
    this.$store.dispatch("clients/getClientTypes");
    this.handleFormContent();
  },
};
</script>

<style></style>
