<template>
  <div>
    <h2 v-if="!apiData">No Data Available</h2>
    <ColumnChart
      :hcTitle="hcTitle"
      :hcSubtitle="hcSubtitle"
      :hcData="hcData"
      :AxisCategories="AxisCategories"
      :xAxisTitle="xAxisTitle"
      :yAxisTitle="yAxisTitle"
      :dataLabels="false"
    />
  </div>
</template>

<script>
import ColumnChart from "@/components/charts/highcharts/ColumnChart.vue";
// import ColumnChart from "@/components/charts/highcharts/SplineChart.vue";
import IneAPI from "@/api/ine";
export default {
  components: { ColumnChart },
  props: ["hcTitle", "serie"],
  data() {
    return {
      apiData: null,
    };
  },
  methods: {
    getApiData() {
      IneAPI.seriesData(this.serie).then((response) => {
        this.apiData = response.data;
      });
    },
  },
  watch: {
    $props: {
      handler() {
        this.getApiData;
      },
      deep: true,
    },
    serie: function(newVal, oldVal) {
      newVal;
      oldVal;
      this.getApiData();
    },
  },
  computed: {
    AxisCategories() {
      let categories;
      if (this.apiData) {
        categories = this.apiData.Data.map((item) => {
          return item.period;
        });
      } else {
        categories = [];
      }
      return categories;
    },
    hcSubtitle() {
      if (this.apiData) {
        return this.apiData.Unidad.Nombre;
      } else {
        return null;
      }
    },
    hcData() {
      if (this.apiData) {
        let series = {
          name: this.apiData.Nombre,
          color: "#18515E",
          data: this.apiData.Data.map((item) => {
            return item.value;
          }),
        };
        return series;
      } else {
        return null;
      }
    },
    xAxisTitle() {
      return null;
    },
    yAxisTitle() {
      if (this.apiData) {
        return this.apiData.Unidad.nombre;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getApiData();
  },
};
</script>

<style></style>
