<template>
  <v-app>
    <baseline-layout>
      <v-container slot="main">
        <v-row justify="center">
          <v-col align="center">
            <h1>User Folder</h1>
          </v-col>
        </v-row>
        <v-row>
          {{ userFolder }}
        </v-row>
        <v-row>
          <v-treeview
            v-model="tree"
            :open="initiallyOpen"
            :items="items"
            activatable
            item-key="name"
            open-on-click
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.file">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <v-icon v-else>
                {{ files[item.file] }}
              </v-icon>
            </template>
          </v-treeview>
        </v-row>
      </v-container>
    </baseline-layout>
  </v-app>
</template>

<script>
import BaselineLayout from "@/layouts/BaselineLayout.vue";
import UserFolder from "@/api/user-folder";
export default {
  components: {
    BaselineLayout,
  },
  data() {
    return {
      userFolder: null,
      initiallyOpen: ["public"],
      files: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xls: "mdi-file-excel",
      },
      tree: [],
      items: [
        {
          name: ".git",
        },
        {
          name: "node_modules",
        },
        {
          name: "public",
          children: [
            {
              name: "static",
              children: [
                {
                  name: "logo.png",
                  file: "png",
                },
              ],
            },
            {
              name: "favicon.ico",
              file: "png",
            },
            {
              name: "index.html",
              file: "html",
            },
          ],
        },
        {
          name: ".gitignore",
          file: "txt",
        },
        {
          name: "babel.config.js",
          file: "js",
        },
        {
          name: "package.json",
          file: "json",
        },
        {
          name: "README.md",
          file: "md",
        },
        {
          name: "vue.config.js",
          file: "js",
        },
        {
          name: "yarn.lock",
          file: "txt",
        },
      ],
    };
  },
  methods: {
    getUserFolder() {
      UserFolder.treeview().then((response) => {
        this.userFolder = response.data;
      });
    },
  },
  mounted() {
    this.getUserFolder();
  },
};
</script>

<style></style>
