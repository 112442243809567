<template>
  <div>
    <template>
      <v-bottom-navigation shift>
        <v-btn small @click="setPageView('search')">Search Criteria</v-btn>
        <v-btn small @click="addBasemapGallery">Basemap Gallery</v-btn>
        <v-btn small @click="removeBasemapGallery"
          >Remove Basemap Gallery</v-btn
        >

        <v-btn small @click="zoomIn">Zoom In</v-btn>
        <v-btn small @click="zoomOut">Zoom Out</v-btn>
        <v-btn small @click="addGraphicsLayerComps">Graphics Layer Comps</v-btn>
        <v-btn small @click="addGraphicsLayerPolygon"
          >Graphics Layer Polygon</v-btn
        >
        <v-btn small @click="addGraphicsLayerPolygons2"
          >Graphics Layer Polygon 2</v-btn
        >

        <v-btn small @click="loadProvince">Load Shape</v-btn>
        <v-btn small @click="addWMSEstacionesServicio">WMS Layer</v-btn>

        <!-- <v-btn>
          <span>Video</span>

          <v-icon>mdi-television-play</v-icon>
        </v-btn>

        <v-btn>
          <span>Music</span>

          <v-icon>mdi-music-note</v-icon>
        </v-btn>

        <v-btn>
          <span>Book</span>

          <v-icon>mdi-book</v-icon>
        </v-btn>

        <v-btn>
          <span>Image</span>

          <v-icon>mdi-image</v-icon>
        </v-btn> -->
      </v-bottom-navigation>
    </template>
    <v-container fluid>
      <v-row fill-height>
        <v-col fill-width>
          <div id="viewDiv"></div>
          <div id="mapWidgets"></div>
        </v-col>
      </v-row>
      <v-row>
        <div></div>
      </v-row>
    </v-container>

    <!-- <div id="mapWidgets"></div> -->

    <!-- <div id="mapWidgets"></div> -->
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import Shapes from "@/api/shapes";
// import SideBar from "@/components/real-estate/SideBar.vue";

export default {
  name: "web-map",
  components: {
    // SideBar,
  },
  data() {
    return {
      map: null,
      basemap: "topo-vector",
      polygons: [],
    };
  },
  methods: {
    addBasemapToggle() {
      loadModules(["esri/widgets/BasemapToggle"], { css: true }).then(
        ([BasemapToggle]) => {
          var toggle = new BasemapToggle({
            // 2 - Set properties
            view: this.view, // view that provides access to the map's 'topo-vector' basemap
            nextBasemap: "hybrid", // allows for toggling to the 'hybrid' basemap
          });
          this.view.ui.add(toggle, "top-right");
        }
      );
    },
    addBasemapGallery() {
      loadModules(["esri/widgets/BasemapGallery"], { css: true }).then(
        ([BasemapGallery]) => {
          this.basemapGallery = new BasemapGallery({
            view: this.view,
          });
          this.view.ui.add(this.basemapGallery, {
            position: "top-right",
          });
        }
      );
    },
    addGraphicsLayerComps() {
      //   let vm = this;
      loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"], {
        css: true,
      }).then(([GraphicsLayer, Graphic]) => {
        this.places = [
          {
            id: 1,
            address: "Address 1",
            longitude: -3.70256,
            latitude: 40.4165,
          },
          { id: 2, address: "Address 2", longitude: -3.7027, latitude: 40.42 },
          { id: 3, address: "Address 3", longitude: -3.7019, latitude: 40.39 },
        ];

        let graphics = this.places.map(function(place) {
          return new Graphic({
            attributes: {
              id: place.id,
              address: place.address,
              longitude: place.longitude,
              latitude: place.latitude,
            },
            geometry: {
              type: "point",
              longitude: place.longitude,
              latitude: place.latitude,
            },
            symbol: {
              type: "simple-marker",
              style: "circle",
              color: "blue",
              size: 10,
              outline: {
                color: "blue", // white
                width: 1,
              },
            },
            popupTemplate: {
              title: `Comp Information: ${place.id}`,
              //   content: `<ul> <li>${place.address}</li><li>${place.longitude}</li><li>${place.latitude}</li> </ul>`,
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: "id",
                      label: "ID",
                      isEditable: false,
                      tooltip: "The Comp ID",
                      visible: true,
                      format: null,
                      stringFieldOption: "text-box",
                    },
                    {
                      fieldName: "address",
                      label: "Address",
                      isEditable: false,
                      tooltip: "The comp Address",
                      visible: true,
                      format: null,
                      stringFieldOption: "text-box",
                    },
                    {
                      fieldName: "longitude",
                      label: "Longitude",
                      isEditable: false,
                      tooltip: "The comp Longitude",
                      visible: true,
                      format: null,
                      stringFieldOption: "text-box",
                    },
                    {
                      fieldName: "latitude",
                      label: "Latitude",
                      isEditable: false,
                      tooltip: "The comp Latitude",
                      visible: true,
                      format: null,
                      stringFieldOption: "text-box",
                    },
                  ],
                },
                {
                  type: "media",
                  mediaInfos: [
                    {
                      title: "<b>Mexican Fan Palm</b>",
                      type: "image", // Autocasts as new ImageMediaInfo object
                      caption: "tree species",
                      // Autocasts as new ImageMediaInfoValue object
                      value: {
                        sourceURL:
                          "https://www.sunset.com/wp-content/uploads/96006df453533f4c982212b8cc7882f5-800x0-c-default.jpg",
                      },
                    },
                  ],
                },
              ],
            },
          });
        });

        let graphicsLayer = new GraphicsLayer({
          title: "Asking Comps",
        });
        graphics.forEach((graphic) => {
          graphicsLayer.add(graphic);
        });
        this.map.layers.add(graphicsLayer);
        // this.view.popup.watch("selectedFeature", function(graphic) {
        //   console.log(graphic);
        //   console.log(graphic.getEffectivePopupTemplate());
        //   if (graphic) {
        //     var graphicTemplate = graphic.getEffectivePopupTemplate();
        //     graphicTemplate.actions.items[0].visible = graphic.attributes
        //       .website
        //       ? true
        //       : false;
        //   }
        // });
      });
    },
    addGraphicsLayerPolygon() {
      //   let vm = this;
      loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"], {
        css: true,
      }).then(([GraphicsLayer, Graphic]) => {
        Shapes.provincias("28").then((response) => {
          console.log(response.data);
          let polygon = new Graphic({
            geometry: {
              type: "polygon",
              rings: response.data.geom.coordinates[1],
            },
            symbol: {
              type: "simple-fill",
              style: "solid",

              color: [24, 81, 94, 0.2],
              outline: {
                color: [24, 81, 94, 0.2],
                width: 1,
              },
            },
            popupTemplate: {
              title: `${response.data.natlevname}: ${response.data.nameunit}`,
              //   content: `<ul> <li>${place.address}</li><li>${place.longitude}</li><li>${place.latitude}</li> </ul>`,
              // content: [
              //   {
              //     type: "fields",
              //     fieldInfos: [
              //       {
              //         fieldName: "id",
              //         label: "ID",
              //         isEditable: false,
              //         tooltip: "The Comp ID",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "address",
              //         label: "Address",
              //         isEditable: false,
              //         tooltip: "The comp Address",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "longitude",
              //         label: "Longitude",
              //         isEditable: false,
              //         tooltip: "The comp Longitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "latitude",
              //         label: "Latitude",
              //         isEditable: false,
              //         tooltip: "The comp Latitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //     ],
              //   },
              //   {
              //     type: "media",
              //     mediaInfos: [
              //       {
              //         title: "<b>Mexican Fan Palm</b>",
              //         type: "image", // Autocasts as new ImageMediaInfo object
              //         caption: "tree species",
              //         // Autocasts as new ImageMediaInfoValue object
              //         value: {
              //           sourceURL:
              //             "https://www.sunset.com/wp-content/uploads/96006df453533f4c982212b8cc7882f5-800x0-c-default.jpg",
              //         },
              //       },
              //     ],
              //   },
              // ],
            },
          });
          let graphicsLayer = new GraphicsLayer({
            title: "Polygons",
          });

          graphicsLayer.add(polygon);

          this.map.layers.add(graphicsLayer);
        });
      });
    },
    addGraphicsLayerPolygons() {
      //   let vm = this;
      loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"], {
        css: true,
      }).then(([GraphicsLayer, Graphic]) => {
        Shapes.provincias("28").then((response) => {
          console.log(response.data);
          let polygon = new Graphic({
            geometry: {
              type: "polygon",
              rings: response.data.geom.coordinates[1],
            },
            symbol: {
              type: "simple-fill",
              style: "solid",

              color: [24, 81, 94, 0.2],
              outline: {
                color: [24, 81, 94, 0.2],
                width: 1,
              },
            },
            popupTemplate: {
              title: `${response.data.natlevname}: ${response.data.nameunit}`,
              //   content: `<ul> <li>${place.address}</li><li>${place.longitude}</li><li>${place.latitude}</li> </ul>`,
              // content: [
              //   {
              //     type: "fields",
              //     fieldInfos: [
              //       {
              //         fieldName: "id",
              //         label: "ID",
              //         isEditable: false,
              //         tooltip: "The Comp ID",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "address",
              //         label: "Address",
              //         isEditable: false,
              //         tooltip: "The comp Address",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "longitude",
              //         label: "Longitude",
              //         isEditable: false,
              //         tooltip: "The comp Longitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "latitude",
              //         label: "Latitude",
              //         isEditable: false,
              //         tooltip: "The comp Latitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //     ],
              //   },
              //   {
              //     type: "media",
              //     mediaInfos: [
              //       {
              //         title: "<b>Mexican Fan Palm</b>",
              //         type: "image", // Autocasts as new ImageMediaInfo object
              //         caption: "tree species",
              //         // Autocasts as new ImageMediaInfoValue object
              //         value: {
              //           sourceURL:
              //             "https://www.sunset.com/wp-content/uploads/96006df453533f4c982212b8cc7882f5-800x0-c-default.jpg",
              //         },
              //       },
              //     ],
              //   },
              // ],
            },
          });
          let graphicsLayer = new GraphicsLayer({
            title: "Polygons",
          });

          graphicsLayer.add(polygon);

          this.map.layers.add(graphicsLayer);
        });
      });
    },
    addGraphicsLayerPolygons2() {
      //   let vm = this;
      loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"], {
        css: true,
      }).then(([GraphicsLayer, Graphic]) => {
        Shapes.municipios(34025050297).then((response) => {
          console.log(response.data);
          let polygon = new Graphic({
            geometry: {
              type: "polygon",
              rings: response.data.geom.coordinates,
            },
            symbol: {
              type: "simple-fill",
              style: "solid",

              color: [166, 44, 43, 0.8],
              outline: {
                color: [166, 44, 43, 0.8],
                width: 1,
              },
            },
            popupTemplate: {
              title: `${response.data.natlevname}: ${response.data.nameunit}`,
              //   content: `<ul> <li>${place.address}</li><li>${place.longitude}</li><li>${place.latitude}</li> </ul>`,
              // content: [
              //   {
              //     type: "fields",
              //     fieldInfos: [
              //       {
              //         fieldName: "id",
              //         label: "ID",
              //         isEditable: false,
              //         tooltip: "The Comp ID",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "address",
              //         label: "Address",
              //         isEditable: false,
              //         tooltip: "The comp Address",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "longitude",
              //         label: "Longitude",
              //         isEditable: false,
              //         tooltip: "The comp Longitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "latitude",
              //         label: "Latitude",
              //         isEditable: false,
              //         tooltip: "The comp Latitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //     ],
              //   },
              //   {
              //     type: "media",
              //     mediaInfos: [
              //       {
              //         title: "<b>Mexican Fan Palm</b>",
              //         type: "image", // Autocasts as new ImageMediaInfo object
              //         caption: "tree species",
              //         // Autocasts as new ImageMediaInfoValue object
              //         value: {
              //           sourceURL:
              //             "https://www.sunset.com/wp-content/uploads/96006df453533f4c982212b8cc7882f5-800x0-c-default.jpg",
              //         },
              //       },
              //     ],
              //   },
              // ],
            },
          });
          let graphicsLayer = new GraphicsLayer({
            title: "Polygons",
          });

          graphicsLayer.add(polygon);

          this.map.layers.add(graphicsLayer);
        });
      });
    },
    addGraphicsLayerPolygons3() {
      //   let vm = this;
      loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"], {
        css: true,
      }).then(([GraphicsLayer, Graphic]) => {
        Shapes.seccionesCensales(2807904009).then((response) => {
          console.log(response.data);
          let polygon = new Graphic({
            geometry: {
              type: "polygon",
              rings: response.data.geom.coordinates,
            },
            symbol: {
              type: "simple-fill",
              style: "solid",

              color: [166, 44, 43, 0.8],
              outline: {
                color: [166, 44, 43, 0.8],
                width: 1,
              },
            },
            popupTemplate: {
              title: `${response.data.natlevname}: ${response.data.nameunit}`,
              //   content: `<ul> <li>${place.address}</li><li>${place.longitude}</li><li>${place.latitude}</li> </ul>`,
              // content: [
              //   {
              //     type: "fields",
              //     fieldInfos: [
              //       {
              //         fieldName: "id",
              //         label: "ID",
              //         isEditable: false,
              //         tooltip: "The Comp ID",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "address",
              //         label: "Address",
              //         isEditable: false,
              //         tooltip: "The comp Address",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "longitude",
              //         label: "Longitude",
              //         isEditable: false,
              //         tooltip: "The comp Longitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //       {
              //         fieldName: "latitude",
              //         label: "Latitude",
              //         isEditable: false,
              //         tooltip: "The comp Latitude",
              //         visible: true,
              //         format: null,
              //         stringFieldOption: "text-box",
              //       },
              //     ],
              //   },
              //   {
              //     type: "media",
              //     mediaInfos: [
              //       {
              //         title: "<b>Mexican Fan Palm</b>",
              //         type: "image", // Autocasts as new ImageMediaInfo object
              //         caption: "tree species",
              //         // Autocasts as new ImageMediaInfoValue object
              //         value: {
              //           sourceURL:
              //             "https://www.sunset.com/wp-content/uploads/96006df453533f4c982212b8cc7882f5-800x0-c-default.jpg",
              //         },
              //       },
              //     ],
              //   },
              // ],
            },
          });
          let graphicsLayer = new GraphicsLayer({
            title: "Polygons",
          });

          graphicsLayer.add(polygon);

          this.map.layers.add(graphicsLayer);
        });
      });
    },

    addWidgetSearch() {
      loadModules(["esri/widgets/Search"], { css: true }).then(([Search]) => {
        this.search = new Search({
          view: this.view,
          container: "mapWidgets",
        });

        this.search.goToOverride = function(view, goToParams) {
          console.log(goToParams.target.target.geometry.centroid.longitude);
          console.log(goToParams.target.target.geometry.centroid.latitude);
          //   console.log(goToParams.options);
          alert(
            `${goToParams.target.target.geometry.centroid.longitude} - ${goToParams.target.target.geometry.centroid.latitude}`
          );
          return view.goTo(goToParams.target, goToParams.options);
        };
        this.view.ui.add(this.search, {
          position: "top-left",
          container: "mapWidgets",
        });
      });
    },
    addWidgetZoom() {
      loadModules(["esri/widgets/Zoom"], { css: true }).then(([Zoom]) => {
        let zoom = new Zoom({
          view: this.view,
          layout: "horizontal",
        });

        this.view.ui.add(zoom, {
          position: "top-left",
        });
      });
    },
    addWidgetLayerList() {
      loadModules(["esri/widgets/LayerList"], { css: true }).then(
        ([LayerList]) => {
          var layerList = new LayerList({
            view: this.view,
          });
          this.view.ui.add(layerList, {
            position: "top-right",
          });
        }
      );
    },
    addWMSEstacionesServicio() {
      loadModules(["esri/layers/WMSLayer"], { css: true }).then(
        ([WMSLayer]) => {
          const layer = new WMSLayer({
            url:
              "https://geoportalgasolineras.es/cgi-bin/mapserv?request=GetCapabilities&service=WMS",
          });
          layer.load().then(() => {
            const names = layer.allSublayers
              .filter((sublayer) => !sublayer.sublayers) // Non-grouping layers will not have any "sublayers".
              .map((sublayer) => sublayer.name);
            console.log("Names of all child sublayers", names.join());
          });
        }
      );
    },
    // configEsri() {
    //   loadModules(["esri/config"]).then(([esriConfig]) => {
    //     esriConfig.request.interceptors.push({
    //       before: function() {},
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     });
    //   });
    // },
    createMap() {
      loadModules(["esri/Map"], { css: true }).then(([ArcGISMap]) => {
        this.map = new ArcGISMap({
          basemap: this.basemap,
        });
      });
      // MAP PROPERTIES
      //   console.log("allLayers", this.map.allLayers);
      //   console.log("allTables", this.map.allTables);
      //   console.log("basemap", this.map.basemap);
      //   console.log("ground", this.map.ground);
      //   console.log("layers", this.map.layers);
      //   console.log("tables", this.map.tables);
    },
    createMapView() {
      loadModules(["esri/views/MapView"], { css: true }).then(([MapView]) => {
        this.view = new MapView({
          // container: this.$el,
          container: "viewDiv",
          map: this.map,
          center: [-3.70256, 40.4165],
          zoom: 6,
          ui: {
            components: ["attribution"],
          },
        });

        // Event when loaded
        this.view.when(function() {
          //   console.log("scale", this.view.scale);
          //   console.log("center", this.view.center);
          //   console.log("zoom", this.view.zoom);
          //   console.log("ground", this.view.ground);
          //   console.log("layers", this.view.layers);
          //   console.log("tables", this.view.tables);
        });

        // this.view.popup.autoOpenEnabled = false;
        // MapView on Click
        // let view = this.view;
        // this.view.on("click", function(event) {
        //   //   alert("View has been Clicked!");
        //   console.log(event);
        //   view.popup.location = event.mapPoint;
        //   view.popup.open({
        //     // Set the popup
        //     title: "Asset Detail",
        //     content: `<strong>Longitude:</strong>${event.mapPoint.longitude} <br><strong>Latitude:</strong>${event.mapPoint.latitude}`,
        //   });
        // });

        // MapView on Double-Click
        this.view.on("double-click", function() {});
      });
    },

    removeBasemapGallery() {
      this.view.ui.remove(this.basemapGallery);
    },

    zoomIn() {
      this.view.zoom = 14;
      this.view.center = [-3.70256, 40.4165];
    },
    zoomOut() {
      this.view.zoom = 6;
      this.view.center = [-3.70256, 40.4165];
    },

    loadSeccionesCensales() {
      Shapes.seccionesCensales(2807904009).then((response) => {
        this.polygons = response.data.geom;
        this.drawPolygons();
      });
    },
    loadProvince() {
      Shapes.provincias("28").then((response) => {
        this.polygons = response.data.geom.coordinates[1];
        this.drawPolygons();
      });
    },
    loadCCAA() {
      Shapes.ccaa("07").then((response) => {
        this.polygons = response.data.geom;
        this.drawPolygons();
      });
    },

    drawPolygons() {
      loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"], {
        css: true,
      })
        .then(([GraphicsLayer, Graphic]) => {
          let graphicsLayer = new GraphicsLayer();
          this.map.add(graphicsLayer);
          let polygon = {
            type: "polygon",
            rings: this.polygons,
          };

          let simpleFillSymbol = {
            type: "simple-fill",
            color: [227, 139, 79, 0.8], // orange, opacity 80%
            outline: {
              color: [255, 255, 255],
              width: 1,
            },
          };

          var polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
          });

          graphicsLayer.add(polygonGraphic);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setPageView(view) {
      this.$store.dispatch("realEstate/setPageView", view);
    },
  },
  mounted() {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    // this.configEsri();
    this.createMap();
    this.createMapView();
    // this.addBasemapToggle();
    // this.addBasemapGallery();
    this.addWidgetSearch();
    this.addWidgetZoom();
    this.addWidgetLayerList();
    // this.addWMSEstacionesServicio();
  },
  beforeDestroy() {
    if (this.view) {
      // destroy the map view
      this.view.destroy();
    }
  },
};
</script>

<style scoped>
div {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
