<template>
  <Layout>
    <v-container fluid class="full-height" slot="main">
      <v-container>
        <v-toolbar dense class="warning">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <h2>Cadastre Street Browser</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-row>
          <v-col>
            <v-card elevation="6">
              <v-card-title>Search Options</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row justify="center" dense>
                    <v-col cols="3">
                      <Combobox
                        outlined
                        v-model="userInputs.provincia"
                        :items="provincias"
                        item-value="codigo_ine"
                        item-text="nombre"
                        label="Provincia"
                        @input="getMunicipios"
                      />
                    </v-col>
                    <v-col cols="4">
                      <Combobox
                        outlined
                        v-model="userInputs.municipio"
                        :items="municipios.results"
                        item-value="codigo_ine"
                        item-text="nombre"
                        label="Municipio"
                        @input="getVias"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center" dense>
                    <v-col cols="7">
                      <v-text-field
                        dense
                        outlined
                        label="Search"
                        v-model="userInputs.search"
                        append-icon="mdi-magnify"
                        @click:append="searchVias"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-title>Search Results</v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-overlay :value="isBusy.search" :absolute="false">
                      <v-progress-circular
                        v-if="isBusy.search"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                    <v-col>
                      <v-pagination
                        v-if="pageCount > 1"
                        v-model="userInputs.page"
                        class="my-4"
                        :length="pageCount"
                        @input="navigateVias"
                      ></v-pagination>
                      <DataTableVias
                        title="Vias"
                        :tableData="vias"
                        :fields="[
                          'tipo_via',
                          'nombre_via',
                          'municipio_nombre',
                          'provincia_nombre',
                          'summary',
                          'getData',
                        ]"
                        :isBusy="false"
                      >
                      </DataTableVias>
                    </v-col>
                  </v-row>
                  <!-- <v-row justify="center">
                    <v-col cols="9"> -->
                  <!-- </v-col>
                  </v-row> -->
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="6">
              <!-- <v-data-table> </v-data-table> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-row class="ma-0 pa-0"> <v-toolbar dense></v-toolbar></v-row> -->
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/databank/catastro/Layout.vue";
import Combobox from "@/components/base/Combobox.vue";
import DataTableVias from "@/components/databank/catastro/DataTableCalles.vue";

// import Sidebar from "@/components/databank/catastro/Sidebar.vue";
// import Btn from "@/components/base/buttons/Btn.vue";
// import TextField from "@/components/base/TextField.vue";

// import { saveAs } from "file-saver";

let storeModule = "catastro";

export default {
  components: {
    Layout,
    // Btn,
    Combobox,
    DataTableVias,

    // Sidebar,
    // TextField,
  },
  data() {
    return {
      isBusy: {
        search: false,
      },
      userInputs: {
        provincia: null,
        municipio: null,
        search: null,
        page: 1,
      },
      drawer: true,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      mini: true,
    };
  },
  methods: {
    getMunicipios() {
      this.$store.dispatch(
        `${storeModule}/getMunicipios`,
        this.userInputs.provincia
      );
    },
    async getVias() {
      this.isBusy.search = true;
      let params = {
        provincia: this.userInputs.provincia,
        municipio: this.userInputs.municipio,
      };
      await this.$store.dispatch(`${storeModule}/getVias`, params);
      this.isBusy.search = false;
    },
    async searchVias() {
      this.isBusy.search = true;
      let params = {
        q: this.userInputs.search,
      };
      if (this.userInputs.provincia) {
        params.provincia = this.userInputs.provincia;
      }
      if (this.userInputs.municipio) {
        params.municipio = this.userInputs.municipio;
      }
      await this.$store.dispatch(`${storeModule}/searchVias`, params);
      this.isBusy.search = false;
    },
    navigateVias() {
      alert(this.userInputs.page);
    },
  },
  computed: {
    provincias() {
      return this.$store.getters[`${storeModule}/provincias`];
    },
    municipios() {
      return this.$store.getters[`${storeModule}/municipios`];
    },
    vias() {
      return this.$store.getters[`${storeModule}/vias`];
    },
    pageCount() {
      if (this.vias) {
        return Math.ceil(this.vias.count / 50);
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.$store.dispatch(`${storeModule}/getProvincias`);
  },
};
</script>

<style></style>
