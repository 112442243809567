import News from "@/api/news.js";

export default {
  namespaced: true,
  state: {
    articles: [],
  },
  getters: {},
  mutations: {
    getClientArticles(state, payload) {
      state.articles = payload;
    },
  },
  actions: {
    postArticle(context, articleForm) {
      context;
      articleForm;
    },
    getClientArticles(context, clientId) {
      context;
      return News.clientNews({ clients: clientId });
      // .then((response) => {
      //   context.commit("getClientArticles", response.data);
      // })
      // .catch((error) => {
      //   let snackbar = { text: `Client News Search: ${error}` };
      //   context.dispach("errorSnackbar", snackbar);
      // });
    },
  },
};
