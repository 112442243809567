<template>
  <Layout>
    <v-container slot="main">
      <v-row dense justify="center">
        <v-col align="center">
          <h1 class="marco--text">User Tasks</h1>
          <v-btn @click="getUserTasks">Refresh</v-btn>
        </v-col>
      </v-row>
      <v-row dense v-if="Object.keys(tasks).length === 0" justify="center">
        <v-col align="center">
          <h2 class="marco--text">There are currently no task results!</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-tabs dense>
            <v-tab
              v-for="(section, index) in Object.keys(tasks)"
              :key="index"
              >{{ section }}</v-tab
            >
            <v-tab-item
              v-for="(section, index) in Object.keys(tasks)"
              :key="index"
            >
              <v-card>
                <v-card-text>
                  <v-data-table
                    dense
                    show-select
                    :single-select="false"
                    v-model="selectedTasks"
                    :items="tasks[section]"
                    item-key="task_id"
                    :headers="headers.tasks"
                    :hide-default-footer="false"
                    :items-per-page="100"
                  >
                    <template v-slot:top>
                      <v-btn @click="dialog.delete = true">Delete</v-btn>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip x-small :color="getColor(item.status)">{{
                        item.status
                      }}</v-chip>
                    </template>
                    <template v-slot:item.info="{ item }">
                      <v-icon small color="marco" @click="getTaskInfo(item)"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <template v-slot:item.result="{ item }">
                      <p v-if="item.result">{{ item.result.current }}%</p>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col align="center">
          <v-dialog v-model="dialog.info" width="60vw" height="60vh">
            <v-card>
              <v-card-title class="marco white--text"
                >Task: {{ task.task_id }}</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-tabs>
                  <v-tab>Arguments</v-tab>
                  <v-tab v-if="task.traceback">Traceback</v-tab>
                  <v-tab-item>
                    <v-container>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Arguments"
                            :value="task.task_args"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Keyword Arguments"
                            :value="task.task_kwargs"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item v-if="task.traceback">
                    <v-container>
                      <v-row dense>
                        <v-col>
                          <v-textarea
                            outlined
                            height="250"
                            readonly
                            :value="task.traceback"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="error">Delete</v-btn>
                <v-btn text color="marco" @click="dialog = false">Ok</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog.delete" width="20vw" height="60vh">
            <v-card>
              <v-card-title class="marco--text">
                Delete Tasks?
              </v-card-title>

              <v-card-text>
                <h3>
                  Are you sure you wish to delete the seleted task results?
                </h3>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="dialog.delete = false">
                  Cancel
                </v-btn>
                <v-btn color="success" text @click="deleteTasks">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/user-tasks/Layout.vue";
import TaskAPI from "@/api/user-tasks.js";
export default {
  components: { Layout },
  data() {
    return {
      dialog: { info: false, delete: false },
      task: {},
      tasks: {},
      headers: {
        tasks: [
          { text: "Task", value: "name" },
          { text: "ID", value: "task_id" },
          { text: "Created", value: "created" },
          { text: "Done", value: "done" },
          { text: "Status", value: "status" },
          { text: "Result", value: "result" },
          { text: "Info", value: "info" },
          //   { text: "Args", value: "task_args" },
          //   { text: "Kwargs", value: "task_kwargs" },
          //   { text: "Result", value: "result" },
        ],
      },
      selectedTasks: [],
      statusColors: {
        SUCCESS: "success",
        FAILURE: "error",
        PROGRESS: "warning",
      },
    };
  },
  methods: {
    deleteTasks() {
      let form = new FormData();
      let task_ids = [];
      this.selectedTasks.forEach((element) => {
        task_ids.push(element.id);
        form.append("id", element.id);
      });
      TaskAPI.delete(form).then((response) => {
        this.dialog.delete = false;
        this.getUserTasks();
        this.$store.dispatch("snackbarSuccess", response.data);
      });
    },
    getColor(status) {
      return this.statusColors[status];
    },
    getUserTasks() {
      TaskAPI.getUserTasks().then((response) => {
        this.tasks = response.data;
      });
    },
    getTaskInfo(task) {
      this.task = task;
      this.dialog.info = true;
    },
  },
  mounted() {
    this.getUserTasks();
  },
};
</script>

<style></style>
