<template>
  <Layout>
    <v-container fluid slot="main">
      <v-toolbar dense class="warning" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title
          ><h2>
            Instituto Nacional de Estadística (INE) Wizard
          </h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row>
        <v-col>
          INE Variables
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" cols="6">
          <v-card>
            <v-card-title class="marco white--text">Variables</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-overlay :absolute="true" :value="isBusy.variables">
                      <v-progress-circular
                        indeterminate
                        color="marco"
                      ></v-progress-circular>
                    </v-overlay>
                    <v-data-table
                      dense
                      fixed-header
                      show-select
                      single-select
                      height="300px"
                      :items="apiData.variables ? apiData.variables : []"
                      :headers="headers.variables"
                      :items-per-page="-1"
                      hide-default-footer
                      v-model="userSelections.variable"
                      @input="onSelectVariable"
                      :search="search.variables"
                    >
                      <template v-slot:top>
                        <v-text-field
                          label="Search Variables"
                          v-model="search.variables"
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col align="center" cols="6">
          <v-card>
            <v-card-title class="marco white--text"
              >Variable Values</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-overlay :absolute="true" :value="isBusy.valoresVariable">
                      <v-progress-circular
                        indeterminate
                        color="marco"
                      ></v-progress-circular>
                    </v-overlay>
                    <v-data-table
                      dense
                      fixed-header
                      height="300px"
                      :items="
                        apiData.valoresVariable ? apiData.valoresVariable : []
                      "
                      :headers="headers.valoresVariable"
                      :items-per-page="-1"
                      hide-default-footer
                      :search="search.variableValues"
                    >
                      <template v-slot:top>
                        <v-text-field
                          label="Search Variable Values"
                          v-model="search.variableValues"
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          INE Operations
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center" cols="6">
          <v-card>
            <v-card-title class="marco white--text"
              >INE Operations</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                      dense
                      fixed-header
                      show-select
                      single-select
                      height="300px"
                      :items="ineOperationsList ? ineOperationsList : []"
                      :headers="headers.ineOperations"
                      :items-per-page="-1"
                      hide-default-footer
                      v-model="userSelections.operations"
                      @input="onSelectOperation"
                      :search="search.operations"
                    >
                      <template v-slot:top>
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              label="Search Operations"
                              v-model="search.operations"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              v-model="userInputs.apiQueryPageStart"
                              type="number"
                              label="Page Number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:item.download="{ item }">
                        <v-icon
                          color="green"
                          @click="downloadOperacionDatasets(item)"
                          >mdi-download-circle-outline</v-icon
                        >
                      </template>
                      <template v-slot:item.valoresVariables="{ item }">
                        <v-icon color="marco" @click="getValoresVariables(item)"
                          >mdi-variable</v-icon
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col align="center" cols="6">
          <v-card>
            <v-card-title class="marco white--text"
              >Variables Operacion</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                      dense
                      fixed-header
                      height="370px"
                      :items="
                        apiData.variablesOperacion
                          ? apiData.variablesOperacion
                          : []
                      "
                      :headers="headers.variablesOperacion"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  {{ apiData.variablesOperacion }}
                </v-row> -->
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          INE Datasets
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="marco white--text">INE Datasets</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-overlay :absolute="true" :value="isBusy.seriesOperacion">
                      <v-progress-circular
                        indeterminate
                        color="marco"
                      ></v-progress-circular>
                    </v-overlay>

                    <v-data-table
                      dense
                      fixed-header
                      show-select
                      single-select
                      v-model="userSelections.dataset"
                      height="500px"
                      :items="
                        apiData.seriesOperacion
                          ? apiData.seriesOperacion.results
                          : []
                      "
                      :headers="headers.seriesOperacion"
                      :items-per-page="-1"
                      hide-default-footer
                      @input="onSelectDataset"
                    >
                      <template v-slot:top>
                        <v-row justify="center">
                          <v-col cols="6">
                            <v-text-field
                              label="Search Datasets"
                              v-model="search.datasets"
                              append-icon="mdi-magnify"
                              @click:append="searchDatasets"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col>
                            <v-spacer></v-spacer>
                            <v-pagination
                              v-model="userSelections.datasetPage"
                              :length="numPages"
                              :total-visible="7"
                              circle
                              small
                              @input="navigateDatasets"
                            >
                            </v-pagination>
                            <v-spacer></v-spacer>
                            <p v-if="apiData.seriesOperacion">
                              Results: {{ apiData.seriesOperacion.count }}
                            </p>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:item.chart="{ item }">
                        <v-icon @click="viewChart(item)">mdi-chart-line</v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title class="marco white--text"
              >Dataset Variables</v-card-title
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                      dense
                      fixed-header
                      height="635px"
                      :items="
                        apiData.valoresVariableSerie
                          ? apiData.valoresVariableSerie
                          : []
                      "
                      :headers="headers.valoresVariableSerie"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-dialog v-model="chartDialog.dialog" max-width="700px">
          <v-card>
            <v-card-title class="marco white--text">{{
              chartDialog.title
            }}</v-card-title>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col justify="center" align="center">
                    <IneColumnChart
                      :hcTitle="null"
                      :serie="chartDialog.serie"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="marco" dark @click="chartDialog.dialog = false"
                >Ok</v-btn
              >
              <v-btn
                color="success"
                dark
                @click="downloadDataset(chartDialog.serie)"
                >Download</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "@/components/databank/ine/Layout.vue";

import IneAPI from "@/api/ine";

import IneColumnChart from "@/components/databank/ine/IneColumnChart.vue";

export default {
  components: {
    Layout,
    IneColumnChart,
    // Btn,
    // Combobox,
    // TextField,
    // DataTable,
    // IneDatasetTable,
    // IneDatasetSelectionsTable,
  },
  data() {
    return {
      chartDialog: {
        dialog: false,
        serie: null,
        title: null,
      },
      tab: 0,
      tabs: ["Search", "Selections", "Stat Groups"],

      operations: null,
      columns: ["cod", "nombre_eng", "view"],
      selectedDatasets: [],
      userSelections: {
        operations: null,
        variable: null,
        datasets: null,
        dataset: null,
        datasetPage: 1,
      },
      userInputs: {
        searchStr: null,
        apiQueryPageStart: 1,
      },
      search: {
        datasets: null,
        operations: null,
        variables: null,
        variableValues: null,
      },
      apiData: {
        seriesOperacion: null,
        variables: null,
        variablesOperacion: null,
        valoresVariable: null,
        valoresVariableSerie: null,
      },
      isBusy: {
        seriesOperacion: false,
        variables: false,
        variablesOperacion: false,
        valoresVariable: false,
        valoresVariableSerie: false,
      },
      headers: {
        seriesOperacion: [
          { text: "Code", value: "cod" },
          { text: "Name", value: "nombre" },
          { text: "View", value: "chart" },
        ],
        variables: [
          { text: "ID", value: "id" },
          { text: "Name", value: "nombre" },
        ],
        variablesOperacion: [
          { text: "ID", value: "id" },
          { text: "Code", value: "codigo" },
          { text: "Name", value: "nombre" },
        ],
        valoresVariable: [
          { text: "Variable", value: "fk_variable" },
          { text: "ID", value: "id_ine" },
          { text: "Name", value: "nombre" },
        ],
        valoresVariableSerie: [
          { text: "ID Variable", value: "variable.id" },
          { text: "Variable", value: "variable.nombre" },
          { text: "ID Valor", value: "value.id_ine" },
          { text: "Valor", value: "value.nombre" },
        ],
        ineOperations: [
          { text: "Code", value: "codigo" },
          { text: "Name", value: "nombre" },
          { text: "Download", value: "download" },
          { text: "Valores Variables", value: "valoresVariables" },
        ],
      },
    };
  },
  methods: {
    downloadOperacionDatasets(operacion) {
      let params = {
        operacion: operacion.codigo,
        start_page: this.userInputs.apiQueryPageStart,
      };
      IneAPI.loader("series-operacion", params).then((response) => {
        console.log(response);
      });
    },
    getValoresVariables(operacion) {
      let params = {
        operacion: operacion.codigo,
      };
      IneAPI.loader("valores-variables-series-operacion", params).then(
        (response) => {
          console.log(response);
        }
      );
    },
    loader(task, params) {
      IneAPI.loader(task, params);
    },
    async onSelectOperation() {
      let operacion = this.userSelections.operations[0].codigo;
      IneAPI.variablesOperacion(operacion).then((response) => {
        this.apiData.variablesOperacion = response.data.variable;
      });

      this.isBusy.seriesOperacion = true;
      await IneAPI.seriesOperacion(operacion).then((response) => {
        this.apiData.seriesOperacion = response.data;
      });
      this.isBusy.seriesOperacion = false;
    },
    async onSelectVariable() {
      this.isBusy.valoresVariable = true;
      let variable = this.userSelections.variable[0].id;
      await IneAPI.valoresVariable(variable).then((response) => {
        this.apiData.valoresVariable = response.data;
      });
      this.isBusy.valoresVariable = false;
    },
    async onSelectDataset() {
      this.isBusy.valoresVariableSerie = true;
      let variable = this.userSelections.dataset[0].id;

      await IneAPI.seriesVarVals(variable).then((response) => {
        this.apiData.valoresVariableSerie = response.data;
      });
      this.isBusy.valoresVariableSerie = false;
    },
    async getVariables() {
      this.isBusy.variables = true;
      await IneAPI.variables().then((response) => {
        this.apiData.variables = response.data;
      });
      this.isBusy.variables = false;
    },
    getOperations() {
      this.$store.dispatch("databank/getOperations");
    },
    async searchDatasets() {
      let params = {};
      params.ine_operations = this.userSelections.operations[0].codigo;
      if (this.search.datasets) {
        params.q = this.search.datasets;
      }
      this.isBusy.seriesOperacion = true;
      await IneAPI.datasets(params).then((response) => {
        this.apiData.seriesOperacion = response.data;
      });
      this.isBusy.seriesOperacion = false;
    },
    async navigateDatasets(pageNum = null) {
      //   let form = new Form();
      //   form.append("ine_operations", this.userSelections.operations[0].codigo);

      //   if (this.userInputs.searchStr) {
      //     form.append("q", this.userInputs.searchStr);
      //   }
      //   if (pageNum) {
      //     form.append("page", pageNum);
      //   }
      let params = {};
      params.ine_operations = this.userSelections.operations[0].codigo;
      if (this.search.datasets) {
        params.q = this.search.datasets;
      }
      if (pageNum) {
        params.page = pageNum;
      }

      this.isBusy.seriesOperacion = true;
      await IneAPI.datasets(params).then((response) => {
        this.apiData.seriesOperacion = response.data;
      });
      this.isBusy.seriesOperacion = false;
    },
    clearSelectedDatasets() {
      this.selectedDatasets = [];
    },
    downloadDataset(serie) {
      let url = `http://localhost/ine/dataset/download/${serie}`;
      window.location.assign(url);
    },
    downloadDatasets() {
      let codList = [];
      this.selectedDatasets.forEach((element) => {
        codList.push(element.cod);
      });
      let url = `http://localhost/ine/datasets/download/?cod_list=${codList}`;
      window.location.assign(url);
    },
    viewChart(item) {
      this.chartDialog.title = item.nombre;
      this.chartDialog.serie = item.cod;
      this.chartDialog.dialog = true;
    },
  },

  computed: {
    ineOperationsList() {
      return this.$store.state.databank.ineOperationsList;
    },
    numPages() {
      if (this.apiData.seriesOperacion) {
        return Math.ceil(this.apiData.seriesOperacion.count / 100);
      } else {
        return 0;
      }
    },
  },
  created() {
    this.getOperations();
    this.getVariables();
  },
};
</script>

<style></style>
