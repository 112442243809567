import { render, staticRenderFns } from "./Combobox.vue?vue&type=template&id=2d17b1e8&scoped=true&"
import script from "./Combobox.vue?vue&type=script&lang=js&"
export * from "./Combobox.vue?vue&type=script&lang=js&"
import style0 from "./Combobox.vue?vue&type=style&index=0&id=2d17b1e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d17b1e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
